import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import { Link } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate, useParams } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Form } from "react-bootstrap";
import moment from "moment";
import SetupMenu from "./SetupMenu";
import { scrollToTop } from "../Shared/ScrollOnTop";

function EditSettings(props) {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [validated, setValidated] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [Setting, setSetting] = useState({});

  let params = useParams();
  const [SettingID, setSettingID] = useState(params.id);

  let navigate = useNavigate();

  useEffect(() => {
    document.title = "Add/Update Settings";

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      ID: SettingID,
    };
    //console.log(data);
    var api_config = {
      method: "post",
      url: config.base_url + "Setting/GetSettingByID",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        if (response.data.status_code == 1) {
          setSetting(response.data.Setting);
        }

        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        toast.error("Network Error..", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setLoading(true);
      const data = {
        AccessKey: AccessKey,
        UserID: UserID,
        MemberType: MemberType,
        ID: SettingID,
        Name: event.target.txtSettingName.value,
        Value: event.target.txtSettingValue.value,
      };

     //console.log(data);

      var api_config = {
        method: "post",
        url: config.base_url + "Setting/UpdateSetting",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(api_config)
        .then(function (response) {
          //console.log(response.data);
          if (response.data.status_code == 1) {
            toast.success(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }

          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          toast.error("Network Error..", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }

    setValidated(true);
  };

  return (
    <>
      <SetupMenu />
      <ToastContainer />
      <div className="page-head">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/Dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    {" "}
                    <Link to="/Setup/SetupDashboard">Settings</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Edit Settings
                  </li>
                </ol>
              </nav>
              <div className="page-title">Edit Settings</div>
            </div>
            <div className="col-md-6">
              <div className="page-right-actions">
                <Link
                  to="/Setup/ManageSettings"
                  className="button button-white button-icon"
                >
                  Manage Settings
                  <i className="ri-list-check"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-content">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body p-md-4">
                  <Form
                    noValidate
                    validated={validated}
                    onSubmit={handleSubmit}
                  >
                    <div className="row align-items-center">
                      <div className="col-md-4">
                        <div className="form-group mb-3">
                          <label >Setting Name:</label>
                          <input
                            type="text"
                            defaultValue={Setting.Name}
                            className="form-control"
                            disabled
                            name="txtSettingName"
                          />
                        </div>
                      </div>
                      <div className="col-md-5">
                        <div className="form-group mb-3">
                          <label  className="">
                            Setting Value:
                          </label>
                          <input
                            type="text"
                            defaultValue={Setting.Value}
                            className="form-control"
                            required
                            name="txtSettingValue"
                          />
                        </div>
                      </div>

                      <div className="col-md-2 text-end">
                        <div className="form-group ">
                          {loading ? (
                            <Spinner animation="border" role="status"></Spinner>
                          ) : (
                            <button
                              type="submit"
                              value="Save"
                              className="button button-primary w-100 "
                            >
                              Update
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditSettings;
