import React, { useEffect, useState } from "react";
import YearsDropDown from "../Shared/YearsDropDown";
import { NavLink } from "react-router-dom";
import MonthsDropDown from "../Shared/MonthsDropDown";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import moment from "moment";
import { scrollToTop } from "../Shared/ScrollOnTop";
import EmployeeDropDown from "../Shared/EmployeeDropDown";
import Select from "react-select";
import EmployeeDropDownSearch from "../Shared/EmployeeDropDownSearch";
import EmployeeDropDownSearch_Retired from "../Shared/EmployeeDropDownSearch_Retired";
import TeacherMenu from "./TeacherMenu";

function ViewPaySlips(props) {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [Code, setCode] = useState(localStorage.getItem("ID"));
  const [Month, setMonth] = useState(moment().format("MM"));
  const [Year, setYear] = useState(moment().format("YYYY"));
  const [EmployeeId, setEmployeeId] = useState(localStorage.getItem("ID"));
  const [EmployeeID, setEmployeeID] = useState(localStorage.getItem("ID"));
  const [EmployeeName, setEmployeeName] = useState("");
  const [RetireMentStatus, setRetireMentStatus] = useState("Current");

  useEffect(() => {
    document.title = "Print PaySlips for Employees";
  }, []);

  const getEmployee = () => {
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      ID: Code,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Payroll/GetEmployeeByID",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        setEmployeeId(response.data.Employee.ID);
        let id = response.data.Employee.ID;
        let url =
          "/PayRoll/PaySlip/?month=" + Month + "&year=" + Year + "&ids=" + id;
        window.open(url, "_blank");
      })
      .catch(function (error) {});
  };



  return (
    <>
    <TeacherMenu />
      
      <div className="container body-content">
        <div className="page-head">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <NavLink to="/teacher/Dashboard">Home</NavLink>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Pay Slips
                    </li>
                  </ol>
                </nav>
                <div className="page-title">Pay Slips</div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-content">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body p-md-4">
                    <div className="row align-items-center">
                      
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Month</label>
                          <select
                            className="form-select"
                            onChange={(e) => setMonth(e.target.value)}
                          >
                            <option value="">Please Select</option>
                            <MonthsDropDown />
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Year</label>
                          <select
                            className="form-select"
                            onChange={(e) => setYear(e.target.value)}
                          >
                            <option value="">Please Select</option>
                            <YearsDropDown />
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2 offset-md-10 text-end">
                                                <button onClick={getEmployee} type="button" className="button button-primary w-100 mt-4">GO</button>
                                            </div>
                  </div>
                </div>
              </div>
           
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewPaySlips;
