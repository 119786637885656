import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate, useParams } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import SetupMenu from "../Setup/SetupMenu";

import moment from "moment";
import WingsDropDown from "../Shared/WingsDropDown";
import SessionsDropDown from "../Shared/SessionsDropDown";
import Pagination from "../General/Pagination";
import { scrollToTop } from "../Shared/ScrollOnTop";
import LibraryMenu from "./LibraryMenu";


const ReturnReport = () => {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );

  const [SessionID, setSessionId] = useState(
    localStorage.getItem("DefaultSession")
  );
  const [listOfLedger, setLedger] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);
  const [loading, setLoading] = useState(true);

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [StartDate, setStartDate] = useState(null);
  const [EndDate, setEndDate] = useState(null);

  useEffect(() => {
    setIsUpdated(true);
    document.title = "Return Report ";
    setLoading(true);
    const dashboardParms = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      PageNo: pageNo,
      PageSize: pageSize,
      StartDate: StartDate,
      EndDate: EndDate,
    };

    //console.log(dashboardParms);

    var api_config = {
      method: "post",
      url: config.base_url + "Library/GetReturnedReport",
      headers: {
        "Content-Type": "application/json",
      },
      data: dashboardParms,
    };

   //console.log(data)
    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        if (response.data.status_code != 0) {
          setLedger(response.data.ListofRecords);
          setTotalRecords(response.data.totalRecords);
          setIsUpdated(false);
          setLoading(false);
        } else {
          setLedger(null);
          setTotalRecords(0);
          setIsUpdated(false);

        }
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, [UserID, AccessKey, StartDate, EndDate, pageNo, pageSize]);

  const handlePageChange = (page) => {
    setPageNo(page);
  };

  const renderLedger = () => {
    if (listOfLedger != null && listOfLedger.length > 0) {
      let count = 1;
      return listOfLedger.map((item, index) => {
        return (
          <tr key={index}>
            <td>{count++ + (pageSize * (pageNo - 1))}</td>
            <td>{item.Name_Of_Book}</td>
            <td>{moment(item.Date_Of_Issue).format('DD/MM/YYYY')}</td>
            <td>{item.Student_Name}</td>
            <td>{item.Employee_Name}</td>
            <td>{item.Remarks}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="6">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };

  return (
    <>
      <ToastContainer />
      <LibraryMenu />

      <div className="page-head">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <NavLink to="/Dashboard">Dashboard</NavLink>
                  </li>
                  <li className="breadcrumb-item">
                    <NavLink href="/Library/LibraryDashboard">
                      Library
                    </NavLink>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Return Report
                  </li>
                </ol>
              </nav>
              <div className="page-title">Return Report</div>
            </div>
            <div className="col-md-6">
              <div className="page-right-actions">
                <NavLink
                  to="/Library/ViewLibrary"
                  className="button button-white button-icon"
                >
                  Manage Library
                  <i className="ri-file-list-2-line"></i>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="page-content">
        <div className="container">
          <div className="card mb-2">
            <div className="card-body p-md-4">
              <div className="row align-items-center">
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Start Date :</label>
                    <input
                      type="date"
                      className="form-control"
                      onChange={(e) => setStartDate(e.target.value)}
                      max={moment().format(config.date_format_input)}
                      min={moment()
                        .subtract(2, "months")
                        .format(config.date_format_input)}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label>End Date :</label>
                    <input
                      type="date"
                      className="form-control"
                      onChange={(e) => setEndDate(e.target.value)}
                      max={moment().format(config.date_format_input)}
                      required
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="counting mt-4 text-end">
                    <span className="">{totalRecords} </span> Records Found!
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body table-wrap">
              <table className="table table-theme table-hover">
                <thead>
                  <tr>
                    <th>Sr. No.</th>
                    <th>Book Name</th>
                    <th>Issuance Date</th>
                    <th>Student Name</th>
                    <th>Employee Name</th>
                    <th>Remarks</th>
                  </tr>
                </thead>

                <tbody>
                  {loading ? (
                    <tr>
                      <td className="text-center" colSpan="7">
                        <Spinner animation="border" role="status"></Spinner>
                      </td>
                    </tr>
                  ) : (
                    <>{renderLedger()}</>
                  )}
                </tbody>
              </table>
             
              {totalRecords > 10 && 
                  <div className="pagination-wrap">
                    <div className="row align-items-center">
                      <div className="col-md-2 form_default">
                        <div className="d-flex align-items-center">
                          <label className="me-2" style={{ fontSize: "15px" }}>
                            Page Size
                          </label>
                          <div className="form-group">
                            <select
                              className="form-select"
                              style={{ width: "100px" }}
                              onChange={(e) => {
                                setPageNo(1);
                                setPageSize(parseInt(e.target.value));
                              }}
                            >
                              <option value="10" selected>
                                10
                              </option>
                              <option value="20">20</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                              <option value="500">500</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <Pagination
                        pageNo={pageNo}
                        pageSize={pageSize}
                        totalRecords={totalRecords}
                        onPageChange={handlePageChange}
                      />
                    </div>
                  </div>
                }
              {/* {
                totalRecords > 10 &&
                <div className="pagination-wrap">

                  <div className="row align-items-center">
                    <div className="col-md-2 form_default">
                      <div className="d-flex align-items-center">
                        <label className="me-2" style={{ fontSize: "15px" }}>Page Size</label>
                        <div className="form-group">
                          <select className="form-select"
                            style={{ width: "100px" }}
                            onChange={(e) => {
                              setPageNo(1);
                              setPageSize(parseInt(e.target.value));


                            }}>
                            <option value="10" selected>10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="500">500</option>

                          </select>
                        </div>
                      </div>
                    </div>

                    <Pagination
                      pageNo={pageNo}
                      pageSize={pageSize}
                      totalRecords={totalRecords}
                      onPageChange={handlePageChange}
                    />
                  </div>
                </div>

              } */}
            </div>
          </div>
          <div className="card mt-md-3">
            <div className="card-body">
              <div className="row justify-content-end">
                <div className="col-md-2">
                  <NavLink
                    target="_blank"
                    to={`/Library/PrintReturnReport/?startDate=${StartDate}&EndDate=${EndDate}`}
                    className="button button-primary w-100"
                  >
                    Print This Report
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default ReturnReport;
