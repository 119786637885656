import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import LibraryMenu from './LibraryMenu';
import { NavLink } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { Form } from "react-bootstrap";
import { scrollToTop } from '../Shared/ScrollOnTop';

function AddBook(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [validated, setValidated] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [hasError, setHasError] = useState(false);
    const [loading, setLoading] = useState(false);

    document.title='Add Book'

    let navigate = useNavigate();

    const handleSubmit = (event) => {

        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            setLoading(true);
            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
MemberType: MemberType,
                Book_No: event.target.txtBookNo.value,
                Book_Name: event.target.txtBookName.value,
                Writer_Name: event.target.txtWriterName.value,
                Publisher_Name: event.target.txtPublisherName.value,
                Category: event.target.txtCategory.value,
                Edition: event.target.txtEdition.value,
                Total_Pages: event.target.txtTotalPages.value,
                Binding_Type: event.target.txtBindingType.value,
                Price: event.target.txtPrice.value,
                Purchasing_Source: event.target.txtPurchasingSource.value,
                Title_Image: "",
                Jild_No: event.target.txtJildNo.value,
                Current_Status: event.target.txtCurrentStatus.value,
                Remarks: event.target.txtRemarks.value,
            };

            ////console.log(data);

            var api_config = {
                method: 'post',
                url: config.base_url + 'Library/AddLibrary',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };



            axios(api_config)
                .then(function (response) {
                    ////console.log(response.data);
                    if (response.data.status_code == 1) {

                        toast.success(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });

                    }
                    else {
                        toast.error(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }

                    setLoading(false);


                })
                .catch(function (error) {
                    setLoading(false);
                    toast.error('Network Error..', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                });



        }

        setValidated(true);
    };


    return (
        <>
            <LibraryMenu />
            <ToastContainer />
            <div className="container body-content">
                <div className="page-head">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><NavLink to="/Dashboard">Dashboard</NavLink></li>
                                        <li className="breadcrumb-item"><NavLink href="/Library/LibraryDashboard">Library</NavLink></li>
                                        <li className="breadcrumb-item active" aria-current="page">Add Library Book</li>
                                    </ol>
                                </nav>
                                <div className="page-title">Add Library Book</div>
                            </div>
                            <div className="col-md-6">
                                <div className="page-right-actions">
                                    <NavLink to="/Library/ViewLibrary" className="button button-white button-icon">
                                        Manage Library
                                        <i className="ri-file-list-2-line"></i>
                                    </NavLink>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="page-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body card-form-wrap">
                                        <div className="">
                                            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                                <div className="row align-items-center">
                                                    <div className="col-md-3">
                                                        <div className="form-group mb-3">
                                                            <label  className=""> Book No:</label>
                                                            <input type="text" className="form-control" name="txtBookNo"
                                                                required />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group mb-3">
                                                            <label  className=""> Book Name:</label>
                                                            <input type="text" name="txtBookName" className="form-control" required />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group mb-3">
                                                            <label  className="">Writer Name:</label>
                                                            <input type="text" name="txtWriterName" className="form-control" required />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group mb-3">
                                                            <label  className="">
                                                                Publisher
                                                                Name:
                                                            </label>
                                                            <input type="text" name="txtPublisherName" className="form-control" required />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group mb-3">
                                                            <label  className="">
                                                                Enter Classification
                                                                No:
                                                            </label>
                                                            <input type="text" name="txtCategory" className="form-control" required />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group mb-3">
                                                            <label  className="">Edition:</label>
                                                            <input type="text" name="txtEdition" className="form-control" required />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group mb-3">
                                                            <label  className="">No of Pages:</label>
                                                            <input type="number" name="txtTotalPages" className="form-control" required />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group mb-3">
                                                            <label  className="">Binding Type:</label>
                                                            <select className="form-select" name="txtBindingType" required>
                                                                <option Selected Value="Not Available">Not Available</option>
                                                                <option Value="Tape Binding">Tape Binding</option>
                                                                <option Value="Hard Binding">Hard Binding</option>
                                                                <option Value="Paper Binding">Paper Binding</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group mb-3">
                                                            <label  className="">Book Price:</label>
                                                            <input type="number" name="txtPrice" className="form-control" required />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group mb-3">
                                                            <label  className=""> Jild No:</label>
                                                            <input type="text" name="txtJildNo" className="form-control" required />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group mb-3">
                                                            <label  className="">
                                                                Purchasing Source
                                                                :
                                                            </label>
                                                            <input type="text" name="txtPurchasingSource" className="form-control" required />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group mb-3">
                                                            <label  className="">Status:</label>
                                                            <select className="form-select" name="txtCurrentStatus" required>
                                                                <option Value="Available">Available</option>
                                                                <option Value="Issued">Issued</option>
                                                                <option Value="Lost">Lost</option>
                                                                <option Value="Disposed">Disposed</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label  className="">Remarks:</label>
                                                            <input type="text" name="txtRemarks" className="form-control" required />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3 offset-md-6 text-end mt-md-3">
                                                        <div className="form-group">
                                                            {
                                                                loading ?
                                                                    <Spinner animation="border" role="status"></Spinner>
                                                                    :
                                                                    <button type="submit" value="Save" className="button button-primary w-100">SAVE</button>
                                                            }

                                                        </div>
                                                    </div>
                                                </div>

                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}

export default AddBook;