import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import SessionsDropDown from "../Shared/SessionsDropDown";
import WingsDropDown from "../Shared/WingsDropDown";
import ClassesDropDown from "../Shared/ClassesDropDown";
import SectionDropDown from "../Shared/SectionDropDown";
import StudentGroupDropDown from "../Shared/StudentGroupDropDown";
import StudentStatusDropDown from "../Shared/StudentStatusDropDown";
import { toast, ToastContainer } from "react-toastify";
import { NavLink, Link } from "react-router-dom";
import moment from "moment";
import Pagination from "../General/Pagination";
import Dropdown from "react-bootstrap/Dropdown";
import { Form, PageItem } from "react-bootstrap";
import MonthsDropDown from "../Shared/MonthsDropDown";
import YearsDropDown from "../Shared/YearsDropDown";
import PayrollMenu from "./PayrollMenu";
import { scrollToTop } from "../Shared/ScrollOnTop";
import csvDownload from "json-to-csv-export";

function ManageLoanDeduction() {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  //const [DefaultSession, setDefaultSession] = useState(localStorage.getItem("DefaultSession"));

  const [showFilters, setShowFilters] = useState(true);

  const [loading, setLoading] = useState(false);
  const [listOfLedger, setLedger] = useState([]);
  const [validated, setValidated] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);

  const [loaddata, setLoaddata] = useState(false);

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);

  const [EmployeeCode, setEmployeeCode] = useState("");
  const [Month, setMonth] = useState(moment().format("M"));
  const [Year, setYear] = useState(moment().format("YYYY"));

  useEffect(() => {
    document.title = "Manage Loan Deductions";

    setIsUpdated(false);
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      pageNo: pageNo,
      pageSize: pageSize,
      SelectedMonth: Month,
      FYear: Year,
      EmployeeCode: EmployeeCode,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "LoanDeduction/GetLoanDeductions",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        setLedger(response.data.ListofRecords);
        setLoading(false);
        setTotalRecords(response.data.totalRecords);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, [pageNo, pageSize, isUpdated, Month, Year]);

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setYear(event.target.Year.value);
      setMonth(event.target.Month.value);
    }

    setValidated(true);
  };

  const handlePageChange = (page) => {
    setPageNo(page);
  };

  const handleSubmitForCvs = (event) => {
    //console.log(event);

    setLoaddata(true);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      pageNo: pageNo,
      pageSize: 10000,
      SelectedMonth: Month,
      FYear: Year,
      EmployeeCode: EmployeeCode,
    };

   //console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "LoanDeduction/GetLoanDeductions",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
  
        //console.log(response.data);
        if (response.data.status_code == 1) {
          if (response.data.totalRecords > 0) {
            let data = response.data.ListofRecords;
            let count = 0;
            const listOfLedgerNew = data.map(({ ...rest }) => ({
              Serial_No: count++ + pageSize * (pageNo - 1),
              ID: rest.ID,
              Name: rest.Name,
              Code: rest.Code,
              Amount: rest.Loan_Amount,
              Deduction: rest.Deduction,
              Description: rest.Description,
              Dated: moment(rest.Dated).format("DD/MM/YYYY"),

              Status: rest.Status,
            }));

            const dataToConvert = {
              data: listOfLedgerNew,
              filename: "ManageLoanDeduction",
              delimiter: ",",
              headers: [
                "Serial_No",
                "ID",
                "Name",
                "Code",
                "Amount",
                "Deduction",
                "Description",
                "Dated",
                "Status",
              ],
            };
            csvDownload(dataToConvert);

            setLoaddata(false);
          } else if(response.data.totalRecords == 0) {
            toast.error("There is no data available for download", {
              position: toast.POSITION.TOP_RIGHT,
            });
            setLoaddata(false);
          }
        } else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoaddata(false);
        }
      })
      .catch(function (error) {
        toast.error("Network Error..", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoaddata(false);
      });
  };

  const renderLedger = () => {
    if (listOfLedger != null && listOfLedger.length > 0) {
      let count = 1;
      return listOfLedger.map((item, index) => {
        return (
          <tr key={index}>
            <td>{count++ + pageSize * (pageNo - 1)}</td>
            <td>{item.ID}</td>
            <td>{item.Status}</td>
            <td>{item.Deduction}</td>
            <td>{item.Loan_Amount}</td>
            <td>{item.Code}</td>
            <td>{item.Name}</td>
            <td>{moment(item.Dated).format("DD/MM/YYYY")}</td>
            <td>
              <ul
                className="inline-action"
                aria-labelledby="dropdownMenuButton1"
              >
                <li>
                  <NavLink
                    to={"/Payroll/EditLoanDeduction/" + item.ID}
                    className=" green"
                  >
                    <i className="ri-edit-line"></i>
                  </NavLink>
                </li>
                <li>
                  <button
                    onClick={(e) => deleteLoanDeduction(item.ID)}
                    className="red"
                  >
                    <i className="ri-delete-bin-line"></i>
                  </button>
                </li>
              </ul>
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="9">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };
  const deleteLoanDeduction = (ID) => {
    const confirm = window.confirm("Are you sure to proceed this action ?");
    if (confirm) {
      setIsUpdated(true);
      const data = {
        AccessKey: AccessKey,
        UserID: UserID,
        MemberType: MemberType,
        ID: ID,
      };

      var api_config = {
        method: "post",
        url: config.base_url + "LoanDeduction/DeleteLoanDeduction",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(api_config)
        .then(function (response) {
          //console.log(response.data);
          if (response.data.status_code == 1) {
            toast.success(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setIsUpdated(true);
          }
        })
        .catch(function (error) {
          //console.log(error);
        });

      setIsUpdated(false);
    }
  };

  const handleCurrentMonth = () => {
    setIsUpdated(true);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
    };
    var api_config = {
      method: "post",
      url: config.base_url + "/LoanDeduction/GenerateForCurrentMonth",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        if (response.data.status_code == 1) {
          toast.success(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });

          setIsUpdated(false);
        }
      })
      .catch(function (error) {
        toast.error(error, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
    setIsUpdated(true);
  };
  return (
    <>
      <PayrollMenu />
      <ToastContainer />
      <div className="page-head">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/Dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    {" "}
                    <Link to="/Payroll/PayrollDashboard">Payroll</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Manage Loan Deductions
                  </li>
                </ol>
              </nav>
              <div className="page-title">Manage Loan Deductions</div>
            </div>
            <div className="col-md-6">
              <div className="page-right-actions">
                <Link
                  to="/PayRoll/AddLoanDeduction"
                  className="button button-white button-icon"
                >
                  Add Loan Deduction
                  <i className="ri-add-line"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`collapse ${showFilters ? "show" : ""} `}
        id="collapseFilteres"
      >
        <div className="page-content">
          <div className="container">
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <div className="card mb-2">
                <div className="card-body p-md-4">
                  <div className="row align-items-center">
                    <div className="col-md-3">
                      <div className="form-group">
                        <label  className="">
                          Select Month:
                        </label>
                        <select
                          name="Month"
                          defaultValue={Month}
                          onChange={(e) => setMonth(e.target.value)}
                          className="form-select"
                        >
                          <option value="">Please Select</option>
                          <MonthsDropDown />
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label  className="">
                          Select Year:
                        </label>
                        <select
                          name="Year"
                          value={Year}
                          onChange={(e) => setYear(e.target.value)}
                          className="form-select"
                        >
                          <option value="">Please Select</option>
                          <YearsDropDown />
                        </select>
                      </div>
                    </div>
                    {loaddata ? (
                      <div className="col-md-2 offset-8 mt-4 text-end ">
                        <div>
                          <div>
                            <Spinner animation="border" role="status"></Spinner>
                          </div>
                        </div>
                      </div>
                    ) : (
                    <div className="col-md-2 offset-8 text-end ">
                          <div className="form-group text-end mt-4">
                          <a
                              type="button"
                              onClick={handleSubmitForCvs}
                              className="print-icon"
                            >
                              <span>
                                <i className="ri-file-excel-2-line"></i>
                              </span>
                            
                            </a>
                          </div>
                        </div>
                    )}

                    <div className="col-md-2  text-end">
                      <div className="form-group text-end">
                        <button
                          type="submit"
                          value="Search"
                          className="w-100 button button-primary mt-4"
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>

      <div className="page-content">
        <div className="container">
          <div className="card">
            <div className="card-body">
              <table className="table table-theme table-hover">
                <thead>
                  <tr>
                    <th>Sr.#</th>
                    <th>ID</th>
                    <th>Status</th>
                    <th>Deduction</th>
                    <th>Amount</th>
                    <th>Staff Code</th>
                    <th>Staff Name</th>
                    <th>Dated</th>
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td className="text-center" colSpan="9">
                        <Spinner animation="border" role="status"></Spinner>
                      </td>
                    </tr>
                  ) : (
                    <>{renderLedger()}</>
                  )}
                </tbody>
              </table>
              {totalRecords > 10 && (
                <div className="pagination-wrap">
                  <div className="row align-items-center">
                    <div className="col-md-2 form_default">
                      <div className="d-flex align-items-center">
                        <label className="me-2" style={{ fontSize: "15px" }}>
                          Page Size
                        </label>
                        <div className="form-group">
                          <select
                            className="form-select"
                            style={{ width: "100px" }}
                            onChange={(e) => {
                              setPageNo(1);
                              setPageSize(parseInt(e.target.value));
                            }}
                          >
                            <option value="10" selected>
                              10
                            </option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <Pagination
                      pageNo={pageNo}
                      pageSize={pageSize}
                      totalRecords={totalRecords}
                      onPageChange={handlePageChange}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="container">
          <div className="card mt-2">
            <div className="card-body p-md-4">
              <div className="row ">
                <div className=" col-md-10 text-end">
                  <button
                    type="button"
                    className="button button-primary"
                    onClick={handleCurrentMonth}
                  >
                    Generate for Current Month
                  </button>
                </div>
                <div className="col-md-2  ">
                  <NavLink
                    className="button button-primary"
                    to={`/PayRoll/LoanDeductionsPrint?Month=${Month}&Year=${Year}
                                       `}
                    target="_blank"
                  >
                    Print Report
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ManageLoanDeduction;
