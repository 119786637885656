import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import { Link } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate, useParams } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Form } from "react-bootstrap";
import moment from "moment";
import AccountsMenu from "./AccountsMenu";
import { scrollToTop } from "../Shared/ScrollOnTop";

function EditOpeningBalance(props) {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [validated, setValidated] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [Account, setAccount] = useState({});
  const [OpBalanceID, setOpBalanceID] = useState(0);

  let params = useParams();
  const [AccountID, setAccountID] = useState(params.id);

  let navigate = useNavigate();

  useEffect(() => {
    document.title = "Add/Update Account Opening Balance";

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      ID: AccountID,
    };
    //console.log(data);
    var api_config = {
      method: "post",
      url: config.base_url + "Accounts/GetAccountByID",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        if (response.data.status_code == 1) {
          setAccount(response.data.Account);
          setOpBalanceID(response.data.Account.OpBalanceID);
        }

        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        toast.error("Network Error..", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setLoading(true);
      const data = {
        AccessKey: AccessKey,
        UserID: UserID,
        MemberType: MemberType,
        OpBalanceID: OpBalanceID,
        Amount: event.target.txtAmount.value,
        AmountType: event.target.ddlAmountType.value,
        Budget_Allocation: event.target.txtBudgetAllocation.value,
        Account_Description: event.target.txtAccountName.value,
      };

      //console.log(data);

      var api_config = {
        method: "post",
        url: config.base_url + "Accounts/UpdateOpeningBalance",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(api_config)
        .then(function (response) {
          //console.log(response.data);
          if (response.data.status_code == 1) {
            toast.success(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }

          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          toast.error("Network Error..", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }

    setValidated(true);
  };

  return (
    <>
      <AccountsMenu />
      <ToastContainer />
      <div className="container body-content">
        <div className="page-head">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <NavLink to="/Dashboard">Dashboard</NavLink>
                    </li>
                    <li className="breadcrumb-item">
                      <NavLink to="/Accounts/AccountsDashboard">
                        Accounts
                      </NavLink>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Edit Open Balance
                    </li>
                  </ol>
                </nav>
                <div className="page-title">Edit / Update Opening Balance </div>
              </div>
              <div className="col-md-6">
                <div className="page-right-actions">
                  <a
                    href="/Accounts/OpeningBalances"
                    className="button button-white button-icon"
                  >
                    Opening Balances
                    <i className="ri-add-line"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="page-content">
            <div className="container">
              <div className="card">
                <div className="card-body p-md-4">
                  <Form
                    noValidate
                    validated={validated}
                    onSubmit={handleSubmit}
                  >
                    <div className="row align-items-center">
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label>Selected Account Details</label>
                          <label className="label-gray">
                            {Account.Account_Code + " | " + Account.Year}
                          </label>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label>Account Name</label>
                          <input
                            type="text"
                            className="form-control"
                            name="txtAccountName"
                            defaultValue={Account.Account_Description}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label>Opening Balance</label>
                          <input
                            type="text"
                            className="form-control"
                            name="txtAmount"
                            defaultValue={Account.Amount}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label>Balance Type</label>
                          <select
                            className="form-select"
                            name="ddlAmountType"
                            defaultValue={Account.OpeningBalanceType}
                            selected={Account.OpeningBalanceType}
                          >
                            <option value="Debit">Debit</option>
                            <option value="Credit">Credit</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label>Budget Allocation</label>
                          <input
                            type="text"
                            className="form-control"
                            name="txtBudgetAllocation"
                            defaultValue={Account.Budget_Allocation}
                          />
                        </div>
                      </div>
                      <div className="col-md-3 offset-md-6 text-end">
                        <div className="form-group">
                          {loading ? (
                            <Spinner animation="border" role="status"></Spinner>
                          ) : (
                            <button
                              type="submit"
                              value="Save"
                              className="button button-primary w-100 "
                            >
                              SAVE
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditOpeningBalance;
