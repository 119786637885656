import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import { useNavigate, useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import SchoolLeavingCertificate from "./templates/SchoolLeavingCertificate";
import Page from "../General/Page";
import { toast, ToastContainer } from 'react-toastify';
import HopeCertificate from "./templates/HopeCertificate";
import CharacterCertificate from "./templates/CharacterCertificate";
import BonafideCertificate from "./templates/BonafideCertificate";
import { scrollToTop } from '../Shared/ScrollOnTop';

function PrintStudentCertificates(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [student, setStudent] = useState({});
    const [loading, setLoading] = useState(false);

    let params = useParams();

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const type = searchParams.get('type');

    const student_code = searchParams.get('student_code');

    useEffect(() => {
        setLoading(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            Registration_No: student_code,
        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'Student/GetStudentByRegistrationNo',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                if (response.data.status_code == 1) {
                    setStudent(response.data.Student);
                }
                else {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }

                setLoading(false);


            })
            .catch(function (error) {
                //console.log(error);
            });

    }, []);



    if (type == "schoolleaving") {

        const classname = searchParams.get('class');
        const extraline = searchParams.get('extraline');
        const paymentdate = searchParams.get('paymentdate');
        const leftdate = searchParams.get('date');
        return (
            <>
                <Page orientation="portrait" size="a4">
                    <SchoolLeavingCertificate
                        className={classname}
                        extraline={extraline}
                        name={student.Student_Name}
                        fathername={student.Father_Name}
                        paymentdate={paymentdate}
                        dob={student.Date_Of_Birth}
                        code={student_code}
                        leftdate={leftdate}
                        doj={student.Date_Of_Joining}
                    />
                </Page>
            </>
        );
    }
    else if (type == "hope") {

        const classname = searchParams.get('class');
        const extraline = searchParams.get('extraline');
        const marks = searchParams.get('marks');
        return (
            <>
                <Page orientation="portrait" size="a4">

                    <HopeCertificate
                        className={classname}
                        extraline={extraline}
                        name={student.Student_Name}
                        fathername={student.Father_Name}
                        class1={student?.SelectedClass?.Class_Name}
                        section={student?.SelectedSection?.Section_Name}
                        dob={student.Date_Of_Birth}
                        code={student_code}
                        marks={marks}
                        doj={student.Date_Of_Joining}
                    />
                </Page>
            </>
        );
    }
    else if (type == "character") {

        const classname = searchParams.get('class');
        const extraline = searchParams.get('extraline');
        const school = searchParams.get('SchoolName');
        const session = searchParams.get('Session');
        return (
            <>
                <Page orientation="portrait" size="a4">

                    <CharacterCertificate
                        className={classname}
                        extraline={extraline}
                        name={student.Student_Name}
                        fathername={student.Father_Name}
                        school={school}
                        session={session}

                        code={student_code}
                    />
                </Page>
            </>
        );
    }
    else if (type == "bonafide") {

        const extraline = searchParams.get('extraline');
        const date = searchParams.get('date');
        return (
            <>
                <Page orientation="portrait" size="a4">

                    <BonafideCertificate

                        extraline={extraline}
                        name={student.Student_Name}
                        fathername={student.Father_Name}
                        date={date}
                        dob={student.Date_Of_Birth}
                        code={student_code}
                        section={student?.SelectedSection?.Section_Name}
                        class1={student?.SelectedClass?.Class_Name}
                    />
                </Page>
            </>
        );
    }


}

export default PrintStudentCertificates;