import React from 'react';
import { Link } from "react-router-dom";
import PrintCertificates_navbar from './PrintCertificates_navbar';
import certificate_issued from '../../assets/images/dashboard_icon/certificate_issued.svg';
import certificate from '../../assets/images/dashboard_icon/certificate.svg';
import { scrollToTop } from '../Shared/ScrollOnTop';

const CertificateDashboard = () => {
    return (
        <>
            <PrintCertificates_navbar />
            <div className="page-head">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/Dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Ceritficate Dashboard</li>
                                </ol>
                            </nav>
                            <div className="page-title">Ceritficate Dashboard</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-content">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-4 col-sm-6 offset-md-2">
                            <div className="card m-card">
                                <div className="card-body text-center">
                                    <img src={certificate} className="pb-3" alt=""/>
                                        <p className="m-2">Certificate Fee</p>
                                        <h4 className="m-2">Rs. 200</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="card m-card">
                                <div className="card-body text-center">
                                    <img src={certificate_issued} className="pb-3" alt=""/>
                                        <p className="m-2">Total issued</p>
                                        <h4 className="m-2">No. 300</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CertificateDashboard;