import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate, useParams } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Form, FormCheck } from "react-bootstrap";
import moment from "moment";
import { scrollToTop } from "../Shared/ScrollOnTop";
import { flatMap } from "lodash";
import RightsCheck from "../General/RightsCheck";
import SetupMenu from "./SetupMenu";

function EditRuleApplicable(props) {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [validated, setValidated] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [listOfLedger, setLedger] = useState([]);
  const [AnnualCode, setAnnualCode] = useState('');
  const [isUpdated, setIsUpdated] = useState(false);
  const [loadingAnnual, setloadingAnnual] = useState(null);
  const [loadingMonthly, setloadingMonthly] = useState(null);

  const [RuleName, setRuleName] = useState("");

  let params = useParams();

  const [RuleID, setRuleID] = useState(params.id);

  let navigate = useNavigate();

  useEffect(() => {
    document.title = "Rule Applicable Settings";

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      Concession_Rule_ID: RuleID,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "FeeType/GetFeeTypesByRuleID",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data)
        if (response.data.status_code == 1) {
          setRuleName(response.data.RuleName);
          setLedger(response.data.ListofRecords || []);
        }
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        toast.error("Network Error..", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });

    setLoading(true);
    setIsUpdated(true);
  }, [isUpdated]);

  const renderLedger = () => {
    if (listOfLedger.length > 0) {
      let count = 1;
      return listOfLedger.map((item, index) => {
        return (
          <tr key={index}>
            <td>{count++}</td>
            <td>{item.Fee_Type_Code}</td>
            <td>{item.Fee_Type_Name}</td>
            <td>
              {/* {item.AnnualApplicable ? 'true': 'false'} */}
              {loadingAnnual === item.Fee_Type_Code ? (
                <Spinner animation="grow" size="sm" />
              ) : (
                <>
                  <Form.Check
                    type="checkbox"
                    id={`custom-switch-${index}`}
                    checked={item.AnnualApplicable === true}
                    onChange={(e, FeeCode) => UpdateAnnualSetting(e, item.Fee_Type_Code)}
                  />
                </>

              )}
            </td>
            <td>
              {/* {item.MonthlyApplicable ? 'true': 'false'} */}
              {loadingMonthly === item.Fee_Type_Code ? (
                <Spinner animation="grow" size="sm" />
              ) : (
                <>
                  <Form.Check
                    type="checkbox"
                    id={`Annual-Setting-${index}`}
                    checked={item.MonthlyApplicable === true}
                    onChange={(e, FeeCode) => UpdateMonthlySetting(e, item.Fee_Type_Code)}
                  />
                </>

              )}
            </td>
          </tr >
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="5">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };

  const UpdateAnnualSetting = (e, FeeCode) => {
    // e.preventDefault();
    setloadingAnnual(FeeCode);
    setIsUpdated(true);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      Concession_Rule_ID: RuleID,
      Fee_Code: FeeCode,
      AnnualApplicable: e.target.checked,
    };

    //console.log(data);
    var api_config = {
      method: "post",
      url: config.base_url + "FeeType/UpdateAnnualApplicable",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data, 'checked');
        setIsUpdated(false);
        setloadingAnnual(null);
        toast.success(response.data.status_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .catch(function (error) {
        //console.log(error);
      });
  };

  const UpdateMonthlySetting = (e, FeeCode) => {

    e.preventDefault();
    setloadingMonthly(FeeCode);
    setIsUpdated(true);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      Concession_Rule_ID: RuleID,
      Fee_Code: FeeCode,
      MonthlyApplicable: e.target.checked,
    };

    //console.log(data);
    var api_config = {
      method: "post",
      url: config.base_url + "FeeType/UpdateMonthlyApplicable",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        setIsUpdated(false);
        setloadingMonthly(null);
        toast.success(response.data.status_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .catch(function (error) {
        //console.log(error);
      });
  };

  return (
    <>
      {/* <LoginCheck /> */}
      <SetupMenu />
      <ToastContainer />
      <RightsCheck />
      <div className="page-head">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/Dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/Setup/SetupDashboard">Setup</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Concession Rule Applicable
                  </li>
                </ol>

              </nav>
              <div className="page-title">
                Apply Rule Settings for {RuleName}
              </div>
            </div>
            <div className="col-md-6">
              <div className="page-right-actions">
                <Link
                  to="/Setup/ManageConcession"
                  className="button button-white button-icon"
                >
                  Manage Concession Rules
                  <i className="ri-list-check"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="page-content">
        <div className="container">
          <div className="card">
            <div className="card-body">
              <table className="table table-theme table-hover">
                <thead>
                  <tr>
                    <th>Sr. No</th>
                    <th>Fee Code</th>
                    <th>Name</th>
                    <th>Annual</th>
                    <th>Monthly</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <>
                      <div className="my-2">
                        <Spinner animation="border" role="status"></Spinner>
                      </div>
                    </>
                  ) : (
                    <>{renderLedger()}</>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="card mt-3">
            <div className="card-body text-end">
              <Link className="button button-primary" target="_blank" to={`/setup/editruleapplicable-print?RuleID=${RuleID}`}>Print</Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditRuleApplicable;
