import React, { useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import Monthly_navbar from "./Monthlytasks_navbar";
import { Form, Spinner } from "react-bootstrap";
import MonthsDropDown from "../Shared/MonthsDropDown";
import YearsDropDown from "../Shared/YearsDropDown";
import Pagination from "../General/Pagination";
import moment from "moment";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { scrollToTop } from "../Shared/ScrollOnTop";
import SessionsDropDown from "../Shared/SessionsDropDown";
import config from "../../Config";
const StartNewMonth2 = () => {
    document.title = "Post Fee";
    const [Month, setMonth] = useState(moment().format("M"));
    const [Year, setYear] = useState(moment().format("YYYY"));
    const [MonthForConcessions, setMonthForConcessions] = useState(
        moment().format("M")
    );
    const [YearForConcessions, setYearForConcessions] = useState(
        moment().format("YYYY")
    );
    const [MonthForArrears, setMonthForArrears] = useState(moment().format("M"));
    const [YearForArrears, setYearForArrears] = useState(moment().format("YYYY"));
    const [MonthForPostAdvance, setMonthForPostAdvance] = useState(
        moment().format("M")
    );
    const [YearForPostAdvance, setYearForPostAdvance] = useState(
        moment().format("YYYY")
    );
    const [MonthForDeleteFee, setMonthForDeleteFee] = useState(
        moment().format("M")
    );
    const [YearForDeleteFee, setYearForDeleteFee] = useState(
        moment().format("YYYY")
    );
    const [MonthForPostFine, setMonthForPostFine] = useState(
        moment().format("M")
    );
    const [YearForPostFine, setYearForPostFine] = useState(
        moment().format("YYYY")
    );

    const [MonthForPostAbsentFine, setMonthForPostAbsentFine] = useState(
        moment().format("M")
    );
    const [YearForPostAbsentFine, setYearForPostAbsentFine] = useState(
        moment().format("YYYY")
    );

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [User_Name, setUser_Name] = useState(localStorage.getItem("UserName"));
    const [MemberType, setMemberType] = useState(
        localStorage.getItem("MemberType")
    );
    const [SessionID, setSessionID] = useState(
        localStorage.getItem("DefaultSession")
    );

    const [TransportFee, setTransportFee] = useState(false);

    const [loading, setLoading] = useState(false);
    const [Concessionsloading, setConcessionsloading] = useState(false);
    const [Arrearsloading, setArrearsloading] = useState(false);
    const [AdjustAdvanceloading, setAdjustAdvanceloading] = useState(false);
    const [DeleteFeeloading, setDeleteFeeloading] = useState(false);
    const [PostFineloading, setPostFineloading] = useState(false);
    const [PostAbsentFineloading, setPostAbsentFineloading] = useState(false);
    const [DateForPostFine, setDateForPostFine] = useState();
    const [SessionId, setSessioinId] = useState(localStorage.getItem("DefaultSession"));
    const [ClassId, setClassId] = useState(0);
    const [Ledger, setLedger] = useState([]);
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(1000000);
    const [totalRecords, setTotalRecords] = useState(0);
    const [StartMonthLoading, setStartMonthLoading] = useState(false)
    const [checkedItems, setCheckedItems] = useState({});
    const [selectedClassIds, setSelectedClassIds] = useState([]);
    const [selectedClassNames, setSelectedClassNames] = useState([]);
    const [challanDueDate, setChallanDueDate] = useState("");
    const [BusTotalDays, setBusTotalDays] = useState(30);
    const [BusAvailDays, setBusAvailDays] = useState(30);


    const handleDateChange = (event) => {
        setChallanDueDate(event.target.value);
    };


    const handleCheckboxChange = (classId, className, event) => {
        let tempCheckedItems = { ...checkedItems };
        tempCheckedItems[classId] = event.target.checked;
        setCheckedItems(tempCheckedItems);

        let updatedSelectedClassIds = [...selectedClassIds];
        let updatedSelectedClassNames = [...selectedClassNames];

        if (event.target.checked) {
            updatedSelectedClassIds.push(classId);
            updatedSelectedClassNames.push(className);
        } else {
            updatedSelectedClassIds = updatedSelectedClassIds.filter(id => id !== classId);
            updatedSelectedClassNames = updatedSelectedClassNames.filter(name => name !== className);
        }

        // Update the state
        setSelectedClassIds(updatedSelectedClassIds);
        setSelectedClassNames(updatedSelectedClassNames);
    };


    const handleSelectAll = (event) => {
        let tempCheckedItems = {};
        let updatedSelectedClassIds = [];
        let updatedSelectedClassNames = [];

        if (event.target.checked) {
            Ledger.forEach((item) => {
                item.ListofClassesInWing.forEach((detail) => {
                    tempCheckedItems[detail.Class_Id] = true;
                    updatedSelectedClassIds.push(detail.Class_Id);
                    updatedSelectedClassNames.push(detail.Class_Name);
                });
            });
        } else {
            Ledger.forEach((item) => {
                item.ListofClassesInWing.forEach((detail) => {
                    tempCheckedItems[detail.Class_Id] = false;
                });
            });
            updatedSelectedClassIds = [];
            updatedSelectedClassNames = [];
        }

        setCheckedItems(tempCheckedItems);
        setSelectedClassIds(updatedSelectedClassIds);
        setSelectedClassNames(updatedSelectedClassNames);
    };

    //console.log(selectedClassIds);


    useEffect(() => {
        GetClassesBySessionID();

        const today = new Date();

        const tenDaysFromNow = new Date(today);
        tenDaysFromNow.setDate(today.getDate() + 10);
        const formattedDate = tenDaysFromNow.toISOString().split('T')[0];

        setChallanDueDate(formattedDate);
    }, [])

    const GetClassesBySessionID = () => {
        setLoading(true)
        const dashboardParms = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            PageNo: pageNo,
            PageSize: pageSize,
            SessionID: SessionID
        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'Wings/GetSchoolWingWithClasses',
            headers: {
                'Content-Type': 'application/json'
            },
            data: dashboardParms
        };

        axios(api_config)
            .then(function (response) {
                if (response.data.status_code != 0) {
                    setLedger(response.data.SchoolWing || []);
                    setTotalRecords(response.data.SchoolWing.length || 0);
                    setLoading(false);
                }
                else {
                    setLedger(null);
                    setTotalRecords(0);
                    setLoading(false);
                }

            })
            .catch(function (error) {
                ////console.log(error);
                setLoading(false);
            });
    }

    const renderLedger = () => {

        if (Ledger != null && Ledger.length > 0) {
            return (
                Ledger.map((item, index) => {
                    return (
                        <Fragment key={item.id}>
                            <tr>
                                <td colSpan={6} className="fw-bold">
                                    Wing: {item.Wing_Detail}{" "}
                                </td>
                            </tr>
                            {item.ListofClassesInWing && (
                                <>
                                    {item.ListofClassesInWing.map((detail) => (
                                        <tr>
                                            <td>{detail.Class_Id}</td>
                                            <td>{detail.Class_Code}</td>
                                            <td>{detail.Class_Short_Name}</td>
                                            <td>{detail.Class_Name}</td>
                                            <td>{detail.Selected_Session.Session_Details}</td>
                                            <td><div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id={`checkbox-${detail.Class_Id}`}
                                                    value={detail.Class_Id}
                                                    checked={!!checkedItems[detail.Class_Id]}
                                                    onChange={(e) => handleCheckboxChange(detail.Class_Id, detail.Class_Name, e)}
                                                />
                                            </div>
                                            </td>
                                        </tr>
                                    ))}
                                </>
                            )}
                        </Fragment>
                    )
                })
            )
        }
        else {
            return (
                <tr>
                    <td colSpan="6"><h4 className="mt-3" style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}>No Data Found</h4></td>
                </tr>
            )
        }

    }

    const handleMonth = () => {
        setStartMonthLoading(true);

        if (selectedClassIds.length <= 0) {
            toast.error("Please select atleast one class", {
                position: toast.POSITION.TOP_RIGHT,
            });
            setStartMonthLoading(false);
            return;
        }
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            SelectedMonth: Month,
            FYear: Year,
            SessionID: SessionID,
            AddTransportFee: TransportFee,
            SeletedClassIDs: selectedClassIds,
            SeletedClassNames: selectedClassNames,
            User_Name: User_Name,
            Challan_DueDate: challanDueDate,
            BusTotalDays: BusTotalDays,
            BusAvailDays: BusAvailDays
        };
        const api_config = {
            method: "post",
            url: config.base_url + "/ADLedger/StartNewMonthWithAD",

            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(api_config)
            .then(function (response) {
                if (response.data.status_code == 1) {
                    toast.success(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    setStartMonthLoading(false);
                } else {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    setStartMonthLoading(false);
                }
            })
            .catch(function (error) {
                setStartMonthLoading(false);
                toast.error("Network Error..", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            });
    };

    const handleAdjustAdvance = () => {
        setAdjustAdvanceloading(true);
        const data = {
            AccessKey: AccessKey,
            MemberType: MemberType,
            UserID: UserID,
            SelectedMonth: MonthForPostAdvance,
            FYear: YearForPostAdvance,
        };

        const api_config = {
            method: "post",
            url: config.base_url + "ADLedger/AdjustAdvance",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };
        //console.log(data);
        axios(api_config)
            .then(function (response) {
                //console.log("response", response.data);
                if (response.data.status_code == 1) {
                    toast.success(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });

                    setAdjustAdvanceloading(false);
                } else {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    setAdjustAdvanceloading(false);
                }
            })
            .catch(function (error) {
                setAdjustAdvanceloading(false);
                toast.error("Network Error..", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            });
    };
    //console.log(TransportFee)
    return (
        <>
            <Monthly_navbar />
            <ToastContainer />

            <div className="page-head">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="/MonthlyTasks/MonthlyTasksDashboard">
                                            Fee Posting
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        Post Fee
                                    </li>
                                </ol>
                            </nav>
                            <div className="page-title">Post Fee</div>
                        </div>
                        <div className="col-md-6">
                            <div className="page-right-actions">
                                <Link
                                    to="/MonthlyTasks/ManageMonth"
                                    className="button button-white button-icon"
                                >
                                    Manage Posted Fee
                                    <i className="ri-list-check"></i>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="page-content">
                <div className="container">
                    <div className="card mb-2">
                        <div className="card-body p-md-4">
                            <div className="bg-light p-md-4">
                                <p className="mb-1">
                                    Post Fee is a Fee Posting and will be perform to add
                                    dues in the students ledger, just verify the date and click on
                                    the button below, this will update the ledgers with current
                                    feeses mentioned as monthly fee in classes defination.
                                </p>
                                <h5>
                                    <b>Note:</b>
                                </h5>
                                <p className="mb-0">
                                    This Process may take a little time to post enteries in the
                                    system so do not press the button twice and wait for its
                                    completion.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>



                <div className="container mt-2">
                    <div className="card">
                        <div className="card-body p-md-4">
                            <div className="row align-items-center">
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label className="">Select Session:</label>
                                        <select className="form-select"
                                            onChange={(e) => { setSessioinId(e.target.value); setClassId(0); }}
                                            name="SessionID">
                                            <option value="0">---All---</option>
                                            <SessionsDropDown selectedSession={SessionId} />
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label>Current Month</label>
                                        <select
                                            className="form-select"
                                            onChange={(e) => setMonth(e.target.value)}
                                        >
                                            <option value="">Please Select</option>
                                            <MonthsDropDown />
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label>Current Year</label>
                                        <select
                                            className="form-select"
                                            onChange={(e) => setYear(e.target.value)}
                                        >
                                            <option value="">Please Select</option>
                                            <YearsDropDown />
                                        </select>
                                    </div>
                                </div>

                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label>Today</label>
                                        <input
                                            disabled
                                            className="form-control"
                                            value={moment().format("h:mm A")}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label>Challan Due Date</label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            value={challanDueDate}
                                            required
                                            onChange={handleDateChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <Form.Group className="mt-4">
                                        <Form.Check
                                            required
                                            label="Add Transport Fee:"
                                            feedbackType="invalid"
                                            checked={TransportFee}
                                            onChange={(e) => setTransportFee(e.target.checked)}
                                        />
                                    </Form.Group>
                                </div>
                                {TransportFee ?
                                    <>
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label>Bus Month Days</label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    value={BusTotalDays}
                                                    required
                                                    onChange={(e) => setBusTotalDays(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label>Bus Avail Days</label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    value={BusAvailDays}
                                                    required
                                                    onChange={(e) => setBusAvailDays(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </>
                                    : null}

                            </div>

                            <div className="card mt-3">
                                <div className="card-body table-wrap">
                                    <table className="table table-theme table-hover">
                                        <thead>
                                            <tr>
                                                <th>
                                                    Class ID
                                                </th>
                                                <th>
                                                    Class Code
                                                </th>
                                                <th>
                                                    Class Short Name
                                                </th>
                                                <th>
                                                    Class Name
                                                </th>
                                                <th>
                                                    Session
                                                </th>
                                                <th><div class="form-check">
                                                    <input class="form-check-input" type="checkbox" value=""
                                                        onChange={handleSelectAll}
                                                        id="flexCheckDefault" />
                                                </div></th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {
                                                loading ?
                                                    <tr>
                                                        <td className="text-center" colSpan="7">
                                                            <Spinner animation="border" role="status"></Spinner>
                                                        </td>
                                                    </tr>
                                                    :
                                                    <>
                                                        {renderLedger()}
                                                    </>

                                            }
                                        </tbody>
                                    </table>
                                    {
                                        totalRecords > { pageSize } &&
                                        <div className="pagination-wrap">

                                            <div className="row align-items-center">
                                                <div className="col-md-2 form_default">
                                                    <div className="d-flex align-items-center">
                                                        <label className="me-2" style={{ fontSize: "15px" }}>Page Size</label>
                                                        <div className="form-group">
                                                            <select className="form-select"
                                                                style={{ width: "100px" }}
                                                                onChange={(e) => {
                                                                    setPageNo(1);
                                                                    setPageSize(parseInt(e.target.value));


                                                                }}>
                                                                <option value="10" selected>10</option>
                                                                <option value="20">20</option>
                                                                <option value="50">50</option>
                                                                <option value="100">100</option>
                                                                <option value="500">500</option>


                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>

                                                <Pagination
                                                    pageNo={pageNo}
                                                    pageSize={pageSize}
                                                    totalRecords={totalRecords}
                                                />
                                            </div>
                                        </div>

                                    }

                                </div>
                            </div>

                            <div className=" text-end mt-3">
                                {StartMonthLoading ? (
                                    <Spinner animation="border" role="status"></Spinner>
                                ) : (
                                    <button
                                        type="submit"
                                        className="button button-primary "
                                        onClick={handleMonth}
                                    >
                                        Post Fee{" "}
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container mt-2">
                    <div className="card">
                        <div className="card-body p-md-4">
                            <div className="row align-items-end">
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label>Current Month</label>
                                        <select
                                            className="form-select"
                                            onChange={(e) => setMonthForPostAdvance(e.target.value)}
                                        >
                                            <option value="">Please Select</option>
                                            <MonthsDropDown />
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label>Current Year</label>
                                        <select
                                            className="form-select"
                                            onChange={(e) => setYearForPostAdvance(e.target.value)}
                                        >
                                            <option value="">Please Select</option>
                                            <YearsDropDown />
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-2 offset-md-6 text-end">
                                    {AdjustAdvanceloading ? (
                                        <Spinner animation="border" role="status"></Spinner>
                                    ) : (
                                        <button
                                            type="button"
                                            className="button button-primary w-100"
                                            onClick={handleAdjustAdvance}
                                        >
                                            Adjust Advances
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default StartNewMonth2;