import React, { useState, useEffect } from 'react';
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';


function ClassesDropDown(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [classes, setClasses] = useState([]);
    
    const [loading, setLoading] = useState(false);

    useEffect(() => {


        setLoading(true);

        if(props.WingID > 0 && props.SessionID > 0) {
        const data = {
           AccessKey: AccessKey,
            UserID: UserID,
MemberType: MemberType,
            Wing_Id: props.WingID,
            SessionID: props.SessionID,
            pageNo: 1,
            pageSize: 1000,
        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'WingClass/GetClassesByWingnSessionID',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

      //console.log(data);

        axios(api_config)
            .then(function (response) {
                ////console.log(response.data);
                setClasses(response.data.ListofRecords);
                setLoading(false);
            })
            .catch(function (error) {
                //console.log(error);
            });

            
        }
        else {
            setLoading(false);
        }

    }, [props.WingID, props.SessionID]);

    
        if(loading) {
            return <option selected disabled>Loading...</option>
        }
        else {
            if (classes != null && classes.length > 0) {
                return (
    
                    classes.map((item, index) => {
                        return (
                            <option value={item.Class_Id} key={index} selected={item.Class_Id == props.selectedClassId}>{item.Class_Name}</option>
                        )
                    })
                );
            }
        }
        
    
}

export default ClassesDropDown;