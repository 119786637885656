import ReportsMenu from "./ReportsMenu";
import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import WingsDropDown from "../Shared/WingsDropDown";
import { toast, ToastContainer } from "react-toastify";
import { NavLink, Link } from "react-router-dom";
import moment from "moment";
import Pagination from "../General/Pagination";

import MonthsDropDown from "../Shared/MonthsDropDown";
import YearsDropDown from "../Shared/YearsDropDown";


const FeePostingIssues = () => {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [showFilters, setShowFilters] = useState(true);

  const [loading, setLoading] = useState(false);
  const [listOfLedger, setLedger] = useState([]);
  const [validated, setValidated] = useState(false);
 
  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);


  const [isUpdated, setIsUpdated] = useState(false);
  const [ReportMonth, setReportMonth] = useState(moment().format("MM"));
  const [ReportYear, setReportYear] = useState(moment().format("YYYY"));
 
  useEffect(() => {
    document.title = "Fee Issues in Selected Month";

    setIsUpdated(false);
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      ReportMonth: ReportMonth,
      ReportYear: ReportYear,
     
    };

   //console.log(">>>>>",data);

    var api_config = {
      method: "post",
      url: config.base_url + "FeeSystem/GetFeePostingIssues",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
       console.log(response.data);
        setLedger(response.data.ListofRecords);
        setLoading(false);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, [ReportMonth, ReportYear]);

  const handlePageChange = (page) => {
    setPageNo(page);
  };

  
  const renderLedger = () => {
    if (listOfLedger != null && listOfLedger.length > 0) {
      let count = 1;
      return listOfLedger.map((item, index) => {
        return (
          <tr key={index}>
            <td>{count++ + pageSize * (pageNo - 1)}</td>
            <td>{item.Source}</td>
            <td>{item.Entry_Id}</td>
            <td>
              <NavLink
                target="_blank"
                to={"/Students/StudentLedger/" + item.Student_id}
                className="red"
              >
                {item.Student_id}
              </NavLink>
            </td>
            <td>{moment(item.Dated).format("DD/MM/YYYY")}</td>
            <td>{item.Receipt_No}</td>
            <td>{item.Amount_Deposited}</td>
            <td>{item.Posted_In_Ledger}</td>
            <td>{item.BenchNumber}</td>
            <td>{item.Transaction_ID}</td>
            <td>{item.Transaction_Date ? moment(item.Transaction_Date).format("DD/MM/YYYY") : ''}</td>
            <td>{item.Fee_Code}</td>
            <td>{item.Credit}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="14">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };


  return (
    <>
      <ToastContainer />
      <ReportsMenu />
      <div className="page-head">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/Dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/Reports/ReportsDashboard">Reports</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Fee Posting Problems
                  </li>
                </ol>
              </nav>
              <div className="page-title">Fee Posting Problems</div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`collapse ${showFilters ? "show" : ""} `}
        id="collapseFilteres"
      >
        {/* <Form noValidate validated={validated} onSubmit={handleSubmit}> */}
        <div className="page-content">
          <div className="container">
            <div className="card mb-2">
              <div className="card-body p-md-4">
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label  className="">
                        Select Month:
                      </label>
                      <select
                        name="ReportMonth"
                        onChange={(e) => setReportMonth(e.target.value)}
                        className="form-select"
                      >
                        <option value="" disabled="" selected="selected">
                          Please Select
                        </option>
                        <MonthsDropDown />
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label  className="">
                        Select Year:
                      </label>
                      <select
                        name="ReportYear"
                        onChange={(e) => setReportYear(e.target.value)}
                        className="form-select"
                      >
                        <option value="" disabled="" selected="selected">
                          Please Select
                        </option>
                        <YearsDropDown />
                      </select>
                    </div>
                  </div>
                 
                  <div className="col-md-2 text-end">
                    <div className="form-group">
                      <button
                        type="submit"
                        value="Search"
                        className="w-100 button button-primary"
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-content">
        <div className="container">
          <div className="card">
            <div className="card-body">
              <table className="table table-theme">
                <thead>
                  <tr>
                    <th colSpan={5}>{totalRecords} Records Found</th>
                    <th colSpan={9} className="text-end">
                    </th>
                  </tr>
                </thead>
                <thead>
                  <tr>
                    <th>Sr. No.</th>
                    <th>Source</th>
                    <th>Entry ID</th>
                    <th>Student ID</th>
                    <th>Dated</th>  
                    <th>Receipt No</th>
                    <th>Amount Deposited</th>
                    <th>Posted In Ledger</th>
                    <th>Bench NO</th>
                    <th>Transaction ID</th>
                    <th>Transaction Date</th>
                    <th>Fee Code</th>
                    <th>Credit</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td className="text-center" colSpan="13">
                        <Spinner animation="border" role="status"></Spinner>
                      </td>
                    </tr>
                  ) : (
                    <>{renderLedger()}</>
                  )}
                 
                </tbody>
              </table>
              {totalRecords > 10 && (
                <div className="pagination-wrap">
                  <div className="row align-items-center">
                    <div className="col-md-2 form_default">
                      <div className="d-flex align-items-center">
                        <label className="me-2" style={{ fontSize: "15px" }}>
                          Page Size
                        </label>
                        <div className="form-group">
                          <select
                            className="form-select"
                            style={{ width: "100px" }}
                            onChange={(e) => {
                              setPageNo(1);
                              setPageSize(parseInt(e.target.value));
                            }}
                          >
                            <option value="10" selected>
                              10
                            </option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="500">500</option>
                            <option value="1000">1000</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <Pagination
                      pageNo={pageNo}
                      pageSize={pageSize}
                      totalRecords={totalRecords}
                      onPageChange={handlePageChange}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="container">
          <div className="card mt-2">
            <div className="card-body p-md-4">
              <div className="row">
                <div className="col-md-12 text-end">
                  <div className="form-group text-end">
                    <NavLink
                      className="button button-primary"
                      to={`/Reports/FeeReportingPrint?Month=${ReportMonth}&Year=${ReportYear}
                                       `}
                      target="_blank"
                    >
                      Print Report
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeePostingIssues;
