import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Form } from "react-bootstrap";
import UserGroupDropDown from "../Shared/UserGroupDropDown";
import SetupMenu from "./SetupMenu";
import { scrollToTop } from "../Shared/ScrollOnTop";

function AddBusRoutes() {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [validated, setValidated] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(false);

  let navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setLoading(true);
      const data = {
        AccessKey: AccessKey,
        UserID: UserID,
        MemberType: MemberType,
        Root_Code: event.target.Root_Code.value,
        Root_Name: "",
        Root_Details: event.target.Root_Details.value,
        Root_Fee: 0,
        BusNumber: event.target.txtBusNumber.value,
        BusDetails: event.target.txtBusDetails.value,
        FuelType: event.target.txtFuelType.value,
        Ownership: event.target.txtBusOwnership.value,
        School_ID: 1,
        Area_Name: event.target.Area_Name.value,
        PickUp_Fee: event.target.PickUp_Fee.value,
        DropOf_Fee: event.target.DropOf_Fee.value,
        PickDrop_Fee: event.target.PickDrop_Fee.value,
        Zone: event.target.Zone.value,
      };

      //console.log(data);

      var api_config = {
        method: "post",
        url: config.base_url + "Busroute/AddBusRoute",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(api_config)
        .then(function (response) {
          //console.log(response.data);
          if (response.data.status_code == 1) {
            toast.success(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }

          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          toast.error("Network Error..", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }

    setValidated(true);
  };

  return (
    <>
      <SetupMenu />
      <ToastContainer />
      <div className="page-head">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/Dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    {" "}
                    <Link to="/Setup/SetupDashboard">Setup</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Add Bus Routes
                  </li>
                </ol>
              </nav>
              <div className="page-title">Add Bus Routes</div>
            </div>
            <div className="col-md-6">
              <div className="page-right-actions">
                <Link
                  to="/SetUp/ManageBusRoutes"
                  className="button button-white button-icon"
                >
                  Manage Bus Routes
                  <i className="ri-list-check"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-content">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body p-md-4">
                  <Form
                    noValidate
                    validated={validated}
                    onSubmit={handleSubmit}
                  >
                    <div className="row align-items-center">
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label>Route Code:</label>
                          <input
                            type="text"
                            className="form-control"
                            required
                            name="Root_Code"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label className="">Area Name:</label>
                          <input
                            type="text"
                            className="form-control"
                            required
                            name="Area_Name"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label className="">Zone:</label>
                          <input
                            type="text"
                            className="form-control"
                            required
                            name="Zone"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label className="">Bus Number:</label>
                          <input
                            type="text"
                            className="form-control"
                            required
                            name="txtBusNumber"
                          />
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label className="">Pick & Drop Fee:</label>
                          <input
                            type="text"
                            className="form-control"
                            required
                            name="PickDrop_Fee"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label className="">PickUp Fee:</label>
                          <input
                            type="text"
                            className="form-control"
                            required
                            name="PickUp_Fee"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label className="">DropOff Fee:</label>
                          <input
                            type="text"
                            className="form-control"
                            required
                            name="DropOf_Fee"
                          />
                        </div>
                      </div>
                      <div className="col-md-10">
                        <div className="form-group mb-3">
                          <label className="">Route Details:</label>
                          <input
                            type="text"
                            className="form-control"
                            required
                            name="Root_Details"
                          />
                        </div>
                      </div>

                      <div className="col-md-2">
                        <div className="form-group mb-3">
                          <label className="">Bus Ownership:</label>
                          <input
                            type="text"
                            className="form-control"
                            required
                            name="txtBusOwnership"
                          />
                        </div>
                      </div>

                      <div className="col-md-10">
                        <div className="form-group mb-3">
                          <label className="">Bus Details:</label>
                          <input
                            type="text"
                            className="form-control"
                            required
                            name="txtBusDetails"
                          />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group mb-3">
                          <label className="">Fuel Type:</label>
                          <input
                            type="text"
                            className="form-control"
                            required
                            name="txtFuelType"
                          />
                        </div>
                      </div>

                      <div className="col-md-2 text-end">
                        <div className="form-group ">
                          {loading ? (
                            <Spinner animation="border" role="status"></Spinner>
                          ) : (
                            <button
                              type="submit"
                              value="Save"
                              className="button button-primary w-100 "
                            >
                              SAVE
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddBusRoutes;
