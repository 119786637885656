import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import QRCode from "react-qr-code";
import StudentInfo from "../FeeSystem/StudentInfo";
import moment from "moment";
import PrintHeader from "../General/PrintHeader";
import Page from "../General/Page";
import { calculateColumnTotal, getMonthName } from "../General/Utility";
import { scrollToTop } from "../Shared/ScrollOnTop";

const PrintAttendanceReport = () => {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [loading, setLoading] = useState(false);
  const [listOfLedger, setLedger] = useState([]);
  const [Year, setYear] = useState("");
  const [Month, setMonth] = useState("");
//   const [EmployeeTypeId, setEmployeeTypeId] = useState();
//   const [leaveType, setleaveType] = useState();

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [TotalAmount, setTotalAmount] = useState(0);

  useEffect(() => {
    document.title = "Print Attendance Reports";

    setLoading(true);
    // Get the URL parameters
    const urlParams = new URLSearchParams(window.location.search);

    // Retrieve individual parameter values
    const Month = urlParams.get("Month");
    const Year = urlParams.get("Year");
    const LeaveType = urlParams.get("leaveType");
    const StaffId = urlParams.get("staffIid");

    setYear(Year);
    setMonth(Month);
    // setleaveType(LeaveType);
    // setEmployeeTypeId(StaffId);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      SelectedMonth: Month,
      ReportYear: Year,
      PageNo: 1,
      PageSize: 10000,
      leaveType: LeaveType,
      StaffTypeID: StaffId,
    };

    //console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "StaffAttendance/GetStaffAttendanceReport",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log("response of report", response.data);
        setLedger(response.data.ListofRecords);
        setLoading(false);
        setTotalRecords(response.data.totalRecords);
        // setTotalAmount(response.data.TotalAmount);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, []);

  const renderLedger = () => {
    if (listOfLedger.length>0 && listOfLedger!=null) {
      let count = 1;
      let countbreak = 1;
      return listOfLedger.map((item, index) => {
        return (
          <tr key={index}>
            <td>{count++}</td>
            <td>{item.ID}</td>
            <td>{item.Name}</td>
            <td>{item.Code}</td>
            <td>{item.Absent_Leave}</td>
            <td>{item.SelectedDesignation.Department}</td>
            <td>{moment(item.Dated).format("DD/MM/YYYY")}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="20">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };

  return (
    <>
      <Page orientation="portrait" size="a4">
        <table className="table table-sm table-bordered table-print">
          <thead>
            <tr>
              <th colSpan={20}>
                <PrintHeader
                  title={`Staff Attendance Report for the month of ${getMonthName(Month)} ${Year} `} 
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td className="text-center" colSpan="8">
                  <Spinner animation="border" role="status"></Spinner>
                </td>
              </tr>
            ) : (
              <>
           
                <tr>
                  <th>Sr.</th>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Code</th>
                  <th>Absent Leave</th>
                  <th>Designation</th>
                  <th>Dated</th>
                </tr>
                {renderLedger()}
              </>
            )}
          </tbody>
        </table>








        <div style={{ marginTop: "30px", width: "100%" }}>
                    <table className="table table-sm table-bordered table-print" style={{ borderCollapse: "collapse", border: "transparent" }}>
                        <tbody>
                            <tr className="my-5">
                                <th style={{ width: "33.33%", textAlign: "left", textDecoration: "underline" }}>
                                Accountant
                                </th>
                                <th style={{ width: "33.33%", textAlign: "center", textDecoration: "underline" }}>
                                    Burser
                                </th>
                                <th style={{ width: "33.33%", textAlign: "right", textDecoration: "underline" }}>
                                    Head of Wing / Deptt
                                </th>
                            </tr>
                            <tr className="my-5">
                                <br /><br />
                            </tr>
                            <tr className="my-5">
                                <th style={{ width: "33.33%", textAlign: "left", textDecoration: "underline" }}>
                                    Admin Officer
                                </th>
                                <th style={{ width: "33.33%", textAlign: "center", textDecoration: "underline" }}>

                                </th>
                                <th style={{ width: "33.33%", textAlign: "right", textDecoration: "underline" }}>
                                    Principal
                                </th>
                            </tr>
                        </tbody>
                    </table>
                </div>























      </Page>
    </>
  );
};

export default PrintAttendanceReport;
