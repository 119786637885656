import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import { toast, ToastContainer } from 'react-toastify';
import SetupMenu from './SetupMenu';
import bank from '../../assets/images/dashboard_icon/bank.svg';
import wings from '../../assets/images/dashboard_icon/wings.svg';
import section from '../../assets/images/dashboard_icon/section.svg';
import bus_route from '../../assets/images/dashboard_icon/bus_route.svg';
import classes from '../../assets/images/dashboard_icon/classes.svg';
import Academic_Session from '../../assets/images/dashboard_icon/Academic_Session.svg';
import Fee_Type from '../../assets/images/dashboard_icon/Fee_Type.svg';
import Concession_Rules from '../../assets/images/dashboard_icon/Concession_Rules.svg';
import { Link } from 'react-router-dom';
import { scrollToTop } from '../Shared/ScrollOnTop';

function SetupDashboard(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [DefaultSession, setDefaultSession] = useState(localStorage.getItem("DefaultSession"));
    const [dData, setdData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        document.title = "Setup Dashboard";

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            SessionID: DefaultSession

        };
        //console.log(data);
        var api_config = {
            method: 'post',
            url: config.base_url + 'Setup/GetSetupDashboard',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
               //console.log(response.data);
                if (response.data.status_code == 1) {
                    setdData(response.data);

                }
                setLoading(false);
            })
            .catch(function (error) {
                setLoading(false);
                toast.error('Network Error..', {
                    position: toast.POSITION.TOP_RIGHT
                });
            });


    }, []);


    return (
        <>
            <SetupMenu />
            <ToastContainer />
            <div className="page-head">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/Dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Setup</li>
                                </ol>
                            </nav>
                            <div className="page-title">Setup Dashboard</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-content">
                <div className="container">
                    <div className="row align-items-center gy-3">
                        <div className="col-md-3 col-sm-6">
                            <Link to="/AnnualTasks/ManageSessions" className="card">
                                <div className="card-body text-center">
                                    <img src={Academic_Session} className="pb-3" alt="" />
                                    <p className="m-0">Academic Session</p>
                                    <h4 className="m-2">{dData.AcademicSession}</h4>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <Link to="/Setup/ManageSection" className="card a-card">
                                <div className="card-body text-center">
                                    <img src={section} className="pb-3" alt="" />
                                    <p className="m-0">Sections</p>
                                    <h4 className="m-2">{dData.TotalSections}</h4>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <Link to="/Setup/ManageClasses" className="card">
                                <div className="card-body text-center">
                                    <img src={classes} className="pb-3" alt="" />
                                    <p className="m-0">Classes</p>
                                    <h4 className="m-2">{dData.TotalClasses}</h4>
                                </div>
                            </Link>
                        </div>

                        <div className="col-md-3 col-sm-6">
                            <Link to="/Setup/ManageWings" className="card a-card">
                                <div className="card-body text-center">
                                    <img src={wings} className="pb-3" alt="" />
                                    <p className="m-0">Wings</p>
                                    <h4 className="m-2">{dData.TotalWings}</h4>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <Link to="/Setup/ManageBankAccounts" className="card">
                                <div className="card-body text-center">
                                    <img src={bank} className="pb-3" alt="" />
                                    <p className="m-0">Banks</p>
                                    <h4 className="m-2">{dData.TotalBanks}</h4>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <Link to="/Setup/ManageBusRoutes" className="card a-card">
                                <div className="card-body text-center">
                                    <img src={bus_route} className="pb-3" alt="" />
                                    <p className="m-0">Bus Routes</p>
                                    <h4 className="m-2">{dData.TotalBusRouts}</h4>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <Link to="/Setup/ManageConcession" className="card">
                                <div className="card-body text-center">
                                    <img src={Concession_Rules} className="pb-3" alt="" />
                                    <p className="m-0">Concession Rules</p>
                                    <h4 className="m-2">{dData.TotalConcessionRules}</h4>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <Link to="/Setup/ManageFeeTypes" className="card a-card">
                                <div className="card-body text-center">
                                    <img src={Fee_Type} className="pb-3" alt="" />
                                    <p className="m-0">Fee Types</p>
                                    <h4 className="m-2">{dData.TotalFeeTypes}</h4>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SetupDashboard;