import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { NavLink } from "react-router-dom";
import ExamsDropDown from "../Shared/ExamsDropDown";
import { useParams } from "react-router-dom";
import WingsDropDown from "../Shared/WingsDropDown";
import ClassesDropDown from "../Shared/ClassesDropDown";
import EmployeeDropDown from "../Shared/EmployeeDropDown";
import EmployeeDropDownSearch from "../Shared/EmployeeDropDownSearch";
import { Form } from "react-bootstrap";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SessionsDropDown from "../Shared/SessionsDropDown";
import SubjectDropDown from "../Shared/SubjectDropDown";
import SetupMenu from "./SetupMenu";
import Modal from "react-bootstrap/Modal";
import { scrollToTop } from "../Shared/ScrollOnTop";
import Select from "react-select";

function UpdateSection(props) {
  let params = useParams();
  const storedDefaultYear = localStorage.getItem("DefaultFYear");
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [defaultYear, setDefaultYear] = useState(
    parseInt(storedDefaultYear, 10)
  );
  const [section, setSection] = useState({});
  const [SectionID, setSectionID] = useState(params.id);
  const [loading, setLoading] = useState(false);

  const [validatedTeacher, setvalidatedTeacher] = useState(false);
  const [Teacher, setTeacher] = useState({});
  const [loadingTeacher, setloadingTeacher] = useState(false);
  const [IsTeacherUpdated, setIsTeacherUpdated] = useState(false);

  const [employes, setEmployees] = useState([]);
  const [EmployeeID, setEmployeeID] = useState(0);
  const [EmployeeName, setEmployeeName] = useState("");

  const [startDate, setStartDate] = useState(
    moment(`04/01/${defaultYear}`).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(`03/31/${defaultYear + 1}`).format("YYYY-MM-DD")
  );

  // section related
  const [validatedSection, setvalidatedSection] = useState(false);
  const [loadingSection, setloadingSection] = useState(false);
  const [IsSectionUpdated, setIsSectionUpdated] = useState(false);

  // subjects

  const [subjects, setSubjects] = useState([]);
  const [ExamID, setExamID] = useState(0);
  const [validatedSubject, setvalidatedSubject] = useState(false);
  const [loadingSubjects, setloadingSubjects] = useState(false);
  const [IsSubjectAdded, setIsSubjectAdded] = useState(false);
  const [loadingsubjectButton, setLoadingSubjectButton] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState({});
  const [loadingUpdatesubjectButton, setloadingUpdatesubjectButton] = useState(false);
  const [IsSubjectUpdated, setIsSubjectUpdated] = useState(false);
  const [validatedSubjectUpdate, setValidatedSubjectUpdate] = useState(false);
  const [passingMarks, setPassingMarks] = useState();
  const [totalMarks, settotalMarks] = useState();

  // edit modal
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setSelectedSubject({});
  };
  const handleShow = (item) => {
    setShow(true);
    setSelectedSubject(item);
    //console.log(item);
    setEmployeeName(item.Name);
  };

  useEffect(() => {
    setLoading(true);
    setIsSectionUpdated(false);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      ID: SectionID,
    };

    ////console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Sections/GetSectionByID",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        ////console.log(response.data);
        if (response.data.status_code != 0) {
          setSection(response.data.Section);
        }

        setLoading(false);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, [IsSectionUpdated]);

  useEffect(() => {
    setloadingTeacher(true);
    setIsTeacherUpdated(false);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      ID: SectionID,
    };

    ////console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Sections/GetSectionTeacherBySectionID",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          setTeacher(response.data);

          //console.log(response.data);
        }

        setloadingTeacher(false);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, [IsTeacherUpdated]);

  useEffect(() => {
    setloadingSubjects(true);
    setIsSubjectAdded(false);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      PageNo: 1,
      PageSize: 10,
      Section_Id: SectionID,
      ExamID: ExamID,
    };

    ////console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Sections/GetSectionSubjects",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        if (response.data.status_code != 0) {
          setSubjects(response.data.ListofRecords);
        }
        setloadingSubjects(false);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, [ExamID, IsSubjectAdded]);

  const handleUpdateTeacher = (event) => {
    if(EmployeeID==0){
      toast.error("Please Select a Teacher", {
        position: toast.POSITION.TOP_RIGHT,
      });

    }
    event.preventDefault();
    event.stopPropagation();
 
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setloadingTeacher(true);
     
      const data = {
        UserID: UserID,
        AccessKey: AccessKey,
        MemberType: MemberType,
        Section_ID: SectionID,
        Teacher_ID: EmployeeID,
        Started_Date: startDate,
        Ending_Date: endDate,
      };

      //console.log(data);
      var api_config = {
        method: "post",
        url: config.base_url + "Sections/UpdateSectionTeacher",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(api_config)
        .then(function (response) {
          //console.log(response);
         if (response.data.status_code == 1) {
            toast.success(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }

          setIsTeacherUpdated(true);
          setloadingTeacher(false);
        })
        .catch(function (error) {
          setLoading(false);
          toast.error("Network Error..", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }

    setvalidatedTeacher(true);
  };

  const handleUpdateSection = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setloadingSection(true);
      const data = {
        UserID: UserID,
        AccessKey: AccessKey,
        MemberType: MemberType,
        Section_ID: SectionID,
        Section_Code: event.target.Section_Code.value,
        Section_Name: event.target.Section_Name.value,
        Section_Short_Name: event.target.Section_Short_Name.value,
        Section_Strength_Limit: event.target.Section_Strength_Limit.value,
        Class_Id: event.target.Class_Id.value,
      };

      var api_config = {
        method: "post",
        url: config.base_url + "Sections/UpdateSection",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(api_config)
        .then(function (response) {
         //console.log(response.data);
          if (response.data.status_code == 1) {
            toast.success(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }

          setIsSectionUpdated(true);
          setloadingSection(false);
        })
        .catch(function (error) {
          setLoading(false);
          toast.error("Network Error..", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }

    setvalidatedSection(true);
  };

  const renderSubjects = () => {
    if (subjects != null && subjects.length > 0) {
      return subjects.map((item, index) => {
        return (
          <>
            <tr key={index}>
              <td>{item.SubjectName}</td>
              <td>{item.Total_Marks}</td>
              <td>{item.Pass_Marks}</td>
              <td>{item.Name}</td>
              <td>
                <ul
                  className="inline-action"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li>
                    <button
                      type="button"
                      className="green"
                      onClick={(e) => handleShow(item)}
                    >
                      <i className="ri-edit-line"></i>
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      onClick={(e) => deleteSubject(item.ID)}
                      className="red"
                    >
                      <i className="ri-delete-bin-line"></i>
                    </button>
                  </li>
                </ul>
              </td>
            </tr>
          </>
        );
      });
    }
  };

  const deleteSubject = (ID) => {
    const confirmed = window.confirm("Are you sure you want to delete?");
    if (confirmed) {
      const data = {
        UserID: UserID,
        AccessKey: AccessKey,
        MemberType: MemberType,
        ID: ID,
      };

     //console.log(data);

      var api_config = {
        method: "post",
        url: config.base_url + "Sections/DeleteSectionSubject",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(api_config)
        .then(function (response) {
         //console.log(response.data);
          if (response.data.status_code == 1) {
            toast.success(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }

          setIsSubjectAdded(true);
        })
        .catch(function (error) {
          setLoading(false);
          toast.error("Network Error..", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  const handleAddSubject = (event) => {


 
    event.preventDefault();
    event.stopPropagation();
    if(EmployeeID==0){
      toast.error("Please Select a Teacher", {
        position: toast.POSITION.TOP_RIGHT,
      });
      //console.log("error occured",EmployeeID);

    }
    const totalMark = parseFloat(event.target.Total_Marks.value);
    const passMark = parseFloat(event.target.Pass_Marks.value);

    if (passMark >totalMark) {
      toast.error("Pass marks should be less than total marks", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      } else {
        setLoadingSubjectButton(true);
        const data = {
          UserID: UserID,
          AccessKey: AccessKey,
          MemberType: MemberType,
          SectionID: SectionID,
          Subject_ID: event.target.Subject_ID.value,
          Teacher_ID: EmployeeID,
          Exam_ID: ExamID,
          Total_Marks: event.target.Total_Marks.value,
          Pass_Marks: event.target.Pass_Marks.value,
        };

        ////console.log(data);

        var api_config = {
          method: "post",
          url: config.base_url + "Sections/AddSectionSubject",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };

        axios(api_config)
          .then(function (response) {
            ////console.log(response.data);
            if (response.data.status_code == 1) {
              toast.success(response.data.status_message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            } else {
              toast.error(response.data.status_message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            }

            setIsSubjectAdded(true);
            setLoadingSubjectButton(false);
          })
          .catch(function (error) {
            setLoading(false);
            toast.error("Network Error..", {
              position: toast.POSITION.TOP_RIGHT,
            });
          });
      }

      setvalidatedSubject(true);
    }
  };

  const handleUpdateSubject = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const totalMark = parseFloat(event.target.Total_Marks.value);
    const passMark = parseFloat(event.target.Pass_Marks.value);

    if (passMark > totalMark) {
      toast.error("Pass marks should be less than total marks", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      } else {
        setloadingUpdatesubjectButton(true);
        const data = {
          UserID: UserID,
          AccessKey: AccessKey,
          MemberType: MemberType,
          ID: selectedSubject.ID,
          SectionID: SectionID,
          Subject_ID: event.target.Subject_ID.value,
          Teacher_ID: EmployeeID,
          Exam_ID: ExamID,
          Total_Marks: event.target.Total_Marks.value,
          Pass_Marks: event.target.Pass_Marks.value,
        };

        ////console.log(data);

        var api_config = {
          method: "post",
          url: config.base_url + "Sections/UpdateSectionSubject",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };

        axios(api_config)
          .then(function (response) {
            //console.log(response.data);
            if (response.data.status_code == 1) {
              toast.success(response.data.status_message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            } else {
              toast.error(response.data.status_message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            }

            setIsSubjectAdded(true);
            setloadingUpdatesubjectButton(false);
            handleClose();
          })
          .catch(function (error) {
            setLoading(false);
            toast.error("Network Error..", {
              position: toast.POSITION.TOP_RIGHT,
            });
          });
      }

      setValidatedSubjectUpdate(true);
    }
  };

  const TotalMarks = () => {
    let total = 0;
    subjects.forEach((item) => {
      total += item.Total_Marks;
    });
    return <span>{total}</span>;
  };

  const PassMarks = () => {
    let total = 0;
    subjects.forEach((item) => {
      total += item.Pass_Marks;
    });
    return <span>{total}</span>;
  };

  const dropdownOptions = employes.map((item) => ({
    value: item.ID,
    label: item.Name + "(" + item.Code + ")",
    code: item.Code,
  }));

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "100%", // Set your desired width here
    }),
  };

  const handleDropdownChange = (selectedOption) => {
    //let label = selectedOption.label;

    setEmployeeID(selectedOption.value);
    
  };

  const options = EmployeeDropDownSearch();

  const defaultValue = {
    value: EmployeeID,
    label: EmployeeName,
  };

  return (
    <>
      <ToastContainer />
      <SetupMenu />

      <Modal className="theme-modal" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Section Subject</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            noValidate
            validated={validatedSubjectUpdate}
            onSubmit={handleUpdateSubject}
          >
            <div style={{ padding: "20px" }}>
              <div className="form-group mb-3">
                <label>Select Subject</label>
                <select className="form-select" name="Subject_ID" required>
                  <SubjectDropDown selected={selectedSubject.Subject_ID} />
                </select>
              </div>
              <div className="form-group mb-3">
                <label>Teacher Name</label>
                {/* <select className="form-select" name="Teacher_ID" required>
                                    <EmployeeDropDown selected={selectedSubject.Teacher_ID} />
                                </select> */}
                {!options ? (
                  <Spinner animation="border" size="sm" role="status"></Spinner>
                ) : (
                  <Select
                    styles={customStyles}
                    onChange={handleDropdownChange}
                    options={options}
                    defaultValue={defaultValue}
                    //selectedOption={selectedSubject.Teacher_ID}
                    required
                  />
                )}
              </div>
              <div className="form-group mb-3">
                <label>Total Marks</label>
                <input
                  type="number"
                  defaultValue={selectedSubject.Total_Marks}
                  name="Total_Marks"
                  className="form-control"
                
                />
              </div>
              <div className="form-group mb-3">
                <label>Pass Marks</label>
                <input
                  type="number"
                  defaultValue={selectedSubject.Pass_Marks}
                  name="Pass_Marks"
                  className="form-control"
                />
              </div>
              {loadingUpdatesubjectButton ? (
                <div className="text-center">
                  <Spinner animation="border" role="status"></Spinner>
                </div>
              ) : (
                <button type="submit" className="button button-primary w-100">
                  UPDATE
                </button>
              )}
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      <div className="container body-content">
        <div className="page-head">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <NavLink to="/Dashboard">Dashboard</NavLink>
                    </li>
                    <li className="breadcrumb-item">
                      {" "}
                      <NavLink to="/Setup/SetupDashboard">Setup</NavLink>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Edit Section
                    </li>
                  </ol>
                </nav>
                <div className="page-title">Edit Section</div>
              </div>
              <div className="col-md-6">
                <div className="page-right-actions">
                  <NavLink
                    to="/Setup/ManageSection"
                    className="button button-white button-icon"
                  >
                    Manage Sections
                    <i className="ri-file-list-2-line"></i>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="page-content">
          <div className="container">
            <div className="card">
              <div className="card-body m-md-4">
                <h5 className="mb-3 text-uppercase">
                  <strong>Teacher Details</strong>
                </h5>
                {loading ? (
                  <Spinner animation="border" role="status"></Spinner>
                ) : (
                  <>
                    <Form
                      noValidate
                      validated={validatedTeacher}
                      onSubmit={handleUpdateTeacher}
                    >
                      <div className="row align-items-center">
                        <div className="col-md-6">
                          <div className="form-group mb-3">
                            <label >Current Teacher:</label>
                            <div className="label-gray">
                              {Teacher.TeacherName}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="form-group mb-3">
                            <label >Start Date:</label>
                            <div className="label-gray">
                              {moment(Teacher.Started_Date).format("M/D/YYYY")}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group mb-3">
                            <label >End Date:</label>
                            <div className="label-gray">
                              {moment(Teacher.Ending_Date).format("M/D/YYYY")}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group mb-3">
                            <label>New Teacher: </label>

                            {!options ? (
                              <Spinner
                                animation="border"
                                size="sm"
                                role="status"
                              ></Spinner>
                            ) : (
                              <Select
                                styles={customStyles}
                                onChange={handleDropdownChange}
                                options={options}
                                required
                              />
                            )}

                            {/* <select className="form-select" name="TeacherID" required>
                                                                <option value="">Please Select</option>
                                                                <EmployeeDropDown />
                                                            </select> */}
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group mb-3">
                            <label >Start Date:</label>
                            <input
                              type="date"
                              name="Start_Date"
                              className="form-control"
                              value={startDate}
                              onChange={(e) => setStartDate(e.target.value)}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group mb-3">
                            <label >End Date:</label>
                            <input
                              type="date"
                              name="End_Date"
                              className="form-control"
                              value={endDate}
                              onChange={(e) => setEndDate(e.target.value)}
                              required
                            />
                          </div>
                        </div>
                        {/* here */}
                        <div className="col-md-2 offset-md-10">
                          {loadingTeacher ? (
                            <Spinner animation="border" role="status"></Spinner>
                          ) : (
                            <button
                              type="submit"
                              className="button button-primary w-100"
                            >
                              Update
                            </button>
                          )}
                        </div>
                      </div>
                    </Form>
                  </>
                )}
              </div>
            </div>

            <Form
              noValidate
              validated={validatedSection}
              onSubmit={handleUpdateSection}
            >
              <div className="card mt-3">
                <div className="card-body m-md-4">
                  <h5 className="mb-3 text-uppercase">
                    <strong>Section Details</strong>
                  </h5>
                  <div className="row align-items-center">
                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <label>Select Session: </label>
                        <select className="form-select">
                          <option value="">Please Select</option>

                          <SessionsDropDown
                            selectedSession={
                              section.SelectedClass?.Selected_Session
                                ?.Session_ID
                            }
                          />
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <label >Select Wing: </label>
                        <select className="form-select">
                          <option value="">Please Select</option>
                          <WingsDropDown
                            selectedWingId={section.SelectedClass?.Wing_Id}
                          />
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <label>Select Class:</label>
                        <select
                          className="form-select"
                          name="Class_Id"
                          required
                        >
                          <option value="">Please Select</option>
                          <ClassesDropDown
                            SessionID={
                              section.SelectedClass?.Selected_Session
                                ?.Session_ID
                            }
                            WingID={section.SelectedClass?.Wing_Id}
                            selectedClassId={section.SelectedClass?.Class_Id}
                          />
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <label > Section Code:</label>
                        <input
                          type="text"
                          defaultValue={section.Section_Code}
                          name="Section_Code"
                          className="form-control"
                          required=""
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label  className="">
                          {" "}
                          Section Short Name:
                        </label>
                        <input
                          type="text"
                          defaultValue={section.Section_Short_Name}
                          name="Section_Short_Name"
                          className="form-control"
                          required=""
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label  className="">
                          {" "}
                          Section Name:
                        </label>
                        <input
                          type="text"
                          defaultValue={section.Section_Name}
                          name="Section_Name"
                          className="form-control"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label  className="">
                          {" "}
                          Strength Limit:
                        </label>
                        <input
                          type="number"
                          defaultValue={section.Section_Strength_Limit}
                          name="Section_Strength_Limit"
                          className="form-control"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-2 offset-md-1">
                      <div className="form-group mt-md-4">
                        {loadingSection ? (
                          <Spinner animation="border" role="status"></Spinner>
                        ) : (
                          <button
                            type="submit"
                            className="button button-primary w-100"
                          >
                            Update
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>

        <div className="container mt-3">
          <div className="card" style={{ minHeight: "400px" }}>
            <div className="card-body">
              <div className="row align-items-center mb-3">
                <div className="col-md-6">
                  <p className="m-0">
                    <b>Section Subject Details</b>
                  </p>
                </div>
                <div className="col-md-6">
                  <div className="d-flex align-items-center justify-content-end">
                    <span className="me-2" style={{ whiteSpace: "nowrap" }}>
                      Select Exam:
                    </span>
                    {loading ? (
                      <Spinner
                        animation="border"
                        size="sm"
                        role="status"
                      ></Spinner>
                    ) : (
                      <select
                        className="form-select"
                        style={{ width: "400px" }}
                        value={ExamID}
                        onChange={(e) => setExamID(e.target.value)}
                      >
                        <option value="">Please Select</option>
                        <ExamsDropDown
                          SessionID={
                            section.SelectedClass?.Selected_Session?.Session_ID
                          }
                        />
                      </select>
                    )}
                  </div>
                </div>
              </div>
              {ExamID > 0 && (
                <Form
                  noValidate
                  validated={validatedSubject}
                  onSubmit={handleAddSubject}
                >
                  <table className="table table-theme">
                    <thead>
                      <tr>
                        <th>Subject</th>
                        <th>Total Marks</th>
                        <th>Pass Marks</th>
                        <th>Teacher Name</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <select
                            className="form-select"
                            name="Subject_ID"
                            style={{ width: "200px" }}
                            required
                          >
                            <option value="">Please Select</option>
                            <SubjectDropDown />
                          </select>
                        </td>
                        <td>
                          <input
                            type="number"
                            style={{ width: "100px" }}
                            name="Total_Marks"
                            className="form-control"
                            required
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            style={{ width: "100px" }}
                            name="Pass_Marks"
                            className="form-control"
                            required
                          />
                        </td>
                        <td>
                          {/* <select style={{ width: "150px" }} name="Teacher_ID" className="form-select" required>
                                                        <option value="">Please Select</option>
                                                        <EmployeeDropDown />
                                                    </select> */}

                          {!options ? (
                            <Spinner
                              animation="border"
                              size="sm"
                              role="status"
                            ></Spinner>
                          ) : (
                            <Select
                              styles={customStyles}
                              onChange={handleDropdownChange}
                              options={options}
                              required
                            />
                          )}
                        </td>
                        <td className="text-end">
                          <ul
                            className="inline-action"
                            aria-labelledby="dropdownMenuButton1"
                          >
                            <li>
                              {loadingsubjectButton ? (
                                <Spinner
                                  animation="grow"
                                  size="sm"
                                  variant="danger"
                                />
                              ) : (
                                <button type="submit" className="blue">
                                  <i className="ri-add-fill fs-4"></i>
                                </button>
                              )}
                            </li>
                          </ul>
                        </td>
                      </tr>
                      {loadingSubjects ? (
                        <tr>
                          <td colSpan={5} className="text-center">
                            <Spinner animation="border" role="status"></Spinner>
                          </td>
                        </tr>
                      ) : (
                        <>
                          {renderSubjects()}
                          <tr>
                            <td className="text-end">Total:</td>
                            <td>{TotalMarks()}</td>
                            <td>{PassMarks()}</td>
                            <td></td>
                            <td></td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </table>
                </Form>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UpdateSection;
