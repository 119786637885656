import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate, useParams } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import Pagination from "../General/Pagination";
import FeeSystemMenu from "./FeeSystemMenu";
import { scrollToTop } from "../Shared/ScrollOnTop";
import csvDownload from "json-to-csv-export";


function CollectionReport(props) {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );

  const [loading, setLoading] = useState(false);
  const [totalDeposit, settotalDeposit] = useState("");
  const [StartDate, setStartDate] = useState(null);
  const [EndDate, setEndDate] = useState(null);

  const [ledger, setLedger] = useState([]);
  const [ledgerRecord, setLedgerRecord] = useState([]);
  const [showDateColumn, setShowDateColumn] = useState(true)
  const [hideZeroColumn, setHideZeroColumn] = useState(true)

  const [loaddata, setLoaddata] = useState(false)

  let totalReceipts = 0;

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);

  useEffect(() => {
    setLoading(true);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      StartDate: StartDate,
      EndDate: EndDate,
      pageNo: pageNo,
      pageSize: pageSize,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "FeeSystem/GetCollectionReport",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        if (response.data.status_code == 1) {
          setLedger(response.data.ListofRecords);
          setTotalRecords(response.data.totalRecords);
        }

        setLoading(false);
      })
      .catch(function (error) { });
  }, [pageNo, pageSize]);

  const renderLedger = () => {
    if (ledger.length != null && ledger.length > 0) {
      return ledger.map((item, index) => {
        totalReceipts += item.NoofReceipts;
        return (
          <tr key={index}>
            <td>{item.Serial_No}</td>
            <td>{item.BenchNumber}</td>
            <td>{moment(item.Dated).format(config.date_format)}</td>
            <td>{item.NoofReceipts}</td>
            <td>{item.TotalDeposited}</td>
            <td>
              <ul className="inline-action">
                <li>
                  <NavLink
                    to={
                      "/FeeSystem/CollectionReportDetail/" +
                      item.BenchNumber +
                      "/" +
                      item.Dated
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    className=" green"
                  >
                    <i className="ri-printer-line"></i>
                  </NavLink>
                </li>
              </ul>
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="6">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };

  const GetReport = () => {
    setLoading(true);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      StartDate: StartDate,
      EndDate: EndDate,
      pageNo: pageNo,
      pageSize: pageSize,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "FeeSystem/GetCollectionReport",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        if (response.data.status_code == 1) {
          setLedger(response.data.ListofRecords);
          setTotalRecords(response.data.totalRecords);
          settotalDeposit(response.data.totalDeposit);
        }

        setLoading(false);
      })
      .catch(function (error) {
        toast.error("Network Error..", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };
  const handlePageChange = (page) => {
    setPageNo(page);
  };

  let count = 0;
  const listOfLedgerNew = ledgerRecord.map(({ ...rest }) => ({
    ...rest,
  }));

  const dataToConvert = {
    data: listOfLedgerNew,
    filename: "CollectionReportData",
    delimiter: ",",
    // headers: ['Serial_No','Reg_No' ,"Name","Father_Name", "DOB", "DOJ", "Mobile","Wing","Class","Section"]
  };



  useEffect(() => {



  }, []);


  const handleDownload = () => {
    setLoaddata(true);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      StartDate: StartDate,
      EndDate: EndDate,
      ShowDateColumn: showDateColumn,
      HideZeroColumns:hideZeroColumn
    };

    var api_config = {
      method: "post",
      url: config.base_url + "FeeSystem/FeePostingDetails",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);

        if (response.data.status_code === 1) {
          if (response.data.TotalRecords > 0) {
            setLedgerRecord(response.data.ListofRecords);

            let data = response.data.ListofRecords;

            const listOfLedgerNew = data.map(({ ...rest }) => ({
              ...rest,
              Transaction_Date: showDateColumn ? moment(rest.Transaction_Date).format("DD/MM/YYYY") :null
         
            }));

            const dataToConvert = {
              data: listOfLedgerNew,
              filename: "CollectionReportData",
              delimiter: ",",
            };

            csvDownload(dataToConvert);
            setLoaddata(false);
          } else if (response.data.TotalRecords === 0) {
            toast.error("There is no data available for download", {
              position: toast.POSITION.TOP_RIGHT,
            });
            setLoaddata(false);
          }
        } else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoaddata(false);
        }
      })
      .catch(function (error) {
        toast.error("Network Error..", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoaddata(false);
      });
  };

  return (
    <>
      <ToastContainer />

      <FeeSystemMenu />
      <div className="container body-content">
        <div className="page-head">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <NavLink to="/Dashboard">Dashboard</NavLink>
                    </li>
                    <li className="breadcrumb-item">
                      <NavLink to="/FeeSystem/FeeSystemDashboard">
                        Fee System
                      </NavLink>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Collection Report
                    </li>
                  </ol>
                </nav>
                <div className="page-title">Collection Report</div>
              </div>
              <div className="col-md-6">
                <div className="page-right-actions">
                  <a
                    href="javascript:;"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFilteres"
                    className="button button-white button-icon"
                  >
                    Filters
                    <i className="ri-filter-3-line"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="collapse show" id="collapseFilteres">
          <div className="page-content">
            <div className="container">
              <div className="card mb-2">
                <div className="card-body p-md-4">
                  <div className="row align-items-center">
                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <label>Start Date :</label>
                        <input
                          type="date"
                          className="form-control"
                          onChange={(e) => setStartDate(e.target.value)}
                          max={moment().format(config.date_format_input)} 
                          min={moment().subtract(4, 'months').format(config.date_format_input)}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <label>End Date :</label>
                        <input
                          type="date"
                          className="form-control"
                          onChange={(e) => setEndDate(e.target.value)}
                          max={moment().format(config.date_format_input)} 
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-2 offset-md-4">
                      <div className="form-group text-end">
                        <button
                          type="button"
                          onClick={GetReport}
                          className="button button-primary w-100"
                        >
                          View Report
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="page-content">
          <div className="container">
            <div className="card">
              <div className="card-body">
                <table className="table table-theme">
                  <thead>
                    <tr>
                      <th colSpan="6">{totalRecords} Records Found!</th>
                    </tr>

                    <tr>
                      <th>Serial No</th>
                      <th>Bench No.</th>
                      <th>Dated</th>
                      <th>No. of Receipts</th>
                      <th>Total Deposited</th>
                      <th className="text-center">Print Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td className="text-center" colSpan="6">
                          <Spinner animation="border" role="status"></Spinner>
                        </td>
                      </tr>
                    ) : (
                      <>{renderLedger()}</>
                    )}
                    <tr>
                      <td colSpan={2}></td>
                      <td>
                        <b>Total </b>
                      </td>
                      <td>
                        <b>{totalReceipts}</b>
                      </td>
                      <td>
                        <b>{totalDeposit}</b>
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
                {totalRecords > 10 && (
                  <div className="pagination-wrap">
                    <div className="row align-items-center">
                      <div className="col-md-2 form_default">
                        <div className="d-flex align-items-center">
                          <label className="me-2" style={{ fontSize: "15px" }}>
                            Page Size
                          </label>
                          <div className="form-group">
                            <select
                              className="form-select"
                              style={{ width: "100px" }}
                              onChange={(e) => {
                                setPageNo(1);
                                setPageSize(parseInt(e.target.value));
                              }}
                            >
                              <option value="10" selected>
                                10
                              </option>
                              <option value="20">20</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                              <option value="500">500</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <Pagination
                        pageNo={pageNo}
                        pageSize={pageSize}
                        totalRecords={totalRecords}
                        onPageChange={handlePageChange}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="container">
            <div className="card mt-2">
              <div className="card-body p-md-4">
                <div className="row text-end align-items-center">

                <div className="col-md-2 offset-2">
                  <lable>Hide Zero Columns</lable>
                  </div>

                  <div className="col-md-1  text-end">

                    <div className="form-check ">

                      <input
                        className="form-check-input"
                        checked={hideZeroColumn}
                        type="checkbox"
                        onChange={() => setHideZeroColumn(!hideZeroColumn)}
                      />
                     
                    </div>
                  </div>
                  <div className="col-md-2  ">
                  <lable>Show Date Column</lable>
                  </div>

                  <div className="col-md-1  text-end">

                    <div className="form-check ">

                      <input
                        className="form-check-input"
                        checked={showDateColumn}
                        type="checkbox"
                        onChange={() => setShowDateColumn(!showDateColumn)}
                      />
                     
                    </div>
                  </div>
                  {loaddata ?
                    <div className="col-md-2 ">
                      <div className="text-center" >
                        <Spinner animation="border" role="status"></Spinner>
                      </div>
                    </div>

                    : <div className="col-md-2 ">
                      <button
                        type="button"
                        className="button button-primary w-100"
                        onClick={handleDownload}
                      >
                        Download Data
                      </button>
                    </div>}



                  <div className="col-md-2">
                    <NavLink
                      className={`button button-primary w-100 ${ledger.length == 0 ? "disabled" : ""
                        }`}
                      to={`/FeeSystem/PrintCollectionReport/?start=${StartDate}&end=${EndDate}`}
                      target="_blank"
                    >
                      Print Report
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CollectionReport;
