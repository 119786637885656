import React, { useState, useEffect } from 'react';
import config from "../../Config";
import axios from "axios";


function SectionDropDown(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [classes, setClasses] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
            
        setLoading(true);

        if(props.ClassID > 0) {
        const data = {
           AccessKey: AccessKey,
            UserID: UserID,
MemberType: MemberType,
            Class_Id: props.ClassID,
            pageNo: 1,
            pageSize: 1000,
        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'Sections/GetAllSections',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

      //console.log(data);

        axios(api_config)
            .then(function (response) {
                ////console.log(response.data);
                setClasses(response.data.ListofRecords);
                setLoading(false);
            })
            .catch(function (error) {
                //console.log(error);
            });
        }
        else {
            setLoading(false);
        }

    }, [props.ClassID]);


    if(loading) {
        return <option selected disabled>Loading...</option>
    }
    else {
        if(classes.length > 0) {
            return (
                
                classes.map((item, index) => {
                    return (
                        <option value={item.Section_ID} key={index} selected={item.Section_ID === props.selectedSectionId}>{item.Section_Name}</option>
                    )
                })
            );
        }
    }
}

export default SectionDropDown;
