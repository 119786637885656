import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import SecurityMenu from "./SecurityMenu";
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate, useParams } from 'react-router-dom';
import { NavLink } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { Form } from "react-bootstrap";
import moment from "moment";
import UserGroupDropDown from "../Shared/UserGroupDropDown";
import { scrollToTop } from '../Shared/ScrollOnTop';

function EditGroup(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [validated, setValidated] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [hasError, setHasError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [UserGroup, setUserGroup] = useState({});
    let params = useParams();

    const [GroupID, setGroupID] = useState(params.id);
    
    let navigate = useNavigate();

    useEffect(() => {
        document.title = "Add/Update User Group";
        
        const data = {
           AccessKey: AccessKey,
            UserID: UserID,
MemberType: MemberType,
            ID: GroupID            
        };
        //console.log(data);
        var api_config = {
            method: 'post',
            url: config.base_url + 'Members/GetUserGroupByID',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
        .then(function (response) {
            //console.log(response.data);
            if (response.data.status_code == 1) {
                setUserGroup(response.data.UserGroup);

            }
           

            setLoading(false);


        })
        .catch(function (error) {
            setLoading(false);
            toast.error('Network Error..', {
                position: toast.POSITION.TOP_RIGHT
            });
        });


    }, []);
    
    const handleSubmit =(event)=>{
        
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            setLoading(true);
            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
MemberType: MemberType,
                ID: GroupID,
                GroupName: event.target.txtGroupName.value,
                GroupDetails: event.target.txtGroupDetails.value,
            };

          //console.log(data);

            var api_config = {
                method: 'post',
                url: config.base_url + 'Members/UpdateSecurityGroup',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };



            axios(api_config)
                .then(function (response) {
                    //console.log(response.data);
                    if (response.data.status_code == 1) {
                       
                        toast.success(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });

                    }
                    else {
                        toast.error(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }

                    setLoading(false);


                })
                .catch(function (error) {
                    setLoading(false);
                    toast.error('Network Error..', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                });

        }

        setValidated(true);
    };
    

    return (
        <>
            {/* <LoginCheck /> */}
            <SecurityMenu />
            <ToastContainer />
            <div className="page-head">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/Home/Index">Dashboard</a></li>
                                    <li className="breadcrumb-item"><a href="/Security/SecurityDashboard">Security</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Add Group</li>
                                </ol>
                            </nav>
                            <div className="page-title">Update Group</div>
                        </div>
                        <div className="col-md-6">
                            <div className="page-right-actions">
                                <NavLink to="/Security/GroupManagement" className="button button-white button-icon">
                                    Manage Groups
                                    <i className="ri-list-check"></i>
                                </NavLink>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-content">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <div className="card">

                                <div className="card-body p-md-4">
                                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                        <div className="row align-items-center">
                                            <div className="col-md-3">
                                                <div className="form-group mb-3">
                                                    <label >Group Name:</label>
                                                    <input type="text" defaultValue={UserGroup.GroupName} className="form-control" required name="txtGroupName" />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group mb-3">
                                                    <label  className="">Group Details</label>
                                                    <input type="text" defaultValue={UserGroup.GroupDetails} className="form-control" required name="txtGroupDetails" />
                                                </div>
                                            </div>
                                            
                                           
                                            <div className="col-md-2 offset-md-3 text-end">
                                                <div className="form-group">
                                                    {
                                                        loading ?
                                                            <Spinner animation="border" role="status"></Spinner>

                                                            :
                                                            <button type="submit" value="Save" className="button button-primary w-100">SAVE</button>

                                                    }

                                                </div>
                                            </div>

                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EditGroup;