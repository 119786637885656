import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate, useParams } from 'react-router-dom';
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import moment from "moment";
import Pagination from "../General/Pagination";
import PayrollMenu from './PayrollMenu';
import ShowStaff from "../Shared/ShowStaff";
import SalaryTypeDropDown from "../Shared/SalaryTypeDropDown";
import { Form } from "react-bootstrap";
import { scrollToTop } from '../Shared/ScrollOnTop';

function ManageSalary(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [loading, setLoading] = useState(false);
    const [listOfLedger, setLedger] = useState([]);
    const [isUpdated, setIsUpdated] = useState(false);
    const [validated, setValidated] = useState(false);


    let params = useParams();
    const [EmployeeID, setEmployeeID] = useState(params.id);
    let totalSelary = 0;
    // pagination 
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(100);
    const [totalRecords, setTotalRecords] = useState(0);

    let navigate = useNavigate();

    useEffect(() => {

        document.title = "View Staff Salary";

        setIsUpdated(false);
        setLoading(true);

        const data = {
           AccessKey: AccessKey,
            UserID: UserID,
MemberType: MemberType,
            pageNo: pageNo,
            pageSize: pageSize,
            ID: EmployeeID,

        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'Payroll/GetEmployeeSalary',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                setLedger(response.data.ListofRecords);
                setLoading(false);
                setTotalRecords(response.data.totalRecords);

            })
            .catch(function (error) {
                //console.log(error);
            });

    }, [pageNo, pageSize, isUpdated]);

    const handlePageChange = (page) => {
        setPageNo(page);
    };

    const renderLedger = () => {

        if (listOfLedger != null && listOfLedger.length > 0) {
            let count = 1;
            return (
                listOfLedger.map((item, index) => {
        totalSelary += item.Salary_Value;

                    return (
                        <tr key={index}>
                            <td>{count++ + (pageSize * (pageNo - 1))}</td>
                            <td>{item.ID}</td>
                            <td>{item.Name}</td>
                            <td>{moment(item.Last_Updated).format(config.date_format)}</td>
                            <td>{item.Salary_Value}</td>
                            <td className="text-center">
                                <ul className="inline-action" aria-labelledby="dropdownMenuButton1">
                                    <li><button onClick={(e) => deleteEmployeeSalary(item.ID)} className="red"><i className="ri-delete-bin-line"></i></button></li>
                                </ul>
                            </td>
                        </tr>
                    )
                })
            )
        }
        else {
            return (
                <tr>
                    <td colSpan="6"><h4 className="mt-3" style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}>No Data Found</h4></td>
                </tr>
            )
        }

    }
    const deleteEmployeeSalary = (ID) => {

        const data = {
           AccessKey: AccessKey,
            UserID: UserID,
MemberType: MemberType,
            ID: ID
        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'Payroll/DeleteEmployeeSalary',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                if (response.data.status_code == 1) {
                    setIsUpdated(true);
                }
                // setIsUpdated(false);

            })
            .catch(function (error) {
                //console.log(error);
            });
    }

    const handleAddSalary = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            setLoading(true);
            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
MemberType: MemberType,
                StaffID: EmployeeID,
                SalaryTypeID: event.target.elements.SalaryType.value,
                Salary_Value: event.target.elements.Amount.value,
            };

            //console.log(data);

            var api_config = {
                method: 'post',
                url: config.base_url + 'Payroll/AddEmployeeSalary',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(api_config)
                .then(function (response) {
                    //console.log(response.data);
                    if (response.data.status_code == 1) {

                        toast.success(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        setIsUpdated(true);

                    }
                    else {
                        toast.error(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }

                    setLoading(false);


                })
                .catch(function (error) {
                    setLoading(false);
                    toast.error('Network Error..', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                });



        }

        setValidated(true);
    };


    return (
        <>
            <PayrollMenu />

            <div className="container body-content">
                <div className="page-head">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><NavLink to="/Dashboard">Dashboard</NavLink></li>
                                        <li className="breadcrumb-item"><NavLink to="/PayRoll/PayRollDashboard">Payroll</NavLink></li>
                                        <li className="breadcrumb-item active" aria-current="page">Manage Employee Salary</li>
                                    </ol>
                                </nav>
                                <div className="page-title">Manage Employee Salary</div>
                            </div>
                            <div className="col-md-6">
                                <div className="page-right-actions">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ShowStaff EmployeeID={EmployeeID} />
                <div className="page-content">
                    <div className="container">
                        <div className="card">
                            <div className="card-body card-form-wrap">

                                <div className="form-wrap">
                                    <div className="form-legend">
                                        Salary Details
                                    </div>
                                    <table className="table table-theme">
                                        <thead>
                                            <tr>
                                                <th colSpan="6">
                                                    {listOfLedger.length}  &nbsp; Records Found!
                                                </th>
                                            </tr>
                                            <tr>
                                                <th>Sr. NO.</th>
                                                <th>ID</th>
                                                <th>Name / Salary Type</th>
                                                <th>Last Updated</th>
                                                <th>Salary Value</th>
                                                <th className="text-center">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                loading ?
                                                    <tr>
                                                        <td className="text-center" colSpan="6">
                                                            <Spinner animation="border" role="status"></Spinner>
                                                        </td>
                                                    </tr>
                                                    :
                                                    <>
                                                        {renderLedger()}
                                                        <tr>
                            <td colSpan="3"></td>
                            <td className="text-end"> 
                              <b> Total </b>
                            </td>
                            <td colSpan="1">
                              <b>{totalSelary} </b>
                            </td>
                            <td></td>
                          </tr>

                                                    </>

                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className="form-wrap">
                                    <div className="form-legend">Attach New Salary Item</div>
                                    <Form noValidate validated={validated} onSubmit={handleAddSalary}>
                                        <div className="row align-items-center">
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label  className="col-form-label col-form-label-sm">Salary Type: </label>
                                                    <select className="form-select" name="SalaryType" required>
                                                        <option value="0" disabled selected>Please Select</option>
                                                        <SalaryTypeDropDown />
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label  className="col-form-label col-form-label-sm">Amount:</label>
                                                    <input type="text" className="form-control" name="Amount" required />
                                                </div>
                                            </div>
                                            <div className="col-md-6 text-end">
                                                <div className="form-group">
                                                    <input type="submit" value="Save" className="button button-primary mt-5" />
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>




        </>
    );
}

export default ManageSalary;