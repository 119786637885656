import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate, useParams } from 'react-router-dom';
import Page from "../General/Page";
import BioDataForms from "../Shared/BioDataForms";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import PrintHeader from '../General/PrintHeader';
import user from "../../assets/images/user.png";

const PrintBioDataFormsforSection = () => {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [listOfLedger, setLedger] = useState("");
    const [isUpdated, setIsUpdated] = useState(false);
    let params = useParams();
    const [SectionID, setSectionID] = useState(params.id);


    useEffect(() => {

        document.title = "Students BIO Data Details";
        // { //console.log("SectionID", SectionID) }
        setIsUpdated(false);
        setLoading(true);

        // setselectedMonth(moment().format('MMMM'));

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            pageNo: 1,
            pageSize: 2000,
            Section_Id: SectionID,
        };

       //console.log(data);

        var api_config = {
            method: 'post',
            url: config.base_url + 'Student/SearchStudents',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

       //console.log(data)
        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                setLedger(response.data.ListofRecords);
                setLoading(false);
                setTotalRecords(response.data.totalRecords);

            })
            .catch(function (error) {
                //console.log(error);
            });

    }, []);


    const renderLedger = () => {

        if (listOfLedger.length > 0) {
            let count = 1;
            let countbreak = 1;
            return (
                listOfLedger.map((item, index) => {
                    return (
                        <>
                            <tbody key={index}>
                                <tr style={{ border: "transparent" }}>
                                    <td style={{ width: "75%" }}>
                                        <div>
                                            <h6 className=""><b>Registration Details</b></h6>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ width: "150px" }}>Form No</td>
                                                        <td style={{ width: "10px" }}>:</td>
                                                        <td>{item.Form_No}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Registration No</td>
                                                        <td>:</td>
                                                        <td>{item.Registration_No}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Student ID</td>
                                                        <td>:</td>
                                                        <td>{item.Student_ID}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Bay Form No</td>
                                                        <td>:</td>
                                                        <td>{item.Bay_Form_No}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </td>
                                    <td align="right" valign="top">
                                        {/* {Student.Image} */}
                                        <img src={user} />
                                    </td>
                                </tr>
                                <tr style={{ border: "transparent" }}>
                                    <td style={{ width: "75%" }}>
                                        <div>
                                            <h6 className=""><b>Basic Information</b></h6>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ width: "150px" }}>Student Name</td>
                                                        <td style={{ width: "10px" }}>:</td>
                                                        <td>{item.Student_Name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Father Name</td>
                                                        <td>:</td>
                                                        <td>{item.Father_Name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Father CNIC No</td>
                                                        <td>:</td>
                                                        <td>{item.Father_CNIC}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </td>
                                    <td>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>Gender</td>
                                                    <td>:</td>
                                                    <td>{item.Gender ? 'Male' : 'Female'}</td>
                                                </tr>
                                                <tr>
                                                    <td>Date of Birth</td>
                                                    <td>:</td>
                                                    <td>{moment(item.Date_Of_Birth).format('DD/MM/YYYY')}</td>
                                                </tr>
                                                <tr>
                                                    <td>Group</td>
                                                    <td>:</td>
                                                    <td>{item.Student_Group_Id}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr style={{ border: "transparent" }}>
                                    <td colSpan={3}>
                                        <h6 className=""><b>Contact Details</b></h6>
                                        <table style={{ width: "100%" }}>
                                            <tbody>
                                                <tr>
                                                    <td style={{ width: "150px" }}>Temporary Address</td>
                                                    <td style={{ width: "10px" }}>:</td>
                                                    <td>{item.Temporary_Address}</td>
                                                </tr>
                                                <tr>
                                                    <td>Permanent Residence</td>
                                                    <td>:</td>
                                                    <td>{item.Permanent_Address}</td>
                                                </tr>
                                                <tr>
                                                    <td>Contact Phone</td>
                                                    <td>:</td>
                                                    <td>{item.Contact_Phone}</td>
                                                </tr>
                                                <tr>
                                                    <td>Mobile</td>
                                                    <td>:</td>
                                                    <td>{item.Contact_Mobile}</td>
                                                </tr>
                                                <tr>
                                                    <td>Email Address</td>
                                                    <td>:</td>
                                                    <td>{item.Contact_Email}</td>
                                                </tr>
                                                <tr>
                                                    <td>Class Details</td>
                                                    <td>:</td>
                                                    <td>{item?.SelectedClass?.Class_Name}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr style={{ border: "transparent" }}>
                                    <td colSpan={2}>
                                        <h6 className=""><b>Facilities Taking</b></h6>
                                        <table style={{ width: "100%" }}>
                                            <tbody>
                                                <tr>
                                                    <td style={{ width: "150px" }}>Transport Facility</td>
                                                    <td style={{ width: "10px" }}>:</td>
                                                    <td>{item?.SelectedRoot?.Root_Name}</td>
                                                </tr>
                                                <tr>
                                                    <td>Concession Rule</td>
                                                    <td>:</td>
                                                    <td>{item?.SelectedConcessionRule?.Concession_Rule_Name}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr style={{ border: "transparent" }}>
                                    <td colSpan={2}>
                                        <h6 className=""><b>Other Information</b></h6>
                                        <table style={{ width: "100%" }}>
                                            <tbody>
                                                <tr>
                                                    <td style={{ width: "150px" }}>Prev. School Info</td>
                                                    <td style={{ width: "10px" }}>:</td>
                                                    <td>{item.Previous_School_Info}</td>
                                                </tr>
                                                <tr>
                                                    <td>NOC Board</td>
                                                    <td>:</td>
                                                    <td>{item.NOC_Board}</td>
                                                </tr>
                                                <tr>
                                                    <td>Remarks</td>
                                                    <td>:</td>
                                                    <td>{item.Remarks}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr style={{ border: "transparent" }}>
                                    <td colSpan={2}>
                                        <h6 className="" style={{ marginTop: "10px" }}><b>Affidavit</b></h6>
                                        <tr>
                                            <td style={{ color: "black" }} colSpan={3}>
                                                <p>
                                                    I certify that the information provided above is according to the birth certificate / bay form. i am fully responsible for any mistake / mismatch of record.
                                                </p>
                                                <p>
                                                    Parent / Guardian Name:_____________________________________________ Parent / Guardian Signature:_______________________________________________
                                                </p>
                                                <p>
                                                    Student Name: _______________________________________________________ Students Signature :_______________________________________________________
                                                </p>
                                            </td>
                                        </tr>
                                        <tr style={{ border: "1px solid", height: "150pt" }}>
                                            <td style={{ verticalAlign: "top" }} valign="top" colSpan={3}>Remarks</td>
                                        </tr>
                                    </td>
                                </tr>
                            </tbody>
                            <div style={{ pageBreakAfter: "always" }}>
                            </div>
                        </>
                    )
                })
            )
        }
        else {
            return (
                <tr>
                    <td colSpan="7"><h4 className="mt-3" style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}>No Data Found</h4></td>
                </tr>
            )
        }

    }

    return (
        <>
            <ToastContainer />
            <Page orientation="portrait" size="a4">
                <table className="table table-sm table-bordered table-print white-space-wrap">
                    <thead>
                        <tr>
                            <th colSpan={2}>
                                <PrintHeader title={`Students BIO Data Details`} />
                            </th>
                        </tr>
                    </thead>
                    {
                        loading ?
                            <tr>
                                <td className="text-center" colSpan="70">
                                    <Spinner animation="border" role="status"></Spinner>
                                </td>
                            </tr>
                            :
                            <>
                                {renderLedger()}
                            </>
                    }
                </table>
            </Page>
        </>
    );
}

export default PrintBioDataFormsforSection;
