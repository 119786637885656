import React, { useState, useEffect } from "react";
import TeacherMenu from "./TeacherMenu";
import ShowStaff from "../Shared/ShowStaff";
import { scrollToTop } from "../Shared/ScrollOnTop";
import { NavLink } from "react-router-dom";

function TeacherDashboard(props) {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );

  return (
    <>
      <TeacherMenu />
      <div className=" body-content">
        <div className="page-head">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <NavLink to="/teacher/Dashboard">Home</NavLink>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Teacher Dashboard
                    </li>
                  </ol>
                </nav>
                <div className="page-title">Teacher Dashboard</div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-content">
          <ShowStaff EmployeeID={UserID} />
          <div className="container">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12">
                    <b>Leaves in this Month</b>
                  </div>
                  <div className="col-md-12">
                    <table className="table table-theme">
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Staff Name</th>
                          <th>Description</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody></tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TeacherDashboard;
