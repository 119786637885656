import React, { useState, useEffect } from 'react';
import config from "../../Config";
import axios from "axios";


function SectionByTeacherDropDown(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [SessionID, setSessionID] = useState(0); // useState(localStorage.getItem("DefaultSession"));
    const [Sections, setSections] = useState([]);

    const [loading, setLoading] = useState(false);

    useEffect(() => {

        setLoading(true);
        //console.log(props.Sessionid);
        if (props.Sessionid > 0 ) {

            setSessionID(props.Sessionid);
            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
                MemberType: MemberType,
                SessionID: props.Sessionid,
                TeacherID: UserID,
                pageNo: 1,
                pageSize: 1000,
            };
            //console.log(data);
            var api_config = {
                method: 'post',
                url: config.base_url + 'Sections/GetSectionsByTeacherID',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

             //console.log(data);

            axios(api_config)
                .then(function (response) {
                    //console.log(response.data);
                    setSections(response.data.ListofRecords);
                    setLoading(false);
                })
                .catch(function (error) {
                    //console.log(error);
                });
        }
        else {
            setLoading(false);
        }

    }, [props.Sessionid]);


    if (loading) {
        return <option selected disabled>Loading...</option>
    }
    else {
        if (Sections != null && Sections.length > 0) {
            return (

                Sections.map((item, index) => {
                    return (
                        <option value={item.Section_ID} key={index} selected={item.Section_ID === props.selectedSectionId}>{item.Section_Name + ` ` + item.SelectedClass?.Class_Name}</option>
                    )
                })
            );
        }
    }
}

export default SectionByTeacherDropDown;
