import React from "react";
import imgLogo from "../../assets/images/logo.png"
import moment from "moment/moment";

function PrintHeader(props) {
    return (
        <>
            <div className="report-header">
                <table style={{ width: "100%" }}>
                    <tr>
                        <td style={{ width: "50%" }}>
                            <div className="logo">
                                <img src={imgLogo} alt="logo" />
                            </div>
                        </td>
                        <td style={{ textAlign: "right", fontSize: "10pt",display:"flex",flexDirection:"column"}}>
                            <b>Printed on: </b>
                            <span>
                                {moment().format("MMMM Do YYYY, h:mm:ss a")}
                            </span>
                        </td>
                    </tr>
                </table>
            </div>
            <h3 style={{ fontSize: "13pt", fontWeight: "600" }}>{props.title}</h3>
        </>
    );
}

export default PrintHeader;