import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import PrintHeader from "../General/PrintHeader";
import Page from "../General/Page";
import { useNavigate, useParams } from "react-router-dom";
import { calculateColumnTotal } from "../General/Utility";


const PrintIncomeExpenditure = () => {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [DefaultFYear, setDefaultFYear] = useState(
    localStorage.getItem("DefaultFYear")
  );
  const [listOfLedger, setLedger] = useState([]);

  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [LedgerIncome, setLedgerIncome] = useState([]);
  const [LedgerExpenses, setLedgerExpenses] = useState([]);

  const [Account_Description, setAccount_Description] = useState("");
  const [IncomeTotal, setIncomeTotal] = useState(0);
  const [ExpanditureTotal, setExpanditureTotal] = useState(0);


  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(1000);
  const [totalRecords, setTotalRecords] = useState(0);

  const urlParams = new URLSearchParams(window.location.search);

  const StartDate = urlParams.get("StartDate");
  const EndDate = urlParams.get("EndDate");
  const zeroAccounts = urlParams.get("zeroAccount");


  useEffect(() => {
    document.title = "Print Income Expenditure";
    setLoading(true);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      PageNo: pageNo,
      PageSize: 10000,
      FYear: DefaultFYear,
      StartDate: StartDate,
      EndDate: EndDate,
      ShowZeroAccounts: zeroAccounts,
      Accountname: Account_Description,
    };

    //console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Accounts/GetIncomeExpenditure",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        if (response.data.status_code != 0) {
          setLedgerIncome(response.data.IncomeAccounts);
          setLedgerExpenses(response.data.ExpenditureAccounts);
          setIncomeTotal(response.data.IncomeTotal);
          setExpanditureTotal(response.data.ExpanditureTotal);

        } else {
          setLedgerIncome(null);
          setLedgerExpenses(null);
        }

        setLoading(false);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, [pageNo, pageSize, DefaultFYear, EndDate, zeroAccounts, StartDate]);

  const renderLedgerIncome = () => {
    if (LedgerIncome != null && LedgerIncome.length > 0) {
      let count = 1;
      return LedgerIncome.map((item, index) => {
        return (
          <tr key={index}>
            <td>{item.Account_Code}</td>
            <td>{item.Account_Description}</td>
            <td>{item.Amount}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="4">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };

  const renderLedgerExpenditure = () => {
    if (LedgerExpenses != null && LedgerExpenses.length > 0) {
      let count = 1;
      return LedgerExpenses.map((item, index) => {
        return (
          <tr key={index}>
            <td>{item.Account_Code}</td>
            <td>{item.Account_Description}</td>
            <td>{item.Amount}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="4">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };
  return (
    <>
      <Page orientation="portrait" size="a4">
        <PrintHeader title={`Income & Expenditure Account `} />
        <div className="row">
          <div className="col-md-6">
            <table className="table table-sm table-bordered table-print">
              <tbody>
                <tr>
                  <th colSpan={3}>Income</th>
                </tr>
                <tr>
                  <th>Account Code</th>
                  <th>Account Name</th>
                  <th>Amount</th>
                </tr>
                {loading ? (
                  <tr>
                    <td className="text-center" colSpan="4">
                      <Spinner animation="border" role="status"></Spinner>
                    </td>
                  </tr>
                ) : (
                  <>
                    {renderLedgerIncome()}
                    <tr>                       
                      <td className="text-end" colSpan={3}>
                      Total Amount:
                        {/* {calculateColumnTotal(listOfLedger, "Amount")} */}
                        {IncomeTotal}
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
          </div>
          <div className="col-md-6">
            <table className="table table-sm table-bordered table-print">
              <tbody>
                <tr>
                  <th colSpan={3}>Expenditure</th>
                </tr>
                <tr>
                  <th>Account Code</th>
                  <th>Account Name</th>
                  <th>Amount</th>
                </tr>
                {loading ? (
                  <tr>
                    <td className="text-center" colSpan="4">
                      <Spinner animation="border" role="status"></Spinner>
                    </td>
                  </tr>
                ) : (
                  <>
                    {renderLedgerExpenditure()}
                    <tr>                       
                      <td className="text-end" colSpan={3}>
                      Total Amount:
                        {/* {calculateColumnTotal(listOfLedger, "Amount")} */}
                        {ExpanditureTotal}
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </Page>
    </>
  );
};

export default PrintIncomeExpenditure;
