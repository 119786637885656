import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import AnnualMenu from "./AnnualMenu";
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate } from 'react-router-dom';
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import Pagination from "../General/Pagination";
import { scrollToTop } from '../Shared/ScrollOnTop';

function ManageSessions(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [loading, setLoading] = useState(false);
    const [listOfLedger, setLedger] = useState([]);
    const [isUpdated, setIsUpdated] = useState(false);

    // pagination 
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);


    useEffect(() => {
        document.title = "Manage Sessions";

        setIsUpdated(true);
        setLoading(true);
        const dashboardParms = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            pageNo: pageNo,
            pageSize: pageSize,
            SchoolID: 1
        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'AcademicSession/GetAcademicSessions',
            headers: {
                'Content-Type': 'application/json'
            },
            data: dashboardParms
        };


        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                setLedger(response.data.ListofRecords);
                setLoading(false);
                setTotalRecords(response.data.totalRecords);

            })
            .catch(function (error) {
                //console.log(error);
            });

    }, [pageNo, pageSize, isUpdated]);

    const handlePageChange = (page) => {
        setPageNo(page);
    };




    const renderLedger = () => {

        if (listOfLedger.length > 0) {
            return (
                listOfLedger.map((item, index) => {
                    return (
                        <tr key={index}>
                            <td>{item.Session_ID}</td>
                            <td>{item.Session_Code}</td>
                            <td>{item.Session_Name}</td>
                            <td>{moment(item.Session_Start_Date).format('DD/MM/YYYY')}</td>
                            <td>{moment(item.Session_End_Date).format('DD/MM/YYYY')}</td>
                            <td>
                                <ul className="inline-action" aria-labelledby="dropdownMenuButton1">
                                    <li><NavLink to={'/AnnualTasks/EditSession/' + item.Session_ID} className=" green"><i className="ri-edit-line"></i></NavLink></li>
                                    <li><button onClick={(e) => deleteSession(item.Session_ID)} className="red"><i className="ri-delete-bin-line"></i></button></li>
                                </ul>
                            </td>
                        </tr>
                    )
                })
            )
        }
        else {
            return (
                <tr>
                    <td colSpan="6"><h4 className="mt-3" style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}>No Data Found</h4></td>
                </tr>
            )
        }

    }

    const deleteSession = (ID) => {
const confirm = window.confirm("Are you sure to proceed this action ?")
if(confirm){
    setIsUpdated(false)

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            Session_ID: ID
        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'AcademicSession/DeleteSession',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                if (response.data.status_code == 1) {
                    setIsUpdated(true);
                }
                setIsUpdated(false);

            })
            .catch(function (error) {
                //console.log(error);
            });
        }
    }

    return (
        <>
            {/* <LoginCheck /> */}
            <AnnualMenu />
            <ToastContainer />
            <div className="page-head">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/Dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item"><Link to="/AnnualTasks/AnnualTasksDashboard">Annual Tasks </Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Manage Sessions</li>
                                </ol>
                            </nav>
                            <div className="page-title">MANAGE ACADEMIC SESSIONS</div>
                        </div>
                        <div className="col-md-6">
                            <div className="page-right-actions">
                                <Link to="/AnnualTasks/AddSessions" className="button button-white button-icon">
                                    Add Sessions
                                    <i className="ri-add-line"></i>
                                </Link>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-content">
                <div className="container">
                    <div className="card">
                        <div className="card-body table-wrap">

                            <div className="">
                                <table className="table table-theme table-hover">
                                    <thead>
                                        <tr>
                                            <th>
                                                ID
                                            </th>
                                            <th>
                                                Code
                                            </th>
                                            <th>
                                                Session Name
                                            </th>
                                            <th>
                                                Start Date
                                            </th>
                                            <th>
                                                End Date
                                            </th>
                                            <th className="text-center">
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            loading ?
                                                <tr>
                                                    <td className="text-center" colSpan="6">
                                                        <Spinner animation="border" role="status"></Spinner>
                                                    </td>
                                                </tr>
                                                :
                                                <>
                                                    {renderLedger()}


                                                </>

                                        }


                                    </tbody>
                                </table>

                                {
                                    totalRecords > 10 &&
                                    <div className="pagination-wrap">

                                        <div className="row align-items-center">
                                            <div className="col-md-2 form_default">
                                                <div className="d-flex align-items-center">
                                                    <label className="me-2" style={{ fontSize: "15px" }}>Page Size</label>
                                                    <div className="form-group">
                                                        <select className="form-select"
                                                            style={{ width: "100px" }}
                                                            onChange={(e) => {
                                                                setPageNo(1);
                                                                setPageSize(parseInt(e.target.value));


                                                            }}>
                                                            <option value="10" selected>10</option>
                                                            <option value="20">20</option>
                                                            <option value="50">50</option>
                                                            <option value="100">100</option>
                                                            <option value="500">500</option>

                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <Pagination
                                                pageNo={pageNo}
                                                pageSize={pageSize}
                                                totalRecords={totalRecords}
                                                onPageChange={handlePageChange}
                                            />
                                        </div>
                                    </div>

                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ManageSessions;