import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate } from 'react-router-dom';
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import LibraryMenu from "./LibraryMenu";
import { Dropdown } from "react-bootstrap";
import Pagination from "../General/Pagination";
import { scrollToTop } from '../Shared/ScrollOnTop';
import { Form, PageItem } from 'react-bootstrap';
import Modal from "react-bootstrap/Modal";

function ViewLibrary(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [designationID, setDesignationId] = useState(0);
    const [empTypeID, seEmployeeTypeId] = useState(0);
    const [isUpdated, setIsUpdated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [listOfLedger, setLedger] = useState([]);
    const [showFilters, setShowFilters] = useState(true);
    const [validated, setValidated] = useState(false);

    const [WingId, setWingId] = useState(0);
    const [SessionId, setSessioinId] = useState(
        localStorage.getItem("DefaultSession")
    );
    const [ClassId, setClassId] = useState(0);
    const [SectionID, setSectionID] = useState(0);
    const [WingName, setWingName] = useState("");
    const [ClassName, setClassName] = useState("");
    const [SessionName, setSessionName] = useState("");
    const [GroupId, setGroupId] = useState(0);
    const [Status_Type_Id, SetStatusTypeId] = useState(0);

    // pagination 
    const [pageno, setPageNo] = useState(1);
    const [pagesize, setPageSize] = useState(10);
    const [totalrecords, setTotalRecords] = useState(0);
    const [Status, setStatus] = useState('');
    const [OrderBy, setOrderBy] = useState('Book_No');
    const [BookName, setBookName] = useState('');
    const [WriterName, setWriterName] = useState('');
    const [Publisher, setPublisher] = useState('');
    const [BookNo, setBookNo] = useState('');
    // edit modal
    const [validatedSubjectUpdate, setValidatedSubjectUpdate] = useState(false);
    const [selectedSubject, setSelectedSubject] = useState({});
    const [loadingUpdatesubjectButton, setloadingUpdatesubjectButton] = useState(false);
    const [EmployeeName, setEmployeeName] = useState("");
    const [IsSubjectAdded, setIsSubjectAdded] = useState(false);
    const [Actual_Return_Date, setActual_Return_Date] = useState("");
    const [ReturnRemarks, setReturnRemarks] = useState("");
    const [ID, setID] = useState("0");


    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
        setSelectedSubject({});
    };
    const handleShow = (item) => {
        setShow(true);
        setSelectedSubject(item);
        //console.log(item);
        setEmployeeName(item.Name);
    };

    useEffect(() => {

        document.title = "Manage Library";

        setIsUpdated(false);
        setLoading(true);

        const data = {

            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            pageNo: pageno,
            pageSize: pagesize,
            Book_Name: BookName,
            Writer_Name: WriterName,
            Publisher_Name: Publisher,
            Category: '',
            Book_No: BookNo,
            Status: Status,
            OrderBy: OrderBy,

            // Actual_Return_Date: "555",
            // Remarks : "000",
        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'Library/GetAllBooks',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        //console.log(data);

        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                setLedger(response.data.ListofRecords);
                setLoading(false);
                setTotalRecords(response.data.totalRecords);
                ////console.log(response.data.totalRecords);
            })
            .catch(function (error) {
                //console.log(error);
            });

    }, [pageno, pagesize, isUpdated, BookName, WriterName, Publisher, BookNo, Status, OrderBy]);

    const handleSubmit = (event) => {

        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();

        }
        else {
            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
                MemberType: MemberType,
                pageNo: pageno,
                pageSize: pagesize,
                Book_Name: BookName,
                Writer_Name: WriterName,
                Publisher_Name: Publisher,
                Category: '',
                Book_No: BookNo,
                Status: Status,
                OrderBy: OrderBy,
            };

            var api_config = {
                method: 'post',
                url: config.base_url + 'Library/GetAllBooks',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            //console.log(data);
            axios(api_config)
                .then(function (response) {
                    //console.log(response.data);
                    setLedger(response.data.ListofRecords);
                    setLoading(false);
                    setTotalRecords(response.data.totalRecords);
                    ////console.log(response.data.totalRecords);
                })
                .catch(function (error) {
                    //console.log(error);
                });
        }

        setValidated(true);
    };

    const handlePageChange = (page) => {
        setPageNo(page);
    };

    const renderLedger = () => {

        if (listOfLedger != null && listOfLedger.length > 0) {
            return (
                listOfLedger.map((item, index) => {
                    return (
                        <tr key={index}>
                            <td>{item.ID}</td>
                            <td>{item.Book_No}</td>
                            <td>{item.Writer_Name}</td>
                            <td>{item.Book_Name}</td>
                            <td>{item.Publisher_Name}</td>
                            <td>{item.Current_Status}</td>
                            <td>{item.Remarks}</td>
                            <td>
                                <div className="dropdown dropdown-table-actions">
                                    <Link href="javascript:;" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" className="">
                                        <i className="ri-more-fill"></i>
                                    </Link>
                                    <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                                        <li><NavLink to={'/Library/EditBook/' + item.ID} className="dropdown-item" ><i className="ri-edit-line"></i>Edit</NavLink></li>
                                        {item.Current_Status === "Available" ?
                                            <li><NavLink to={'/Library/IssueBook/' + item.ID} className="dropdown-item" ><i className="ri-pages-line"></i>Issue Book</NavLink></li>
                                            :
                                            <li><NavLink onClick={(e) => handleShow(item)} className="dropdown-item" ><i className="ri-pages-line"></i>Return Book</NavLink></li>
                                        }
                                    </ul>
                                </div>
                            </td>
                        </tr>
                    )
                })
            )
        }
        else {
            return (
                <tr>
                    <td colSpan="8"><h4 className="mt-3" style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}>No Data Found</h4></td>
                </tr>
            )
        }

    };

    const deleteBook = (ID) => {

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            ID: ID
        };

        var api_config = {
            method: 'post',
            url: config.base_url + '/Library/DeleteBook',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                if (response.data.status_code == 1) {
                    setIsUpdated(true);
                }
                // setIsUpdated(false);

            })
            .catch(function (error) {
                //console.log(error);
            });
    };

    const handelReturnBook = (event) => {

        event.preventDefault();
        event.stopPropagation();
        const actualReturnDate = event.target.elements.Actual_Return_Date.value;
        const remarks = event.target.elements.Remarks.value;
        //console.log(remarks)
        handleClose();

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            ID: selectedSubject.ID,
            Actual_Return_Date: actualReturnDate,
            Remarks: remarks,
        };
        //console.log(data);

        var api_config = {
            method: 'post',
            url: config.base_url + '/Library/ReturnBook',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                if (response.data.status_code == 1) {
                    setIsUpdated(true);
                }
                // setIsUpdated(false);

            })
            .catch(function (error) {
                //console.log(error);
            });
    };

    return (
        <>
            <LibraryMenu />
            <ToastContainer />

            <div className="page-head">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/Dashboard">Dashboard</a></li>
                                    <li className="breadcrumb-item"><a href="/Library/LibraryDashboard">Library</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">MANAGE BOOKS LIBRARY</li>
                                </ol>
                            </nav>
                            <div className="page-title">MANAGE BOOKS LIBRARY</div>
                        </div>
                        <div className="col-md-6">
                            <div className="page-right-actions">
                                <a href="/Library/AddBook" className="button button-white button-icon">
                                    Add Book
                                    <i className="ri-add-fill"></i>
                                </a>
                                <button
                                    onClick={() => {
                                        setShowFilters((prevIsActive) => !prevIsActive);
                                    }}
                                    className="button button-white button-icon">
                                    {showFilters ? 'Hide Filters' : 'Show Filters'}
                                    <i className="ri-filter-3-line"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`collapse ${showFilters ? 'show' : ''} `} id="collapseFilteres">
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <div className="page-content">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card mb-md-4">
                                        <div className="card-body card-form-wrap">
                                            <div className="">
                                                <div className="row align-items-center">
                                                    <div className="col-md-3">
                                                        <div className="form-group mb-3">
                                                            <label  className="">Book Name:</label>
                                                            <input type="text"
                                                                value={BookName}
                                                                onChange={(e) => setBookName(e.target.value)}
                                                                className="form-control" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group mb-3">
                                                            <label  className="">Writer Name:</label>
                                                            <input type="text"
                                                                value={WriterName}
                                                                onChange={(e) => setWriterName(e.target.value)}
                                                                className="form-control" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group mb-3">
                                                            <label  className="">Publisher:</label>
                                                            <input type="text"
                                                                value={Publisher}
                                                                onChange={(e) => setPublisher(e.target.value)}
                                                                className="form-control"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group mb-3">
                                                            <label  className="">Book No:</label>
                                                            <input type="text"
                                                                value={BookNo}
                                                                onChange={(e) => setBookNo(e.target.value)}
                                                                className="form-control" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label  className="">Status:</label>
                                                            <select className="form-select"
                                                                value={Status}
                                                                onChange={(e) => setStatus(e.target.value)}
                                                            >
                                                                <option Value="All">--All--</option>
                                                                <option Value="Available">Available</option>
                                                                <option Value="Issued">Issued</option>
                                                                <option Value="Lost">Lost</option>
                                                                <option Value="Disposed">Disposed</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label  className="">Order By:</label>
                                                            <select className="form-select"
                                                                value={OrderBy}
                                                                onChange={(e) => setOrderBy(e.target.value)}
                                                            >
                                                                <option Value="Book_No">Book No</option>
                                                                <option Value="Book_Name">Book Name</option>
                                                                <option Value="Writer_Name">Writer Name</option>
                                                                <option Value="Publisher_Name">Publisher Name</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2 offset-md-4">
                                                        <div className="form-group mt-3">
                                                            <button type="submit" className="button button-primary w-100">Search</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            </div>

            <div className="page-content">
                <div className="container">
                    <div className="card">
                        <div className="card-body">
                            <table className="table table-theme">
                                <thead>
                                    <tr>
                                        <th colSpan="8">
                                            {totalrecords} Total records Found!
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>ID</th>
                                        <th>BookNo</th>
                                        <th>Writer Name</th>
                                        <th>Book Name</th>
                                        <th>Publisher</th>
                                        <th>Status</th>
                                        <th>Remarks</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        loading ?
                                            <tr>
                                                <td className="text-center" colSpan="8">
                                                    <Spinner animation="border" role="status"></Spinner>
                                                </td>
                                            </tr>
                                            :
                                            <>
                                                {renderLedger()}
                                            </>

                                    }
                                </tbody>
                            </table>
                            {
                                totalrecords > 10 &&
                                <div className="pagination-wrap">
                                    <div className="row align-items-center">
                                        <div className="col-md-2 form_default">
                                            <div className="d-flex align-items-center">
                                                <label className="me-2" style={{ fontSize: "15px" }}>Page Size</label>
                                                <div className="form-group">
                                                    <select className="form-select"
                                                        style={{ width: "100px" }}
                                                        onChange={(e) => {
                                                            setPageNo(1);
                                                            setPageSize(parseInt(e.target.value));
                                                        }}>
                                                        <option value="10" selected>10</option>
                                                        <option value="20">20</option>
                                                        <option value="50">50</option>
                                                        <option value="100">100</option>
                                                        <option value="500">500</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <Pagination
                                            pageNo={pageno}
                                            pageSize={pagesize}
                                            totalRecords={totalrecords}
                                            onPageChange={handlePageChange}
                                        />

                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="card mt-2">
                        <div className="card-body p-md-4">
                            <div className="row align-items-center gy-3">
                                <div className="col-md-2 offset-md-10">
                                    <div className="form-group">
                                        <Link
                                            to={`/Library/PrintViewLibrary?BookName=${BookName}&WriterName=${WriterName}&Publisher=${Publisher}&Book-No=${BookNo}&Status=${Status}&Order-by=${OrderBy}`}
                                            target="_black"
                                            className="w-100 button button-primary"
                                        >
                                            Print This List
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal className="theme-modal" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Return Book</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form
                        noValidate
                        validated={validatedSubjectUpdate}
                        onSubmit={handelReturnBook}
                    >
                        <div className="form-group mb-3">
                            <label>Return date: </label>
                            <input
                                type="date"
                                name="Actual_Return_Date"
                                className="form-control"

                            />
                        </div>
                        <div className="form-group mb-3">
                            <label>Remarks:</label>
                            <textarea

                                name="Remarks"
                                className="form-control"
                                required
                            />
                        </div>
                        {loadingUpdatesubjectButton ? (
                            <div className="text-center">
                                <Spinner animation="border" role="status"></Spinner>
                            </div>
                        ) : (
                            <button type="submit" className="button button-primary w-100">
                                Submit
                            </button>
                        )}
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ViewLibrary;