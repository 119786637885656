import moment from 'moment';
import React from 'react';

function CharacterCertificate(props) {
    return (
        <>
            <div style={{ width: '624px', fontSize: '14px', height: 'auto', margin: '336px auto 0 auto', lineHeight: '25px' }} align="left">
                <div align="center">
                    <h3 className="style1" style={{ marginBottom: '20px', textDecoration: "underline" }}>
                    CHARACTER CERTIFICATE</h3>
                </div>
                <div align="right">
                    Student Code: {props.code}
                </div>
                <br />
                <br />
                <div>
                This is certified that <strong>{props.name}</strong> S/O <strong>{props.fathername}</strong>  has been a bonafide student of the {props.school}. During his stay at school, he has been regular in studies, respectful to the teachers and well behaved towards the school fellows. He has been taking active part in co-curricular activities of this institution. He passed his  Passed Examination  examination in the session {props.session} as a regular student. {props.extraline}
                    <br />
                    <br />
                </div>
                We wish his best of luck and success.
                <br />
                <br />
                <div align="left">
                    Dated: {moment().format("MMM DD, YYYY")}
                </div>
                <br />
                <div style={{ marginLeft: '400px', lineHeight: '18px' }}>
                    <b>Principal</b><br />
                    District Jinnah Public School <br />
                    &amp; College, M.B.Din.
                </div>
            </div>
        </>
    );
}

export default CharacterCertificate;