import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import moment, { months } from "moment";
import PrintHeader from "../General/PrintHeader";
import Page from "../General/Page";
import { calculateColumnTotal, getMonthName } from "../General/Utility";

const PrintExamDataEntryOptions = () => {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [listOfLedger, setLedger] = useState([]);

  useEffect(() => {
    document.title = "Print Exam Data Entry Options";

    setLoading(true);
    // Get the URL parameters
    const urlParams = new URLSearchParams(window.location.search);

    // Retrieve individual parameter values
    const ExamID = urlParams.get("ExamID");
    const Wing_Id = urlParams.get("Wing_Id");
    const Class_Id = urlParams.get("Class_Id");
    const Section_Id = urlParams.get("Section_Id");

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      ExamID: ExamID,
      Wing_Id: Wing_Id,
      Class_Id: Class_Id,
      Section_Id: Section_Id,
    };

   //console.log(data);
    var api_config = {
      method: "post",
      url: config.base_url + "Exam/GetExamEntrySheets",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    //console.log(api_config);

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        setLedger(response.data.ListofRecords);
        // setTotalRecords(response.data.totalRecords);
        // setTotalDeductions(response.data.TotalDeductions);
        // setTotalLoans(response.data.TotalLoans);
        // setTotalGross(response.data.TotalGross);
        // setTotalNet(response.data.TotalNet);
        setLoading(false);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, []);

  return (
    <>
      <Page orientation="potrait" size="a4">

        <table className="table table-sm table-bordered table-print" style={{ border: "transparent" }}>
          <thead>
            <tr>
              <th>
                <PrintHeader title="" />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div>
                  {loading ? (
                    <Spinner animation="border" role="status"></Spinner>
                  ) : (
                    <>
                      { listOfLedger && 
                      listOfLedger.map((item, index) => (
                        <div key={index} >
                          <div className="row">
                            <div className="col-md-6">
                            <h6>
                            Data Entry Sheet for:{item.Wing_Name}-{item.Class_Name}-
                            {item.Section_Name}
                          </h6>
                            </div>
                            <div className="col-md-6 text-end">
                            <h6>{item.Section_Teacher_Name}</h6>
                            </div>
                          </div>
                         
                          
                          <table className="table table-sm table-bordered table-print">
                            <thead>
                              <tr>
                                <th style={{ width: "50px", textAlign: "center", verticalAlign: "top" }}>SR #</th>
                                <th style={{ width: "90px", verticalAlign: "top" }}>Code #</th>
                                <th style={{ width: "160px", verticalAlign: "top" }}>Student Name</th>
                                <th style={{ width: "160px", verticalAlign: "top" }}>Father Name</th>
                                {item.ListofSubjects.map((itemSubjects) => (
                                  <th style={{ textAlign: "center", verticalAlign: "top" }}>
                                    {itemSubjects.Name}
                                    <br /> {itemSubjects.Total_Marks}
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {item.StudentsInSection.map((itemStudent, index) => (
                                <tr>
                                  <td className="text-center">{itemStudent.Serial_No}</td>
                                  <td>{itemStudent.Registration_No}</td>
                                  <td>{itemStudent.Student_Name}</td>
                                  <td>{itemStudent.Father_Name}</td>
                                  {itemStudent.ListofSubjectsInner.map(() => (
                                    <td></td>
                                  ))}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </td>
            </tr>
          </tbody>
        </table>

      </Page>
    </>
  );
};

export default PrintExamDataEntryOptions;
