import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import moment from "moment";
import { toast, ToastContainer } from 'react-toastify';
import { NavLink } from "react-router-dom";
import { useNavigate, useParams } from 'react-router-dom';


function ShowStudent(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));

    const [loading, setLoading] = useState(false);
    const [Student, setStudent] = useState({});



    let params = useParams();
    // const [StudentID, setStudentID] = useState(params.id);

    let navigate = useNavigate();

    useEffect(() => {

        if ([props.StudentID] > 0) {

            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
                MemberType: MemberType,
                ID: props.StudentID

            };
            setLoading(true);
            var api_config = {
                method: 'post',
                url: config.base_url + 'Student/GetStudentByID',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(api_config)
                .then(function (response) {
                   //console.log(response.data.Student);
                    if (response.data.status_code == 1) {
                        setStudent(response.data.Student);
                    }
                    setLoading(false);
                })
                .catch(function (error) {
                    setLoading(false);
                    toast.error('Network Error..', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                });
        }
        else {
            setLoading(false);
        }

    }, [props.StudentID]);


    return (
        <>
            <div className="page-content">
                <div className="container">
                    {
                        loading ?
                            <div className="card">
                                <div className="card-body p-3 text-center">
                                    <Spinner animation="border" role="status"></Spinner>
                                </div>
                            </div>
                            :
                            <div className="student-card">
                                <div className="card">
                                    <div className="card-header">
                                        <div className="profile">
                                            <img className="img-fluid" src={config.StudentImagePath + Student.Registration_No + '.jpg'} />
                                        </div>
                                        <div className="user-name">
                                            <h1>{Student.Student_Name}</h1>
                                            <p>{Student.Father_Name}</p>
                                        </div>
                                        <b>Student ID: {Student.Student_ID}</b>
                                    </div>
                                    <div className="card-body">
                                        <div className="menu">
                                            <div className="outline">
                                                <span>Wing:<b> {Student.SelectedClass?.SelectedWing?.Wing_Name}</b></span>
                                            </div>
                                            <div className="outline">
                                                <span>Class:<b> {Student.SelectedClass?.Class_Name}</b></span>
                                            </div>
                                            <div className="outline">
                                                <span>Section:<b> {Student.SelectedSection?.Section_Name}</b></span>
                                            </div>
                                        </div>
                                        <ul className="profile-list">
                                            <li>Registration No:</li>
                                            <li>{Student.Registration_No && Student.Registration_No.length !== 0 ? Student.Registration_No : "----------------------------"}</li>
                                            <li>Mobile No:</li>
                                            {/* <li>{Student.Contact_Mobile}</li> */}
                                            <li>{Student.Contact_Mobile && Student.Contact_Mobile.length !== 0 ? Student.Contact_Mobile : "----------------------------"}</li>
                                            <li>Status:</li>
                                            {/* <li>{Student.Status_Type_Id}</li> */}
                                            <li>{
                                                Student.Status_Type_Id === 1 ? "Normal" :
                                                    Student.Status_Type_Id === 2 ? "Stucked Off" :
                                                        Student.Status_Type_Id === 3 ? "Left" :
                                                            Student.Status_Type_Id === 4 ? "Study Complete" :
                                                                Student.Status_Type_Id === 5 ? "SLC" :
                                                                    Student.Status_Type_Id === 6 ? "Freezed" : "Not Enrolled"

                                            }</li>
                                            {/* <li>{Student.Status_Type_Id && Student.Status_Type_Id.length !== 0 ? Student.Status_Type_Id : "----------------------------"}</li> */}
                                            <li>Gender:</li>
                                            <li>{Student.Gender === 'male' ? (
                                                <>Female</>
                                            ) : (
                                                <>Male</>
                                            )}</li>
                                            <li>Bay Form No:</li>
                                            {/* <li>{Student.Bay_Form_No}</li> */}
                                            <li>{Student.Bay_Form_No && Student.Bay_Form_No.length !== 0 ? Student.Bay_Form_No : "----------------------------"}</li>
                                            <li>Date Of Birth:</li>
                                            <li>{moment(Student.Date_Of_Birth).format('DD/MM/YYYY')}</li>
                                            <li>Date of Joining:</li>
                                            <li>{moment(Student.Date_Of_Joining).format('DD/MM/YYYY')}</li>
                                        </ul>
                                        <div className="location shadow-sm">
                                            <i className="ri-map-pin-fill"></i>
                                            {/* <p>{Student.Permanent_Address}  </p> */}
                                            <p>{Student.Permanent_Address && Student.Permanent_Address.length !== 0 ? Student.Permanent_Address : "----------------------------"}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    }

                </div>
            </div >
        </>
    );
}

export default ShowStudent;