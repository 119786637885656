import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import QRCode from "react-qr-code";
import StudentInfo from "../FeeSystem/StudentInfo";
import moment from "moment";
import PrintHeader from "../General/PrintHeader";
import Page from "../General/Page";
import { calculateColumnTotal, getMonthName } from "../General/Utility";
import { scrollToTop } from "../Shared/ScrollOnTop";

const LoanDeductionsPrint = () => {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [loading, setLoading] = useState(false);
  const [listOfLedger, setLedger] = useState([]);
  const [Year, setYear] = useState("");
  const [Month, setMonth] = useState("");

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [TotalAmount, setTotalAmount] = useState(0);

  useEffect(() => {
    document.title = "AGS School Management System";

    setLoading(true);
    // Get the URL parameters
    const urlParams = new URLSearchParams(window.location.search);

    // Retrieve individual parameter values
    const Month = urlParams.get("Month");
    const Year = urlParams.get("Year");

    setYear(Year);
    setMonth(Month);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      pageNo: 1,
      pageSize: 10000,
      SelectedMonth: Month,
      FYear: Year,
    };

    //console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "LoanDeduction/GetLoanDeductions",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        setLedger(response.data.ListofRecords);
        setLoading(false);
        setTotalRecords(response.data.totalRecords);
        setTotalAmount(response.data.TotalAmount);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, []);

  const renderLedger = () => {
    if (listOfLedger != null && listOfLedger.length > 0) {
      let count = 1;
      let countbreak = 1;
      return listOfLedger.map((item, index) => {
        const isPageBreak = index > 0 && index % 36 == 0;
        return (
          <tr key={index} className={isPageBreak ? "page-break" : ""}>
            <td>{count++ + pageSize * (pageNo - 1)}</td>
            <td>{item.Code}</td>
            <td>{item.Name}</td>
            <td>{item.Status}</td>
            <td>{item.Loan_Amount}</td>
            <td>{item.Deduction}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="20">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };

  return (
    <>
      <Page orientation="portrait" size="a4">
        <table className="table table-sm table-bordered table-print">
          <thead>
            <tr>
              <th colSpan={20}>
                <PrintHeader
                  title={`Deduction of Refundable Loan for the month of ${getMonthName(
                    Month
                  )} ${Year} `}
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td className="text-center" colSpan="8">
                  <Spinner animation="border" role="status"></Spinner>
                </td>
              </tr>
            ) : (
              <>
                <tr
                  style={{ borderCollapse: "collapse", border: "transparent" }}
                >
                  <td colSpan={6}></td>
                </tr>
                <tr
                  style={{ borderCollapse: "collapse", border: "transparent" }}
                >
                  <td colSpan={6}>
                    1. It is stated that following staff members drawn
                    refundable loan out of School Welfare A/C and now in the
                    month of an amount @ Rs. 10% of the loan is being deducted
                    from them:-
                  </td>
                </tr>
                <tr
                  style={{ borderCollapse: "collapse", border: "transparent" }}
                >
                  <td colSpan={6}></td>
                </tr>
                <tr>
                  <th>Sr #</th>
                  <th>Code</th>
                  <th>Staff Name</th>
                  <th>Status</th>
                  <th>Loan Amount Rs.</th>
                  <th>Deduction Rs.</th>
                </tr>
                {renderLedger()}
                <tr>
                  <th colSpan="4">Total Amount:</th>
                  <th>
                    <th>{calculateColumnTotal(listOfLedger, "Loan_Amount")}</th>
                  </th>
                  <td>
                    <th>{calculateColumnTotal(listOfLedger, "Deduction")}</th>
                  </td>
                </tr>
                <tr
                  style={{ borderCollapse: "collapse", border: "transparent" }}
                >
                  <td colSpan={6}></td>
                </tr>
                <tr
                  style={{ borderCollapse: "collapse", border: "transparent" }}
                >
                  <td colSpan={6}></td>
                </tr>
                <tr
                  style={{ borderCollapse: "collapse", border: "transparent" }}
                >
                  <td colSpan={6}>
                    2. So, before transfer of salary of these{" "}
                    {listOfLedger.length} staff members, this amount i.e. Rs.{" "}
                    {calculateColumnTotal(listOfLedger, "Deduction")} has been
                    withheld in Principal A/C.
                  </td>
                </tr>
                <tr
                  style={{ borderCollapse: "collapse", border: "transparent" }}
                >
                  <td colSpan={6}></td>
                </tr>
                <tr
                  style={{ borderCollapse: "collapse", border: "transparent" }}
                >
                  <td colSpan={6}>
                    3. Now a crossed cheque No ____________________________
                    amounting to Rs.{" "}
                    {calculateColumnTotal(listOfLedger, "Deduction")} has been
                    prepared out of Principal A/C for transfer into staff
                    welfare A/C.
                  </td>
                </tr>
                <tr
                  style={{ borderCollapse: "collapse", border: "transparent" }}
                >
                  <td colSpan={6}></td>
                </tr>
                <tr
                  style={{ borderCollapse: "collapse", border: "transparent" }}
                >
                  <td colSpan={6}> 4. Presented for Signature please.</td>
                </tr>
                <tr
                  style={{ borderCollapse: "collapse", border: "transparent" }}
                >
                  <td colSpan={6}></td>
                </tr>
                <tr
                  style={{ borderCollapse: "collapse", border: "transparent" }}
                >
                  <td colSpan={6}></td>
                </tr>
                <tr
                  style={{ borderCollapse: "collapse", border: "transparent" }}
                >
                  <th colSpan={6}>Bursar DPS</th>
                </tr>
                <tr
                  style={{ borderCollapse: "collapse", border: "transparent" }}
                >
                  <td colSpan={6}></td>
                </tr>
                <tr
                  style={{ borderCollapse: "collapse", border: "transparent" }}
                >
                  <td colSpan={6}></td>
                </tr>
                <tr
                  style={{ borderCollapse: "collapse", border: "transparent" }}
                >
                  <th colSpan={6}>Admin Officer</th>
                </tr>
                <tr
                  style={{ borderCollapse: "collapse", border: "transparent" }}
                >
                  <td colSpan={6}></td>
                </tr>
                <tr
                  style={{ borderCollapse: "collapse", border: "transparent" }}
                >
                  <td colSpan={6}></td>
                </tr>
                <tr
                  style={{ borderCollapse: "collapse", border: "transparent" }}
                >
                  <th colSpan={6}>Principal</th>
                </tr>
              </>
            )}
          </tbody>
        </table>
      </Page>
    </>
  );
};

export default LoanDeductionsPrint;
