import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import { Link } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate, useParams } from 'react-router-dom';
import { NavLink } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { Form } from "react-bootstrap";
import moment from "moment";
import UserGroupDropDown from "../Shared/UserGroupDropDown";
import Stock_navbar from './StockNavbar';
import { scrollToTop } from '../Shared/ScrollOnTop';

const IssueItems = () => {
    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [validated, setValidated] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [hasError, setHasError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [StockItem, setStockItem] = useState({});
    const [RegisterType, setRegisterType] = useState("");
    const [type, setType] = useState('0');
    const [Quantity, setQuantity] = useState("");

    const [TypeIn, setTypeIn] = useState(0);
    const [TypeOut, setTypeOut] = useState(0);


    let params = useParams();
    const [ItemID, setItemID] = useState(params.id);

    let navigate = useNavigate();

    useEffect(() => {

        document.title = "Issue / Receive Stock Items";
        // setLoading(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            ID: ItemID

        };
       //console.log(data);
        var api_config = {
            method: 'post',
            url: config.base_url + 'Stock/GetStockItemByID',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

       //console.log(StockItem)

        axios(api_config)
            .then(function (response) {
               //console.log(response.data);
                if (response.data.status_code == 1) {
                    setStockItem(response.data.StockItem);

                }


                // setLoading(false);


            })
            .catch(function (error) {
                // setLoading(false);
                toast.error('Network Error..', {
                    position: toast.POSITION.TOP_RIGHT
                });
            });


    }, [Quantity]);

    const handleSubmit = (event) => {


        if (event.target.ddlInOut.value == "IN") {
            setTypeIn(Quantity);
            setTypeOut(0);

        } else if (event.target.ddlInOut.value == "OUT") {
            setTypeOut(Quantity);
            setTypeIn(0);
        }

        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            setLoading(true);
            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
                MemberType: MemberType,
                Dated: event.target.txtDated.value,
                Description: event.target.txtDescription.value,
                Custodian: event.target.txtCustodian.value,
                Item_ID: ItemID,
                Item_Out: TypeOut,
                Item_In: TypeIn,
                Use_Detail: event.target.txtUse_Detail.value,
            };

            //console.log(data);

            var api_config = {
                method: 'post',
                url: config.base_url + 'Stock/AddItemDetail',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };



            axios(api_config)
                .then(function (response) {
                   //console.log(response.data);
                    if (response.data.status_code == 1) {

                        toast.success(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        navigate('/StockManagement/ManageStockCategory');
                    }
                    else {
                        toast.error(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }

                    setLoading(false);


                })
                .catch(function (error) {
                    setLoading(false);
                    toast.error('Network Error..', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                });

        }

        setValidated(true);
    };

    const handleType = (e) => {
        if (e.target.value == "IN") {
            setTypeIn(Quantity);
            setTypeOut(0);

        } else if (e.target.value == "OUT") {
            setTypeOut(Quantity);
            setTypeIn(0);
        }
    }

    return (
        <>
            <Stock_navbar />
            <ToastContainer />
            <div className="page-head">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/Dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item"><Link to="/StockManagement/StocksDashboard">Stocks</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Issue Or Receive Stock Items</li>
                                </ol>
                            </nav>
                            <div className="page-title">Issue Or Receive Stock Items</div>
                        </div>
                        <div className="col-md-6">
                            <div className="page-right-actions">
                                <Link to="/StockManagement/ManageStockCategory" className="button button-white button-icon">
                                    Manage Stock
                                    <i className="ri-list-check"></i>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="page-content">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body p-md-4">
                                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                        <div className="row align-items-center">
                                            <div className="col-md-3">
                                                <div className="form-group mb-3">
                                                    <label >Stock Code:</label>
                                                    <input type="text" disabled defaultValue={StockItem.Stock_Code} className="form-control" required name="txtStock_Code" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group mb-3">
                                                    <label  className="">Item Name:</label>
                                                    <input type="text" disabled defaultValue={StockItem.Item_Name} className="form-control" required name="txtItem_Name" />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group mb-3">
                                                    <label  className="">Register Type:</label>
                                                    <input type="text" disabled defaultValue={StockItem.Register_Type} className="form-control" required name="txtItem_Name" />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group mb-3">
                                                    <label  className="">Dated:</label>
                                                    <input type="date" Value={StockItem.Dated} className="form-control" required name="txtDated" />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group mb-3">
                                                    <label  className="">Quantity:</label>
                                                    <input type="text" onChange={(e) => setQuantity(e.target.value)} className="form-control" required name="txtItem_Name" />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group mb-3">
                                                    <label  className="">Type:</label>
                                                    <select className="form-select" name="ddlInOut"
                                                        defaultValue={StockItem.Item_Out}
                                                        onChange={(e) => handleType(e)}
                                                        required>
                                                        <option value="">Select Type</option>
                                                        <option value="IN">IN</option>
                                                        <option value="OUT">Out</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group mb-3">
                                                    <label  className="">Custodian:</label>
                                                    <input type="text" defaultValue={StockItem.Custodian} className="form-control" required name="txtCustodian" />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group mb-3">
                                                    <label  className="">Description:</label>
                                                    <input type="text" defaultValue={StockItem.Description} className="form-control" required name="txtDescription" />
                                                </div>
                                            </div>
                                            <div className="col-md-10">
                                                <div className="form-group mb-3">
                                                    <label  className="">Usage Details:</label>
                                                    <input type="text" defaultValue={StockItem.Use_Detail} className="form-control" required name="txtUse_Detail" />
                                                </div>
                                            </div>
                                            <div className="col-md-2 mt-md-2">
                                                <div className="form-group ">
                                                    {
                                                        loading ?
                                                            <Spinner animation="border" role="status"></Spinner>

                                                            :
                                                            <button type="submit" value="Save" className="button button-primary w-100 ">SAVE</button>

                                                    }

                                                </div>
                                            </div>

                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default IssueItems;
