import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AccountsMenu from "./AccountsMenu";
import Pagination from "../General/Pagination";
import { scrollToTop } from "../Shared/ScrollOnTop";

function ManageAccount(props) {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
  const [loading, setLoading] = useState(false);
  const [Level, setLevel] = useState("0");
  const [FYear, setFYear] = useState(localStorage.getItem("DefaultFYear"));
  const [listOfLedger, setLedger] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);
  const [Account_Code,setAccount_Code] = useState("");
  const [Account_Description, setAccount_Description] = useState("");

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [totalRecords, setTotalRecords] = useState(0);

  const handleAccountCodeChange = (event) => {
    setAccount_Code(event.target.value);
  };
  const handleAccountNameChange = (event) => {
    setAccount_Description(event.target.value);
  };

  useEffect(() => {
    document.title = "Manage Accounts";

    setIsUpdated(false);
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      FYear: FYear,
      pageNo: pageNo,
      pageSize: pageSize,
      AccountLevel: Level,
      AccountCode: Account_Code,
      Accountname:Account_Description,

    };

    var api_config = {
      method: "post",
      url: config.base_url + "Accounts/GetAccountsByYear",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        setLedger(response.data.ListofRecords);
        setLoading(false);
        setTotalRecords(response.data.totalRecords);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, [pageNo, pageSize, isUpdated, Level,   Account_Code,
    Account_Description,]);

  const handleSubmit = (event) => {
    setLoading(true);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      FYear: FYear,
      AccountLevel: Level,
      pageNo: pageNo,
      pageSize: pageSize,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Accounts/GetAccountsByYear",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        if (response.data.status_code == 1) {
          setLedger(response.data.ListofRecords);
          setLoading(false);
          setTotalRecords(response.data.totalRecords);
        } else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }

        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        toast.error("Network Error..", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handlePageChange = (page) => {
    setPageNo(page);
  };

  const renderLedger = () => {
    if (listOfLedger) {
      let count = 1;

      return listOfLedger.map((item, index) => {
        return (
          <tr key={index}>
            <td>{count++ + pageSize * (pageNo - 1)}</td>
            <td>
              {item.iLevel === 3 ? (
                <NavLink to={"/Accounts/GeneralLedger/" + item.Account_Code}>
                  {item.Account_Code}
                </NavLink>
              ) : (
                item.Account_Code
              )}
            </td>
            <td>{item.Account_Description}</td>
            <td>{item.iLevel}</td>
            <td>
              <ul className="inline-action">
                <li>
                  {item.iLevel >= 2 && (
                    <NavLink
                      to={"/Accounts/EditOpeningBalance/" + item.ID}
                      className="green"
                    >
                      <i className="ri-edit-line"></i>
                    </NavLink>
                  )}
                </li>
              </ul>
            </td>
            <td>
              <ul className="inline-action">
                <li>
                  {item.iLevel == 3 && (
                    <button
                      onClick={(e) => deleteAccount(item.ID)}
                      className={`red`}
                    >
                      <i className="ri-delete-bin-line"></i>
                    </button>
                  )}
                </li>
              </ul>
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="6">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };

  const deleteAccount = (ID) => {
    const confirm = window.confirm("Are you sure you want to proceed ?");
    if (confirm) {
      const data = {
        AccessKey: AccessKey,
        UserID: UserID,
        MemberType: MemberType,
        ID: ID,
      };

      var api_config = {
        method: "post",
        url: config.base_url + "Accounts/DeleteAccount",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(api_config)
        .then(function (response) {
          //console.log(response.data);
          if (response.data.status_code == 1) {
            toast.success(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });

            setIsUpdated(true);
          }
          // setIsUpdated(false);
        })
        .catch(function (error) {
          //console.log(error);
        });
    }
  };

  return (
    <>
      <AccountsMenu />
      <ToastContainer />
      <div className="container body-content">
        <div className="page-head">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <NavLink to="/Dashboard">Dashboard</NavLink>
                    </li>
                    <li className="breadcrumb-item">
                      <NavLink to="/Accounts/AccountsDashboard">
                        Accounts
                      </NavLink>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Manage Accounts
                    </li>
                  </ol>
                </nav>
                <div className="page-title">Manage Accounts</div>
              </div>
              <div className="col-md-6">
                <div className="page-right-actions">
                  <NavLink
                    to="/Accounts/AddNewAccount"
                    className="button button-white button-icon"
                  >
                    Add Account
                    <i className="ri-add-line"></i>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="page-content">
          <div className="container">
            <div className="card">
              <div className="card-body p-md-4">
                <div className="row ">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Select Level:</label>
                      <select
                        className="form-select"
                        required
                        onChange={(e) => setLevel(e.target.value)}
                      >
                        <option value="" disabled selected>
                          Please Select
                        </option>
                        <option value="0">All Levels</option>
                        <option value="1">Level 1</option>
                        <option value="2">Level 2</option>
                        <option value="3">Level 3</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Account No:</label>
                      <input
                        type="text"
                        className="form-control"
                        value={Account_Code}
                        onChange={handleAccountCodeChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Account Name:</label>
                      <input
                        type="text"
                        className="form-control"
                        value={Account_Description}
                        onChange={handleAccountNameChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-3  text-end">
                    <div className="form-group ">
                      {loading ? (
                        <Spinner animation="border" role="status"></Spinner>
                      ) : (
                        <button
                          type="submit"
                          onClick={handleSubmit}
                          value="SEARCH"
                          className="mt-md-4 w-100 button button-primary"
                        >
                          Search
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card mt-3">
              <div className="card-body table-wrap">
                <table className="table table-theme table-hover">
                  <thead>
                    <tr>
                      <th>Sr#</th>
                      <th>Account Code</th>
                      <th>Account Name.</th>
                      <th>Level</th>
                      <th className="text-center">Edit</th>
                      <th className="text-center">Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td className="text-center" colSpan="6">
                          <Spinner animation="border" role="status"></Spinner>
                        </td>
                      </tr>
                    ) : (
                      <>{renderLedger()}</>
                    )}
                  </tbody>
                </table>
                {totalRecords > 10 && (
                  <div className="pagination-wrap">
                    <div className="row align-items-center">
                      <div className="col-md-2 form_default">
                        <div className="d-flex align-items-center">
                          <label className="me-2" style={{ fontSize: "15px" }}>
                            Page Size
                          </label>
                          <div className="form-group">
                            <select
                              className="form-select"
                              style={{ width: "100px" }}
                              onChange={(e) => {
                                setPageNo(1);
                                setPageSize(parseInt(e.target.value));
                              }}
                            >
                              <option value="10">10</option>
                              <option value="20">20</option>
                              <option value="50">50</option>
                              <option value="100" selected>
                                100
                              </option>
                              <option value="200">200</option>
                              <option value="500">500</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <Pagination
                        pageNo={pageNo}
                        pageSize={pageSize}
                        totalRecords={totalRecords}
                        onPageChange={handlePageChange}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="container">
            <div className="card mt-2">
              <div className="card-body p-md-4">
                <div className="row align-items-center gy-3 float-end">
                  <div className="col-md-12 ">
                    <div className="form-group">
                      <Link
                        to={`/Accounts/PrintManageAccount?level=${Level}&Accountlevel=${Level}`}
                        target="_blank"
                        className="w-100 button button-primary"
                      >
                        Print This List
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ManageAccount;
