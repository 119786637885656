import ReportsMenu from "./ReportsMenu";
import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import SessionsDropDown from "../Shared/SessionsDropDown";
import WingsDropDown from "../Shared/WingsDropDown";
import ClassesDropDown from "../Shared/ClassesDropDown";
import SectionDropDown from "../Shared/SectionDropDown";
import StudentGroupDropDown from "../Shared/StudentGroupDropDown";
import StudentStatusDropDown from "../Shared/StudentStatusDropDown";
import { toast, ToastContainer } from "react-toastify";
import { NavLink, Link } from "react-router-dom";
import moment from "moment";
import Pagination from "../General/Pagination";
import Dropdown from "react-bootstrap/Dropdown";
import { Form, PageItem } from "react-bootstrap";
import MonthsDropDown from "../Shared/MonthsDropDown";
import YearsDropDown from "../Shared/YearsDropDown";
import { scrollToTop } from "../Shared/ScrollOnTop";
import csvDownload from "json-to-csv-export";


const StatusWiseChange = () => {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  //const [DefaultSession, setDefaultSession] = useState(localStorage.getItem("DefaultSession"));

  const [showFilters, setShowFilters] = useState(true);

  const [loading, setLoading] = useState(false);
  const [listOfLedger, setLedger] = useState([]);
  const [validated, setValidated] = useState(false);
  const [listOfLedgerforcvs, setLedgerforcvs] = useState([]);
  const [loaddata, setLoaddata] = useState(false);

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);

  const [Wing, setWing] = useState("");
  const [Status, setStatus] = useState("");

  const [Reappear, setReappear] = useState(false);
  const [StartDate, setStartDate] = useState(moment().format("MMMM Do YYYY"));
  const [EndDate, setEndDate] = useState(moment().format("MMMM Do YYYY"));

  document.title='Status Wise Change'

  // for filters

  useEffect(() => {
    setLoading(true);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      pageNo: pageNo,
      pageSize: pageSize,
      StartDate: StartDate,
      EndDate: EndDate,
      Wing_Id: Wing,
      Status_Type_Id: Status,

      Reappear: Reappear,
    };
    var api_config = {
      method: "post",
      url: config.base_url + "/Reports/GetStatusChangeList",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    //console.log(data);

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        setLedger(response.data.ListofRecords);
        setLoading(false);
        setTotalRecords(response.data.totalRecords);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, [pageNo, pageSize, StartDate, EndDate, Wing, Status, Reappear]);

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setStartDate(event.target.StartDate.value);
      setEndDate(event.target.EndDate.value);
      setWing(event.target.Wing.value);
      setStatus(event.target.Status.value);

      //setReappear(Reappear);
    }

    setValidated(true);
  };

  const handlePageChange = (page) => {
    setPageNo(page);
  };

  const renderLedger = () => {
    if (listOfLedger != null && listOfLedger.length > 0) {
      return listOfLedger.map((item, index) => {
        return (
          <tr key={index}>
            <td>{item.Serial_No}</td>
            <td>{item.Registration_No}</td>
            <td>{item.Student_Name}</td>
            <td>{moment(item.Dated).format("DD/MM/YYYY")}</td>
            <td>{item.Modified_By}</td>
            <td>{item.Remarks}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="12">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };

  const handleSubmitForCvs = (event) => {
    //console.log(event);

    setLoaddata(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      pageNo: pageNo,
      pageSize: 10000,
      StartDate: StartDate,
      EndDate: EndDate,
      Wing_Id: Wing,
      Status_Type_Id: Status,

    };

   //console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "/Reports/GetStatusChangeList",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        if (response.data.status_code == 1) {
          if (response.data.totalRecords > 0) {
        setLedgerforcvs(response.data.ListofRecords);
        let data = response.data.ListofRecords;
        let count = 0;
        const listOfLedgerNew = data.map(({ ...rest }) => ({
          Serial_No: rest.Serial_No,
          Student_ID:rest.Student_ID,
          Reg_No: rest.Registration_No,
          Name: rest.Student_Name,
          Date: moment(rest.Dated).format("DD/MM/YYYY"),
          Modified_By:rest.Modified_By,
          Wing: rest.Wing_Name,
          Class: rest.Class_Name,
          Section: rest.Section_Name,
          Remarks:rest.Remarks
        }));
        //   //console.log(listOfLedgerNew);

        const dataToConvert = {
          data: listOfLedgerNew,
          filename: "StatusWiseChange",
          delimiter: ",",
          headers: [
            "Serial_No",
            "Student_ID",
            "Reg_No",
            "Name",
            "Date",
            "Modified_By",
            "Wing",
            "Class",
            "Section",
            "Remarks",
          ],
        };
       csvDownload(dataToConvert);

        setLoaddata(false);
      } else if (response.data.totalRecords === 0) {
        toast.error("There is no data available for download", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoaddata(false);
      }
    } else {
      toast.error(response.data.status_message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoaddata(false);
    }
  })
  .catch(function (error) {
    toast.error("Network Error..", {
      position: toast.POSITION.TOP_RIGHT,
  });
    setLoaddata(false);
  });
};

  return (
    <>
      <ReportsMenu />
      <ToastContainer />
      <div className="page-head">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/Dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/Reports/ReportsDashboard">Reports</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Status Wise Change
                  </li>
                </ol>
              </nav>
              <div className="page-title">Status Wise Change</div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`collapse ${showFilters ? "show" : ""} `}
        id="collapseFilteres"
      >
        <div className="page-content">
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <div className="container">
              <div className="card mb-2">
                <div className="card-body p-md-4">
                  <div className="row align-items-center">
                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <label  className="">
                          Start Date:
                        </label>
                        <input
                          type="date"
                          defaultValue={moment().format(
                            config.date_format_input
                          )}
                          className="form-control"
                          required
                          name="StartDate"
                          max={moment().format(config.date_format_input)}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <label  className="">
                          End Date:
                        </label>
                        <input
                          type="date"
                          defaultValue={moment().format(
                            config.date_format_input
                          )}
                          className="form-control"
                          required
                          name="EndDate"
                          max={moment().format(config.date_format_input)}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <label>Select Wing:</label>
                        <select
                          name="Wing"
                          className="form-select"
                          onChange={(e) => setWing(e.target.value)}
                        >
                          <option value="">---All---</option>
                          <WingsDropDown />
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <label>Status Type:</label>
                        <select
                          name="Status"
                          onChange={(e) => setStatus(e.target.value)}
                          className="form-select"
                        >
                          <option value="">Please Select</option>
                          <StudentStatusDropDown />
                        </select>
                      </div>
                    </div>
                    <div className="col-md-2 mt-4">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          onChange={(e) => setReappear(e.target.checked)}
                          checked={Reappear}
                          id="flexCheckDefault"
                        />
                        <label className="form-check-label" htmlFor="flexCheckDefault">
                          Reappear List Only
                        </label>
                      </div>
                    </div>
                    {loaddata ? (
                        <div className="col-md-2 offset-6 text-center">
                          <div>
                         
                            <div>
                              <Spinner
                                animation="border"
                                role="status"
                              ></Spinner>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="col-md-2 offset-6 text-end ">
                        <div className="form-group text-end mt-4">
                        <a
                            type="button"
                            onClick={handleSubmitForCvs}
                            className="print-icon"
                          >
                            <span>
                              <i className="ri-file-excel-2-line"></i>
                            </span>
                          
                          </a>
                        </div>
                      </div>
                      )}
                    <div className="col-md-2 text-end">
                      <div className="form-group text-end">
                        <button
                          type="submit"
                          value="Search"
                          className="w-100 button button-primary mt-4"
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
      <div className="page-content">
        <div className="container">
          <div className="card">
            <div className="card-body">
              <table className="table table-theme">
                <thead>
                  <tr>
                    <th>Serial No</th>
                    <th>Registration No</th>
                    <th>Student Name</th>
                    <th>Dated</th>
                    <th>Modified By</th>
                    <th>Remarks</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td className="text-center" colSpan="12">
                        <Spinner animation="border" role="status"></Spinner>
                      </td>
                    </tr>
                  ) : (
                    <>{renderLedger()}</>
                  )}
                </tbody>
              </table>
              {totalRecords > 10 && (
                <div className="pagination-wrap">
                  <div className="row align-items-center">
                    <div className="col-md-2 form_default">
                      <div className="d-flex align-items-center">
                        <label className="me-2" style={{ fontSize: "15px" }}>
                          Page Size
                        </label>
                        <div className="form-group">
                          <select
                            className="form-select"
                            style={{ width: "100px" }}
                            onChange={(e) => {
                              setPageNo(1);
                              setPageSize(parseInt(e.target.value));
                            }}
                          >
                            <option value="10" selected>
                              10
                            </option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <Pagination
                      pageNo={pageNo}
                      pageSize={pageSize}
                      totalRecords={totalRecords}
                      onPageChange={handlePageChange}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="container">
          <div className="card mt-2">
            <div className="card-body p-md-4">
              <div className="row">
                <div className="col-md-12 text-end">
                  <NavLink
                    className="button button-primary"
                    to={`/Reports/StatusWisePrint?StartDate=${StartDate}&EndDate=${EndDate}&Wing_Id=${Wing}&Status=${Status}
                                       `}
                    target="_blank"
                  >
                    Print Report
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StatusWiseChange;
