import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import AccountsMenu from "./AccountsMenu";
import { Link, NavLink } from "react-router-dom";
import YearsDropDown from "../Shared/YearsDropDown";
import Pagination from "../General/Pagination";
import { toast, ToastContainer } from "react-toastify";
import { calculateColumnTotal } from "../General/Utility";


import moment from "moment";

function OpeningBalances(props) {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [DefaultFYear, setDefaultFYear] = useState(
    localStorage.getItem("DefaultFYear")
  );
  const [VoucherType, setVoucherType] = useState(0);
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [listOfLedger, setLedger] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);

  useEffect(() => {
    document.title = "Opening Balance";
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      PageNo: pageNo,
      PageSize: pageSize,
      FYear: DefaultFYear,
      VoucherTypeID: VoucherType,
    };

    //console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Accounts/GetOpeningBalancesByYear",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        if (response.data.status_code != 0) {
          setLedger(response.data.ListofRecords);
          setTotalRecords(response.data.totalRecords);
        } else {
          setLedger(null);
          setTotalRecords(0);
        }
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, [pageNo, pageSize, DefaultFYear, VoucherType, isUpdated]);

  const handlePageChange = (page) => {
    setPageNo(page);
  };

  const renderLedger = () => {
    if (listOfLedger != null && listOfLedger.length > 0) {
      let count = 1;
      return listOfLedger.map((item, index) => {
        return (
          <tr key={index}>
            <td>{count++ + pageSize * (pageNo - 1)}</td>
            <td>
              <NavLink
                to={
                  "/Accounts/GeneralLedger/" +
                  item.Selected_Account.Account_Code
                }
              >
                {item.Selected_Account.Account_Code}{" "}
              </NavLink>
            </td>
            <td>{item.Selected_Account.Account_Description}</td>
            <td> {item.DR_Amt}</td>
            <td>{item.CR_Amt}</td>
            <td>{item.Budget_Allocation}</td>
            <td>
              <ul className="inline-action">
                <li>
                  <NavLink
                    to={"/Accounts/EditOpeningBalance/" + item.AccountID}
                    className="green"
                  >
                    <i className="ri-edit-line"></i>
                  </NavLink>
                </li>
                <li>
                  <button
                    onClick={(e) => deleteOpeningBalance(item.ID)}
                    className="red"
                  >
                    <i className="ri-delete-bin-line"></i>
                  </button>
                </li>
              </ul>
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="7">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };

  const deleteOpeningBalance = (ID) => {
    const confirm = window.confirm("Are you sure you want to proceed ?");
    if (confirm) {
      const data = {
        AccessKey: AccessKey,
        UserID: UserID,
        MemberType: MemberType,
        ID: ID,
      };

      //console.log(data);

      var api_config = {
        method: "post",
        url: config.base_url + "Accounts/DeleteOpeningBalance",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(api_config)
        .then(function (response) {
          //console.log(response.data);
          if (response.data.status_code == 1) {
            toast.success(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setIsUpdated(true);
          } else {
            setIsUpdated(false);
            toast.error(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
          // setIsUpdated(false);
        })
        .catch(function (error) {
          //console.log(error);
        });
    }
  };

  return (
    <>
      <AccountsMenu />
      <ToastContainer />
      <div className="container body-content">
        <div className="page-head">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <NavLink to="/Dashboard">Dashboard</NavLink>
                    </li>
                    <li className="breadcrumb-item">
                      <NavLink to="/Accounts/AccountsDashboard">
                        Accounts
                      </NavLink>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Opening Balances
                    </li>
                  </ol>
                </nav>
                <div className="page-title">Opening Balances</div>
              </div>
            </div>
          </div>
        </div>

        <div className="page-content">
          <div className="container">
            <div className="card">
              <div className="card-body p-md-4">
                <div className="row gy-3">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Select Year:</label>
                      <select
                        className="form-select"
                        required
                        name="ddlYears"
                        onChange={(e) => setDefaultFYear(e.target.value)}
                      >
                        <option value="">Please Select</option>
                        <YearsDropDown selected={DefaultFYear} />
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      {/* <label>Select Type:</label>
                                            <select className="form-select disabled" required name="ddlVoucherType" onChange={(e) => setVoucherType(e.target.value)}  >
                                                <option value="">Please Select</option>
                                                <VoucherTypeDropDown />
                                            </select> */}
                    </div>
                  </div>
                  <div className="col-md-6 text-end">
                    <div className="form-group">
                      <input
                        type="button"
                        value="Search"
                        className="mt-md-4 button button-primary"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card mt-3">
              <div className="card-body table-wrap">
                <table className="table table-theme table-hover">
                  <thead>
                    <tr>
                      <th>Sr#</th>
                      <th>Code</th>
                      <th>Account</th>
                      <th>Debit</th>
                      <th>Credit</th>
                      <th>Budget Allocation</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td className="text-center" colSpan="7">
                          <Spinner animation="border" role="status"></Spinner>
                        </td>
                      </tr>
                    ) : (
                      <>
                        {renderLedger()}
                        {
                          <tr>
                            <th className="text-end" colSpan={3}>
                              Total DR/CR:
                            </th>
                            <th className="" colSpan={1}>
                              {calculateColumnTotal(
                                listOfLedger,
                                "DR_Amt"
                              )}
                            </th>
                            <th className="" colSpan={1}>
                              {calculateColumnTotal(
                                listOfLedger,
                                "CR_Amt"
                              )}
                            </th>
                          </tr>
                        }
                      </>
                    )}
                  </tbody>
                </table>

                {totalRecords > pageSize && (
                  <div className="pagination-wrap">
                    <div className="row align-items-center">
                      <div className="col-md-2 form_default">
                        <div className="d-flex align-items-center">
                          <label className="me-2" style={{ fontSize: "15px" }}>
                            Page Size
                          </label>
                          <div className="form-group">
                            <select
                              className="form-select"
                              style={{ width: "100px" }}
                              onChange={(e) => {
                                setPageNo(1);
                                setPageSize(parseInt(e.target.value));
                              }}
                            >
                              <option value="10" selected>
                                10
                              </option>
                              <option value="20">20</option>
                              <option value="50">50</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <Pagination
                        pageNo={pageNo}
                        pageSize={pageSize}
                        totalRecords={totalRecords}
                        onPageChange={handlePageChange}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="container">
            <div className="card mt-2">
              <div className="card-body p-md-4">
                <div className="row align-items-center gy-3 float-end">
                  <div className="col-md-12 ">
                    <div className="form-group">
                      <Link
                        to={`/Accounts/PrintOpeningBalance?FYear=${DefaultFYear}`}
                        target="_black"
                        className="w-100 button button-primary"
                      >
                        Print This List
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OpeningBalances;
