import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import SessionsDropDown from "../Shared/SessionsDropDown";
import WingsDropDown from "../Shared/WingsDropDown";
import ClassesDropDown from "../Shared/ClassesDropDown";
import SectionDropDown from "../Shared/SectionDropDown";
import { toast, ToastContainer } from "react-toastify";
import { NavLink, Link } from "react-router-dom";
import moment from "moment";
import Pagination from "../General/Pagination";
import Dropdown from "react-bootstrap/Dropdown";
import { Form, PageItem } from "react-bootstrap";
import Stock_navbar from "./StockNavbar";
import { scrollToTop } from "../Shared/ScrollOnTop";
import csvDownload from "json-to-csv-export";

const ManageStockCategory = (props) => {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  //const [DefaultSession, setDefaultSession] = useState(localStorage.getItem("DefaultSession"));

  const [showFilters, setShowFilters] = useState(true);

  const [loading, setLoading] = useState(false);
  const [listOfLedger, setLedger] = useState([]);
  const [validated, setValidated] = useState(false);

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);

  const [isUpdated, setIsUpdated] = useState(false);
  const [Stock_Code, setStock_Code] = useState("");
  const [OrderBy, setOrderBy] = useState("");
  const [Register_Type, setRegister_Type] = useState("");
  const [Item_Name, setItem_Name] = useState("");
  const [loaddata, setLoaddata] = useState(false);

  useEffect(() => {
    document.title = "Manage Stock Categories";

    setIsUpdated(false);
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      pageNo: pageNo,
      pageSize: pageSize,
      Stock_Code: Stock_Code,
      Item_Name: Item_Name,
      Register_Type: Register_Type,
      OrderBy: OrderBy,
    };

    //console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Stock/GetStockItems",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        setLedger(response.data.ListofRecords);
        setLoading(false);
        setTotalRecords(response.data.totalRecords);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, [
    pageNo,
    pageSize,
    isUpdated,
    Stock_Code,
    Item_Name,
    Register_Type,
    OrderBy,
  ]);

  const handlePageChange = (page) => {
    setPageNo(page);
  };
  const handleSubmitForCvs = (event) => {
    //console.log(event);

    setLoaddata(true);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      pageNo: pageNo,
      pageSize: 10000,
      Stock_Code: Stock_Code,
      Item_Name: Item_Name,
      Register_Type: Register_Type,
      OrderBy: OrderBy,
    };

    //console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Stock/GetStockItems",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        if (response.data.status_code == 1) {
          if (response.data.totalRecords > 0) {
            let data = response.data.ListofRecords;
            let count = 0;
            const listOfLedgerNew = data.map(({ ...rest }) => ({
              Serial_No: count++ + pageSize * (pageNo - 1),
              Stock_Code: rest.Stock_Code,
              Item_Name: rest.Item_Name,
              Main_Custodian: rest.Main_Custodian,
              Register_Type: rest.Register_Type,
              Current_Balance: rest.Current_Balance,
              Price_Per_Unit: rest.Price_Per_Unit,
              Register_Ref: rest.Register_Ref,
              CurrentBookValue: rest.CurrentBookValue,
              DateOfPurchase: moment(rest.DateOfPurchase).format("DD/MM/YYYY"),
              DepreciationRate: rest.DepreciationRate,


            }));

            const dataToConvert = {
              data: listOfLedgerNew,
              filename: "ManageStockCategory",
              delimiter: ",",
              headers: [
                "Serial_No",
                "Stock_Code",
                "Item_Name",
                "Main_Custodian",
                "Register Type",
                "Current Balance",
                "Price_Per Unit",
                "Register Ref",
                "CurrentBookValue",
                "DateOfPurchase",
                "DepreciationRate",
               
              ],
            };
            csvDownload(dataToConvert);

            setLoaddata(false);
          } else if (response.data.totalRecords == 0) {
            toast.error("There is no data available for download", {
              position: toast.POSITION.TOP_RIGHT,
            });
            setLoaddata(false);
          }
        } else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoaddata(false);
        }
      })
      .catch(function (error) {
        toast.error("Network Error..", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoaddata(false);
      });
  };
  const renderLedger = () => {
    if (listOfLedger.length > 0) {
      return listOfLedger.map((item, index) => {
        return (
          <tr key={index}>
            <td>{item.ID}</td>
            <td>{item.Stock_Code}</td>
            <td>{item.Item_Name}</td>
            <td>{item.Register_Type}</td>
            <td>{item.Current_Balance}</td>
            <td>{item.Price_Per_Unit}</td>
            <td>{item.Register_Ref}</td>
            <td>{item.CurrentBookValue}</td>
            <td>{item.DateOfPurchase == null ? ' ' : moment(item.DateOfPurchase).format("YYYY-MM-DD")}</td>
            <td>{item.DepreciationRate}</td>
            <td>
              <ul
                className="inline-action"
                aria-labelledby="dropdownMenuButton1"
              >
                <li>
                  <NavLink
                    to={"/StockManagement/EditStockItems/" + item.ID}
                    className=" green"
                  >
                    <i className="ri-edit-line"></i>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={"/StockManagement/IssueItems/" + item.ID}
                    className=" blue"
                  >
                    <i className="ri-arrow-left-right-line"></i>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={"/StockManagement/StockDetail/" + item.ID}
                    className="red"
                  >
                    <i className="ri-pages-line"></i>
                  </NavLink>
                </li>

                {/* <li><button onClick={(e) => DeleteStockItem(item.ID)} className="red"><i className="ri-delete-bin-line"></i></button></li> */}
              </ul>
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="8">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };

  const DeleteStockItem = (ID) => {
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      ID: ID,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "/Stock/DeleteStockItemByID",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    //console.log(data);

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        if (response.data.status_code == 1) {
          setIsUpdated(true);
        }
        // setIsUpdated(false);
      })
      .catch(function (error) {
        //console.log(error);
      });
  };
  return (
    <>
      <Stock_navbar />
      <ToastContainer />
      <div className="page-head">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/Dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/StockManagement/StocksDashboard">Stocks</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Manage Stock Items
                  </li>
                </ol>
              </nav>
              <div className="page-title">Manage Stock Items</div>
            </div>
            <div className="col-md-6">
              <div className="page-right-actions">
                <Link
                  to="/StockManagement/AddStockCategory"
                  className="button button-white button-icon"
                >
                  Add Stock
                  <i className="ri-add-fill"></i>
                </Link>
                <button
                  onClick={() => {
                    setShowFilters((prevIsActive) => !prevIsActive);
                  }}
                  className="button button-white button-icon"
                >
                  {showFilters ? "Hide Filters" : "Show Filters"}
                  <i className="ri-filter-3-line"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`collapse ${showFilters ? "show" : ""} `}
        id="collapseFilteres"
      >
        {/* <Form noValidate validated={validated} onSubmit={handleSubmit}> */}
        <div className="page-content">
          <div className="container">
            <div className="card mb-2">
              <div className="card-body p-md-4">
                <div className="row align-items-center gy-3">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label className="">
                        item Code:
                      </label>
                      <input
                        type="text"
                        name="itemCode"
                        onChange={(e) => setStock_Code(e.target.value)}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Item Name:</label>
                      <input
                        type="text"
                        name="ItemName"
                        onChange={(e) => setItem_Name(e.target.value)}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label className="">
                        Register Type:
                      </label>
                      <select
                        className="form-select"
                        value={Register_Type}
                        onChange={(e) => setRegister_Type(e.target.value)}
                        name="Status"
                      >
                        <option value="" selected="selected">
                          --All--
                        </option>
                        <option value="Fixed Assets">Fixed Assets</option>
                        <option value="Current Asset">Current Asset</option>
                        <option value="Consumeable Items - Physics">Consumeable Items - Physics</option>
                        <option value="Consumeable Items - Chemistry">Consumeable Items - Chemistry</option>
                        <option value="Consumeable/General">Consumeable/General</option>
                        <option value="Consumeable/Stationery">Consumeable/Stationery</option>
                        <option value="TPT - Spare Parts">TPT - Spare Parts</option>
                        <option value="Consumeable Items - Biology">Consumeable Items - Biology</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label className="">
                        Order By:
                      </label>
                      <select
                        className="form-select"
                        value={OrderBy}
                        onChange={(e) => setOrderBy(e.target.value)}
                        name="OrderBy"
                      >
                        <option value="">--All--</option>
                        <option value="Stock_Code">Stock Code</option>
                        <option value="Item_Name">Item Name</option>
                        <option value="Register_Type" selected="selected">
                          Register Type
                        </option>
                      </select>
                    </div>
                  </div>

                  <div className="col-md-2 offset-md-8 text-end ">
                    <div className="form-group text-end ">
                      {loaddata ?
                        <Spinner animation="border" role="status"></Spinner>
                        :

                        <a
                          type="button"
                          onClick={handleSubmitForCvs}
                          className="print-icon"
                        >
                          <span>
                            <i className="ri-file-excel-2-line"></i>
                          </span>

                        </a>
                      }
                    </div>
                  </div>

                  <div className="col-md-2">
                    <div className="form-group">
                      <button
                        type="submit"
                        value="Search"
                        className="w-100 button button-primary"
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </Form> */}
      </div>
      <div className="page-content">
        <div className="container">
          <div className="card">
            <div className="card-body table-wrap">
              <table className="table table-theme">
                <thead>
                  <tr>
                    <th colSpan={11}>{totalRecords} Records Found!</th>
                  </tr>
                  <tr>
                    <th>ID</th>
                    <th>Stock Code</th>
                    <th>Item Name</th>
                    <th>Register Type</th>
                    <th>Current Balance</th>
                    <th>Price <br /> Per Unit</th>
                    <th>Register Ref</th>
                    <th>Current <br /> Book Value</th>
                    <th>Date Of <br /> Purchase</th>
                    <th>Depreciation <br /> Rate</th>
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td className="text-center" colSpan="12">
                        <Spinner animation="border" role="status"></Spinner>
                      </td>
                    </tr>
                  ) : (
                    <>{renderLedger()}</>
                  )}
                </tbody>
              </table>
              {totalRecords > 10 && (
                <div className="pagination-wrap">
                  <div className="row align-items-center">
                    <div className="col-md-2 form_default">
                      <div className="d-flex align-items-center">
                        <label className="me-2" style={{ fontSize: "15px" }}>
                          Page Size
                        </label>
                        <div className="form-group">
                          <select
                            className="form-select"
                            style={{ width: "100px" }}
                            onChange={(e) => {
                              setPageNo(1);
                              setPageSize(parseInt(e.target.value));
                            }}
                          >
                            <option value="10" selected>
                              10
                            </option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>

                            <option value="500">500</option>

                          </select>
                        </div>
                      </div>
                    </div>
                    <Pagination
                      pageNo={pageNo}
                      pageSize={pageSize}
                      totalRecords={totalRecords}
                      onPageChange={handlePageChange}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="container">
          <div className="card mt-2">
            <div className="card-body p-md-4">
              <div className="row">
                <div className="col-md-12 text-end">
                  <NavLink
                    className="button button-primary"
                    to={`/StockManagement/StockItemsPrint?Stock_Code=${Stock_Code}&Item_Name=${Item_Name}&Register_Type=${Register_Type}&OrderBy=${OrderBy}`}
                    target="_blank"
                  >
                    Print This List
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageStockCategory;
