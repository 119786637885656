import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import Page from "../General/Page";
import moment from "moment";
import PrintHeader from "../General/PrintHeader";

const PrintManageUser = () => {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [loading, setLoading] = useState(false);
  const [listOfLedger, setLedger] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10000);
  const [totalRecords, setTotalRecords] = useState(0);

  useEffect(() => {
    document.title = "Manage Users";

    setIsUpdated(false);
    setLoading(true);
    const dashboardParms = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      pageNo: pageNo,
      pageSize: pageSize,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Members/GetAllUsers",
      headers: {
        "Content-Type": "application/json",
      },
      data: dashboardParms,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        setLedger(response.data.ListofRecords);
        setLoading(false);
        setTotalRecords(response.data.totalRecords);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, [pageNo, pageSize, isUpdated]);

  const renderLedger = () => {
    if (listOfLedger.length > 0) {
      return listOfLedger.map((item, index) => {
        return (
          <tr key={index}>
            <td>{item.ID}</td>
            <td>{item.FullName}</td>
            <td>{item.UserName}</td>
            <td>{item.Selected_Group.GroupName}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="8">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };

  return (
    <>
      <Page orientation="portrait" size="a4">
        <table className="table table-sm table-bordered table-print">
          <thead>
            <tr>
              <th colSpan={20}>
                <PrintHeader
                  title={`List of Newly Admitted But Not Paid Students:`}
                />
              </th>
            </tr>
          </thead>
          {loading ? (
            <tr>
              <td className="text-center" colSpan="8">
                <Spinner animation="border" role="status"></Spinner>
              </td>
            </tr>
          ) : (
            <>
              <tr>
                <th>ID</th>
                <th>Full Name</th>
                <th>User Name</th>
                <th>Group</th>
              </tr>
              {renderLedger()}
            </>
          )}
        </table>
      </Page>
    </>
  );
};

export default PrintManageUser;
