import React, { useState } from 'react';
import FeeSystemMenu from './FeeSystemMenu';
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import { NavLink, useSearchParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import ShowStudent from '../Shared/ShowStudent';
import { Form } from 'react-bootstrap';
import StudentStatusDropDown from '../Shared/StudentStatusDropDown';
import { scrollToTop } from '../Shared/ScrollOnTop';

function ChangeStudentStatus(props) {

    const [loading, setLoading] = useState(false);
    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [Code, setCode] = useState("");
    const [ValidCode, setValidCode] = useState(false);
    const [student, setStudent] = useState({});
    const [StudentID, setStudentID] = useState(0);

    const [validated, setValidated] = useState(false);
    const [studentvalidated, setstudentvalidated] = useState(false);

    const handledsearch = (event) => {

        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidCode(false)
        }
        else {
            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
                MemberType: MemberType,
                Registration_No: Code,
            };

            var api_config = {
                method: 'post',
                url: config.base_url + 'Student/GetStudentByRegistrationNo',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(api_config)
                .then(function (response) {
                    ////console.log(response.data);
                    if (response.data.status_code == 1) {
                        setStudent(response.data.Student);
                        setStudentID(response.data.Student.Student_ID);
                        setValidCode(true)
                    }
                    else {
                        toast.error(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        setValidCode(false)
                    }

                    setLoading(false);


                })
                .catch(function (error) {
                    //console.log(error);
                });

        }

        setstudentvalidated(true);
    }

    const handleSubmit = (event) => {

        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();

        }
        else {
            //setLoading(true);
            const data = {
                UserID: UserID,
                AccessKey: AccessKey,
                Registration_No: Code,
                Status_Type_Id: event.target.Status_Type_Id.value,
                Description: event.target.reason.value
            }

            var api_config = {
                method: 'post',
                url: config.base_url + 'FeeSystem/ChangeStudentStatus',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };


            axios(api_config)
                .then(function (response) {
                    //console.log(response.data);
                    if (response.data.status_code == 1) {

                        toast.success(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });

                    }
                    else {
                        toast.error(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }




                })
                .catch(function (error) {
                    //console.log(error);
                });

        }

        setValidated(true);
    }


    return (
        <>
            <ToastContainer />
            <FeeSystemMenu />

            <div className="container body-content">
                <div className="page-head">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><NavLink to="/Dashboard">Dashboard</NavLink></li>
                                        <li className="breadcrumb-item"><NavLink to="/FeeSystem/FeeSystemDashboard">Fee System</NavLink></li>
                                        <li className="breadcrumb-item active" aria-current="page"> Change Student Status</li>
                                    </ol>
                                </nav>
                                <div className="page-title">
                                    Change Student Status
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 offset-md-1">
                                <div className="card">
                                    <div className="card-body card-form-wrap">
                                        <div className="form-wrap">
                                            <div className="form-legend">Step 1.Search Student</div>
                                            <Form noValidate validated={studentvalidated} onSubmit={handledsearch}>
                                                <div className="row">
                                                    <label  className="col-md-3 col-form-label col-form-label-sm">
                                                        Enter Student
                                                        Code:
                                                    </label>
                                                    <div className="col-md-3">
                                                        <input type="text" className="form-control"
                                                            value={Code}
                                                            onChange={(e) => setCode(e.target.value)}
                                                            required />
                                                    </div>

                                                    <div className="col-md-2 offset-md-4">
                                                        <button type="submit"
                                                            value="Search"
                                                            className="button button-primary w-100"
                                                        >Search</button>
                                                    </div>
                                                </div>
                                            </Form>
                                        </div>
                                        {Code.length != 0 && (
                                            <>
                                                {
                                                    (ValidCode === true) && (
                                                        <>
                                                            <div className="form-wrap">

                                                                <div className="form-legend">Step 2. Confirm Details</div>
                                                                {
                                                                    loading ?
                                                                        <Spinner animation="border" role="status"></Spinner>
                                                                        :
                                                                        <ShowStudent StudentID={StudentID} />
                                                                }
                                                            </div>
                                                            <div className="form-wrap">
                                                                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                                                    <div className="form-legend">Step 3. Change Student Status</div>
                                                                    <div className="row mb-3">
                                                                        <label  className="col-md-2 col-form-label col-form-label-sm">
                                                                            Change Status:
                                                                        </label>
                                                                        <div className="col-md-3">
                                                                            <select className="form-select" name='Status_Type_Id' required>
                                                                                <option Value="">Select Status</option>
                                                                                <StudentStatusDropDown />
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row align-items-center">
                                                                        <label  className="col-md-2 col-form-label col-form-label-sm">
                                                                            Change Reason:
                                                                        </label>
                                                                        <div className="col-md-10">
                                                                            <input type="text" className="form-control" name="reason" />
                                                                        </div>
                                                                        <div className="col-md-3 offset-md-9 mt-3">
                                                                            <input type="submit" value="Update Status" className="button button-primary w-100"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </Form>
                                                            </div>
                                                        </>
                                                    )
                                                }
                                            </>
                                        )}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ChangeStudentStatus;