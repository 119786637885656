import React, { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import config from "../../Config";
import Spinner from "react-bootstrap/Spinner";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import AnnualMenu from "./AnnualMenu";

function AnnualTasksDashboard(props) {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [loading, setLoading] = useState(false);
  const [ListOfSummarySheet, setListOfSummarySheet] = useState({});

  useEffect(() => {
    document.title = "Annual Tasks DashBoard";

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
    };
    //console.log(data);
    var api_config = {
      method: "post",
      url: config.base_url + "AnnualTask/GetAnnualTaskDashboard",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        if (response.data.status_code == 1) {
          //console.log(response.data);
          setListOfSummarySheet(response.data.SummarySheet);
        }

        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        toast.error("Network Error..", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }, []);

  const renderAnnualTasks = () => {
    if (ListOfSummarySheet.length > 0) {
      return ListOfSummarySheet.map((item, index) => {
        return (
          <div className="col-md-4">
            <div className="icon-box-1 fs-sm m-vertical mb-3">
              <div className="left">
                <p className="fw-bolder m-0">{item.Status_Type_Name}</p>
                <div className="d-flex justify-content-center  align-items-center mt-3">
                  <h4 className="m-0">{item.NoOfStudents}</h4>
                  <p className="m-1">Student</p>
                </div>
              </div>
              <p className="mt-3 m-0">{item.Status_Type_Code}</p>
            </div>
          </div>
        );
      });
    }
  };

  return (
    <>
      <AnnualMenu />

      <div className="page-head">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/Dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Annual Tasks
                  </li>
                </ol>
              </nav>
              <div className="page-title">Annual Tasks</div>
            </div>
            {/* <div className="col-md-6">
              <div className="page-right-actions">
                <Link
                  to="/Student/SearchStudents"
                  className="button button-white button-icon"
                >
                  Search Students
                  <i className="ri-search-line"></i>
                </Link>
                <Link
                  to="/Student/AddStudent"
                  className="button button-white button-icon"
                >
                  Add Student
                  <svg
                    width="20"
                    height="19"
                    viewBox="0 0 20 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2 10.999L11 11C12.0538 11 12.9181 11.8155 12.9945 12.8507L13 13V14.5C12.999 18 9.284 19 6.5 19C3.77787 19 0.164695 18.044 0.00545406 14.7296L0 14.5V12.999C0 11.9452 0.816397 11.0809 1.85081 11.0045L2 10.999ZM13.22 11H18C19.0538 11 19.9181 11.8164 19.9945 12.8508L20 13V14C19.999 17.062 17.142 18 15 18C14.32 18 13.569 17.904 12.86 17.678C13.196 17.292 13.467 16.851 13.662 16.351C14.205 16.476 14.715 16.5 15 16.5L15.2665 16.494C16.2518 16.4509 18.3529 16.1306 18.4927 14.205L18.5 14V13C18.5 12.7547 18.3222 12.5504 18.0896 12.5081L18 12.5H13.949C13.865 11.9986 13.6554 11.5432 13.3545 11.1598L13.22 11H18H13.22ZM2 12.499L1.89934 12.509C1.77496 12.5343 1.69 12.6018 1.646 12.645C1.6028 12.689 1.53528 12.7733 1.51 12.898L1.5 12.999V14.5C1.5 15.509 1.95 16.222 2.917 16.742C3.74315 17.1869 4.91951 17.4563 6.18258 17.4951L6.5 17.5L6.8174 17.4951C8.08035 17.4563 9.25592 17.1869 10.083 16.742C10.9886 16.2545 11.4416 15.5974 11.4947 14.6849L11.5 14.499V13C11.5 12.7547 11.3222 12.5504 11.0896 12.5081L11 12.5L2 12.499ZM6.5 0C8.985 0 11 2.015 11 4.5C11 6.985 8.985 9 6.5 9C4.015 9 2 6.985 2 4.5C2 2.015 4.015 0 6.5 0ZM15.5 2C17.433 2 19 3.567 19 5.5C19 7.433 17.433 9 15.5 9C13.567 9 12 7.433 12 5.5C12 3.567 13.567 2 15.5 2ZM6.5 1.5C4.846 1.5 3.5 2.846 3.5 4.5C3.5 6.154 4.846 7.5 6.5 7.5C8.154 7.5 9.5 6.154 9.5 4.5C9.5 2.846 8.154 1.5 6.5 1.5ZM15.5 3.5C14.397 3.5 13.5 4.397 13.5 5.5C13.5 6.603 14.397 7.5 15.5 7.5C16.603 7.5 17.5 6.603 17.5 5.5C17.5 4.397 16.603 3.5 15.5 3.5Z"
                      fill="#003970"
                    ></path>
                  </svg>
                </Link>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      <div className="page-content">
        <div className="container">
          <div className="row g-3 ">
            <div className="col-md-8 col-sm-12">
              <div className="card" style={{ minHeight: "228px" }}>
                <div className="card-body">
                  <h4 className="card-title">Overall Information</h4>
                  <div className="row align-items-center pt-3">
                    {loading ? (
                      <Spinner animation="border" role="status"></Spinner>
                    ) : (
                      <>{renderAnnualTasks()}</>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 ">
              <div className="card p-3" >
                <ul className="list-info-boxes ">
                  <li className="justify-content-start">
                    <div className="left">
                      <div className="icon">
                        <span>{1}</span>
                      </div>
                    </div>
                    <div className="right">
                      <h5>Mark as Study Complete</h5>
                    </div>
                  </li>
                  <p style={{ fontWeight: "400", fontSize: "0.9rem", color: "rgba(0, 0, 0, 0.5)", textAlign: "justify" }}>As a first step of annual tasks, mark students as study complete. Most of the cases, the 10th and 12th classes are marked as study complete if the school ends at 10th Level or 12th Level. The students Marked as study complete will be out from the system. </p>
                </ul>
                <ul className="list-info-boxes ">
                  <li className="justify-content-start">
                    <div className="left">
                      <div className="icon">
                        <span>{2}</span>
                      </div>
                    </div>
                    <div className="right">
                      <h5>Add New Academic Session</h5>
                    </div>
                  </li>
                  <p style={{ fontWeight: "400", fontSize: "0.9rem", color: "rgba(0, 0, 0, 0.5)", textAlign: "justify" }}>As a second step, create new academic session. Every year a new academic session will be created and students will be promoted in new session. </p>
                </ul>
                <ul className="list-info-boxes ">
                  <li className="justify-content-start">
                    <div className="left">
                      <div className="icon">
                        <span>{3}</span>
                      </div>
                    </div>
                    <div className="right">
                      <h5>Generate Classes in New Academic Session
                      </h5>
                    </div>
                  </li>
                  <p style={{ fontWeight: "400", fontSize: "0.9rem", color: "rgba(0, 0, 0, 0.5)", textAlign: "justify" }}>As a third step, use the generate classes option to create new classes based on the previous session into the new session. It will also move the fee information from old classes to new session.
                  </p>
                </ul>
                <ul className="list-info-boxes ">
                  <li className="justify-content-start">
                    <div className="left">
                      <div className="icon">
                        <span>{4}</span>
                      </div>
                    </div>
                    <div className="right">
                      <h5>Promote Students
                      </h5>
                    </div>
                  </li>
                  <p style={{ fontWeight: "400", fontSize: "0.9rem", color: "rgba(0, 0, 0, 0.5)", textAlign: "justify" }}>Now use this option to promote the students from previous classes to new classes. Use top-down approach for this purpose i.e. Move higher classes first started from 10th or 12th which is higher in your school.
                  </p>
                </ul>
                <ul className="list-info-boxes ">
                  <li className="justify-content-start">
                    <div className="left">
                      <div className="icon">
                        <span>{5}</span>
                      </div>
                    </div>
                    <div className="right">
                      <h5>Check fee information
                      </h5>
                    </div>
                  </li>
                  <p style={{ fontWeight: "400", fontSize: "0.9rem", color: "rgba(0, 0, 0, 0.5)", textAlign: "justify" }}>Check the fee information of each class, usually fee is increased in the new session so before proceeding check the fee information.
                  </p>
                </ul>
                <ul className="list-info-boxes ">
                  <li className="justify-content-start">
                    <div className="left">
                      <div className="icon">
                        <span>{6}</span>
                      </div>
                    </div>
                    <div className="right">
                      <h5>Start New Month
                      </h5>
                    </div>
                  </li>
                  <p style={{ fontWeight: "400", fontSize: "0.9rem", color: "rgba(0, 0, 0, 0.5)", textAlign: "justify" }}>Now you can use the start new month option to generate fee chalans for the current month with new session. </p>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AnnualTasksDashboard;
