import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { Form } from "react-bootstrap";
import UserGroupDropDown from "../Shared/UserGroupDropDown";
import PayrollMenu from './PayrollMenu';
import DesignationDropDown from "../Shared/DesignationDropDown";
import PayScaleBasic from "../Shared/PayScaleBasic";
import PayScaleGps from "../Shared/PayScaleGps";
import EmployeeTypeDropDown from "../Shared/EmployeeTypeDropDown";
import moment from "moment";
import { scrollToTop } from '../Shared/ScrollOnTop';

function EditEmployee(props) {
    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [validated, setValidated] = useState(false);
    const [Code, setCode] = useState("");

    const [loading, setLoading] = useState(false);
    const [loadingCode, setLoadingCode] = useState(false);
    const [loadingEmployee, setloadingEmployee] = useState(false);


    let params = useParams();
    const [EmployeeID, setEmployeeID] = useState(params.id);
    const [Employee, setEmployee] = useState({});

    // edit form 
    const [Marital_Status, setMarital_Status] = useState("");
    const [EmployementStatus,setEmployementStatus] = useState("");
    const [Gender, setGender] = useState("");
    const [LongLeaveStatus, setLongLeaveStatus] = useState("");
    const [Date_Of_Joining, setDate_Of_Joining] = useState("");
    const [Date_Of_Birth, setDate_Of_Birth] = useState("");
    const [PromotionDate, setPromotionDate] = useState("");

    // photo 
    const [isUpdated, setIsUpdated] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [image, setImage] = useState('');
    const [cropData, setCropData] = useState("");
    const [cropper, setCropper] = useState();
    const [imageSelected, setImageSelected] = useState(false);
    const [imageCropped, setImageCropped] = useState(false);
    const [imgData, setImageData] = useState("");
    const handleShowModal = () => setShowModal(true);

    const handleCloseModal = () => {
        setShowModal(false);
        setImageSelected(false);
        setLoading(false);
    }

    useEffect(() => {
        document.title = "Edit Employee";
        setloadingEmployee(true);
        const data = {
           AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            ID: EmployeeID

        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'Payroll/GetEmployeeByID',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                if (response.data.status_code == 1) {
                    setEmployee(response.data.Employee);
                    setMarital_Status(response.data.Employee.Marital_Status);
                    setEmployementStatus(response.data.Employee.EmployementStatus);
                    setGender(response.data.Employee.Gender);
                    setLongLeaveStatus(response.data.Employee.LongLeaveStatus);
                    setDate_Of_Joining(moment(response.data.Employee.Date_Of_Joining).format(config.date_format_input));
                    setDate_Of_Birth(moment(response.data.Employee.Date_Of_Birth).format(config.date_format_input));
                    setPromotionDate(moment(response.data.Employee.PromotionDate).format(config.date_format_input));
                }
                setloadingEmployee(false);
            })
            .catch(function (error) {
                setLoading(false);
                toast.error('Network Error..', {
                    position: toast.POSITION.TOP_RIGHT
                });
            });


    }, []);




    const handleSubmit = (event) => {

        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            //alert();
            setLoading(true);

            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
                MemberType: MemberType,
                ID: params.id,
                Code: Code,
                Name: event.target.Name.value,
                Father_Name: event.target.Father_Name.value,
                Husband_Name: event.target.Husband_Name.value,
                Password: event.target.Password.value,
                EmployeeTypeID: event.target.EmployeeTypeID.value,
                NoofIncrements:event.target.NoofIncrements.value,
                Marital_Status: Marital_Status,
                Gender: Gender,
                DesignationID: event.target.DesignationID.value,
                Date_Of_Joining: Date_Of_Joining,
                Date_Of_Birth: Date_Of_Birth,
                CNIC_NO: event.target.CNIC_NO.value,
                Account_No: event.target.Account_No.value,
                Home_Address: event.target.Home_Address.value,
                Phone_No: event.target.Phone_No.value,
                Mobile_No: event.target.Mobile_No.value,
                Qualification: event.target.Qualification.value,
                Blood_Group: event.target.Blood_Group.value,
                NTN_NO: event.target.NTN_NO.value,
                EOBI_NO: event.target.EOBI_NO.value,
                Basic_Pay_Scale: event.target.Basic_Pay_Scale.value,
                Email_Address: event.target.Email_Address.value,
                Remarks: event.target.Remarks.value,
                GPS_Scale: event.target.GPS_Scale.value,
                StaffImage: cropData,
                OldSerialNo: event.target.OldSerialNo.value,
                LongLeaveStatus: event.target.LongLeaveStatus.value,
                PromotionDate: PromotionDate,
                EmployementStatus: EmployementStatus,
            };



            //console.log(data);

            var api_config = {
                method: 'post',
                url: config.base_url + 'Payroll/UpdateEmployee',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };



            axios(api_config)
                .then(function (response) {
                    //console.log(response.data);
                    if (response.data.status_code == 1) {

                        toast.success(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });

                    }
                    else {
                        toast.error(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }

                    setLoading(false);


                })
                .catch(function (error) {
                    setLoading(false);
                    toast.error('Network Error..', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                });



        }

        setValidated(true);
    };


    const onChange = (e) => {
        e.preventDefault();
        setImageSelected(true);
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            setImage(reader.result);
        };
        reader.readAsDataURL(files[0]);
    };

    const getCropData = () => {

        if (typeof cropper !== "undefined") {

            var cropData1 = cropper.getCroppedCanvas().toDataURL();
            setImageCropped(true);


            var cleanerBase64 = cropData1.substring(22);
            setCropData(cleanerBase64);
            setImageData(cropData1);

        }

        handleCloseModal();
    };

    const renderButtons = () => {
        if (imageSelected === true) {
            return (
                <>
                    <div className="modal-footer justify-content-center">
                        <button className="button button-outline-primary me-2" onClick={handleCloseModal}>Close</button>
                        <button className="button button-primary" onClick={getCropData}>Save Photo</button>
                    </div>
                </>
            )
        }
        else if (imageSelected === false && isUpdated === true) {
            return (
                <>
                    <div className="modal-footer justify-content-center">
                        <button className="button button-outline-primary me-2" onClick={handleCloseModal}>Close</button>
                    </div>
                </>
            )
        }
    }

    return (
        <>
            <PayrollMenu />
            <ToastContainer />
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Update Profile Photo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        isUpdated ?
                            <>
                                <div className="text-center">

                                    <p>Photo Updated Successfully</p>
                                </div>

                            </>
                            :
                            <>
                                <div className="upload-photo-wrap">
                                    {
                                        imageSelected ?
                                            <Cropper

                                                zoomTo={0.5}
                                                aspectRatio={8 / 8}
                                                preview=".img-preview"
                                                src={image}
                                                dragMode="move"
                                                viewMode={2}
                                                minCropBoxHeight={10}
                                                minCropBoxWidth={10}
                                                background={false}
                                                responsive={true}
                                                autoCropArea={1}
                                                checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                                onInitialized={(instance) => {
                                                    setCropper(instance);
                                                }}
                                                guides={true}
                                            />
                                            :
                                            <div className="choose-photo">
                                                <label htmlFor="ChoosePhoto">
                                                    <i className="ri-upload-cloud-line"></i>
                                                    <span>Browse Photo</span>
                                                    <input id="ChoosePhoto" accept="image/jpeg,image/png" type="file" onChange={onChange} />
                                                </label>

                                            </div>

                                    }


                                </div>
                            </>

                    }

                </Modal.Body>

                {renderButtons()}

            </Modal>
            <div className="page-head">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/Dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item"><Link to="/PayRoll/PayRollDashboard">Pay Roll</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Edit / Update Employee</li>
                                </ol>
                            </nav>
                            <div className="page-title">Edit / Update Employee</div>
                        </div>
                        <div className="col-md-6">
                            <div className="page-right-actions">
                                <Link to="/PayRoll/ManageEmployee" className="button button-white button-icon">
                                    Manage Employees
                                    <i className="ri-list-check"></i>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-content">
                <div className="container">
                    <div className="card">
                        <div className="card-body p-md-4">
                            {
                                loadingEmployee ?
                                    <div className='text-center'>
                                        <Spinner animation="border" role="status"></Spinner>
                                    </div>
                                    :
                                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                        <div className="form-wrap">
                                            <div className="form-legend" style={{ left: "64px" }}>Basic Information</div>
                                            <div className="row align-items-center">
                                                <div className="col-md-9">
                                                    <div className="row align-item-center">
                                                        <div className="col-md-3">
                                                            <div className="form-group mb-3">
                                                                <label>Employee Code:</label>
                                                                <div className="label-gray">
                                                                    {Employee.Code}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group mb-3">
                                                                <label>Employee Name:</label>
                                                                <input type="text" className="form-control" defaultValue={Employee.Name} required name="Name" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group mb-3">
                                                                <label>Father Name:</label>
                                                                <input type="text" className="form-control" defaultValue={Employee.Father_Name} required name="Father_Name" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group mb-3">
                                                                <label>Husband Name:</label>
                                                                <input type="text" defaultValue={Employee.Husband_Name} className="form-control" name="Husband_Name" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group mb-3">
                                                                <label>Marital Status:</label>
                                                                <select
                                                                    className="form-select"
                                                                    value={Marital_Status}
                                                                    name="Marital_Status"
                                                                    onChange={(e) => setMarital_Status(e.target.value)}
                                                                    required>
                                                                    <option value="NotAvailable">Not Available</option>
                                                                    <option value="Single">Single</option>
                                                                    <option value="Married">Married</option>
                                                                    <option value="Divorced">Divorced</option>
                                                                    <option value="Widowed">Widowed</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group mb-3">
                                                                <label>Gender:</label>
                                                                <select className="form-select" value={Gender} onChange={(e) => setGender(e.target.value)} name="Gender" required>
                                                                    <option value="">Select Gender</option>
                                                                    <option value={true}>Male</option>
                                                                    <option value={false}>Female</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group mb-3">
                                                                <label  className="">Date of Birth:</label>
                                                                <input type="date" value={Date_Of_Birth} className="form-control"
                                                                    onChange={(e) => setDate_Of_Birth(e.target.value)}
                                                                    required name="Date_Of_Birth" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group mb-3">
                                                                <label>Blood Group:</label>
                                                                <input type="text" defaultValue={Employee.Blood_Group} className="form-control" name="Blood_Group" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group mb-3">
                                                                <label  className="">CNIC No:</label>
                                                                <input type="text" defaultValue={Employee.CNIC_NO} className="form-control" required name="CNIC_NO" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group mb-3">
                                                                <label  className="">Old Serial No:</label>
                                                                <input type="text" defaultValue={Employee.OldSerialNo} className="form-control" required name="OldSerialNo" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group mb-3">
                                                                <label>Long Leave Status:</label>
                                                                <select className="form-select" value={LongLeaveStatus} onChange={(e) => setLongLeaveStatus(e.target.value)} name="LongLeaveStatus" required>
                                                                    <option value="">Select Status</option>
                                                                    <option value={true}>Long Leave</option>
                                                                    <option value={false}>Available</option>
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-3">
                                                            <div className="form-group mb-3">
                                                                <label  className="">Promotion Date:</label>
                                                                <input type="date" value={PromotionDate} className="form-control"
                                                                    onChange={(e) => setPromotionDate(e.target.value)}
                                                                     name="PromotionDate" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group mb-3">
                                                                <label>Employeement Status:</label>
                                                                <select
                                                                    className="form-select"
                                                                    value={EmployementStatus}
                                                                    name="EmployementStatus"
                                                                    onChange={(e) => setEmployementStatus(e.target.value)}
                                                                    required>
                                                                    <option value="N">Not Available</option>
                                                                    <option value="P">Permanent</option>
                                                                    <option value="T">Temporary</option>
                                                                </select>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="col-md-3 text-center">
                                                    {
                                                        imgData == "" ?
                                                            <img className="mb-3" src={config.StaffImagePath + Employee.Code + ".jpg"} style={{ width: "150px" }} />
                                                            :
                                                            <img className="mb-3" src={imgData} style={{ width: "150px" }} />
                                                    }

                                                    <button type="button" onClick={handleShowModal} className="button  button-small">
                                                        Choose Photo
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-wrap">
                                            <div className="form-legend" style={{ left: "64px" }}>Account Information</div>
                                            <div className="row align-items-center">
                                                <div className="col-md-3">
                                                    <div className="form-group mb-3">
                                                        <label>Bank Account No:</label>
                                                        <input type="text" defaultValue={Employee.Account_No} className="form-control" name="Account_No" />
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group mb-3">
                                                        <label>EOBI No:</label>
                                                        <input type="text" defaultValue={Employee.EOBI_NO} className="form-control" name="EOBI_NO" />
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group mb-3">
                                                        <label>NTN NO:</label>
                                                        <input type="text" defaultValue={Employee.NTN_NO} className="form-control" name="NTN_NO" />
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                            <div className="form-group mb-3">
                                                                <label  className="">Password:</label>
                                                                <input type="password" defaultValue={Employee.Password} className="form-control" required name="Password" />
                                                            </div>
                                                        </div>
                                                        
                                            </div>
                                        </div>
                                        <div className="form-wrap">
                                            <div className="form-legend" style={{ left: "64px" }}>Contact Information</div>
                                            <div className="row align-items-center">
                                                <div className="col-md-3">
                                                    <div className="form-group mb-3">
                                                        <label>Phone No:</label>
                                                        <input type="text" defaultValue={Employee.Phone_No} className="form-control" name="Phone_No" />
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group mb-3">
                                                        <label>Mobile No:</label>
                                                        <input type="text" defaultValue={Employee.Mobile_No} className="form-control" required name="Mobile_No" />
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group mb-3">
                                                        <label  className="">Date of Joining:</label>
                                                        <input type="date"
                                                            value={Date_Of_Joining} className="form-control"
                                                            onChange={(e) => setDate_Of_Joining(e.target.value)}
                                                            required name="Date_Of_Joining" />
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group mb-3">
                                                        <label>Designation:</label>

                                                        <select className="form-select" required name="DesignationID">
                                                            <option value="">Select Designation</option>
                                                            <DesignationDropDown selected={Employee.Selected_Designation?.ID} />
                                                        </select>
                                                    </div>


                                                </div>

                                                <div className="col-md-3">
                                                    <div className="form-group mb-3">
                                                        <label  className="">Email Address:</label>
                                                        <input type="email" defaultValue={Employee.Email_Address} className="form-control" name="Email_Address" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group mb-3">
                                                        <label  className="">Home Address:</label>
                                                        <input type="text" defaultValue={Employee.Home_Address} className="form-control" required name="Home_Address" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-wrap">
                                            <div className="form-legend" style={{ left: "64px" }}>Pay / Qualification Information</div>
                                            <div className="row align-items-center">
                                                <div className="col-md-3">
                                                    <div className="form-group mb-3">
                                                        <label>Basic Pay Scale</label>
                                                        <select className="form-select" name="Basic_Pay_Scale">
                                                            <option value="">Please Select</option>
                                                            <PayScaleBasic selected={Employee.Basic_Pay_Scale} defaultValue={Employee.Basic_Pay_Scale} />
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group mb-3">
                                                        <label>Pay Scale GPS:</label>
                                                        <select className="form-select" name="GPS_Scale">
                                                            <option value="">Please Select</option>
                                                            <PayScaleGps selected={Employee.GPS_Scale} />
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group mb-3">
                                                        <label>Group/Depart:</label>
                                                        <select className="form-select" name="EmployeeTypeID">
                                                            <EmployeeTypeDropDown selected={Employee.EmployeeTypeID} defaultValue={Employee.EmployeeTypeID} />
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group mb-3">
                                                        <label>No of Increment:</label>
                                                        <label  name="NoofIncrements">
                                                            <input type="text" defaultValue={Employee.NoofIncrements} className="form-control" name="NoofIncrements" />
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group mb-3">
                                                        <label  className="">Qualification:</label>
                                                        <input type="text" defaultValue={Employee.Qualification} className="form-control" name="Qualification" />
                                                    </div>
                                                </div>
                                                <div className="col-md-10">
                                                    <div className="form-group mb-3">
                                                        <label  className="">Remarks:</label>
                                                        <input type="text" defaultValue={Employee.Remarks} className="form-control" name="Remarks" />
                                                    </div>
                                                </div>
                                                <div className="col-md-2 mt-md-2 text-end">
                                                    <div className="form-group ">
                                                        {
                                                            loading ?
                                                                <Spinner animation="border" role="status"></Spinner>

                                                                :
                                                                <button type="submit" className="button button-primary w-100 ">SAVE</button>

                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </Form>
                            }

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EditEmployee;