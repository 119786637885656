import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import StudentMenu from './StudentMenu';
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate } from 'react-router-dom';
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { scrollToTop } from '../Shared/ScrollOnTop';
import RightsCheck from '../General/RightsCheck';

function StudentProfileSearch(props) {

    document.title ='Student Profile'

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [validated, setValidated] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [hasError, setHasError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [StudentID, setStudentID] = useState("0");
    const [Student, setStudent] = useState({});
    const [admissionDate, setadmissionDate] = useState("");
    const [receiptNo, setreceiptNo] = useState("0");
    const [DueAmount, setDueAmount] = useState("0");

    const [StudentCode, setStudentCode] = useState(0);
    
    const handleSubmit = (event) => {

        setLoading(true);
        const data = {
           AccessKey: AccessKey,
            UserID: UserID,
MemberType: MemberType,
            ID: StudentID,
            ReceiptNo: receiptNo,
            AdmissionDate: admissionDate,
        };

        ////console.log(data);

        var api_config = {
            method: 'post',
            url: config.base_url + 'Student/GetStudentByIDForAdmit',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };



        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                if (response.data.status_code == 1) {
                    setStudent(response.data.Student);

                }
                else {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }

                setLoading(false);


            })
            .catch(function (error) {
                setLoading(false);
                toast.error('Network Error..', {
                    position: toast.POSITION.TOP_RIGHT
                });
            });

        setValidated(true);
    };

    const StudentProfileSearch = (event) => {

        setLoading(true);
        const data = {
           AccessKey: AccessKey,
            UserID: UserID,
MemberType: MemberType,
            ID: StudentID,
            AdmissionDate: admissionDate,
            ReceiptNo: receiptNo,

        };

        //console.log(data);

        var api_config = {
            method: 'post',
            url: config.base_url + 'Student/AdmitStudent',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };



        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                if (response.data.status_code == 1) {
                    //setStudent(response.data.Student);
                    toast.success(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
                else {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }

                setLoading(false);


            })
            .catch(function (error) {
                setLoading(false);
                toast.error('Network Error..', {
                    position: toast.POSITION.TOP_RIGHT
                });
            });

        setValidated(true);
    };

    return (
        <>
         <RightsCheck />
            <StudentMenu />
            <ToastContainer />
            <div className="container body-content">

                <div className="page-head">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="/Home/Index">Dashboard</a></li>
                                        <li className="breadcrumb-item"><a href="/student/studentdashboard">Students</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Student Profile</li>
                                    </ol>
                                </nav>
                                <div className="page-title">Student Profile</div>
                            </div>

                          
                        </div>
                    </div>
                </div>


                <div className="page-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 offset-md-1">
                                <div className="card">
                                    <div className="card-body card-form-wrap">
                                        <div className="form-wrap">
                                            <div className="form-legend">Step 1.Search Student</div>
                                            <div className="row">
                                                <div className="row">
                                                    <label 
                                                        className="col-md-3 col-form-label col-form-label-sm">
                                                        Enter Student
                                                        ID:
                                                    </label>
                                                    <div className="col-md-3">
                                                        <input type="text" name="Student_ID" onChange={(e) => setStudentID(e.target.value)} className="form-control" required />
                                                    </div>
                                                    <div className="col-md-3 offset-md-3">
                                                        {
                                                            loading ?
                                                                <Spinner animation="border" role="status"></Spinner>
                                                                :
                                                            <a href={`/students/PrintStudentProfile?StudentCode=${StudentID}`} target="_blank" className="button button-primary w-100">Search</a>
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                     
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}

export default StudentProfileSearch;