import React, { useState, useEffect } from 'react';
import config from "../../Config";
import axios from "axios";


function StudentStatusDropDown(props) {
    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [StudentStatus, setStudentStatus] = useState([]);

    useEffect(() => {

        const apiData = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            PageNo: 1,
            PageSize: 100
        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'Student/GetStudentStatusTypes',
            headers: {
                'Content-Type': 'application/json'
            },
            data: apiData
        };

        axios(api_config)
            .then(function (response) {
                ////console.log(response.data);
                setStudentStatus(response.data.ListofRecords || []);
            })
            .catch(function (error) {
                //console.log(error);
            });

    }, []);



    return (
        StudentStatus.map((item, index) => {
            return (
                <option value={item.Status_Type_Id} key={index}>{item.Status_Type_Name}</option>
            )
        })
    );
}

export default StudentStatusDropDown;