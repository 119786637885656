import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import EmployeeTypeDropDown from "../Shared/EmployeeTypeDropDown";

// import StudentMenu from "./StudentMenu";
import { toast, ToastContainer } from "react-toastify";
import { NavLink } from "react-router-dom";
import moment from "moment";
import Pagination from "../General/Pagination";
import Dropdown from "react-bootstrap/Dropdown";
import { Form, PageItem } from "react-bootstrap";
import { scrollToTop } from "../Shared/ScrollOnTop";
import MonthsDropDown from "../Shared/MonthsDropDown";
import YearsDropDown from "../Shared/YearsDropDown";
import StaffAtMenu from "./StaffAtMenu";

import csvDownload from "json-to-csv-export";

function AttendanceReport(props) {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );

  const [showFilters, setShowFilters] = useState(true);

  const [loading, setLoading] = useState(false);
  const [listOfLedger, setLedger] = useState([]);
  const [validated, setValidated] = useState(false);
  const [Month, setMonth] = useState(moment().format("M"));
  const [Year, setYear] = useState(moment().format("YYYY"));
  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);

  const [EmployeeTypeId, setEmployeeTypeId] = useState(0);

  const [leaveType, setleaveType] = useState("");
  const [isUpdated, setIsUpdated] = useState(false);
  const [loaddata, setLoaddata] = useState(false);

  useEffect(() => {
    document.title = "Staff Attendance Report";
    setLoading(true);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      PageNo: pageNo,
      PageSize: pageSize,
      leaveType: leaveType,
      StaffTypeID: EmployeeTypeId,
      SelectedMonth: Month,
      ReportYear: Year,
    };
    var api_config = {
      method: "post",
      url: config.base_url + "/StaffAttendance/GetStaffAttendanceReport",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    //console.log(data);

    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          setLedger(response.data.ListofRecords);
          setLoading(false);
          setTotalRecords(response.data.totalRecords);
        } else {
          setLedger(null);
          setTotalRecords(0);
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoading(false);
        }
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, [pageNo, pageSize]);

  const handleSubmitForCvs = (event) => {
    //console.log(event);

    setLoaddata(true);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      PageNo: pageNo,
      PageSize: 10000,
      leaveType: leaveType,
      StaffTypeID: EmployeeTypeId,
      SelectedMonth: Month,
      ReportYear: Year,
    };

   //console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "/StaffAttendance/GetStaffAttendanceReport",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        if (response.data.status_code == 1) {
          if (response.data.totalRecords > 0) {
            let data = response.data.ListofRecords;
            let count = 0;
            const listOfLedgerNew = data.map(({ ...rest }) => ({
              Serial_No: count++ + pageSize * (pageNo - 1),
              ID: rest.ID,
              Name: rest.Name,
              Dated: moment(rest.Dated).format("DD/MM/YYYY"),
              Absent_Leave: rest.Absent_Leave,
            }));

            const dataToConvert = {
              data: listOfLedgerNew,
              filename: "Attendance_Report",
              delimiter: ",",
              headers: ["Serial_No", "ID", "Name", "Dated", "Absent_Leave"],
            };
            csvDownload(dataToConvert);

            setLoaddata(false);
          } else if (response.data.totalRecords == 0) {
            toast.error("There is no data available for download", {
              position: toast.POSITION.TOP_RIGHT,
            });
            setLoaddata(false);
          }
        } else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoaddata(false);
        }
      })
      .catch(function (error) {
        toast.error("Network Error..", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoaddata(false);
      });
  };

  const handlePageChange = (page) => {
    setPageNo(page);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsUpdated(false);
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      PageNo: pageNo,
      PageSize: pageSize,
      leaveType: leaveType,
      StaffTypeID: EmployeeTypeId,
      SelectedMonth: Month,
      ReportYear: Year,
    };

    //console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "/StaffAttendance/GetStaffAttendanceReport",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        if (response.data.status_code == 1) {
          setLedger(response.data.ListofRecords);
          setLoading(false);
          setTotalRecords(response.data.totalRecords);
        } else {
          setLedger(null);
          setTotalRecords(0);
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoading(false);
        }
      })
      .catch(function (error) {
        //console.log(error);
      });
  };

  const renderLedger = () => {
    if (listOfLedger != null && listOfLedger.length > 0) {
      let count = 1;
      return listOfLedger.map((item, index) => {
        return (
          <tr key={index}>
            <td>{count++}</td>
            <td>{item.ID}</td>
            <td>{item.Name}</td>
            <td>{item.Code}</td>
            <td>{item.Absent_Leave}</td>
            <td>{item.SelectedDesignation.Department}</td>
            <td>{moment(item.Dated).format("DD/MM/YYYY")}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="12">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };

  return (
    <>
      <StaffAtMenu />
      <ToastContainer />
      <div className="container body-content">
        <div className="page-head">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <NavLink to="/Dashboard">Dashboard</NavLink>
                    </li>
                    <li className="breadcrumb-item">
                      <NavLink to="/students/StudentDashboard">Staff</NavLink>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Attendance Report
                    </li>
                  </ol>
                </nav>
                <div className="page-title">Staff Attendance Report</div>
              </div>
            </div>
          </div>
        </div>
        {/* <div
          className={`collapse ${showFilters ? "show" : ""} `}
          id="collapseFilteres"
        > */}
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          {/* <form onSubmit={handleSubmit}> */}
          <div className="page-content">
            <div className="container">
              <div className="card mb-2">
                <div className="card-body p-md-4">
                  <div className="row align-items-center gy-3">
                    <div className="col-md-3">
                      <div className="form-group">
                        <label  className="">
                          Select Month:
                        </label>
                        <select
                          name="Month"
                          defaultValue={Month}
                          onChange={(e) => setMonth(e.target.value)}
                          className="form-select"
                        >
                          <option value="">Please Select</option>
                          <MonthsDropDown />
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label  className="">
                          Select Year:
                        </label>
                        <select
                          name="Year"
                          value={Year}
                          onChange={(e) => setYear(e.target.value)}
                          className="form-select"
                        >
                          <option value="">Please Select</option>
                          <YearsDropDown />
                        </select>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Select Staff Type:</label>
                        <select
                          onChange={(e) => setEmployeeTypeId(e.target.value)}
                          value={EmployeeTypeId}
                          className="form-select"
                          required
                        >
                          <option value="">Please Select</option>
                          <EmployeeTypeDropDown />
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Select Leave Type:</label>
                        <select
                          name="Leave_type"
                          className="form-select"
                          value={leaveType}
                          onChange={(e) => setleaveType(e.target.value)}
                        >
                          <option value="AB">AB</option>
                          <option value="ETC"> ETC</option>
                          <option value="LWOP">LWOP</option>
                          <option value="MAT">MAT</option>
                          <option value="ML">ML</option>
                          <option value="CL">CL</option>
                        </select>
                      </div>
                    </div>

                    {loaddata ? (
                      <div className="col-md-2 offset-8 mt-4 text-center ">
                        <div>
                          <div>
                            <Spinner animation="border" role="status"></Spinner>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="col-md-2 offset-8 text-end ">
                        <div className="form-group text-end mt-4">
                          <a
                            type="button"
                            onClick={handleSubmitForCvs}
                            className="print-icon"
                          >
                            <span>
                              <i className="ri-file-excel-2-line"></i>
                            </span>
                          </a>
                        </div>
                      </div>
                    )}

                    <div className="col-md-2 text-end">
                      <div className="form-group text-end">
                        <button
                          type="submit"
                          value="Search"
                          className="button button-primary mt-4 w-100"
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* </form> */}
        </Form>
        {/* </div> */}
        <div className="page-content">
          <div className="container">
            <div className="card">
              <div className="card-body">
                {/* <div className="text-end mb-2 me-3">
                                    <b>{{totalRecords}} &nbsp; Records Found!</b>

                                </div> */}
                <table className="table table-theme">
                  <thead>
                    <tr>
                      <th>Sr.</th>
                      <th>ID</th>
                      <th>Name</th>

                      <th>Code</th>
                      <th>Absent Leave</th>
                      <th>Designation</th>
                      <th>Dated</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td className="text-center" colSpan="12">
                          <Spinner animation="border" role="status"></Spinner>
                        </td>
                      </tr>
                    ) : (
                      <>{renderLedger()}</>
                    )}
                  </tbody>
                </table>
                {totalRecords > 10 && (
                  <div className="pagination-wrap">
                    <div className="row align-items-center">
                      <div className="col-md-2 form_default">
                        <div className="d-flex align-items-center">
                          <label className="me-2" style={{ fontSize: "15px" }}>
                            Page Size
                          </label>
                          <div className="form-group">
                            <select
                              className="form-select"
                              style={{ width: "100px" }}
                              onChange={(e) => {
                                setPageNo(1);
                                setPageSize(parseInt(e.target.value));
                              }}
                            >
                              <option value="10" selected>
                                10
                              </option>
                              <option value="20">20</option>
                              <option value="50">50</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <Pagination
                        pageNo={pageNo}
                        pageSize={pageSize}
                        totalRecords={totalRecords}
                        onPageChange={handlePageChange}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="container">
            <div className="card mt-2">
              <div className="card-body p-md-4">
                <div className="row align-items-center">
                    <div className="col-md-2 offset-md-8">
                      <button
                        className="button button-primary w-100"
                        type="button"
                        disabled={leaveType !== "AB"}
                      >
                        Deduct Salary
                      </button>
                    </div>
                    <div className=" col-md-2">
                      <NavLink
                        className="button button-primary w-100"
                        to={`/StaffAttendance/PrintReport?Month=${Month}&Year=${Year}&staffIid=${EmployeeTypeId}&leaveType=${leaveType}
                                       `}
                        target="_blank"
                      >
                        Print Report
                      </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AttendanceReport;
