import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import QRCode from "react-qr-code";
import StudentInfo from "../FeeSystem/StudentInfo";
import moment from "moment";
import PrintHeader from "../General/PrintHeader";
import Page from "../General/Page";
import { calculateColumnTotal, getMonthName } from "../General/Utility";
import { scrollToTop } from "../Shared/ScrollOnTop";

const OtherLoanDeductionsPrint = () => {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [loading, setLoading] = useState(false);
  const [listOfLedger, setLedger] = useState([]);
  const [Year, setYear] = useState("");
  const [Month, setMonth] = useState("");

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [TotalAmount, setTotalAmount] = useState(0);

  useEffect(() => {
    document.title = "AGS School Management System";

    setLoading(true);
    // Get the URL parameters
    const urlParams = new URLSearchParams(window.location.search);

    // Retrieve individual parameter values
    const Month = urlParams.get("Month");
    const Year = urlParams.get("Year");

    setYear(Year);
    setMonth(Month);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      pageNo: 1,
      pageSize: 10000,
      SelectedMonth: Month,
      FYear: Year,
    };

    //console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "OtherDeduction/GetOtherDeductions",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        setLedger(response.data.ListofRecords);
        setLoading(false);
        setTotalRecords(response.data.totalRecords);
        setTotalAmount(response.data.TotalAmount);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, [pageNo, pageSize, Month, Year]);

  const renderLedger = () => {
    if (listOfLedger.length > 0) {
      let count = 1;
      let countbreak = 1;
      return listOfLedger.map((item, index) => {
        return (
          <tr key={index}>
            <td>{count++ + pageSize * (pageNo - 1)}</td>
            <td>{item.Employee_ID}</td>
            <td>{item.Name}</td>
            <td>{item.Deduction}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="20">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };

  return (
    <>
      <Page orientation="portrait" size="a4">
        <table className="table table-sm table-bordered table-print">
          <thead>
            <tr>
              <th colSpan={20}>
                <PrintHeader
                  title={`Salary Sheet For the Month of ${getMonthName(
                    Month
                  )} ${Year} `}
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td className="text-center" colSpan="8">
                  <Spinner animation="border" role="status"></Spinner>
                </td>
              </tr>
            ) : (
              <>
                {/* <tr>
                                        <th className="text-end" colSpan={8}>Total Amount: {calculateColumnTotal(listOfLedger, 'Current_Balance')}</th>

                                    </tr> */}
                <tr>
                  <th>Sr #</th>
                  <th>Code</th>
                  <th>Staff Name</th>
                  <th>Deduction Rs</th>
                </tr>
                {renderLedger()}
              </>
            )}

            <tr>
              <td colSpan={3} className="text-end">
                <strong>Total:</strong>
              </td>
              <td>
                <strong>
                  {calculateColumnTotal(listOfLedger, "Deduction")}
                </strong>
              </td>
            </tr>
          </tbody>
        </table>
      </Page>
    </>
  );
};

export default OtherLoanDeductionsPrint;
