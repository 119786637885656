import React, { useState, useEffect } from 'react';
import config from "../../Config";
import axios from "axios";



function DesignationDropDown(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [designations, setDesignations] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {

        setLoading(true);
        const dashboardParms = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            PageNo: 1,
            PageSize: 1000
        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'Payroll/GetAllDesignations',
            headers: {
                'Content-Type': 'application/json'
            },
            data: dashboardParms
        };

        axios(api_config)
            .then(function (response) {

                setDesignations(response.data.ListofRecords || []);
                setLoading(false);
            })
            .catch(function (error) {
                //console.log(error);
            });

    }, []);


    if (loading) {
        return <option selected disabled>Loading...</option>
    }
    else {
        return (
            designations.map((item, index) => {
                return (
                    <option value={item.ID} key={index} selected={item.ID === props.selected}>{item.Name}</option>
                )
            })
        );
    }
}

export default DesignationDropDown;