import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import moment, { months } from "moment";
import PrintHeader from "../General/PrintHeader";
import Page from "../General/Page";
import { calculateColumnTotal } from "../General/Utility";

const PrintBusRoutes = () => {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );

  const urlParams = new URLSearchParams(window.location.search);

  const routename = urlParams.get("routename");
  const routecode = urlParams.get("routecode");
  const busno = urlParams.get("busno");

  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [listOfLedger, setLedger] = useState([]);

  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    document.title = "Print Bus Routes";

    // setIsUpdated(false);
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      pageNo: pageNo,
      pageSize: pageSize,
      Root_Code: routecode,
      Area_Name: routename,
      BusNumber: busno,
      SchoolID: 1,
    };
    //console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Busroute/GetBusRoutes",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        setLedger(response.data.ListofRecords);
        setLoading(false);
        setTotalRecords(response.data.totalRecords);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, []);

  const renderLedger = () => {
    if (listOfLedger.length > 0) {
      let count = 1;

      return listOfLedger.map((item, index) => {
        return (
          <tr key={index}>
            <td>{count++}</td>

            <td>{item.Root_Code}</td>
            <td>{item.Area_Name}</td>
            <td>{item.BusNumber}</td>
            <td>{item.Root_Details}</td>
            <td>{item.PickUp_Fee}</td>
            <td>{item.DropOf_Fee}</td>
            <td>{item.PickDrop_Fee}</td>
            <td>{item.NoofStudents}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="7">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };

  return (
    <>
      <Page orientation="potrait" size="a4">
        <table className="table table-sm table-bordered table-print">
          <thead>
            <tr>
              <td colSpan="8">
                <PrintHeader title={`Bus Routes Report`} />
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Sr.</th>
              <th>Route Code</th>
              <th>Route Name</th>
              <th>Bus Number</th>
              <th>Route Details</th>
              <th>PickUp Fee</th>
              <th>DropOff Fee</th>
              <th>Pick & Drop Fee</th>
              <th>No Of Students</th>
            </tr>
            {loading ? (
              <tr>
                <td className="text-center" colSpan="6">
                  <Spinner animation="border" role="status"></Spinner>
                </td>
              </tr>
            ) : (
              <>
                {renderLedger()}

                {
                  <tr>
                    <th className="text-end" colSpan={6}>
                      Total Students:
                    </th>
                    <th className="" colSpan={1}>
                      {calculateColumnTotal(listOfLedger, "NoofStudents")}
                    </th>
                  </tr>
                }
              </>
            )}
          </tbody>
        </table>
      </Page>
    </>
  );
};

export default PrintBusRoutes;
