import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Form } from "react-bootstrap";
import UserGroupDropDown from "../Shared/UserGroupDropDown";
import Stock_navbar from "./StockNavbar";
import { scrollToTop } from "../Shared/ScrollOnTop";

const AddStockCategory = () => {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [validated, setValidated] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [RegisterType, setRegisterType] = useState("Fixed Assets");
  let navigate = useNavigate();

  document.title = "Add Stock Category";

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setLoading(true);
      const data = {
        AccessKey: AccessKey,
        UserID: UserID,
        MemberType: MemberType,
        Stock_Code: event.target.Stock_Code.value,
        Item_Name: event.target.Item_Name.value,
        Register_Type: RegisterType,
        Current_Balance: "0",
        Price_Per_Unit: event.target.Price_Per_Unit.value,
        Item_Description: event.target.Item_Description.value,
        Main_Custodian: event.target.Main_Custodian.value,
        Register_Ref: event.target.Register_Ref.value,
        CurrentBookValue: event.target.CurrentBookValue.value,
        DateOfPurchase: event.target.DateOfPurchase.value,
        DepreciationRate: event.target.DepreciationRate.value,
      };

      // console.log(data);

      var api_config = {
        method: "post",
        url: config.base_url + "Stock/AddStockItem",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(api_config)
        .then(function (response) {
          //console.log(response.data);
          if (response.data.status_code == 1) {
            toast.success(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }

          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          toast.error("Network Error..", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }

    setValidated(true);
  };

  return (
    <>
      <Stock_navbar />
      <ToastContainer />
      <div className="page-head">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/Dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/StockManagement/StocksDashboard">Stocks</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    AddStockManagment
                  </li>
                </ol>
              </nav>
              <div className="page-title">Add New Stock Category</div>
            </div>
            <div className="col-md-6">
              <div className="page-right-actions">
                <Link
                  to="/StockManagement/ManageStockCategory"
                  className="button button-white button-icon"
                >
                  Manage Stock
                  <i className="ri-list-check"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-content">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body p-md-4">
                  <Form
                    noValidate
                    validated={validated}
                    onSubmit={handleSubmit}
                  >
                    <div className="row align-items-end gy-3">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label >Stock Code:</label>
                          <input
                            type="text"
                            className="form-control"
                            required
                            name="Stock_Code"
                          />
                        </div>
                      </div>
                      <div className="col-md-8">
                        <div className="form-group">
                          <label className="">
                            Item Name:
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            required
                            name="Item_Name"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="">
                            Register Type:
                          </label>
                          <select
                            className="form-select"
                            value={RegisterType}
                            onChange={(e) => setRegisterType(e.target.value)}
                            name="Register_Type"
                            required=""
                          >
                            <option value="Fixed Assets" defaultValue="Fixed Assets">Fixed Assets </option>
                            <option value="Current Asset">Current Asset</option>
                            <option value="Consumeable Items - Physics">Consumeable Items - Physics</option>
                            <option value="Consumeable Items - Chemistry">Consumeable Items - Chemistry</option>
                            <option value="Consumeable/General">Consumeable/General</option>
                            <option value="Consumeable/Stationery">Consumeable/Stationery</option>
                            <option value="TPT - Spare Parts">TPT - Spare Parts</option>
                            <option value="Consumeable Items - Biology">Consumeable Items - Biology</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="">
                            Current Book Value:
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            required
                            name="CurrentBookValue"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="">
                            Purchase Date:
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            required
                            name="DateOfPurchase"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="">
                            Depreciation Rate:
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            required
                            name="DepreciationRate"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="">
                            Price Per Unit:
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            required
                            name="Price_Per_Unit"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="">
                            Register Ref:
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            required
                            name="Register_Ref"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="">
                            Main Custodian:
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            required
                            name="Main_Custodian"
                          />
                        </div>
                      </div>
                      <div className="col-md-10">
                        <div className="form-group">
                          <label className="">
                            Item Detail:
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            required
                            name="Item_Description"
                          />
                        </div>
                      </div>
                      <div className="col-md-2 text-end">
                        <div className="form-group ">
                          {loading ? (
                            <Spinner animation="border" role="status"></Spinner>
                          ) : (
                            <button
                              type="submit"
                              value="Save"
                              className="mt-2 button button-primary w-100 "
                            >
                              SAVE
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddStockCategory;
