import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate, useParams } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import Pagination from "../General/Pagination";
import FeeSystemMenu from "./FeeSystemMenu";
import { scrollToTop } from "../Shared/ScrollOnTop";

function CollectionReportDetail(props) {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [loading, setLoading] = useState(false);
  const [totalDeposit, settotalDeposit] = useState("");
  const grp_id = localStorage.getItem("User_Group_ID");
  const [ledger, setLedger] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);


  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);

  let params = useParams();
  const [StartDate, setStartDate] = useState(
    moment(params.Dated).format(config.date_format_input)
  );
  const [BenchNo, setBenchNo] = useState(params.BenchNo);

  useEffect(() => {
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      StartDate: StartDate,
      BenchNo: BenchNo,
      pageNo: pageNo,
      pageSize: pageSize,
    };
    //console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "FeeSystem/GetCollectionReportDetail",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        if (response.data.status_code == 1) {
          setLedger(response.data.ListofRecords);
          setTotalRecords(response.data.totalRecords);
          settotalDeposit(response.data.totalDeposit);
        }
      })
      .catch(function (error) {
        toast.error("Network Error..", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }, [pageNo, pageSize, StartDate, isUpdated]);

  const handlePageChange = (page) => {
    setPageNo(page);
  };



  const deleteScrollEntry = (ID) => {
    const confirm = window.confirm("Are you sure you want to proceed ?");
    if (confirm) {
      const data = {
        AccessKey: AccessKey,
        UserID: UserID,
        MemberType: MemberType,
        Entry_Id: ID,
      };

      var api_config = {
        method: "post",
        url: config.base_url + "ScrollEntry/DeleteScrollEntry",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      setIsUpdated(false);
      axios(api_config)
        .then(function (response) {
          //console.log(response.data);
          if (response.data.status_code == 1) {
            toast.success(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });

            setIsUpdated(true);
          }
          // setIsUpdated(false);
        })
        .catch(function (error) {
          //console.log(error);
        });
    }
  };


  const renderLedger = () => {
    if (ledger.length > 0) {
      return ledger.map((item, index) => {
        return (
          <tr key={index}>
            <td>{item.Serial_No}</td>
            <td>{item.BenchNumber}</td>
            <td>{item.Entry_Id}</td>
            <td>{moment(item.Dated).format(config.date_format)}</td>
            <td>{item.Registration_No}</td>
            <td>{item.Student_Name}</td>
            <td>{item.Receipt_No}</td>
            <td>{item.Amount_Deposited}</td>
            <td className="text-center">
              {grp_id == 1 || grp_id == 10 ? (
                <ul
                  className="inline-action"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li>
                    <button
                      onClick={(e) =>
                        deleteScrollEntry(item.Entry_Id)
                      }
                      className={`red`}
                    >
                      <i className="ri-delete-bin-line"></i>
                    </button>
                  </li>
                </ul>
              ) : (
                ""
              )}
            </td>

          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="9">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };

  return (
    <>
      <FeeSystemMenu />
      <div className="container body-content">
        <div className="page-head">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <NavLink to="/Dashboard">Dashboard</NavLink>
                    </li>
                    <li className="breadcrumb-item">
                      <NavLink to="/FeeSystem/FeeSystemDashboard">
                        Fee System
                      </NavLink>
                    </li>
                    <li className="breadcrumb-item">
                      <NavLink to="/FeeSystem/CollectionReport">
                        Collection Report
                      </NavLink>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Collection Report Detail
                    </li>
                  </ol>
                </nav>
                <div className="page-title">Collection Report Detail</div>
              </div>
            </div>
          </div>
        </div>

        <div className="page-content">
          <div className="container">
            <div className="card">
              <div className="card-body">
                <table className="table table-theme">
                  <thead>
                    <tr>
                      <th colSpan="9">{totalRecords} &nbsp; Records Found!</th>
                    </tr>

                    <tr>
                      <th>Serial No</th>
                      <th>Bench No</th>
                      <th>Entry ID</th>
                      <th>Dated</th>
                      <th>Registration No</th>
                      <th>Student Name</th>
                      <th>Receipt No.</th>
                      <th>Amount Deposited</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td className="text-center" colSpan="9">
                          <Spinner animation="border" role="status"></Spinner>
                        </td>
                      </tr>
                    ) : (
                      <>{renderLedger()}</>
                    )}
                    <tr>
                      <td colSpan={6}></td>

                      <td className="text-end">
                        <b>Total Deposit </b>
                      </td>
                      <td className="text-end">
                        <b>{totalDeposit}</b>
                      </td>
                      <td className="text-end">
                        <div className="row">
                          <div className="col-md-12 text-end">
                            <div className="form-group text-end">
                              <NavLink
                                className="button button-primary"
                                to={`/FeeSystem/PrintCollectionReportDetail/?banchNo=${BenchNo}&startDate=${StartDate}
                                       `}
                                target="_blank"
                              >
                                Print List
                              </NavLink>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                {totalRecords > 10 && (
                  <div className="pagination-wrap">
                    <div className="row align-items-center">
                      <div className="col-md-2 form_default">
                        <div className="d-flex align-items-center">
                          <label className="me-2" style={{ fontSize: "15px" }}>
                            Page Size
                          </label>
                          <div className="form-group">
                            <select
                              className="form-select"
                              style={{ width: "100px" }}
                              onChange={(e) => {
                                setPageNo(1);
                                setPageSize(parseInt(e.target.value));
                              }}
                            >
                              <option value="10" selected>
                                10
                              </option>
                              <option value="20">20</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                              <option value="500">500</option>
                              <option value="1000">1000</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <Pagination
                        pageNo={pageNo}
                        pageSize={pageSize}
                        totalRecords={totalRecords}
                        onPageChange={handlePageChange}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CollectionReportDetail;
