import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import QRCode from "react-qr-code";
import StudentInfo from "../FeeSystem/StudentInfo";
import moment from "moment";
import PrintHeader from "../General/PrintHeader";
import { useNavigate, useParams } from "react-router-dom";
import Page from "../General/Page";
import { RenderHTMLString } from "../General/Utility";
import { calculateColumnTotal, getMonthName } from "../General/Utility";

const PrintBuswiseAttendenceSheet = () => {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [loading, setLoading] = useState(false);

  const [listOfLedger, setLedger] = useState([]);
  const [BusNumber, setBusNumber] = useState("");
  const [isUpdated, setIsUpdated] = useState(false);
  const [selectedMonth, setselectedMonth] = useState("");

  // Get the current month and number of days
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();
  const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();

  // Generate an array of days in the month
  const monthDays = Array.from(
    { length: daysInMonth },
    (_, index) => index + 1
  );

  // Generate an array of day names for the current month
  const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  useEffect(() => {
    document.title = "AGS School Management System";

    setLoading(true);
    // Get the URL parameters
    const urlParams = new URLSearchParams(window.location.search);

    // Retrieve individual parameter values
    const BusNumber = urlParams.get("BusNumber");

    setBusNumber(BusNumber);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      BusNumber: BusNumber,
      pageNo: 1,
      pageSize: 1000,
    };

    //console.log(data);
    var api_config = {
      method: "post",
      url: config.base_url + "Busroute/GetBusWiseList",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    //console.log(data);

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        setLedger(response.data.ListofRecords);
        setLoading(false);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, []);

  const renderLedger = () => {
    if (listOfLedger.length > 0) {
      let count = 1;
      let countbreak = 1;
      return listOfLedger.map((item, index) => {
        return (
          <tr key={index}>
            <td>{count++}</td>
            <td>{item.Registration_No}</td>
            <td>{item.Student_Name}</td>

            {/* Render the table cells for each day */}
            {monthDays.map((day) => (
              <React.Fragment key={day}>
                <td></td>
                <td></td>
              </React.Fragment>
            ))}
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="7">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };

  return (
    <>
      <Page orientation="landscape" size="a4">
        <table className="table table-sm table-bordered table-print white-space-wrap">
          <thead>
            <tr>
              <th>
                <PrintHeader
                  title={`Monthly Bus Attendence Register For the Month of 
                               ${getMonthName(currentMonth)} ${BusNumber}
                                `}
                />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="p-0">
                <div>
                  <table className="table table-sm table-bordered table-print white-space-wrap">
                    <tr>
                      <th>Sr.#</th>
                      <th>Code</th>
                      <th>Student's Name </th>

                      {/* Render the table headers for each day */}
                      {monthDays.map((day) => (
                        <th
                          key={day}
                          colSpan={2}
                          style={{ width: "18px", padding: "0" }}
                        >
                          {day} <br />
                          {
                            dayNames[
                              new Date(currentYear, currentMonth, day).getDay()
                            ]
                          }
                        </th>
                      ))}

                      <th>Total</th>
                    </tr>
                    <tbody>
                      {loading ? (
                        <tr>
                          <td className="text-center" colSpan="70">
                            <Spinner animation="border" role="status"></Spinner>
                          </td>
                        </tr>
                      ) : (
                        <>{renderLedger()}</>
                      )}
                    </tbody>
                  </table>
                  <div>
                    <table
                      className="table table-sm table-bordered table-print"
                      style={{
                        borderCollapse: "collapse",
                        border: "transparent",
                      }}
                    ></table>
                    <table
                      className="table table-sm table-bordered table-print mt-5"
                      style={{
                        borderCollapse: "collapse",
                        border: "transparent",
                      }}
                    >
                      <tbody>
                        <tr>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th
                            style={{
                              width: "20.33%",
                              textAlign: "center",
                              textDecoration: "",
                            }}
                          >
                            Bus Conductor
                          </th>
                          <th
                            style={{
                              width: "20.33%",
                              textAlign: "right",
                              textDecoration: "",
                            }}
                          >
                            Bus Driver
                          </th>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </Page>
    </>
  );
};

export default PrintBuswiseAttendenceSheet;
