import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { scrollToTop } from '../Shared/ScrollOnTop';

function TeacherRightsCheck(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));

    let navigate = useNavigate();
    useEffect(() => {

        if (AccessKey == null) {
            navigate("/login");
        }
        else {
            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
                MemberType: MemberType,
                URL: window.location.pathname,
            };

            ////console.log(data);

            var api_config = {
                method: 'post',
                url: config.base_url + 'Teacher/ValidateAccessKey',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(api_config)
                .then(function (response) {

                  //  //console.log(response.data);
                    // if (response.data.status_code == 1 && response.data.hasRights == false) {
                    //     //localStorage.clear();
                    //     navigate("/no-access");
                    // }

                })
                .catch(function (error) {
                    //console.log(error);
                });
        }
    }, []);


}

export default TeacherRightsCheck;