import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import config from "../../Config";
import axios from "axios";
import { NavLink } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { Form } from "react-bootstrap";
import ExamsDropDown from "../Shared/ExamsDropDown";
import SessionsDropDown from "../Shared/SessionsDropDown";
import WingsDropDown from "../Shared/WingsDropDown";
import ClassesDropDown from "../Shared/ClassesDropDown";
import SectionDropDown from "../Shared/SectionDropDown";
import Examination_navbar from './Examination_navbar';
import { toast, ToastContainer } from 'react-toastify';


const ExamDataSO = () => {

    const [validated, setValidated] = useState(false);
    const [WingId, setWingId] = useState(0);
    const [SessionId, setSessioinId] = useState(localStorage.getItem("DefaultSession"));
    const [ClassId, setClassId] = useState(0);
    const [SectionID, setSectionID] = useState(0);
    const [FirstExamID, setFirstExamID] = useState(0);
    const [SecondExamID, setSecondExamID] = useState(0);
    const [ThirdExamID, setThirdExamID] = useState(0);
    const [FourthExamID, setFourthExamID] = useState(0);
    const [PrintTotal, setPrintTotal] = useState("No");
    const [Group, setGroup] = useState("No");

    let params = useParams();

    const [ExamID, setExamID] = useState(params.id);



    const handlesetPrintTotalChange = (e) => {
        if (e.target.checked) {
            setPrintTotal("Yes")
        }
        else {
            setPrintTotal("No");
        }
    }

    const handlesetGroupChange = (e) => {
        if (e.target.checked) {
            setGroup("Yes")
        }
        else {
            setGroup("No");
        }
    }

    return (
        <>
            <Examination_navbar />
            <ToastContainer />
            <div className="page-head">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/Dashboard">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="/ExaminationSystem/ExaminationDashboard">
                                            Examinations
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        Print Marks Sheet
                                    </li>
                                </ol>
                            </nav>
                            <div className="page-title">
                                Print Marks Sheet
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="page-content">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body p-md-4">
                                    <Form
                                        noValidate
                                        validated={validated}
                                    >
                                        <div className="row align-items-center">

                                            <div className="col-md-7">


                                                <div className="form-group mb-3">
                                                    <div className="row">
                                                        <label  className="col-sm-5 col-form-label">
                                                            Select First Exam:
                                                        </label>
                                                        <div className="col-sm-7">
                                                            <select
                                                                className="form-select"
                                                                name="FirstExam"
                                                                required
                                                                value={FirstExamID}
                                                                onChange={(e) => setFirstExamID(e.target.value)}
                                                            >
                                                                <option value="">Please Select</option>
                                                                <ExamsDropDown />
                                                            </select>
                                                            <div className="form-text">
                                                                This is Main Exam will be used for master sheet printing.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>



                                                <div className="form-group mb-3">
                                                    <div className="row">
                                                        <label htmlFor="secondExamSelect" className="col-sm-5 col-form-label">Select Second Exam:</label>
                                                        <div className="col-sm-7">
                                                            <select
                                                                className="form-select"
                                                                name="SecondExam"
                                                                required
                                                                value={SecondExamID}
                                                                onChange={(e) => setSecondExamID(e.target.value)}
                                                            >
                                                                <option value="">Please Select</option>
                                                                <ExamsDropDown />
                                                            </select>
                                                            <div className="form-text">Second term exam for printing on result cards</div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="form-group mb-3">
                                                    <div className="row">
                                                        <label  className="col-sm-5 col-form-label">
                                                            Select Third Exam:
                                                        </label>
                                                        <div className="col-sm-7">
                                                            <select
                                                                className="form-select"
                                                                name="ThirdExam"
                                                                required
                                                                value={ThirdExamID}
                                                                onChange={(e) => setThirdExamID(e.target.value)}
                                                            >
                                                                <option value="">Please Select</option>
                                                                <ExamsDropDown />
                                                            </select>
                                                            <div className="form-text">Third term exam for printing on result cards</div>
                                                        </div>
                                                    </div>
                                                </div>





                                                <div className="form-group mb-3">
                                                    <div className="row">
                                                        <label  className="col-sm-5 col-form-label">
                                                            Select Fourth Exam:
                                                        </label>
                                                        <div className="col-sm-7">
                                                            <select
                                                                className="form-select"
                                                                name="FourthExam"
                                                                required
                                                                value={FourthExamID}
                                                                onChange={(e) => setFourthExamID(e.target.value)}
                                                            >
                                                                <option value="">Please Select</option>
                                                                <ExamsDropDown />
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="form-group mb-3">
                                                    <div className="row">
                                                        <label  className="col-sm-5 col-form-label">
                                                            Select Session:
                                                        </label>
                                                        <div className="col-sm-7">
                                                            <select
                                                                className="form-select"
                                                                onChange={(e) => setSessioinId(e.target.value)}
                                                                name="SessionID"
                                                            >
                                                                <option value="0">---All---</option>
                                                                <SessionsDropDown selectedSession={SessionId} />
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>



                                                <div className="form-group mb-3">
                                                    <div className="row">
                                                        <label  className="col-sm-5 col-form-label">Select Wing:</label>
                                                        <div className="col-sm-7">
                                                            <select
                                                                name="WingId"
                                                                onChange={(e) => setWingId(e.target.value)}
                                                                className="form-select"
                                                            >
                                                                <option value="0">---All---</option>
                                                                <WingsDropDown />
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>




                                                <div className="form-group mb-3">
                                                    <div className="row">
                                                        <label  className="col-sm-5 col-form-label">
                                                            Select Class:
                                                        </label>
                                                        <div className="col-sm-7">
                                                            <select
                                                                className="form-select"
                                                                onChange={(e) => setClassId(e.target.value)}
                                                                name="Class_ID"
                                                            >
                                                                <option value="">Please Select</option>
                                                                {SessionId != 0 && WingId != 0 && (
                                                                    <ClassesDropDown
                                                                        SessionID={SessionId}
                                                                        WingID={WingId}
                                                                    />
                                                                )}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>




                                                <div className="form-group mb-3">
                                                    <div className="row">
                                                        <label  className="col-sm-5 col-form-label">
                                                            Select Section:
                                                        </label>
                                                        <div className="col-sm-7">
                                                            <select
                                                                className="form-select"
                                                                onChange={(e) => setSectionID(e.target.value)}
                                                                name="Section_Id"
                                                            >
                                                                <option value="0">Please Select</option>
                                                                {ClassId !== 0 && (
                                                                    <SectionDropDown ClassID={ClassId} />
                                                                )}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>



                                            </div>

                                            <div className="col-md-4 offset-md-1">
                                                <div className="custom-button">
                                                    <div className="form-check mb-3">

                                                        <input className="form-check-input" type="checkbox" onChange={handlesetPrintTotalChange} id="PrintTotalCheck" />
                                                        <label htmlFor="PrintTotalCheck" className="form-check-label">
                                                            Print Totals on Master Sheet
                                                        </label>
                                                    </div>


                                                    <div className="form-check mb-3">


                                                        <input className="form-check-input" type="checkbox" onChange={handlesetGroupChange} id="groupTwoExamsCheckbox" />
                                                        <label htmlFor="groupTwoExamsCheckbox" className="form-check-label">
                                                            Group Two Exams
                                                        </label>

                                                    </div>


                                                    <div className="buttons">
                                                        <NavLink target="_blank"
                                                        
                                                                    
                                                            to={`/ExaminationSystem/ReportPrintExamDataSheet/?ID=${FirstExamID}&WingId=${WingId}&ClassId=${ClassId}&SectionId=${SectionID}&PrintTotal=${PrintTotal}`}
                                                            className={`button button-primary d-block mb-3 ${FirstExamID === 0 || WingId === 0 || ClassId === 0 ? 'disabled' : ''}`}>Print Data Sheets</NavLink>


                                                        <NavLink target="_blank"
                                                            to={`/ExaminationSystem/ReportPrintExamDataSheetMultiple/?FirstExamID=${FirstExamID}&SecondExamID=${SecondExamID}&WingId=${WingId}&ClassId=${ClassId}&SectionId=${SectionID}&PrintTotal=${PrintTotal}&Group=${Group}`} 
                                                            className={`button button-primary  d-block mb-3 ${FirstExamID === 0 || SecondExamID === 0 || WingId === 0 || ClassId === 0 ? 'disabled' : ''}`}>Print Collective Data Sheets</NavLink>

                                                        <button type="submit" className="button button-primary w-100 d-block mb-3">Print Result Cards Play Group Nursery Prep</button>

                                                        <NavLink target="_blank"
                                                            to={`/ExaminationSystem/PrintResultCards/?FirstExamID=${FirstExamID}&SecondExamID=${SecondExamID}&ThirdExamID=${ThirdExamID}&FourthExamID=${FourthExamID}&WingId=${WingId}&ClassId=${ClassId}&SectionId=${SectionID}`} 
                                                            className={`button button-primary  d-block mb-3 ${FirstExamID === 0 || SecondExamID === 0 || WingId === 0 || ClassId === 0 ? 'disabled' : ''}`}>Print Result Cards of Class =1</NavLink>


                                                       

                                                        <button type="submit" className="button button-primary w-100 d-block mb-3">Print Result Cards New</button>
                                                    </div>
                                                </div>
                                            </div>



                                        </div>
                                    </Form>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default ExamDataSO

