import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import moment, { months } from "moment";
import PrintHeader from "../General/PrintHeader";
import Page from "../General/Page";
import { getMonthName } from "../General/Utility";
import { calculateColumnTotal } from "../General/Utility";

const PrintBuswiseStudentList = () => {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [loading, setLoading] = useState(false);
  const [grandTotal, setgrandTotal] = useState(0);

  const [listOfLedger, setLedger] = useState([]);
  const currentDate = new Date();

  const currentMonth = currentDate.getMonth();
  const [totalRecords, setTotalRecords] = useState(0);

  const [BusNumber, setBusNumber] = useState("");
  useEffect(() => {
    document.title = "AGS School Management System";

    setLoading(true);
    // Get the URL parameters
    const urlParams = new URLSearchParams(window.location.search);

    // Retrieve individual parameter values
    const BusNumber = urlParams.get("BusNumber");

    setBusNumber(BusNumber);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      BusNumber: BusNumber,
      pageNo: 1,
      pageSize: 10000,
    };

    //console.log(data);
    var api_config = {
      method: "post",
      url: config.base_url + "Busroute/GetBusWiseList",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    //console.log(data);

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        setLedger(response.data.ListofRecords);
        setgrandTotal(response.data.grandTotal);
        setLoading(false);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, []);

  const renderLedger = () => {
    if (listOfLedger?.length > 0) {
      let count = 1;

      return listOfLedger.map((item, index) => {
        return (
          <tr key={index}>
            <td>{count++}</td>
            <td>{item.Registration_No}</td>
            <td>{item.Student_Name}</td>
            <td>{item.Father_Name}</td>
            <td>{item.Contact_Mobile}</td>
            <td>{item.Class_Name}</td>
            <td>{item.FirstTermObtained_Marks}</td>
            <td>{item.FirstTermPercentMarks}</td>
            <td>{item.Concession_Rule_ID === 3 ? "Staff Concession" : null}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="9">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };

  return (
    <>
      <Page orientation="potrait" size="a4">
        <table className="table table-sm table-bordered table-print">
          <thead>
            <tr>
              <td colSpan="9">
                <PrintHeader
                  title={`Monthly Bus Wise Student List For the Month of ${getMonthName(
                    currentMonth
                  )} ${BusNumber}`}
                />
              </td>
            </tr>
            <tr>
              <th colSpan={9} className="text-end">
                {" "}
                Total Records : {listOfLedger.length}{" "}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Sr.#</th>
              <th>Code</th>
              <th>Student's Name </th>
              <th>Father Name</th>
              <th>Contact Mobile </th>
              <th>Class </th>
              <th>Fee </th>
              <th>Chargeable </th>
              <th>Remarks</th>
            </tr>
            {loading ? (
              <tr>
                <td className="text-center" colSpan="9">
                  <Spinner animation="border" role="status"></Spinner>
                </td>
              </tr>
            ) : (
              <>
                {renderLedger()}
                {
                  <tr>
                    <th className="text-end" colSpan={7}>
                      Total Receiveable:
                    </th>
                    <th className="" colSpan={2}>
                      {calculateColumnTotal(
                        listOfLedger,
                        "FirstTermPercentMarks"
                      )}
                    </th>
                  </tr>
                }
              </>
            )}
          </tbody>
        </table>

        <div style={{ marginTop: "30px", width: "100%" }}>
          <table
            className="table table-sm table-bordered table-print"
            style={{ borderCollapse: "collapse", border: "transparent" }}
          >
            <tbody>
              <tr className="my-5">
                <th></th>
                <th></th>
                <th
                  className=""
                  style={{
                    width: "20.33%",
                    textAlign: "center",
                    textDecoration: "",
                  }}
                >
                  Bus Conductor
                </th>
                <th
                  style={{
                    width: "20.33%",
                    textAlign: "right",
                    textDecoration: "",
                  }}
                >
                  Bus Driver
                </th>
              </tr>
            </tbody>
          </table>
        </div>
      </Page>
    </>
  );
};

export default PrintBuswiseStudentList;
