import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate } from 'react-router-dom';
import { NavLink } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { Form } from "react-bootstrap";
import UserGroupDropDown from "../Shared/UserGroupDropDown";
import ExamsDropDown from "../Shared/ExamsDropDown";
import SessionsDropDown from "../Shared/SessionsDropDown";
import Examination_navbar from './Examination_navbar';
import SubjectTypesDropDown from "../Shared/SubjectTypesDropDown";
import { scrollToTop } from '../Shared/ScrollOnTop';
import WingsDropDown from "../Shared/WingsDropDown";
import ClassesDropDown from "../Shared/ClassesDropDown";
import SectionDropDown from "../Shared/SectionDropDown";


const ExamDataEntryOptions = () => {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [validated, setValidated] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [hasError, setHasError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [WingId, setWingId] = useState(0);
    const [SessionId, setSessioinId] = useState(localStorage.getItem("DefaultSession"));
    const [ClassId, setClassId] = useState(0);
    const [SectionID, setSectionID] = useState(0);

    let params = useParams();
    


    // parameters for print 
    const [ExamID, setExamID] = useState(params.id);
  
    


  return (
    <>
      <Examination_navbar />
      <ToastContainer />
      <div className="page-head">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/Dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/ExaminationSystem/ExaminationDashboard">
                      Examinations
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Exam Data Entry Options
                  </li>
                </ol>
              </nav>
              <div className="page-title">
                EXAM DATA SHEETS PRINTING OPTIONS
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-content">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body p-md-4">
                  <Form
                    noValidate
                    validated={validated}
                    // onSubmit={handleSubmit}
                  >
                    <div className="row align-items-center">
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label  className="">
                            Select Examination:
                          </label>
                          <select
                            className="form-select"
                            name="SelectedExamination"
                            required
                            value={ExamID}
                            onChange={(e) => setExamID(e.target.value)}
                          >
                            <option value="">Please Select</option>
                            <ExamsDropDown />
                          </select>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label  className="">
                            Select Session:
                          </label>
                           <select
                            className="form-select"
                            onChange={(e) => setSessioinId(e.target.value)}
                            name="SessionID"
                          >
                            <option value="0">---All---</option>
                            <SessionsDropDown selectedSession={SessionId} />
                          </select>
                        </div>


                      </div>

                      <div className="col-md-3">
                      <div className="form-group mb-3">
                          <label>Select Wing:</label>
                          <select
                            name="WingId"
                            onChange={(e) => setWingId(e.target.value)}
                            className="form-select"
                          >
                            <option value="0">---All---</option>
                            <WingsDropDown />
                          </select>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="form-group">
                          <label  className="">
                            Select Class:
                          </label>
                          <select
                            className="form-select"
                            onChange={(e) => setClassId(e.target.value)}
                            name="Class_ID"
                          >
                            <option value="">Please Select</option>
                            {SessionId != 0 && WingId != 0 && (
                              <ClassesDropDown
                                SessionID={SessionId}
                                WingID={WingId}
                              />
                            )}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label  className="">
                            Select Section:
                          </label>
                          <select
                            className="form-select"
                            onChange={(e) => setSectionID(e.target.value)}
                            name="Section_Id"
                          >
                            <option value="0">Please Select</option>
                            {ClassId !== 0 && (
                              <SectionDropDown ClassID={ClassId} />
                            )}
                          </select>
                        </div>
                      </div>


                      <div className="text-end">
                        <div className="form-group ">
                        <NavLink
                          to={`/ExaminationSystem/PrintExamDataSheet/?ExamID=${ExamID}&Wing_Id=${WingId}&Class_Id=${ClassId}&Section_Id=${SectionID}`}
                          target="_blank"
                        className="button button-primary">Print</NavLink>
                          

                          
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExamDataEntryOptions;

