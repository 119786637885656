import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import moment, { months } from "moment";
import PrintHeader from "../General/PrintHeader";
import Page from "../General/Page";
import { calculateColumnTotal, getMonthName } from '../General/Utility';

const PrintRuleApplicable = () => {

  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [listOfLedger, setLedger] = useState([]);

  const [RuleName, setRuleName] = useState("");


  useEffect(() => {
    document.title = "Print Concession Rule Applicable"

    const urlParams = new URLSearchParams(window.location.search);


    const RuleID = urlParams.get('RuleID');



    setLoading(true);


    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      Concession_Rule_ID: RuleID,
    };



   //console.log(data);
    var api_config = {
      method: 'post',
      url: config.base_url + 'FeeType/GetFeeTypesByRuleID',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

   //console.log(data);

    axios(api_config)
      .then(function (response) {
       //console.log(response.data);
        if (response.data.status_code == 1) {
          setLedger(response.data.ListofRecords);
          setRuleName(response.data.RuleName);
          setLoading(false);
          setTotalRecords(response.data.totalRecords);
        }
        else {
          setLedger(null);
          setTotalRecords(0)
        }
      })
      .catch(function (error) {
        //console.log(error);
      });

  }, []);

  const renderLedger = () => {

    if (listOfLedger.length > 0) {
      let count = 1;

      return (
        listOfLedger.map((item, index) => {
          return (
            <tr key={index} >
              <td>{count++}</td>
              <td>{item.Fee_Type_Code}</td>
              <td>{item.Fee_Type_Name}</td>
              <td>{item.AnnualApplicable && 'Yes'}</td>
              <td>{item.MonthlyApplicable && 'Yes'}</td>
            </tr>

          )
        })
      )
    }
    else {
      return (
        <tr>
          <td colSpan="5"><h4 className="mt-3" style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}>No Data Found</h4></td>
        </tr>
      )
    }

  }


  return (
    <>
      <Page orientation="potrait" size="a4">
        <table className="table table-sm table-bordered table-print">
          <thead>
            <tr>
              <td colSpan="5">
                <PrintHeader title={`Apply Rule Settings for ${RuleName}`} />
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Sr. No</th>
              <th>Fee Code</th>
              <th>Name</th>
              <th>Annual</th>
              <th>Monthly</th>
            </tr>
            {
              loading ?
                <tr>
                  <td className="text-center" colSpan="5">
                    <Spinner animation="border" role="status"></Spinner>
                  </td>
                </tr>
                :
                <>
                  {renderLedger()}

                </>
            }
          </tbody>
        </table>
      </Page>
    </>
  );
}


export default PrintRuleApplicable;
