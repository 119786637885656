import React, { useState, useEffect } from "react";
import config from "../../../Config"
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import QRCode from "react-qr-code";
import moment from "moment";
import PrintHeader from "../../General/PrintHeader";
import Page from "../../General/Page";
import {calculateColumnTotal} from "../../General/Utility";


const PrintPessiList = () => {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [listOfLedger, setLedger] = useState([]);
  const [sMonth, setsMonth] = useState(0);
  const [sYear, setsYear] = useState(0);
  const [departmentName, setDepartmentName] = useState();

  const thStyle = {
    writingMode: 'vertical-rl', // Vertical writing mode, rotates text
    textAlign: 'center', // Center the text horizontally
  };


  useEffect(() => {
    document.title = "PESSI DEDUCTION LIST";

    setLoading(true);
    // Get the URL parameters
    const urlParams = new URLSearchParams(window.location.search);

    // Retrieve individual parameter values
    const selectedYear = urlParams.get("year");
    const selectedMonth = urlParams.get("month");
    const EmployeeTypeId = urlParams.get("EmployeeTypeId");
    const designationId = urlParams.get("designationId");
    const bpsScale = urlParams.get("bpsScale");
    
    

    setsMonth(selectedMonth);
    setsYear(selectedYear);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      FYear: selectedYear,
      SelectedMonth: selectedMonth,
      EmployeeTypeId: EmployeeTypeId,
      DesignationID: designationId,
      SelectedGrade: bpsScale,
    };

    ////console.log(data);
    var api_config = {
      method: "post",
      url: config.base_url + "Payroll/GetPessiList",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

   //console.log(data);

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        setLedger(response.data.ListofRecords);
        setDepartmentName(response.data.ReportTitleName);
        setLoading(false);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, []);

  const getMonthName = (monthNumber) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    return monthNames[monthNumber - 1];
  };

  const renderLedger = () => {
    if (listOfLedger && listOfLedger.length > 0) {
      let count = 1;
      let countbreak = 1;
      return listOfLedger.map((item, index) => {
        return (
          <tr key={index}>
            <td>{count++}</td>
            <td>{item.Code}</td>
            <td>{item.Employee_Name}</td>
            <td>{item.Father_Name}</td>
            <td>{item.Designation_Name}</td>
            <td>{item.Qualification}</td>
            <td>{item.CNIC_NO}</td>
            <td>{moment(item.Date_Of_Birth).format('DD/MM/YYYY')}</td>
            <td>{moment(item.Date_Of_Joining).format('DD/MM/YYYY')}</td>
            <td>{item.GPS_Scale}</td>
            <td>{item.NTN_No}</td>
            <td>{item.Salary_Type_24}</td>
            <td>{item.GrossSalary}</td>
            <td>{item.TotalDeductions}</td>
            <td>{item.Net_Salary}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="15">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };

  return (
    <>
      <Page orientation="landscape" size="a4">
        <table className="table table-sm table-bordered table-print">
          <thead>
            <tr>
              <th colSpan={25}>
                <PrintHeader
                  title={`CWF DEDUCTION LIST FOR THE MONTH OF ${
                    getMonthName(sMonth) + " " + sYear
                  } `}
                />
                <label>{` Department/Type: ${departmentName}`}</label>
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td className="text-center" colSpan="15">
                  <Spinner animation="border" role="status"></Spinner>
                </td>
              </tr>
            ) : (
              <>
                <tr>
                  <td>Sr.#</td>
                  <td>Code</td>
                  <td>Staff Name </td>
                  <td>Father Name</td>
                  <td>Designation</td>
                  <td>Qualification</td>
                  <td>CNIC</td>
                  <td>Date Of Birth</td>
                  <td>Date of Joining</td>
                  <td>GPS</td>
                  <td>NTN_NO</td>
                  <td>PESSI Deduction</td>
                  <td>Gross Salary</td>
                  <td>Deductions</td>
                  <td>Net Pay</td>
                </tr>
                {renderLedger()}
                <tr>
                  <td colSpan={11}>Total Amount: </td>
                  <td>{calculateColumnTotal(listOfLedger, "Salary_Type_24")}</td>
                  <td>{calculateColumnTotal(listOfLedger, "GrossSalary")}</td>
                  <td>{calculateColumnTotal(listOfLedger, "TotalDeductions")}</td>
                  <td>{calculateColumnTotal(listOfLedger, "Net_Salary")}</td>
                </tr>
              </>
            )}
          </tbody>
        </table>

        <div style={{ marginTop: "30px", width: "100%" }}>
          <table
            className="table table-sm table-bordered table-print"
            style={{ borderCollapse: "collapse", border: "transparent" }}
          >
            <tbody>
              <tr className="my-5">
                <th
                  style={{
                    width: "33.33%",
                    textAlign: "left",
                    textDecoration: "underline",
                  }}
                >
                  Accountant
                </th>
                <th
                  style={{
                    width: "33.33%",
                    textAlign: "center",
                    textDecoration: "underline",
                  }}
                >
                  Burser
                </th>
                <th
                  style={{
                    width: "33.33%",
                    textAlign: "right",
                    textDecoration: "underline",
                  }}
                >
                  Head of Wing / Deptt
                </th>
              </tr>
              <tr className="my-5">
                <br />
                <br />
              </tr>
              <tr className="my-5">
                <th
                  style={{
                    width: "33.33%",
                    textAlign: "left",
                    textDecoration: "underline",
                  }}
                >
                  Admin Officer
                </th>
                <th
                  style={{
                    width: "33.33%",
                    textAlign: "center",
                    textDecoration: "underline",
                  }}
                ></th>
                <th
                  style={{
                    width: "33.33%",
                    textAlign: "right",
                    textDecoration: "underline",
                  }}
                >
                  Principal
                </th>
              </tr>
            </tbody>
          </table>
        </div>
      </Page>
    </>
  );
};

export default PrintPessiList;
