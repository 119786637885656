import React, { useState } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import { NavLink, useSearchParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import ShowStudent from '../Shared/ShowStudent';
import PrintCertificates_navbar from './PrintCertificates_navbar';
import { scrollToTop } from '../Shared/ScrollOnTop';

const PrintCertificates = () => {


    const [loading, setLoading] = useState(false);
    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [Code, setCode] = useState("");
    const [student, setStudent] = useState({});
    const [StudentID, setStudentID] = useState(0);

    const [validated, setValidated] = useState(false);


    const GetStudent = () => {
        setLoading(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            Registration_No: Code,
        };
        //console.log(data)
        var api_config = {
            method: 'post',
            url: config.base_url + 'Student/GetStudentByRegistrationNo',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(api_config)
            .then(function (response) {

                if (response.data.status_code == 1) {
                    //console.log(Code);
                    setStudent(response.data.Student);
                    setStudentID(response.data.Student.Student_ID);
                }
                else {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }

                setLoading(false);

            })
            .catch(function (error) {
                //console.log(error);
            });
    }


    const handleSchoolLeavingCertificate = (event) => {
        event.preventDefault();
        event.stopPropagation();

        let date = event.target.Date.value;
        let className = event.target.Class.value;
        let paymentdate = event.target.PaymentDate.value;
        let extraline = event.target.ExtraLine.value;

        let url = '/Certificate/PrintStudentCertificates/?student_code=' + Code + '&type=schoolleaving&date=' + date + '&className=' + className + '&paymentdate=' + paymentdate + '&extraline=' + extraline;
        window.open(url, '_blank');
    }

    const handleHopeCertificate = (event) => {
        event.preventDefault();
        event.stopPropagation();
        let Marks = event.target.Marks.value;
        let className = event.target.Class.value;
        let extraline = event.target.ExtraLine.value;
        let url = '/Certificate/PrintStudentCertificates/?student_code=' + Code + '&type=hope&marks=' + Marks + '&className=' + className + '&extraline=' + extraline;
        window.open(url, '_blank');

    }

    const handleCharacterCertificate = (event) => {
        event.preventDefault();
        event.stopPropagation();
        let SchoolName = event.target.SchoolName.value;
        let Exam = event.target.Exam.value;
        let Session = event.target.Session.value;
        let extraline = event.target.ExtraLine.value;
        let url = '/Certificate/PrintStudentCertificates/?student_code=' + Code + '&type=character&Exam=' + Exam + '&Session=' + Session + '&SchoolName=' + SchoolName + '&extraline=' + extraline;
        window.open(url, '_blank');
    }

    const handleBonafideCertificate = (event) => {
        event.preventDefault();
        event.stopPropagation();

        let date = event.target.date.value;
        let extraline = event.target.ExtraLine.value;
        let url = '/Certificate/PrintStudentCertificates/?student_code=' + Code + '&type=bonafide&date=' + date + '&extraline=' + extraline;
        window.open(url, '_blank');

    }

    return (
        <>
            <PrintCertificates_navbar />
            <div className="container body-content">
                <div className="page-head">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><NavLink to="/Dashboard">Dashboard</NavLink></li>
                                        <li className="breadcrumb-item"><NavLink to="/Certificate/CertificateDashboard">Certificate</NavLink></li>
                                        <li className="breadcrumb-item active" aria-current="page">Print Certificate</li>
                                    </ol>
                                </nav>
                                <div className="page-title">Print Certificate</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 offset-md-1">
                                <div className="card">
                                    <div className="card-body card-form-wrap">
                                        <div className="form-wrap">
                                            <div className="form-legend">Step 1.Search Student</div>
                                            <div className="row">
                                                <label className="col-md-3 col-form-label col-form-label-sm">
                                                    Enter Student
                                                    Code:
                                                </label>
                                                <div className="col-md-3">
                                                    <input type="text" className="form-control"
                                                        value={Code}
                                                        onChange={(e) => setCode(e.target.value)}
                                                        required />
                                                </div>
                                                <div className="col-md-2 offset-md-4">
                                                    <button type="button"
                                                        value="Search"
                                                        className="button button-primary w-100"
                                                        onClick={GetStudent}
                                                    >Search</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            StudentID != 0 &&
                            <>
                                {
                                    loading ?
                                        <Spinner animation="border" role="status"></Spinner>
                                        :
                                        <div className="row my-3">
                                            <div className="col-md-10 offset-md-1">
                                                <div className="mb-3 card card-body" style={{ color: "#003970", fontSize: "1.2rem", fontWeight: "bold" }}>Step 2. Confirm Details</div>
                                                <ShowStudent StudentID={StudentID} />
                                            </div>
                                        </div>
                                }

                            </>
                        }


                        {
                            StudentID != 0 &&
                            <>
                                <div className="row">
                                    <div className="col-md-10 offset-md-1">
                                        <div className="card">
                                            <div className="card-body card-form-wrap">
                                                <form onSubmit={handleSchoolLeavingCertificate}>
                                                    <div className="form-wrap">
                                                        <div className="form-legend">Step 3.Choose and Print Certificate</div>
                                                        <h5>School Leaving Certificate</h5>
                                                        <div className="row">
                                                            <label className="col-md-3 col-form-label col-form-label-sm">
                                                                Left on Date:
                                                            </label>
                                                            <div className="col-md-3">
                                                                <input type="date" name="Date" className="form-control" required />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <label className="col-md-3 col-form-label col-form-label-sm">
                                                                Studying In Class:
                                                            </label>
                                                            <div className="col-md-3">
                                                                <input type="text" name="Class" className="form-control" required />
                                                            </div>
                                                            <label className="col-md-2 col-form-label col-form-label-sm">
                                                                Payment
                                                                Date:
                                                            </label>
                                                            <div className="col-md-4">
                                                                <input type="text" name="PaymentDate" className="form-control" required />
                                                            </div>
                                                        </div>
                                                        <div className="row m-0">
                                                            <label className="col-md-3 col-form-label col-form-label-sm">Extra Line:</label>
                                                            <div className="col-md-3">
                                                                <input type="text" name="ExtraLine" className="form-control" />
                                                            </div>
                                                            <div className="col-md-3 offset-md-3 text-end">
                                                                <button type="submit" className="button button-primary">Print Certificate</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                                <form onSubmit={handleHopeCertificate}>
                                                    <div className="form-wrap">
                                                        <h5>Hope Certificate</h5>
                                                        <div className="row">
                                                            <label className="col-md-2 col-form-label col-form-label-sm">% Marks:</label>
                                                            <div className="col-md-4">
                                                                <input type="text" name="Marks" className="form-control" />
                                                            </div>
                                                            <label className="col-md-2 col-form-label col-form-label-sm">Class Name: </label>
                                                            <div className="col-md-4">
                                                                <input type="text" name="Class" className="form-control" />
                                                            </div>
                                                        </div>
                                                        <div className="row m-0">
                                                            <label className="col-md-2 col-form-label col-form-label-sm">Extra Line:</label>
                                                            <div className="col-md-4">
                                                                <input type="text" name="ExtraLine" className="form-control" />
                                                            </div>
                                                            <div className="col-md-3 offset-md-3 text-end">
                                                                <input type="submit" value="Print Certificate" className="button button-primary"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                                <form onSubmit={handleCharacterCertificate}>
                                                    <div className="form-wrap" >
                                                        <h5>Character Certificate</h5>
                                                        <div className="row gy-3">
                                                            <label className="col-md-2 col-form-label col-form-label-sm">School Name:</label>
                                                            <div className="col-md-10">
                                                                <input type="text" name="SchoolName" className="form-control" />
                                                            </div>
                                                            <label className="col-md-2 col-form-label col-form-label-sm">
                                                                Passed Examination:
                                                            </label>
                                                            <div className="col-md-10">
                                                                <input type="text" name="Exam" className="form-control" />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <label className="col-md-2 col-form-label col-form-label-sm">Session:</label>
                                                            <div className="col-md-4">
                                                                <input type="text" name="Session" className="form-control" />
                                                            </div>
                                                            <label className="col-md-2 col-form-label col-form-label-sm">Extra Line:</label>
                                                            <div className="col-md-4">
                                                                <input type="text" name="ExtraLine" className="form-control" />
                                                            </div>
                                                        </div>
                                                        <div className="row  m-0">
                                                            <div className="col-md-3 offset-md-9 text-end">
                                                                <input type="submit" value="Print Certificate" className="button button-primary"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                                <form onSubmit={handleBonafideCertificate}>
                                                    <div className="form-wrap" >
                                                        <h5>Bonafide Certificate</h5>
                                                        <div className="row">
                                                            <label className="col-md-3 col-form-label col-form-label-sm">
                                                                Date of
                                                                Joining:
                                                            </label>
                                                            <div className="col-md-3">
                                                                <input type="date" name="date" className="form-control" />
                                                            </div>
                                                            <label className="col-md-3 col-form-label col-form-label-sm">Extra Line:</label>
                                                            <div className="col-md-3 ">
                                                                <input type="text" name="ExtraLine" className="form-control" />
                                                            </div>
                                                        </div>
                                                        <div className="row m-0">
                                                            <div className="col-md-3 offset-md-9 text-end">
                                                                <input type="submit" value="Print Certificate" className="button button-primary"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div >
                                </div >
                            </>
                        }
                    </div >
                </div >
            </div >
        </>
    );
}

export default PrintCertificates;