import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import SecurityMenu from "./SecurityMenu";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate, useParams } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Form } from "react-bootstrap";
import moment from "moment";
import UserGroupDropDown from "../Shared/UserGroupDropDown";
import { scrollToTop } from "../Shared/ScrollOnTop";

function EditUser(props) {
    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(
        localStorage.getItem("MemberType")
    );
    const [validated, setValidated] = useState(false);
    const [Password, setPassword] = useState("");
    const [ConfirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [UserGroupId, setUserGroupID] = useState(0);
    const [User, setUser] = useState({});
    let params = useParams();


    const [ShowPassword, setShowPassword] = useState(false);
    const [ShowConfirmPassword, setShowConfirmPassword] = useState(false);

    const [MemberID, setMemberID] = useState(params.id);

    let navigate = useNavigate();

    useEffect(() => {
        document.title = "Add/Update Users";

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            ID: MemberID,
        };
       //console.log(data);
        var api_config = {
            method: "post",
            url: config.base_url + "Members/GetUserByID",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(api_config)
            .then(function (response) {
               //console.log(response.data);
                if (response.data.status_code == 1) {
                    setUserGroupID(response.data.User.User_Group_ID);
                    setUser(response.data.User);
                    setPassword(response.data.User.Password);
                    setConfirmPassword(response.data.User.Password);
                }

                setLoading(false);
            })
            .catch(function (error) {
                setLoading(false);
                toast.error("Network Error..", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            });
    }, []);

    const handleSubmit = (event) => {

        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {




            if (Password != ConfirmPassword) {
                toast.error('Password and Retype password should be same', {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            else {
                setLoading(true);
                const data = {
                    AccessKey: AccessKey,
                    UserID: UserID,
                    MemberType: MemberType,
                    ID: MemberID,
                    UserName: event.target.txtUserName.value,
                    FullName: event.target.txtFullName.value,
                    Password: Password,
                    User_Group_ID: UserGroupId,
                };

               //console.log(data);

                var api_config = {
                    method: "post",
                    url: config.base_url + "Members/UpdateUser",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    data: data,
                };

                axios(api_config)
                    .then(function (response) {
                       //console.log(response.data);
                        if (response.data.status_code == 1) {
                            toast.success(response.data.status_message, {
                                position: toast.POSITION.TOP_RIGHT,
                            });
                            navigate('/Security/ManageUsers');
                        } else {
                            toast.error(response.data.status_message, {
                                position: toast.POSITION.TOP_RIGHT,
                            });
                        }

                        setLoading(false);
                    })
                    .catch(function (error) {
                        setLoading(false);
                        toast.error("Network Error..", {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    });
            }
        }
        setValidated(true);
    };

    return (
        <>
            {/* <LoginCheck /> */}
            <SecurityMenu />
            <ToastContainer />
            <div className="page-head">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <a href="/Home/Index">Dashboard</a>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <a href="/Security/SecurityDashboard">Security</a>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        Update User
                                    </li>
                                </ol>
                            </nav>
                            <div className="page-title">Update User Information</div>
                        </div>
                        <div className="col-md-6">
                            <div className="page-right-actions">
                                <NavLink
                                    to="/Security/ManageUsers"
                                    className="button button-white button-icon"
                                >
                                    Manage Users
                                    <i className="ri-list-check"></i>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-content">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body p-md-4">
                                    <Form
                                        noValidate
                                        validated={validated}
                                        onSubmit={handleSubmit}
                                    >
                                        <div className="row align-items-center">
                                            <div className="col-md-3">
                                                <div className="form-group mb-3">
                                                    <label >Full Name:</label>
                                                    <input
                                                        type="text"
                                                        defaultValue={User.FullName}
                                                        className="form-control"
                                                        required
                                                        name="txtFullName"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group mb-3">
                                                    <label  className="">
                                                        Login ID
                                                    </label>
                                                    <input
                                                        type="text"
                                                        defaultValue={User.UserName}
                                                        className="form-control"
                                                        required
                                                        name="txtUserName"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group mb-3 position-relative">
                                                    <label  className="">
                                                        Password
                                                    </label>
                                                    <input
                                                        type={ShowPassword ? 'text' : 'password'}
                                                        className="form-control"
                                                        required
                                                        autoComplete="off"
                                                        value={Password}
                                                        onChange={(e) => setPassword(e.target.value)}
                                                        minLength={4}
                                                        maxLength={13}
                                                    />
                                                    <span
                                                        className={`${ShowPassword ? 'ri-eye-off-line' : 'ri-eye-line'}`}
                                                        style={{
                                                            position: "absolute",
                                                            bottom: "6px",
                                                            right: "12px"
                                                        }}
                                                        onClick={(e) => setShowPassword(!ShowPassword)}
                                                    >
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group mb-3 position-relative">
                                                    <label  className="">
                                                        Retype Password
                                                    </label>
                                                    <input type={ShowConfirmPassword ? 'text' : 'password'}
                                                        className="form-control"
                                                        required
                                                        value={ConfirmPassword}
                                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                                    />
                                                    <span
                                                        className={`${ShowConfirmPassword ? 'ri-eye-off-line' : 'ri-eye-line'}`}
                                                        style={{
                                                            position: "absolute",
                                                            bottom: "6px",
                                                            right: "12px"
                                                        }}
                                                        onClick={(e) => setShowConfirmPassword(!ShowConfirmPassword)} >
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label  className="">
                                                        Select User Group
                                                    </label>
                                                    <select
                                                        className="form-select"
                                                        onChange={(e) => setUserGroupID(e.target.value)}
                                                        value={UserGroupId}
                                                        required
                                                        name="ddlUserGroup"
                                                    >
                                                        <option value="">Please Select</option>
                                                        <UserGroupDropDown selected={UserGroupId} />
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-3 offset-md-6 text-end">
                                                <div className="form-group">
                                                    <button type="submit" disabled={loading} value="Update" className="button button-primary">Update {loading ? '...' : ''}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EditUser;
