import React, { useEffect, useState } from 'react'
import StudentForm from '../Shared/StudentForm'
import Pagination from "../General/Pagination";
import config from "../../Config";
import axios from "axios";
import moment from "moment";
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate, useParams } from 'react-router-dom';
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import StudentMenu from './StudentMenu';
import { scrollToTop } from '../Shared/ScrollOnTop';
import RightsCheck from '../General/RightsCheck';

const StudentHistory = () => {
    let params = useParams();
    const [Student_ID, setStudent_ID] = useState(params.id);
    const [Student, setStudent] = useState({});

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    //const [DefaultSession, setDefaultSession] = useState(localStorage.getItem("DefaultSession"));

    const [showFilters, setShowFilters] = useState(true);

    const [loading, setLoading] = useState(false);
    const [listOfLedger, setLedger] = useState([]);
    const [validated, setValidated] = useState(false);

    // pagination 
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);

    const handlePageChange = (page) => {
        setPageNo(page);
    };




    useEffect(() => {


        document.title = "Student History";
        setLoading(true);

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            pageNo: pageNo,
            pageSize: pageSize,
            Student_ID: Student_ID,
        };
        //console.log(data);

        var api_config = {
            method: 'post',
            url: config.base_url + 'Student/GetStudentHistory',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        //console.log(data)

        axios(api_config)
            .then(function (response) {
                //console.log(response.data);

                if (response.data.status_code != 0) {
                    setStudent(response.data.ListofRecords[0]);
                    setTotalRecords(response.data.totalRecords);
                    let student = response.data.ListofRecords[0];
                    setLoading(false)
                }
                else {
                    setLedger(null);
                    setTotalRecords(0)
                }

            })
            .catch(function (error) {
                //console.log(error);
            });

    }, [pageNo, pageSize]);

    return (
        <>
            <RightsCheck />
            <StudentMenu />
            <ToastContainer />
            <div className="container body-content">
                <div className="page-head">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <NavLink to="/Dashboard">Dashboard</NavLink>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <NavLink to="/students/StudentDashboard">
                                                Students
                                            </NavLink>
                                        </li>

                                        <li className="breadcrumb-item active" aria-current="page">
                                            Student History
                                        </li>
                                    </ol>
                                </nav>
                                <div className="page-title">Student History</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 offset-md-1">
                                <div className="card">
                                    <div className="card-body card-form-wrap">
                                        {
                                            loading ?
                                                <>
                                                    <Spinner animation="border" role="status"></Spinner>
                                                </>
                                                :
                                                <>
                                                    <div className="form-wrap">
                                                        <div className="form-legend">Basic Information</div>
                                                        <div className="row align-items-center">
                                                            <div className="col-md-9">
                                                                <div className="row align-items-center g-3 m-0">
                                                                    <label  className="col-md-2 col-form-label col-form-label-sm">Bay Form .</label>
                                                                    <div className="col-md-4">
                                                                        <input type="text" disable className="form-control" value={Student.Bay_Form_No} />
                                                                    </div>
                                                                    <label  className="col-md-2 col-form-label col-form-label-sm">Form No.</label>
                                                                    <div className="col-md-4">
                                                                        <input type="text" disabled numbers-Only className="form-control" value={Student.Form_No} id="" />
                                                                    </div>
                                                                    <label  className="col-md-2 col-form-label col-form-label-sm">Student Name</label>
                                                                    <div className="col-md-4">
                                                                        <input type="text" disabled className="form-control" value={Student.Student_Name} id="" />
                                                                    </div>
                                                                    <label  className="col-md-2 col-form-label col-form-label-sm">Date of Birth</label>
                                                                    <div className="col-md-4">
                                                                        <div className="form-control">
                                                                            {moment(Student.Date_Of_Birth).format('DD/MM/YYYY')}
                                                                        </div>
                                                                    </div>
                                                                    <label  className="col-md-2 col-form-label col-form-label-sm" > Father Name </label>
                                                                    <div className="col-md-4">
                                                                        <input type="text" disabled className="form-control" value={Student.Father_Name} id="" />
                                                                    </div>
                                                                    <label  className="col-md-2 col-form-label col-form-label-sm">Father CNIC</label>
                                                                    <div className="col-md-4">
                                                                        <input type="text" disabled numbers-Only className="form-control" value={Student.Father_CNIC} id="" />
                                                                    </div>
                                                                    <label  className="col-md-2 col-form-label col-form-label-sm">Gender</label>
                                                                    <div className="col-md-9">
                                                                        <div className="mt-1">

                                                                            <div className="form-check form-check-inline">
                                                                                <label className="form-check-label">
                                                                                    <input type="radio" name="rdoResult" checked={Student.Gender} />
                                                                                    Male
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-check form-check-inline me-0">
                                                                                <label className="form-check-label">
                                                                                    <input type="radio" name="rdoResult" checked={!Student.Gender} />
                                                                                    Female
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div className="col-md-3 text-center">
                                                                <img className="mb-3 m-auto" src={config.StudentImagePath + Student.Registration_No + ".jpg"} style={{ width: "150px", height: "150px", border: "1px solid", borderRadius: "50%", display: "block" }} />
                                                            </div>
                                                        </div>
                                                        <div className="row align-items-center mt-0">

                                                            <label  className="col-md-2 col-form-label col-form-label-sm">Vaccination Status</label>
                                                            <div className="col-md-4">
                                                                <input type="text" disabled numbers-Only className="form-control" value={Student.Vaccination_Status} id="" />
                                                            </div>
                                                            <label  className="col-md-2 col-form-label col-form-label-sm">Vaccination Date</label>
                                                            <div className="col-md-4">
                                                                <input type="text" className="form-control" value={Student.Vaccination_Date} id="" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-wrap">
                                                        <div className="form-legend">Contact Information</div>
                                                        <div className="row">
                                                            <label  className="col-md-3 col-form-label col-form-label-sm">Temporary Address</label>
                                                            <div className="col-md-9">
                                                                <input type="text" disabled className="form-control" value={Student.Temporary_Address} id="" />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <label  className="col-md-3 col-form-label col-form-label-sm">Permanent Address</label>
                                                            <div className="col-md-9">
                                                                <input type="text" disabled className="form-control" value={Student.Permanent_Address} id="" />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <label  className="col-md-3 col-form-label col-form-label-sm">Contact Number</label>
                                                            <div className="col-md-3">
                                                                <input type="text" disabled numbers-Only className="form-control" value={Student.Contact_Phone} id="" />
                                                            </div>
                                                            <label  className="col-md-3 col-form-label col-form-label-sm">Mobile Number</label>
                                                            <div className="col-md-3">
                                                                <input type="text" disabled numbers-Only className="form-control" value={Student.Contact_Mobile} id="" />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <label  className="col-md-3 col-form-label col-form-label-sm">Email Address</label>
                                                            <div className="col-md-9">
                                                                <input type="text" disabled className="form-control" value={Student.Contact_Email} id="" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-wrap">
                                                        <div className="form-legend">Class &amp; Transport</div>
                                                        <div className="row no-gutters mb-0">
                                                            <div className="col-md-6">
                                                                <div className="row">
                                                                    <label  className="col-md-6 col-form-label col-form-label-sm">Wing</label>
                                                                    <div className="col-md-6">
                                                                        <input type="text" disabled numbers-Only className="form-control" value={Student?.SelectedClass?.SelectedWing?.Wing_Name} id="" />
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <label  className="col-md-6 col-form-label col-form-label-sm">Session</label>
                                                                    <div className="col-md-6">
                                                                        <input type="text" disabled numbers-Only className="form-control" value={Student?.SelectedClass?.Selected_Session?.Session_Name} id="" />
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <label  className="col-md-6 text-md-end col-form-label">Class:</label>
                                                                    <div className="col-md-6">
                                                                        <input type="text" disabled numbers-Only className="form-control" value={Student?.SelectedClass?.Class_Name} id="" />
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <label  className="col-md-6 col-form-label col-form-label-sm">Section</label>
                                                                    <div className="col-md-6">
                                                                        <input type="text" disabled numbers-Only className="form-control" value={Student?.SelectedSection?.Section_Name} id="" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="row">
                                                                    <label  className="col-md-6 col-form-label col-form-label-sm">Transport Facility</label>
                                                                    <div className="col-md-6">
                                                                        <input type="text" disabled numbers-Only className="form-control" value={Student?.SelectedRoot?.Root_Name} id="" />
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <label  className="col-md-6 col-form-label col-form-label-sm">Concessions</label>
                                                                    <div className="col-md-6">
                                                                        <input type="text" disabled numbers-Only className="form-control" value={Student?.SelectedConcessionRule?.Concession_Rule_Name} id="" />
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <label  className="col-md-6 col-form-label col-form-label-sm">Group</label>
                                                                    <div className="col-md-6">
                                                                        <input type="text" disabled numbers-Only className="form-control" value={Student?.SelectedGroup?.Group_Name} id="" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-wrap">
                                                        <div className="form-legend">Other Details</div>
                                                        <div className="row align-items-center gy-3">
                                                            <label  className="col-md-3 col-form-label col-form-label-sm">Previous School Information</label>
                                                            <div className="col-md-9">
                                                                <input type="text" disabled className="form-control" value={Student.Previous_School_Info} />
                                                            </div>
                                                            <label  className="col-md-3 col-form-label col-form-label-sm">NOC Board Information</label>
                                                            <div className="col-md-9">
                                                                <input type="text" disabled className="form-control" value={Student.NOC_Board} />
                                                            </div>
                                                            <label  className="col-md-3 col-form-label col-form-label-sm">Remarks</label>
                                                            <div className="col-md-9">
                                                                <input type="text" disabled className="form-control" value={Student.Remarks} />
                                                            </div>
                                                            <label  className="col-md-3 col-form-label col-form-label-sm">Record Changed By</label>
                                                            <div className="col-md-4">
                                                                <input type="text" disabled className="form-control" value={`${Student?.Selected_User?.Full_Name} ${moment(Student.Updated_Date).format('DD/MM/YYYY ,h:mm:ss a')}`}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-md-10 offset-md-1">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row mt-2">
                                            <div className="col-md-9">
                                                {
                                                    <div className="pagination-wrap">
                                                        <div className="row align-items-center">
                                                            <Pagination
                                                                pageNo={pageNo}
                                                                pageSize={pageSize}
                                                                totalRecords={totalRecords}
                                                                onPageChange={handlePageChange}
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <div className="col-md-2 offset-md-1">
                                                <Link to="" className="button button-primary w-100">
                                                    Print
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default StudentHistory