import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import moment, { months } from "moment";
import PrintHeader from "../General/PrintHeader";
import Page from "../General/Page";
import { calculateColumnTotal, getMonthName } from "../General/Utility";

const PrintExamDataSheet = () => {
    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(
        localStorage.getItem("MemberType")
    );
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [listOfLedger, setLedger] = useState([]);

    useEffect(() => {
        document.title = "Print Exam Data Entry Options";

        setLoading(true);
        // Get the URL parameters
        const urlParams = new URLSearchParams(window.location.search);

        // Retrieve individual parameter values
        const ExamID = urlParams.get("ID");
        const Wing_Id = urlParams.get("WingId");
        const Class_Id = urlParams.get("ClassId");
        const Section_Id = urlParams.get("SectionId");
        const PrintTotal = urlParams.get("PrintTotal");

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            ID: ExamID,
            Wing_Id: Wing_Id,
            Class_Id: Class_Id,
            Section_Id: Section_Id,
            PrintTotal: PrintTotal,
        };

        //console.log(data);
        var api_config = {
            method: "post",
            url: config.base_url + "Exam/GetExamDataSheet",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

       //console.log(api_config);

        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                setLedger(response.data.ListofSections);
                setLoading(false);
            })
            .catch(function (error) {
                //console.log(error);
            });
    }, []);

    return (
        <>
            <Page orientation="landscape" size="a4">

                <table className="table table-sm table-bordered table-print" style={{ border: "transparent" }}>
                    <thead>
                        <tr>
                            <th>
                                <PrintHeader title="" />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div>
                                    {loading ? (
                                        <Spinner animation="border" role="status"></Spinner>
                                    ) : (
                                        <>
                                            {listOfLedger &&
                                                listOfLedger.map((item, index) => (
                                                    <div  key={index} style={{ pageBreakAfter: index !== listOfLedger.length - 1 ? "always" : "auto" }}>
                                                        <div>
                                                            <div className="row">
                                                                <div className="col-md-4">
                                                                    <h6 style={{ fontSize: "12x" }}>
                                                                        Master Sheet for: {item.Wing_Name}-{item.Class_Name}-
                                                                        {item.Section_Name}
                                                                    </h6>
                                                                </div>
                                                                <div className="col-md-4 text-center">
                                                                    <h6 style={{ fontSize: "12px" }}>Exam: {item.Exam_Name} Total Marks: {item.Total_Marks}</h6>
                                                                </div>
                                                                <div className="col-md-4 text-end">
                                                                    <h6 style={{ fontSize: "12px" }}>{item.Section_Teacher_Name}</h6>
                                                                </div>
                                                            </div>


                                                            <table className="table table-sm table-bordered table-print">
                                                                <thead>
                                                                    <tr>
                                                                        <th style={{ width: "50px", textAlign: "center", verticalAlign: "top" }}>SR #</th>
                                                                        <th style={{ width: "90px", verticalAlign: "top" }}>Code #</th>
                                                                        <th style={{ width: "190px", verticalAlign: "top" }}>Student Name</th>
                                                                        {item.ListofSubjects.map((itemSubjects) => (
                                                                            <th style={{ textAlign: "center", textTransform: "uppercase", verticalAlign: "top" }}>
                                                                                {itemSubjects.Name.substring(0,2)}
                                                                                <br /> {itemSubjects.Total_Marks}
                                                                            </th>
                                                                        ))}
                                                                        <th>Total Marks</th>
                                                                        <th>Position</th>
                                                                        <th>%Age</th>
                                                                        <th>Grade</th>
                                                                        <th>Remarks</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {item.StudentsInSection.map((itemStudent, index) => (
                                                                        <tr>
                                                                            <td className="text-center">{itemStudent.Serial_No}</td>
                                                                            <td>{itemStudent.Registration_No}</td>
                                                                            <td>{itemStudent.Student_Name}</td>
                                                                            {itemStudent.ListofSubjectsInner.map((itemMarks, index) => (
                                                                                <td style={{ width: "40px" }}>
                                                                                    {itemMarks.Obtained_Marks}
                                                                                </td>
                                                                            ))}
                                                                            <td>{itemStudent.FirstTermObtained_Marks}</td>
                                                                            <td>{itemStudent.FirstTermClass_Position}</td>
                                                                            <td>{itemStudent.FirstTermPercentMarks}</td>
                                                                            <td>{itemStudent.FirstTermGrade}</td>
                                                                            <td></td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <table className="table mt-3">
                                                            <tr>
                                                                <td style={{ width: "33%", textAlign: "center" }}>
                                                                    <div style={{ width: "200px", margin: "0 auto 12px auto", borderBottom: "1px solid" }}></div>
                                                                    Class Teacher
                                                                </td>
                                                                <td style={{ width: "33%", textAlign: "center", paddingTop: "10px" }}>
                                                                    <div style={{ width: "200px", margin: "0 auto 12px auto", borderBottom: "1px solid" }}></div>
                                                                    Controller Of Examination
                                                                </td>
                                                                <td style={{ width: "33%", textAlign: "center", paddingTop: "10px" }}>
                                                                    <div style={{ width: "200px", margin: "0 auto 12px auto", borderBottom: "1px solid" }}></div>
                                                                    Principal
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                ))}
                                        </>
                                    )}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>

            </Page>
        </>
    );
};

export default PrintExamDataSheet;
