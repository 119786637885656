import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import StudentMenu from "./StudentMenu";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { scrollToTop } from "../Shared/ScrollOnTop";
import RightsCheck from "../General/RightsCheck";

function AdmitStudent(props) {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [validated, setValidated] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [StudentID, setStudentID] = useState("0");
  const [Student, setStudent] = useState({});
  const [admissionDate, setadmissionDate] = useState("");
  const [receiptNo, setreceiptNo] = useState("0");
  const [DueAmount, setDueAmount] = useState("0");

  document.title = "Admit Student";

  const handleSubmit = (event) => {
    setLoading(true);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      ID: StudentID,
      ReceiptNo: receiptNo,
      AdmissionDate: admissionDate,
    };

    ////console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Student/GetStudentByIDForAdmit",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        if (response.data.status_code == 1) {
          setStudent(response.data.Student);
        } else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }

        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        toast.error("Network Error..", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });

    setValidated(true);
  };

  const admitStudent = (event) => {
    setLoading(true);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      ID: StudentID,
      AdmissionDate: admissionDate,
      ReceiptNo: receiptNo,
    };

    //console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Student/AdmitStudent",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        if (response.data.status_code == 1) {
          //setStudent(response.data.Student);
          toast.success(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }

        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        toast.error("Network Error..", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });

    setValidated(true);
  };

  return (
    <>
      <RightsCheck />
      <StudentMenu />
      <ToastContainer />
      <div className="container body-content">
        <div className="page-head">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="/Home/Index">Dashboard</a>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="/student/studentdashboard">Students</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Admit Student
                    </li>
                  </ol>
                </nav>
                <div className="page-title">Admit Student</div>
              </div>
            </div>
          </div>
        </div>

        <div className="page-content">
          <div className="container">
            <div className="row">
              <div className="col-md-10 offset-md-1">
                <div className="card">
                  <div className="card-body card-form-wrap">
                    <div className="form-wrap">
                      <div className="form-legend">Step 1.Search Student</div>
                      <div className="row">
                        <div className="row">
                          <label className="col-md-3 col-form-label col-form-label-sm">
                            Enter Student ID:
                          </label>
                          <div className="col-md-3">
                            <input
                              type="number"
                              name="Student_ID"
                              onChange={(e) => setStudentID(e.target.value)}
                              className="form-control"
                              required
                            />
                          </div>
                          <div className="col-md-3 offset-md-3">
                            {loading ? (
                              <Spinner
                                animation="border"
                                role="status"
                              ></Spinner>
                            ) : (
                              <button
                                type="submit"
                                onClick={handleSubmit}
                                value="SEARCH"
                                className="button button-primary w-100"
                              >
                                Search
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {Object.keys(Student).length > 0 && (
                      <>
                        <div className="form-wrap">
                          <div className="form-legend">
                            Step 2. Confirm Details
                          </div>
                          <div className="row">
                            <label className="col-md-2 col-form-label col-form-label-sm">
                              Student Name:
                            </label>
                            <div className="col-md-4">
                              <label className="label-value">
                                {Student.Student_Name}
                              </label>
                            </div>
                            <label className="col-md-2 col-form-label col-form-label-sm">
                              Father Name:
                            </label>
                            <div className="col-md-4">
                              <label className="label-value">
                                {" "}
                                {Student.Father_Name}{" "}
                              </label>
                            </div>
                          </div>
                          <div className="row">
                            <label className="col-md-2 col-form-label col-form-label-sm">
                              F CNIC NO:
                            </label>
                            <div className="col-md-4">
                              <label className="label-value">
                                {Student.Father_CNIC}
                              </label>
                            </div>
                            <label className="col-md-2 col-form-label col-form-label-sm">
                              Date of Birth:
                            </label>
                            <div className="col-md-4">
                              <label className="label-value">
                                {Student.Date_Of_Birth}{" "}
                              </label>
                            </div>
                          </div>
                          <div className="row no-gutters mb-3">
                            <label className="col-md-2 col-form-label col-form-label-sm">
                              Form No:
                            </label>
                            <div className="col-md-4">
                              <label className="label-value">
                                {Student.Form_No}
                              </label>
                            </div>
                            <label className="col-md-2 col-form-label col-form-label-sm">
                              Bay Form No:
                            </label>
                            <div className="col-md-4">
                              <label className="label-value">
                                {Student.Bay_Form_No}
                              </label>
                            </div>
                          </div>
                          <div className="row no-gutters mb-3">
                            <label className="col-md-2 col-form-label col-form-label-sm">
                              Contact No:
                            </label>
                            <div className="col-md-4">
                              <label className="label-value">
                                {Student.Contact_Mobile}
                              </label>
                            </div>
                            <label className="col-md-2 col-form-label col-form-label-sm">
                              Gender:
                            </label>
                            <div className="col-md-4">
                              <label className="label-value">
                                {Student.Gender}{" "}
                              </label>
                            </div>
                          </div>
                          <div className="row no-gutters mb-3">
                            <label className="col-md-2 col-form-label col-form-label-sm">
                              Wing Name:
                            </label>
                            <div className="col-md-2">
                              <label className="label-value">
                                {Student.SelectedClass.SelectedWing.Wing_Name}
                              </label>
                            </div>
                            <label className="col-md-2 col-form-label col-form-label-sm">
                              Class Name:
                            </label>
                            <div className="col-md-2">
                              <label className="label-value">
                                {Student.SelectedClass.Class_Name}
                              </label>
                            </div>
                            <label className="col-md-2 col-form-label col-form-label-sm">
                              Section Name:
                            </label>
                            <div className="col-md-2">
                              <label className="label-value">
                                {Student.SelectedSection.Section_Name}
                              </label>
                            </div>
                          </div>

                          <div className="row no-gutters mb-3">
                            <label className="col-md-3 col-form-label col-form-label-sm">
                              Temporary Address:
                            </label>
                            <div className="col-md-9">
                              <label className="label-value">
                                {Student.Temporary_Address}{" "}
                              </label>
                            </div>
                          </div>
                          <div className="row no-gutters mb-3">
                            <label className="col-md-3 col-form-label col-form-label-sm">
                              Permanent Address:
                            </label>
                            <div className="col-md-9">
                              <label className="label-value">
                                {Student.Permanent_Address}{" "}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div
                          className="form-wrap"
                          ng-controller="studentController"
                        >
                          <div className="form-legend">
                            Step 3. Provide Fee Details
                          </div>
                          <div className="row">
                            <label className="col-md-1 col-form-label col-form-label-sm">
                              Dated:{" "}
                            </label>
                            <div className="col-md-3">
                              <input
                                type="date"
                                name="admissionDate"
                                value={admissionDate}
                                onChange={(e) =>
                                  setadmissionDate(e.target.value)
                                }
                                className="form-control"
                                id=""
                              />
                            </div>

                            <label className="col-md-2 col-form-label col-form-label-sm">
                              Receipt No:{" "}
                            </label>
                            <div className="col-md-2">
                              <input
                                type="text"
                                name="Receipt_No"
                                value={receiptNo}
                                onChange={(e) => setreceiptNo(e.target.value)}
                                className="form-control"
                                id=""
                              />
                            </div>
                            <label className="col-md-2 col-form-label col-form-label-sm">
                              Amount:
                            </label>
                            <div className="col-md-2">
                              <input
                                type="text"
                                name="DueAmount"
                                value={DueAmount}
                                onChange={(e) => setDueAmount(e.target.value)}
                                className="form-control"
                                id=""
                              />
                            </div>

                            <div className="col-md-3 offset-md-9 mt-3 text-end">
                              {loading ? (
                                <Spinner
                                  animation="border"
                                  role="status"
                                ></Spinner>
                              ) : (
                                <button
                                  type="submit"
                                  onClick={admitStudent}
                                  className="button button-primary w-100"
                                >
                                  Admit Student
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdmitStudent;
