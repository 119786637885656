import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";

function ShowStaff(props) {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [loading, setLoading] = useState(false);
  const [Employee, setEmployee] = useState("");

  useEffect(() => {
   //console.log(props.EmployeeID)

    if ([props.EmployeeID] > 0) {
      const data = {
        AccessKey: AccessKey,
        UserID: UserID,
        MemberType: MemberType,
        ID: props.EmployeeID,
      };

     //console.log(data);

      var api_config = {
        method: "post",
        url: config.base_url + "Payroll/GetEmployeeByID",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(api_config)
        .then(function (response) {
         //console.log(response.data);
          if (response.data.status_code == 1) {
            setEmployee(response.data.Employee);
          }
          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          toast.error("Network Error..", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    } else {
      setLoading(false);
    }
  }, [props.EmployeeID]);
  function capitalizeWords(name) {
    if (typeof name !== "string") {
      return ""; // Return an empty string if the input is not a string
    }

    return name
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  }
  // function capitalizeWords(name) {
  //     // return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
  //     return name.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
  // }
  const FullName = capitalizeWords(Employee.Name);
  const FatherName = capitalizeWords(Employee.Father_Name);

  return (
    <>
      <div className="container">
        {Employee === "" ? (
          <p>No record found</p>
        ) : (
          <div className="page-content staff-card">
            <div className="row  mb-3">
              <div className="col-md-3">
                <div className="card mt-1">
                  <div className="card-body user-profile">
                    <div className="profile">
                      <img
                        className="img-fluid"
                        src={config.StaffImagePath + Employee.Code + ".jpg"}
                      />
                    </div>

                    <h4>
                      {FullName.length > 16
                        ? `${FullName.substring(0, 16)}...`
                        : FullName}
                    </h4>
                    <span>
                      {FatherName.length > 35
                        ? `${FatherName.substring(0, 35)}...`
                        : FatherName}
                    </span>
                    {/* <div className="location">
                                        <i className="ri-map-pin-fill"></i>
                                        <p>{Employee.Home_Address}</p>
                                    </div> */}
                  </div>
                </div>
              </div>
              <div className="col-md-9">
                <div className="card mt-1">
                  <div className="card-body">
                    <div className="row align-items-center">
                      <div className="col-md-4">
                        <div className="profile-detail">
                          <h6  className="">
                            Full Name:{" "}
                          </h6>
                          <p>{FullName}</p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="profile-detail">
                          <h6  className="">
                            Father Name:{" "}
                          </h6>
                          <p>{FatherName}</p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="profile-detail">
                          <h6  className="">
                            Type/Depart:
                          </h6>
                          <p>{Employee.Employee_Type_BE?.Name}</p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="profile-detail">
                          <h6  className="">
                            Employe Code:{" "}
                          </h6>
                          <p>{Employee.Code}</p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="profile-detail">
                          <h6  className="">
                            Designation:
                          </h6>
                          <p>{Employee.Selected_Designation?.Name}</p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="profile-detail">
                          <h6  className="">
                            Pay Scale:
                          </h6>
                          <p>{Employee.GPS_Scale}</p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="profile-detail">
                          <h6  className="">
                            Joining Date:
                          </h6>
                          <p>
                            {moment(Employee.Date_Of_Joining).format(
                              config.date_format
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="profile-detail">
                          <h6  className="">
                            A/C No:{" "}
                          </h6>
                          <p>{Employee.Account_No}</p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="profile-detail">
                          <h6  className="">
                            CNIC No:
                          </h6>
                          <p>{Employee.CNIC_NO}</p>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="profile-detail">
                          <h6  className="">
                            Address:{" "}
                          </h6>
                          <p>{Employee.Home_Address}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default ShowStaff;
