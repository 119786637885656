import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import moment from "moment";
import PrintHeader from '../General/PrintHeader';
import { useNavigate, useParams } from 'react-router-dom';

import Page from "../General/Page";

const PrintAdvaceSearchReport = () => {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [listOfLedger, setLedger] = useState([]);
    const [isUpdated, setIsUpdated] = useState(false);
    const [selectedMonth, setselectedMonth] = useState('');


    const [WingName, setWingName] = useState("");
    const [ClassName, setClassName] = useState("");
    const [Section_Name, setSection_Name] = useState("");


    const urlParams = new URLSearchParams(window.location.search);

    const lang = urlParams.get('lang');

//SessionID=12&Section_Id=0&WingID=1&ClassId=0&Registration-no=&Keywords=&Balance=10000&BalanceType=-1&BayFormNo=&
//Gender=&DateOfBirth=-1&Concession_Rule_ID=0&BusRouteID=-1&Status_Type_Id=1&Group_Id=0&OrderBy=Student_Name&Student_ID=0
    const SessionId = urlParams.get('SessionID');
    const WingId = urlParams.get('WingID');
    const ClassId = urlParams.get('ClassId');
    const SectionID = urlParams.get('Section_Id');
    const Registration_No = urlParams.get('Registration-no');
    const StudentID = urlParams.get('Student_ID');
    const Keywords = urlParams.get('Keywords');
    const Balance = urlParams.get('Balance');
    const BalanceType = urlParams.get('BalanceType');
    const BayFormNo = urlParams.get('BayFormNo');
    const Gender = urlParams.get('Gender');
    const DateOfBirth = urlParams.get('DateOfBirth');
    const ConcessionId = urlParams.get('Concession_Rule_ID');
    const BusRouteId = urlParams.get('BusRouteID');
    const Status_Type_Id = urlParams.get('Status_Type_Id');
    const GroupId = urlParams.get('Group_Id');
    const OrderBy = urlParams.get('OrderBy');

    useEffect(() => {

        document.title = "Advance Search Report ";

        setIsUpdated(false);
        setLoading(true);


        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            pageNo: 1,
            pageSize: 100000,
            SessionID: SessionId,
            Wing_Id: WingId,
            Class_Id: ClassId,
            Section_Id: SectionID,
            Registration_No: Registration_No,
            Student_ID: StudentID,
            Keywords: Keywords,
            Balance: Balance,
            BalanceType: BalanceType,
            BayFormNo: BayFormNo,
            Gender: Gender,
            DateOfBirth: DateOfBirth,
            Concession_Rule_ID: ConcessionId,
            BusRouteID: BusRouteId,
            Status_Type_Id: Status_Type_Id == null ? "" : Status_Type_Id,
            Group_Id: GroupId,
            OrderBy: OrderBy == null ? "" : OrderBy,
        };

       //console.log(data);

        var api_config = {
            method: "post",
            url: config.base_url + "Student/AdvanceSearch",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        //console.log(data)
        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                setLedger(response.data.ListofRecords);
                setLoading(false);
                setTotalRecords(response.data.totalRecords);

            })
            .catch(function (error) {
                //console.log(error);
            });

    }, []);


    const renderLedger = () => {

        if (listOfLedger != null && listOfLedger.length > 0) {
            let count = 1;
            let countbreak = 1;
            return (
                listOfLedger.map((item, index) => {
                    return (
                        <tr key={index}>
                            <td>
                                {count++}
                            </td>
                            <td>{item.Registration_No}</td>
                            <td>{item.Student_Name}</td>
                            <td>{item.Father_Name}</td>
                            <td>{item.Class_Name}  {item.SelectedSection?.Section_Name}</td>
                            <td>{item.Contact_Phone}</td>
                            <td>{moment(item.Date_Of_Birth).format("DD/MM/YYYY")}</td>
                            <td>{item.Current_Balance}</td>
                            <td>{moment(item.Date_Of_Joining).format("DD/MM/YYYY")}</td>
                            <td></td>

                        </tr>
                    )
                })
            )
        }
        else {
            return (
                <tr>
                    <td colSpan="7"><h4 className="mt-3" style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}>No Data Found</h4></td>
                </tr>
            )
        }

    }

    return (
        <>
            <Page orientation="portrait" size="a4">
                <table className="table table-sm table-bordered table-print white-space-wrap">
                    <thead>
                        <tr>
                            <th colSpan={10}>
                                <PrintHeader title="List Of Students _____________________________________________________" />
                            </th>
                        </tr>
                    </thead>
                    <thead>
                                    <tr>
                                        <th colSpan={12} className="text-end">
                                            Total Records Found:  {totalRecords}
                                        </th>
                                        
                                    </tr>
                                </thead>
                    <tbody>
                        
                        <tr>
                            <th>Sr#</th>
                            <th style={{ width: "70px" }}>Code</th>
                            <th style={{ width: "150px" }}>Student's Name</th>
                            <th className="text-center"> Father Name</th>
                            <th className="text-center"> Class - Section</th>
                            <th className="text-center"> Contact-No</th>
                            <th className="text-center">DOB </th>
                            <th className="text-center">Balance</th>
                            <th className="text-center">DOA </th>
                            <th className="text-center"> Remarks</th>


                          
                        </tr>


                        {
                            loading ?
                                <tr>
                                    <td className="text-center" colSpan="9">
                                        <Spinner animation="border" role="status"></Spinner>
                                    </td>
                                </tr>
                                :
                                <>
                                    {renderLedger()}
                                </>

                        }

                    </tbody>
                </table>
            </Page >
        </>
    );
}

export default PrintAdvaceSearchReport;
