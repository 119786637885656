import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";

function ConcessionDropDown(props) {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [Concessions, setConcessions] = useState([]);

  useEffect(() => {
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      SchoolID: 1,
      pageNo: 1,
      pageSize: 1000,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Concession/GetConcessionRules",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    ////console.log(data);

    axios(api_config)
      .then(function (response) {
        ////console.log(response.data);
        setConcessions(response.data.ListofRecords || []);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, []);

  if (Concessions.length > 0) {
    return Concessions.map((item, index) => {
      return (
        <option
          value={item.Concession_Rule_ID}
          key={index}
          //   selected={item.Concession_Rule_ID == 1 ? true : false}
          selected={props.Selected === -1 ? item.Concession_Rule_ID === 1 : item.Concession_Rule_ID === props.Selected}
        >
          {item.Concession_Rule_Name}
        </option>
      );
    });
  }
}

export default ConcessionDropDown;
