import React, { useState, useEffect } from 'react';
import config from "../../Config";
import axios from "axios";
import moment from "moment";


function YearsDropDown(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [FYear, setFYear] = useState([]);
    const [loading, setLoading] = useState(false);
    const [CurrentYear, setCurrentYear] = useState(moment().format("YYYY"));

    
    useEffect(() => {

        setLoading(true);

        const apiData = {
           AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            PageNo: 1,
            PageSize: 100
        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'Accounts/GetFYears',
            headers: {
                'Content-Type': 'application/json'
            },
            data: apiData
        };

        axios(api_config)
            .then(function (response) {
                ////console.log(response.data);
                setFYear(response.data.ListofRecords);
                setLoading(false);
            })
            .catch(function (error) {
                //console.log(error);
            });

    }, []);



    if (loading) {
        return <option selected disabled>Loading...</option>
    }
    else {
        return (
            FYear.map((item, index) => {
                return (
                    // selected={item.FYear === props.selected}
                    <option value={item.FYear} key={index} selected={CurrentYear == item.FYear}>{item.FYear}</option>
                )
            })
        );
    }
}

export default YearsDropDown; 