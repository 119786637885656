import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import QRCode from "react-qr-code";
import StudentInfo from "../FeeSystem/StudentInfo";
import moment from "moment";
import PrintHeader from "../General/PrintHeader";
import { useNavigate, useParams } from "react-router-dom";
import Page from "../General/Page";
import { RenderHTMLString } from "../General/Utility";
import { calculateColumnTotal, getMonthName } from "../General/Utility";
import { scrollToTop } from "../Shared/ScrollOnTop";

const Absenteelist = (props) => {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [listOfLedger, setLedger] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);
  const [selectedMonth, setselectedMonth] = useState("");

  const [WingName, setWingName] = useState("");
  const [ClassName, setClassName] = useState("");
  const [Section_Name, setSection_Name] = useState("");
  const [SectionID, setSectionID] = useState(0);

  let params = useParams();

  useEffect(() => {
    document.title = "ABSENTEE LIST";

    setIsUpdated(false);
    setLoading(true);

    setselectedMonth(moment().format("MMMM"));

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      pageNo: 1,
      pageSize: 2000,
      Section_Id: props.Section_ID ? props.Section_ID : params.id,
    };

    //console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Student/SearchStudents",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    //console.log(data);
    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        setLedger(response.data.ListofRecords || []);
        setLoading(false);
        setTotalRecords(response.data.totalRecords || 0);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, [props.Section_ID]);

  const renderLedger = () => {
    if (listOfLedger != null && listOfLedger.length > 0) {
      let count = 1;
      let countbreak = 1;
      return listOfLedger.map((item, index) => {
        return (
          <tr key={index}>
            <td>{count++}</td>
            <td>{item.Registration_No}</td>
            <td>{item.Student_Name}</td>
            <td>{item.Father_Name}</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="7">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };

  return (
    <>
      <Page orientation="portrait" size="a4">
        <table className="table table-sm table-bordered table-print white-space-wrap">
          <thead>
            <tr>
              <th colSpan={11}>
                <PrintHeader
                  title={`ABSENTEE LIST 
                            ${listOfLedger[0]?.SelectedClass?.SelectedWing?.Wing_Name}-
                            ${listOfLedger[0]?.SelectedClass?.Class_Name}-
                            ${listOfLedger[0]?.SelectedSection?.Section_Name}`}
                />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th rowSpan={2}>Sr#</th>
              <th rowSpan={2} style={{ width: "70px" }}>
                Code
              </th>
              <th rowSpan={2} style={{ width: "150px" }}>
                Student's Name
              </th>
              <th rowSpan={2} style={{ width: "150px" }}>
                Fahter Name
              </th>
              <th>Jun</th>
              <th>Jun</th>
              <th>Jun</th>
              <th>Jun</th>
              <th>Aug</th>
              <th>Aug</th>
            </tr>
            <tr>
              <th>
                Absent
                <br />
                Day
              </th>
              <th>
                Late
                <br />
                Days
              </th>
              <th>
                Absent
                <br />
                Day
              </th>
              <th>
                Late
                <br />
                Days
              </th>
              <th>
                Absent
                <br />
                Day
              </th>
              <th>
                Late
                <br />
                Days
              </th>
            </tr>

            {loading ? (
              <tr>
                <td className="text-center" colSpan="10">
                  <Spinner animation="border" role="status"></Spinner>
                </td>
              </tr>
            ) : (
              <>{renderLedger()}</>
            )}
          </tbody>
        </table>
      </Page>
    </>
  );
};

export default Absenteelist;
