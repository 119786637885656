import React, { useState } from "react";
import PayrollMenu from './PayrollMenu';
import YearsDropDown from '../Shared/YearsDropDown';
import MonthsDropDown from '../Shared/MonthsDropDown';
import EmployeeTypeDropDown from '../Shared/EmployeeTypeDropDown';
import { NavLink } from 'react-router-dom';
import moment from "moment";
import { scrollToTop } from '../Shared/ScrollOnTop';

function EmployeeReports(props) {

    document.title = "Employee Reports";
    const [Department, setDepartment] = useState(0);
    const [Month, setMonth] = useState(moment().format("M"));
    const [Year, setYear] = useState(moment().format("YYYY"));
    const [Amount, setAmount] = useState("");
    const [Amount1, setAmount1] = useState("");

    return (
        <>
            <PayrollMenu />

            <div className="container body-content">
                <div className="page-head">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><NavLink to="/Dashboard">Dashboard</NavLink></li>
                                        <li className="breadcrumb-item"><NavLink to="/PayRoll/PayRollDashboard">PayRoll</NavLink></li>
                                        <li className="breadcrumb-item active" aria-current="page">Employee Reports</li>
                                    </ol>
                                </nav>
                                <div className="page-title">Employee Reports</div>
                            </div>
                            <div className="col-md-6">

                            </div>
                        </div>
                    </div>
                </div>

                <div className="page-content">
                    <div className="container">
                        <div className="card">
                            <div className="card-body p-md-4">
                                <div className="row gy-3 align-items-center">
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label  className="">Select Department:</label>
                                            <select
                                                className="form-select" onChange={(e) => setDepartment(e.target.value)}>
                                                <option value="0">--All--</option>
                                                <EmployeeTypeDropDown />
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Select Month</label>
                                            <select className="form-select"
                                                required=""
                                                value={Month}
                                                onChange={(e) => setMonth(e.target.value)}>
                                                <MonthsDropDown />
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Select Year</label>
                                            <select className="form-select"
                                                value={Year}
                                                onChange={(e) => setYear(e.target.value)}
                                                required="">
                                                <YearsDropDown />
                                            </select>
                                        </div>
                                    </div>

                                    <div className="row align-items-center gy-3 mb-3">
                                        <div className="col-md-2">
                                            <NavLink
                                                target="_blank"
                                                className={`button button-primary w-100 ${Month === null || Year === null ? 'disabled' : ''}`}
                                                to={`/PayRoll/EmployeeReports/PrintEOBIList/?month=${Month}&year=${Year}&EmployeeTypeId=${Department}`}
                                            >Print EOBI List</NavLink>
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-3">
                                        <div className="col-md-2">
                                            <NavLink
                                                target="_blank"
                                                className={`button button-primary w-100 ${Month === null || Year === null ? 'disabled' : ''}`}
                                                to={`/PayRoll/EmployeeReports/PrintSWFList/?month=${Month}&year=${Year}&EmployeeTypeId=${Department}`}
                                            >Print SWF List</NavLink>
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-3">
                                        <div className="col-md-2">
                                            <NavLink
                                                target="_blank"
                                                className={`button button-primary w-100 ${Month === null || Year === null ? 'disabled' : ''}`}
                                                to={`/PayRoll/EmployeeReports/PrintPessiList/?month=${Month}&year=${Year}&EmployeeTypeId=${Department}`}
                                            >Print PESSI List</NavLink>
                                        </div>
                                    </div>

                                    <div className="row align-items-center mb-3">
                                        <div className="col-md-2">
                                            <NavLink
                                                target="_blank"
                                                className={`button button-primary w-100 ${Month === null || Year === null ? 'disabled' : ''}`}
                                                to={`/PayRoll/EmployeeReports/PrintTransportList/?month=${Month}&year=${Year}&EmployeeTypeId=${Department}`}
                                            >Print Transport List</NavLink>
                                        </div>
                                    </div>

                                    <div className="row align-items-center mb-3">
                                        <div className="col-md-2">
                                            <NavLink
                                                target="_blank"
                                                className={`button button-primary w-100 ${Month === null || Year === null ? 'disabled' : ''}`}
                                                to={`/PayRoll/EmployeeReports/PrintTaxList/?month=${Month}&year=${Year}&EmployeeTypeId=${Department}`}
                                            >Print Tax List</NavLink>
                                        </div>
                                        <div className="col-md-3">
                                            <NavLink
                                                target="_blank"
                                                className={`button button-primary w-100 ${Month === null || Year === null ? 'disabled' : ''}`}
                                                to={`/PayRoll/EmployeeReports/PrintTaxList1/?month=${Month}&year=${Year}&EmployeeTypeId=${Department}&Amount=${Amount}`}
                                            >Print Tax List Over Lac</NavLink>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <input type="text" className="form-control" value={Amount} name="Amount" onChange={(e) => setAmount(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-3">
                                        <div className="col-md-2">
                                            <NavLink
                                                target="_blank"
                                                className={`button button-primary w-100 ${Month === null || Year === null ? 'disabled' : ''}`}
                                                to={`/PayRoll/EmployeeReports/PrintStaffList1/?month=${Month}&year=${Year}&EmployeeTypeId=${Department}`}
                                            >Print Staff List 1</NavLink>
                                        </div>
                                        <div className="col-md-3">
                                            <NavLink
                                                target="_blank"
                                                className={`button button-primary w-100 ${Month === null || Year === null ? 'disabled' : ''}`}
                                                to={`/PayRoll/EmployeeReports/PrintEOBIList/?month=${Month}&year=${Year}&EmployeeTypeId=${Department}`}
                                            >Print Salary List</NavLink>
                                        </div>
                                        <div className="col-md-2 form-group">
                                            <select className="form-select" required>
                                                <option>Select</option>
                                                <option value="Greater">Greater</option>
                                                <option value="Lesser">Lesser</option>
                                            </select>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <input type="text"
                                                    className="form-control"
                                                    name="Amount" value={Amount1} onChange={(e) => setAmount1(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-3">
                                        <div className="col-md-2">
                                            <NavLink
                                                target="_blank"
                                                className={`button button-primary w-100 ${Month === null || Year === null ? 'disabled' : ''}`}
                                                to={`/PayRoll/EmployeeReports/PrintStaffList2/?month=${Month}&year=${Year}&EmployeeTypeId=${Department}`}
                                            >Print Staff List 2</NavLink>
                                        </div>
                                        <div className="col-md-3">
                                            <NavLink
                                                target="_blank"
                                                className={`button button-primary w-100 ${Month === null || Year === null ? 'disabled' : ''}`}
                                                to={`/PayRoll/EmployeeReports/PrintStaffList4/?month=${Month}&year=${Year}&EmployeeTypeId=${Department}`}
                                            >Print Staff List 2 with Photo</NavLink>
                                        </div>
                                    </div>


                                    <div className="row align-items-center">
                                        <div className="col-md-2">
                                            <NavLink
                                                target="_blank"
                                                className={`button button-primary w-100 ${Month === null || Year === null ? 'disabled' : ''}`}
                                                to={`/PayRoll/EmployeeReports/PrintStaffList3/?month=${Month}&year=${Year}&EmployeeTypeId=${Department}`}
                                            >Print Staff List 3</NavLink>
                                        </div>



                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EmployeeReports;