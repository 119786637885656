import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import RightsCheck from '../General/RightsCheck';
import { scrollToTop } from '../Shared/ScrollOnTop';
import NavDropdown from 'react-bootstrap/NavDropdown';


function AccountsMenu(props) {


    return (
        <>
            <RightsCheck />
            <nav className={`navbar navbar-expand-lg navbar-module MenuSticky`}>
                <div className="container-fluid">
                    <div className="collapse navbar-collapse">
                        <ul className="navbar-nav m-auto">

                            <li className="nav-item">
                                <NavLink onClick={scrollToTop} className="nav-link" to="/Accounts/ManageAccounts">Accounts</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink onClick={scrollToTop} className="nav-link" to="/Accounts/ManageVouchers">Vouchers</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink onClick={scrollToTop} className="nav-link" to="/Accounts/OpeningBalances">Opening Balances</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavDropdown title="Trial Balance">
                                    <NavLink onClick={scrollToTop} className="dropdown-item" to="/Accounts/TrialBalanceLevelWise">Trial Balance Level Wise</NavLink>
                                    <NavLink onClick={scrollToTop} className="dropdown-item" to="/Accounts/TrialBalanceHeadWise">Trial Balance Head Wise</NavLink>
                                </NavDropdown>
                            </li>
                            <li className="nav-item">
                                <NavLink onClick={scrollToTop} className="nav-link" to="/Accounts/BudgetHeadWise">Budget Head Wise</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink onClick={scrollToTop} className="nav-link" to="/Accounts/ReceiptPayment">Receipt Payments</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink onClick={scrollToTop} className="nav-link" to="/Accounts/IncomeExpenditure">Income Expenditure</NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    );
}

export default AccountsMenu;