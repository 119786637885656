import React from "react";
import { NavLink } from "react-router-dom";
import { scrollToTop } from "../Shared/ScrollOnTop";
import StudentRightsCheck from "./StudentRightsCheck";
function StudentPanelMenu(props) {
  return (
    <>
      <StudentRightsCheck />
      <nav className="navbar navbar-expand-lg navbar-module MenuSticky">
        <div className="container-fluid">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#NavbarSecondary"
            aria-controls="NavbarSecondary"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="NavbarSecondary">
            <ul className="navbar-nav m-auto">
              <li className="nav-item">
                <NavLink
                  onClick={scrollToTop}
                  className="nav-link"
                  to="/student-panel/update-profile"
                >
                  Student Profile
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  onClick={scrollToTop}
                  className="nav-link"
                  to="/student-panel/student-ledger"
                >
                  Fee Details
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink onClick={scrollToTop} className="nav-link" to="">
                  Exam Results
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  onClick={scrollToTop}
                  className="nav-link"
                  to="/student-panel/student-summary"
                >
                  Attendance
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  onClick={scrollToTop}
                  className="nav-link"
                  to="#/">
                  View Sibling
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default StudentPanelMenu;
