import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import { Link } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate, useParams } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Form } from "react-bootstrap";
import moment from "moment";
import UserGroupDropDown from "../Shared/UserGroupDropDown";
import PayrollMenu from "./PayrollMenu";
import { scrollToTop } from "../Shared/ScrollOnTop";

function EditSalaryType(props) {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [validated, setValidated] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [SalaryType, seSalaryType] = useState({});
  const [SType, setSType] = useState(""); // New state variable for SType

  let params = useParams();
  const [SalaryTypeID, setSalaryTypeID] = useState(params.id);

  let navigate = useNavigate();

  useEffect(() => {
    document.title = "Edit Salary Type";

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      ID: SalaryTypeID,
    };
    //console.log(data);
    var api_config = {
      method: "post",
      url: config.base_url + "SalaryType/GetSalaryTypeByID",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        if (response.data.status_code == 1) {
          seSalaryType(response.data.SalaryType);
          setSType(response.data.SalaryType.SType);
        }
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        toast.error("Network Error..", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setLoading(true);
      const data = {
        AccessKey: AccessKey,
        UserID: UserID,
        MemberType: MemberType,
        ID: SalaryTypeID,
        Name: event.target.txtSalaryTypeName.value,
        DisplayName: event.target.txtDisplayName.value,
        SortOrder: event.target.txtSortOrder.value,
        SType: event.target.ddlSType.value, // Include SType in data
      };

      //console.log(data);

      var api_config = {
        method: "post",
        url: config.base_url + "SalaryType/UpdateSalaryType",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(api_config)
        .then(function (response) {
          //console.log(response.data);
          if (response.data.status_code == 1) {
            toast.success(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }

          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          toast.error("Network Error..", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }

    setValidated(true);
  };

  return (
    <>
      <PayrollMenu />
      <ToastContainer />
      <div className="page-head">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/Dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    {" "}
                    <Link to="/PayRoll/PayrollDashboard">Setup</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Edit Salary Type
                  </li>
                </ol>
              </nav>
              <div className="page-title">Edit Salary Type</div>
            </div>
            <div className="col-md-6">
              <div className="page-right-actions">
                <Link
                  to="/PayRoll/ManageSalaryTypes"
                  className="button button-white button-icon"
                >
                  Manage Salary Types
                  <i className="ri-list-check"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-content">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body p-md-4">
                  <Form
                    noValidate
                    validated={validated}
                    onSubmit={handleSubmit}
                  >
                    <div className="row align-items-center">
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label className="">Salary Type Name:</label>
                          <input
                            type="text"
                            defaultValue={SalaryType.Name}
                            className="form-control"
                            required
                            name="txtSalaryTypeName"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label className="">Display Name:</label>
                          <input
                            type="text"
                            defaultValue={SalaryType.DisplayName}
                            className="form-control"
                            required
                            name="txtDisplayName"
                          />
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label className="">SType:</label>
                          <select
                            className="form-control"
                            name="ddlSType"
                            value={SType}
                            onChange={(e) => setSType(e.target.value)}
                            required
                          >
                            <option value="">Select SType</option>
                            <option value="Allowance">Allowance</option>
                            <option value="Deduction">Deduction</option>
                            <option value="Total">Total</option>
                          </select>
                        </div>
                      </div>
                      
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label className="">Sort Order:</label>
                          <input
                            type="number"
                            defaultValue={SalaryType.SortOrder}
                            className="form-control"
                            required
                            name="txtSortOrder"
                          />
                        </div>
                      </div>


                      <div className="col-md-2 text-end">
                        <div className="form-group ">
                          {loading ? (
                            <Spinner animation="border" role="status"></Spinner>
                          ) : (
                            <button
                              type="submit"
                              value="Save"
                              className="button button-primary w-100 "
                            >
                              SAVE
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditSalaryType;
