import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import { Link } from "react-router-dom";
import Monthly_navbar from "./Monthlytasks_navbar";
import arrears from "../../assets/images/dashboard_icon/arrears.svg";
import no_fee from "../../assets/images/dashboard_icon/no_fee.svg";
import { scrollToTop } from "../Shared/ScrollOnTop";
import { Bar } from "react-chartjs-2";
import moment from "moment";
import Spinner from "react-bootstrap/Spinner";
import { toast, ToastContainer } from "react-toastify";

const MonthlyTasksDashboard = () => {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [Graphdata, setGraphdata] = useState([]);
  const [loading, setLoading] = useState(false);

  const [Month, setMonth] = useState(moment().format("M"));
  const [Year, setYear] = useState(moment().format("YYYY"));

  const [ListofRecords, setListofRecords] = useState([]);
  const [PostedFee, setPostedFee] = useState([]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltip: {
        mode: "index",
        intersect: false,
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        titleColor: "white",
        bodyColor: "white",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        stacked: true,
        grid: {
          drawTicks: false,
          drawBorder: false,
        },
        gridLines: {
          color: "#e6e6e6",
          drawBorder: false,
        },
      },
      x: {
        grid: {
          drawOnChartArea: false,
          drawTicks: false,
          drawBorder: false,
        },
      },
    },
  };

  useEffect(() => {
    document.title = "Fee Posting";

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      SelectedMonth: Month,
      FYear: Year,
    };

    //console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "MonthlyTask/GetMonthlyAdmissionDetails",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        if (response.data.status_code == 1) {
          //console.log(response.data);
          setListofRecords(response.data.ListofRecords);
        }

        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        toast.error("Network Error..", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }, []);

  useEffect(() => {

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      SelectedMonth: Month,
      FYear: Year,
    };

    //console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "MonthlyTask/GetStartedMonths",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        if (response.data.status_code == 1) {
          //console.log(response.data);
          setPostedFee(response.data.ListofRecords);
        }

        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        toast.error("Network Error..", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }, []);

  const renderMonthlyTask = () => {
    if (ListofRecords != null && ListofRecords.length > 0) {
      return ListofRecords.map((item, index) => {
        return (
          <li key={index}>
            <div className="left">
              <h5>{item.Class_Name}</h5>
            </div>
            <div className="right">
              <h4>{item.NoOfStudents}</h4>
            </div>
          </li>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="8">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };

  const renderPostedFee = () => {
    if (PostedFee != null && PostedFee.length > 0) {
      return PostedFee.map((item, index) => {
        return (
          <tr key={index}>
            <td>{item.Month_ID}</td>
            <td>{item.Month_Number}</td>
            <td>{item.Current_Year}</td>
            <td className="text-center">{moment(item.Started_On).format("DD/MM/YYYY")}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="8">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  }

  return (
    <>
      <Monthly_navbar />
      <div className="page-head">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/Dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Fee Posting
                  </li>
                </ol>
              </nav>
              <div className="page-title">Fee Posting</div>
            </div>
            <div className="col-md-6">
              <div className="page-right-actions">
                <Link
                  to="/MonthlyTasks/ManageMonth"
                  className="button button-white button-icon"
                >
                  Manage Posted Fee
                  <i className="ri-list-check"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-content">
        <div className="container">
          <div className="row gy-3 align-items-center">
            <div className="col-md-12">
              <div className="card m-card">
                <div className="card-body p-2">
                  <p className="text-center m-0">
                    <b>Posting Details</b>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card m-card">
                <div className="card-body text-center">
                  <img src={arrears} className="pb-3" alt="" />
                  <p className="m-2">Students With 500 Fine</p>
                  <h4 className="m-2">123</h4>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card m-card">
                <div className="card-body text-center">
                  <img src={arrears} className="pb-3" alt="" />
                  <p className="m-2">Student With 50 Fine</p>
                  <h4 className="m-2">233</h4>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card">
                <div className="card-body text-center">
                  <img src={no_fee} className="pb-3" alt="" />
                  <p className="m-2">Unpaid in Last Month</p>
                  <h4 className="m-2">100</h4>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <table className="table table-theme table-condensed pb-md-0">
                    <thead style={{ backgroundColor: "#F8F8F8" }}>
                      <tr className=" mb-1">
                        <th colSpan="4" className="text-center">
                          MANAGE POSTED FEE
                        </th>
                      </tr>
                    </thead>
                    <thead style={{ backgroundColor: "#F8F8F8" }}>
                      <tr>
                        <th>Month ID</th>
                        <th>Month</th>

                        <th>Year</th>
                        <th className="text-center">Started On</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        loading ? (
                          <tr>
                            <td colSpan="8">
                              <div className="row">
                                <div className="col-md-6 text-center align-items-center">
                                  <Spinner animation="border" role="status" />
                                </div>
                              </div>
                            </td>
                          </tr>
                        ) : (
                          renderPostedFee()
                        )
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="graph mt-5">
            <div className="row">
              <div className="col-md-8">
                <div className="earning-card dash-bg-card">
                  <div className="graph-bar">
                    <div className="dash-header">
                      <div className="heading">Admission Details</div>
                      <div className="menu">
                        <ul className="menu-link">
                          {/* <li><a className={Day == "4" ? "active" : ""} onClick={(e) => setDay("4", e)}>Today</a></li>
                                                <li><a className={Day == "3" ? "active" : ""} onClick={(e) => setDay("3", e)}>This Week</a></li>
                                                <li><a className={Day == "2" ? "active" : ""} onClick={(e) => setDay("2", e)}>Last 15 Days</a></li>
                                                <li><a className={Day == "1" ? "active" : ""} onClick={(e) => setDay("1", e)}>Last Month</a></li> */}
                          <li>
                            <button
                              className=""
                              onClick={(e) => {
                                const picker1 =
                                  document.getElementById("header-date");
                                picker1.showPicker();
                              }}
                            >
                              <i className="ri-calendar-todo-line"></i>
                            </button>
                            <input
                              type="date"
                              id="header-date"
                              name="header-date"
                              className="menu-date"
                              placeholder="DD/MM/YY"
                            />
                          </li>
                        </ul>
                      </div>
                    </div>
                    {loading ? (
                      <Spinner />
                    ) : (
                      <Bar
                        type="Bar"
                        data={{
                          // labels: Graphdata.map((item, index) => item.Date),
                          labels: Graphdata.map((item, index) =>
                            moment(item.Date).format("DD-MMM-YY")
                          ),
                          datasets: [
                            {
                              label: false,
                              data: Graphdata.map(
                                (item, index) => item.AttendancePercentage
                              ), // Ensure Graphdata is defined and has expected structure

                              backgroundColor: ["#003970"],
                              fill: false,
                              borderWidth: 1,
                              barPercentage: 0.28,
                              borderRadius: 40,
                              borderSkipped: false,
                            },
                          ],
                        }}
                        options={options}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card-body dash-bg-card">
                  <h4 className="card-title">Class Wise Admissions</h4>
                  <div>
                    <ul className="birthday-list scrollbar">
                      {loading ? (
                        <div className="row">
                          <div className="col-md-6 text-center align-items-center">
                            <Spinner animation="border" role="status"></Spinner>
                          </div>
                        </div>
                      ) : (
                        <>{renderMonthlyTask()}</>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MonthlyTasksDashboard;
