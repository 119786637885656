import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import { useParams, useNavigate } from 'react-router-dom';
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import PayrollMenu from './PayrollMenu.js';
import moment from "moment";
import csvDownload from 'json-to-csv-export'
import { scrollToTop } from '../Shared/ScrollOnTop';
import SalaryTypeDropDown from "../Shared/SalaryTypeDropDown";
import { calculateColumnTotal } from "../General/Utility";

const SalaryTypeValues = (props) => {

    let params = useParams();


    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [loading, setLoading] = useState(false);
    const [listOfLedger, setLedger] = useState([]);
    const [isUpdated, setIsUpdated] = useState(false);
    const [SalaryType, setSalaryType] = useState(params.id);
    const [TotalNet, setTotalNet] = useState(0);
    const [OrderBy, setOrderBy] = useState("OldSerialNo");





    // pagination 
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(100);
    const [totalRecords, setTotalRecords] = useState(0);
    const [totalGrossPay, setTotalGrossPay] = useState(0);
    const [totalGrossPayNew, setTotalGrossPayNew] = useState(0);

    useEffect(() => {

        document.title = "Salary Type Values";

        setIsUpdated(false);
        setLoading(true);

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            SchoolID: 1,
            SalaryTypeId: SalaryType,
            OrderBy: OrderBy,
        };
        //console.log(data);

        var api_config = {
            method: 'post',
            url: config.base_url + 'Payroll/GetSalaryTypeValues',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                if (response.data.status_code == 1) {
                    ////console.log(response.data);
                    setLedger(response.data.ListofRecords);
                    setLoading(false);

                    let Total = calculateColumnTotal(response.data.ListofRecords, 'GrossPay');
                    setTotalGrossPay(Total);
                    setTotalGrossPayNew(Total);
                }
                else {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setLoading(false);
                }

            })
            .catch(function (error) {
                //console.log(error);
            });

    }, [isUpdated, SalaryType, OrderBy]);

    const saveSalaryValues = () => {
        const updatedLedger = listOfLedger.map((item) => {
            const updatedGrossPay = document.getElementById(`GrossPay_${item.ID}`).value;
            return { ...item, GrossPay: updatedGrossPay };
        });

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            ListofRecords: updatedLedger,
            SalaryTypeId: SalaryType,
        };
        //console.log(data);
        setLoading(true);

        axios
            .post(config.base_url + "Payroll/UpdateSalaryTypeValues", data)
            .then(function (response) {
                //console.log(response.data);
                setLoading(false);
                setIsUpdated(true);
                toast.success("Salary Values Updated successfully.");
            })
            .catch(function (error) {
                //console.log(error);
                toast.error("Failed to update Salary Values.");
            });
    };



    const renderLedger = () => {

        if (listOfLedger != null && listOfLedger.length > 0) {
            let count = 1;
            return (
                listOfLedger.map((item, index) => {
                    return (
                        <tr key={index}>
                            <td>{count++}</td>
                            <td>{item.ID}</td>
                            <td>{item.OldSerialNo}</td>
                            <td>{item.Code}</td>
                            <td>{item.Name}</td>
                            <td>{item.Employee_Type_BE?.Name}</td>
                            <td>{item.Selected_Designation?.Name}</td>
                            <td>{item.GPS_Scale}</td>
                            <td>
                                {item.GrossPay}</td>
                            <td>
                                <input
                                    type="text"
                                    defaultValue={item.GrossPay}
                                    id={`GrossPay_${item.ID}`}
                                    onChange={(e) => handleGrossPayChange(index, e.target.value)}
                                    className="form-control"
                                />
                            </td>
                        </tr>
                    )
                })
            )
        }
        else {
            return (
                <tr>
                    <td colSpan="10"><h4 className="mt-3" style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}>No Data Found</h4></td>
                </tr>
            )
        }

    }


    

    const handleGrossPayChange = (index, newValue) => {
      // Create a copy of the list and update the GrossPay value
      const updatedList = [...listOfLedger];
      updatedList[index].GrossPay = newValue;
    //  setListOfLedger(updatedList);
  
      // Recalculate the total GrossPay
      const newTotalGrossPay = updatedList.reduce((total, item) => total + parseFloat(item.GrossPay || 0), 0);
      setTotalGrossPayNew(newTotalGrossPay);
    };
  

    return (
        <>
            <PayrollMenu />
            <ToastContainer />
            <div className="page-head">

                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/Dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item"><Link to="/PayRoll/PayRollDashboard">PayRoll</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Salary Type Values</li>
                                </ol>
                            </nav>
                            <div className="page-title">Salary Type Values</div>
                        </div>
                        <div className="col-md-6">
                            <div className="page-right-actions">

                                <Link to="javascript:;" data-bs-toggle="collapse" data-bs-target="#collapseFilteres" className="button button-white button-icon">
                                    Show Filters
                                    <i className="ri-filter-3-line"></i>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="collapse show" id="collapseFilteres">
                <div className="container">
                    <div className="card mb-3">
                        <div className="card-body p-md-3">
                            <div className="row align-items-center gy-3">
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Order By</label>
                                        <select className="form-select" required="" onChange={(e) => setOrderBy(e.target.value)} >
                                            <option value="OldSerialNo">OldSerialNo</option>
                                            <option value="ID">Code</option>
                                            <option value="Name">Staff Name</option>
                                            <option value="Emp_Dept">Type/Department</option>
                                            <option value="Designation">Designation</option>
                                            <option value="TotalSalary">Salalry Amount</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Salary Type</label>
                                        <select className="form-select" required="" value={SalaryType} onChange={(e) => setSalaryType(e.target.value)}>
                                            <option value="0">Please Select</option>
                                            <SalaryTypeDropDown selected={SalaryType} />
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-2 offset-md-7">
                                    <input type="button" value="Search" className="button button-primary w-100" />
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
            <div className="page-content">
                <div className="container">
                    <div className="card">
                        <div className="card-body">
                            <table className="table table-theme table-hover">
                                <thead>
                                    <tr>
                                
                                        <th colSpan="8" className="text-end">Total GrossPay:</th>
                                        <th>{Math.round(totalGrossPay)}</th>
                                        <th>{Math.round(totalGrossPayNew)}</th>
                                    
                                    </tr>
                                    <tr>
                                        <th>Sr. No</th>
                                        <th>ID</th>
                                        <th>OldSerialNo</th>
                                        <th>Code</th>
                                        <th>Staff Name</th>
                                        <th>Department Name</th>
                                        <th>Designation</th>
                                        <th>Grade</th>
                                        <th>Salary Value</th>
                                        <th>New Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                

                                    {
                                        loading ?
                                            <tr>
                                                <td className="text-center" colSpan="10">
                                                    <Spinner animation="border" role="status"></Spinner>
                                                </td>
                                            </tr>
                                            :
                                            <>
                                                {renderLedger()}
                                            </>

                                    }
                                    <tr>
                                        <td colSpan="8" className="text-end">Total GrossPay:</td>
                                        <td>{Math.round(totalGrossPay)}</td>
                                        <td>{Math.round(totalGrossPayNew)}</td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <div className="form-group text-end mt-4">
                                <div className="form-group ">
                                    {
                                        loading ?
                                            <Spinner animation="border" role="status"></Spinner>

                                            :
                                            <button
                                                type="submit"
                                                value="Save"
                                                className="button button-primary w-100"
                                                onClick={saveSalaryValues}
                                            >
                                                Update Salary Values
                                            </button>

                                    }
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="card mt-md-3">
                        <div className="card-body">
                            <div className="row justify-content-end">
                                <div className="col-md-2">
                                    {/* <NavLink target="_blank"
                                        to={`/PayRoll/PrintBankSalalryList/?month=${selectedMonth}&year=${selectedYear}&Department=${EmployeeType}&loans=${Loans}&Deductions=${Deductions}`}
                                        className="button button-primary w-100">Print This Report</NavLink> */}

                                </div>
                                <div className="col-md-2">
                                    {/* <button type="button" onClick={() => csvDownload(dataToConvert)} className="button button-primary w-100">Download File</button> */}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SalaryTypeValues;
