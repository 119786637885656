import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import moment from "moment";
import PrintHeader from '../General/PrintHeader';
import { useNavigate, useParams } from 'react-router-dom';
import Page from "../General/Page";


const PrintNewlyAdmittedStudents = () => {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [listOfLedger, setLedger] = useState([]);
    const [isUpdated, setIsUpdated] = useState(false);
    const [selectedMonth, setselectedMonth] = useState('');


    const [WingName, setWingName] = useState("");
    const [ClassName, setClassName] = useState("");
    const [Section_Name, setSection_Name] = useState("");


    const urlParams = new URLSearchParams(window.location.search);

    const lang = urlParams.get('lang');


   
    const SessionId = urlParams.get('SessionID');
    const WingId = urlParams.get('WingID');
    const ClassId = urlParams.get('Class_Id');
    const SectionID = urlParams.get('Section_Id');
    const OrderBy = urlParams.get('OrderBy');
    const StartDate = urlParams.get('StartDate');
    const pageNo = urlParams.get('pageNo');
    const pageSize = urlParams.get('pageSize');







    useEffect(() => {

        document.title = "Print Newly Admitted Students ";

        setIsUpdated(false);
        setLoading(true);


        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            pageNo: 1,
            pageSize: 100000,
            SessionID: SessionId,
            Wing_Id: WingId,
            Class_Id: ClassId,
            Section_Id: SectionID,
            OrderBy: OrderBy,
            StartDate: StartDate,
          };

       //console.log(data);

        var api_config = {
            method: "post",
            url: config.base_url + "Student/GetAdmittedStudents",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        //console.log(data)
        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                setLedger(response.data.ListofRecords);
                setLoading(false);
                setTotalRecords(response.data.totalRecords);

            })
            .catch(function (error) {
                //console.log(error);
            });

    }, []);


    const renderLedger = () => {

        if (listOfLedger != null && listOfLedger.length > 0) {
            let count = 1;
            let countbreak = 1;
            return (
                listOfLedger.map((item, index) => {
                    return (
                        <tr key={index}>
                             <td>
                                {count++}
                            </td>
                            <td>{item.Registration_No}</td>
                            <td>{item.Student_Name}</td>
                            <td>{item.Father_Name}</td>
                            <td>{item.Current_Balance}</td>
                            <td>{item.op_balance_type}</td>
                            <td>{moment(item.Date_Of_Joining).format("DD/MM/YYYY")}</td>
                            <td>{item.Class_Name}</td>
                            <td>{item.Section_Name}</td>
                           
                        </tr>
                    )
                })
            )
        }
        else {
            return (
                <tr>
                    <td colSpan="9"><h4 className="mt-3" style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}>No Data Found</h4></td>
                </tr>
            )
        }

    }

    return (
        <>
            <Page orientation="portrait" size="a4">
                <table className="table table-sm table-bordered table-print white-space-wrap">
                    <thead>
                        <tr>
                            <th colSpan={9}>
                                <PrintHeader title="List Of Students _____________________________________________" />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <th>Sr#</th>
                            <th style={{ width: "70px" }}>RegistrationNo</th>
                            <th style={{ width: "150px" }}>Student Name</th>
                            <th className="text-center"> Father Name</th>
                            <th className="text-center"> Balance</th>
                            <th className="text-center"> Type</th>
                            <th className="text-center">DOA </th>
                            <th className="text-center">Class</th>
                            <th className="text-center">Section </th>
                          
                        </tr>


                        {
                            loading ?
                                <tr>
                                    <td className="text-center" colSpan="9">
                                        <Spinner animation="border" role="status"></Spinner>
                                    </td>
                                </tr>
                                :
                                <>
                                    {renderLedger()}
                                </>

                        }

                    </tbody>
                </table>
            </Page >
        </>
    );
}

export default PrintNewlyAdmittedStudents;
