import React, { useEffect, useState } from 'react';
import config from '../../Config';
import { calculateColumnTotal } from '../General/Utility';
import moment from 'moment';
import { scrollToTop } from '../Shared/ScrollOnTop';

function StudentInfo(props) {

    const currentDate = new Date();
    const month = currentDate.toLocaleString('default', { month: 'long' });
    const [CurrentYear, setCurrentYear] = useState('');
    const [imageError, setImageError] = useState(false);
    const day = currentDate.getDate();
    //const [fee, setFee] = useState([]);

    const RenderHTMLString = (htmlString) => {
        return (
            <div dangerouslySetInnerHTML={{ __html: htmlString }} />
        );
    }

    useEffect(() => {
        //setFee(props.fee);

        const date = new Date();
        const year = date.getFullYear();
        setCurrentYear(year.toString());

    }, []);


    const arrayData = props.fee;


    const renderFeeDetail = () => {
        if (arrayData && arrayData.length > 0) {
            return (
                arrayData.map((item, index) => {
                    if (item.ReceiveableFee != 0) {
                        return (
                            <tr key={index}>
                                <td style={{ width: "50px", border: "1px solid #000000" }} valign="top" align="left">
                                    {item.FeeCode}
                                </td>
                                <td style={{ border: "1px solid #000000" }}>
                                    {item.Description}
                                </td>
                                <td style={{ width: "45px", border: "1px solid #000000" }} valign="top" align="right">
                                    {item.ReceiveableFee}
                                </td>
                            </tr>
                        )
                    }
                })
            )
        }

    }


    const renderYear = () => {
        const classNames = ['xi boys', 'xii boys', 'xi girls', 'xii girls'];

        // Convert props.class to lowercase for comparison
        const lowerCaseClass = props.class.toLowerCase();

        // Check if lowerCaseClass includes any of the valid class names in lowercase
        const isClassValid = classNames.some(className =>
            lowerCaseClass.includes(className)
        );

        if (isClassValid) {
            return (
                <></>
            );
        }

        // Handle the case when no valid class is found
        return null;
    }


    return (
        <>
            <div style={{ padding: '0px 0px 8px 0px', minHeight: '100px' }}>
                <table style={{ width: '100%' }} cellSpacing={0} cellPadding={0}>
                    <tbody>
                        <tr>
                            <td valign="top" align="center">
                                <div style={{ fontSize: '20px' }}>
                                    {/* {props.school} */}
                                    <img src={'https://agsapi.jueducation.com/images/L001.jpg'} width="268" height="52" viewBox="0 0 188 32" alt="" />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ fontSize: '12px', fontWeight: 'bold', padding: '8px 0px 8px 0px' }} align="center">{props.copy}</td>
                        </tr>
                        <tr>
                            <td align="center">
                                <div style={{ fontSize: '12px', paddingBottom: '8px' }}>
                                    {RenderHTMLString(props.bank)}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div style={{ padding: '0px 0px 7px 0px' }}>
                {
                    props.showphoto === true && imageError === false && (
                        <div style={{ width: '100px', height: '95px', right: 0, border: '1px solid', backgroundColor: '#ffffff', zIndex: 1, position: 'absolute' }}>
                            <img src={`${config.StudentImagePath}${props.studentcode}.jpg`}
                                onError={() => {
                                    setImageError(true);

                                }}
                                style={{ height: '100%', width: '100%' }} alt="" />
                        </div>
                    )}

                <table style={{ width: '100%', borderCollapse: 'collapse' }} cellSpacing={2} cellPadding={2}>
                    <tbody>
                        <tr>
                            <td style={{ border: '1px solid #000000', width: '80px' }}>Student Code:</td>
                            <td style={{ border: '1px solid #000000', fontWeight: 'bold' }}>{props.studentcode}</td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid #000000', width: '80px' }}>Class Details:</td>
                            <td style={{ border: '1px solid #000000' }}>
                                {props.wing} - {props.class} - {props.section}
                            </td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid #000000', width: '80px' }}>Month:</td>
                            <td style={{ border: '1px solid #000000' }}>
                                {
                                    props.class == "XI Boys" || props.class == "XI Girls" ?
                                        <span>APRIL-JULY 2024</span>
                                        :
                                        props.class == "XII Boys" || props.class == "XII Girls" ?
                                            <span>May-Aug 2024</span>
                                            :
                                            moment().format("MMM-YYYY")
                                }


                                {/* {renderYear()} */}
                            </td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid #000000', width: '80px', whiteSpace: "nowrap" }}>Student Name:</td>
                            <td style={{ border: '1px solid #000000' }}>
                                {props.name}
                            </td>
                        </tr>
                        <tr>
                            <td style={{ border: '1px solid #000000', width: '80px' }}>Father Name:</td>
                            <td style={{ border: '1px solid #000000' }}>{props.father}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div style={{ padding: '7px 0px 0px 0px' }}>
                <div>
                    <table style={{ width: '100%', borderCollapse: 'collapse' }} cellSpacing={2} cellPadding={2}>
                        <tbody>
                            <tr>
                                <td style={{ width: '50px', border: '1px solid #000000', whiteSpace: "nowrap" }} valign="top" align="left">Fee Code</td>
                                <td style={{ border: '1px solid #000000' }}>Detail</td>
                                <td style={{ width: '45px', border: '1px solid #000000' }} valign="top" align="right">Amount</td>
                            </tr>
                            {renderFeeDetail()}
                        </tbody>
                    </table>
                </div>
            </div>
            <div>
                <table style={{ width: '100%', borderCollapse: 'collapse' }} cellSpacing={2} cellPadding={2}>
                    <tbody>
                        <tr>
                            <td style={{ border: '1px solid #000000' }}>
                                {/* <span style={{ fontWeight: 700 }}>Due Date 20 {moment().format("MMM YYYY")}</span> */}
                                <span style={{ fontWeight: 700 }}>Due Date {props.DueDate}</span>
                            </td>
                            <td style={{ width: '45px', border: '1px solid #000000' }} valign="top" align="right">
                                <span style={{ fontWeight: 700 }}>{calculateColumnTotal(arrayData, 'ReceiveableFee')}</span>
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </>
    );
}

export default StudentInfo;