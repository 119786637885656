import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import QRCode from "react-qr-code";
import StudentInfo from "../FeeSystem/StudentInfo";
import moment from "moment";
import PrintHeader from "../General/PrintHeader";
import Page from "../General/Page";
import { calculateColumnTotal, getMonthName } from "../General/Utility";
import { scrollToTop } from "../Shared/ScrollOnTop";

const PrintDetailSummarySheet = () => {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [listOfLedger, setLedger] = useState([]);
  const [selectedYear, setselectedYear] = useState("");
  const [selectedMonth, setselectedMonth] = useState("");

  useEffect(() => {
    document.title = "Print Detailed Salary Sheet";

    setLoading(true);
    // Get the URL parameters
    const urlParams = new URLSearchParams(window.location.search);

    // Retrieve individual parameter values
    const year = urlParams.get("year");
    const Month = urlParams.get("month");

    setselectedYear(year);
    setselectedMonth(Month);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      Fyear: year,
      SelectedMonth: Month,
    };

    //console.log(data);
    var api_config = {
      method: "post",
      url: config.base_url + "Payroll/GetDetailSalarySheetSummary",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    //console.log(data);

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        setLedger(response.data.SummarySheet);
        setLoading(false);
        //setTotalRecords(response.data.totalRecords);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, []);

  function AcronymConverter(str) {
    // Split the string into words
    const words = str.split(' ');
  
    // Map each word to its first letter and join them
    const acronym = words.map((word) => word.charAt(0).toUpperCase()).join('');
  
    return acronym;
  }

  const renderLedger = () => {
    if (listOfLedger != null && listOfLedger.length > 0) {
      let count = 1;
      let countbreak = 1;
      return listOfLedger.map((item, index) => {
        return (
          <tr key={index} className={countbreak++ == 35 ? "page-break" : ""}>
            <td>{count++}</td>
            <td>{item.Code}</td>
            <td>{item.Employee_Name}</td>
            <td>{AcronymConverter(item.Employee_Type_Name)}</td>
            <td>{item.GPS_Scale}</td>
            <td>{Math.round(item.Salary_Type_1)}</td>
            <td>{Math.round(item.Salary_Type_2)}</td>
            <td>{Math.round(item.Salary_Type_3)}</td>
            <td>{Math.round(item.Salary_Type_4)}</td>
            <td>{Math.round(item.Salary_Type_5)}</td>
            <td>{Math.round(item.Salary_Type_6)}</td>
            <td>{Math.round(item.Salary_Type_7)}</td>
            <td>{Math.round(item.Salary_Type_8)}</td>
            <td>{Math.round(item.Salary_Type_9)}</td>
            <td>{Math.round(item.Salary_Type_10)}</td>
            <td>{Math.round(item.Salary_Type_11)}</td>
            <td>{Math.round(item.Salary_Type_12)}</td>
            <td>{Math.round(item.Salary_Type_13)}</td>
            <td>{Math.round(item.Salary_Type_14)}</td>
            <td>{Math.round(item.Salary_Type_15)}</td>
            <td>{Math.round(item.Salary_Type_16)}</td>
            <td>{Math.round(item.Salary_Type_17)}</td>
            <td>{Math.round(item.Salary_Type_18)}</td>
            <td>{Math.round(item.Salary_Type_19)}</td>
            <td>{Math.round(item.Salary_Type_20)}</td>
            <td>{Math.round(item.Salary_Type_21)}</td>
            <td>{Math.round(item.Salary_Type_22)}</td>
            <td>{Math.round(item.Salary_Type_23)}</td>
            <td>{Math.round(item.Salary_Type_24)}</td>
            <td>{Math.round(item.Salary_Type_25)}</td>
            <td>{Math.round(item.Net_Salary)}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="26">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };

  return (
    <>
      <div className="h200">
        <Page orientation="landscape" size="legal">
          <table className="table table-sm table-bordered table-print" >
            <thead>
              <tr>
                <th colSpan={31}>
                  <PrintHeader
                    title={`Salary Sheet For the Month of ${getMonthName(
                      selectedMonth
                    )} ${selectedYear} `}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td className="text-center" colSpan="31">
                    <Spinner animation="border" role="status"></Spinner>
                  </td>
                </tr>
              ) : (
                <>
                  <tr>
                    <th>Sr.#</th>
                    <th>Code</th>
                    <th>Employee Name</th>
                    <th>Wing</th>
                    <th>GPS</th>
                    <th>1</th>
                    <th>2</th>
                    <th>3</th>
                    <th>4</th>
                    <th>5</th>
                    <th>6</th>
                    <th>7</th>
                    <th>8</th>
                    <th>9</th>
                    <th>10</th>
                    <th>11</th>
                    <th>12</th>
                    <th>13</th>
                    <th>14</th>
                    <th>15</th>
                    <th>16</th>
                    <th>17</th>
                    <th>18</th>
                    <th>19</th>
                    <th>20</th>
                    <th>21</th>
                    <th>22</th>
                    <th>23</th>
                    <th>24</th>
                    <th>25</th>
                    <th>Net Pay</th>
                  </tr>
                  {renderLedger()}
                  <tr >
                    <th colSpan={5}><strong>Totals</strong></th>
                    <th>
                      {Math.round(calculateColumnTotal(listOfLedger, "Salary_Type_1"))}
                    </th>
                    <th>
                      {Math.round(calculateColumnTotal(listOfLedger, "Salary_Type_2"))}
                    </th>
                    <th>
                      {Math.round(calculateColumnTotal(listOfLedger, "Salary_Type_3"))}
                    </th>
                    <th>
                      {Math.round(calculateColumnTotal(listOfLedger, "Salary_Type_4"))}
                    </th>
                    <th>
                      {Math.round(calculateColumnTotal(listOfLedger, "Salary_Type_5"))}
                    </th>
                    <th>
                      {Math.round(calculateColumnTotal(listOfLedger, "Salary_Type_6"))}
                    </th>
                    <th>
                      {Math.round(calculateColumnTotal(listOfLedger, "Salary_Type_7"))}
                    </th>
                    <th>
                      {Math.round(calculateColumnTotal(listOfLedger, "Salary_Type_8"))}
                    </th>
                    <th>
                      {Math.round(calculateColumnTotal(listOfLedger, "Salary_Type_9"))}
                    </th>
                    <th>
                      {Math.round(calculateColumnTotal(listOfLedger, "Salary_Type_10"))}
                    </th>
                    <th>
                      {Math.round(calculateColumnTotal(listOfLedger, "Salary_Type_11"))}
                    </th>
                    <th>
                      {Math.round(calculateColumnTotal(listOfLedger, "Salary_Type_12"))}
                    </th>
                    <th>
                      {Math.round(calculateColumnTotal(listOfLedger, "Salary_Type_13"))}
                    </th>
                    <th>
                      {Math.round(calculateColumnTotal(listOfLedger, "Salary_Type_14"))}
                    </th>
                    <th>
                      {Math.round(calculateColumnTotal(listOfLedger, "Salary_Type_15"))}
                    </th>
                    <th>
                      {Math.round(calculateColumnTotal(listOfLedger, "Salary_Type_16"))}
                    </th>
                    <th>
                      {Math.round(calculateColumnTotal(listOfLedger, "Salary_Type_17"))}
                    </th>
                    <th>
                      {Math.round(calculateColumnTotal(listOfLedger, "Salary_Type_18"))}
                    </th>
                    <th>
                      {Math.round(calculateColumnTotal(listOfLedger, "Salary_Type_19"))}
                    </th>
                    <th>
                      {Math.round(calculateColumnTotal(listOfLedger, "Salary_Type_20"))}
                    </th>
                    <th>
                      {Math.round(calculateColumnTotal(listOfLedger, "Salary_Type_21"))}
                    </th>
                    <th>
                      {Math.round(calculateColumnTotal(listOfLedger, "Salary_Type_22"))}
                    </th>
                    <th>
                      {Math.round(calculateColumnTotal(listOfLedger, "Salary_Type_23"))}
                    </th>
                    <th>
                      {Math.round(calculateColumnTotal(listOfLedger, "Salary_Type_24"))}
                    </th>
                    <th>
                      {Math.round(calculateColumnTotal(listOfLedger, "Salary_Type_25"))}
                    </th>
                    <th>{Math.round(calculateColumnTotal(listOfLedger, "Net_Salary"))}</th>
                  </tr>
                </>
              )}
            </tbody>
          </table>
          <table
            className="table table-sm table-bordered table-print"
            style={{ borderCollapse: "collapse", border: "transparent" }}
          >
            <tbody>
              <tr className="my-5">
                <th style={{ width: "20%", textDecoration: "underline" }}></th>
                <th style={{ width: "20%" }}>Principal</th>
                <th style={{ width: "20%" }}>Admin Officer</th>
                <th style={{ width: "20%" }}>Bursur</th>
                <th style={{ width: "20%" }}>Accountant</th>
              </tr>
            </tbody>
          </table>
        </Page>
      </div>
    </>
  );
};

export default PrintDetailSummarySheet;
