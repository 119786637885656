import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import moment from "moment";
import PrintHeader from '../General/PrintHeader';
import Page from "../General/Page";
import { calculateColumnTotal, getMonthName } from '../General/Utility';


const AppointmentLetterPrint = () => {

    const urlParams = new URLSearchParams(window.location.search);
    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [listOfLedger, setLedger] = useState([]);


    const [FYear, setFYear] = useState(urlParams.get('Year'));
    const [SelectedMonth, setSelectedMonth] = useState(urlParams.get('Month'));



    useEffect(() => {
        document.title = "DJPS School Management System"


        setLoading(true);


        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            pageNo: 1,
            pageSize: 1000,
            SchoolID: 1,
            SelectedMonth: SelectedMonth,
            FYear: FYear,
        };



        //console.log(data);
        var api_config = {
            method: 'post',
            url: config.base_url + 'MonthlyTask/GetMonthDetails',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        //console.log(data);

        axios(api_config)
            .then(function (response) {
                ////console.log(response.data);
                setLedger(response.data.ListofRecords);
                setLoading(false);
                setTotalRecords(response.data.totalRecords);

            })
            .catch(function (error) {
                //console.log(error);
            });

    }, []);

    const renderLedger = () => {
        if (listOfLedger != null && listOfLedger.length > 0) {
            let count = 1;
            return listOfLedger.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{count++}</td>
                        <td>{item.Fee_Code}</td>
                        <td>{item.Description}</td>
                        <td>{item.Debit}</td>
                        <td>{item.Credit}</td>
                        <td>{item.NoofStudents}</td>
                    </tr>
                );
            });
        } else {
            return (
                <tr>
                    <td colSpan="6">
                        <h4
                            className="mt-3"
                            style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
                        >
                            No Data Found
                        </h4>
                    </td>
                </tr>
            );
        }
    };


    return (
        <>
            <Page orientation="portrait" size="a4" Class='appointment-letter'>

                <table className="table print-main-table">
                    <thead>
                        <tr>
                            <td >
                                <PrintHeader title={`Appointment Letter`} />
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div className="row">
                                    <div className="col-6 offset-6">
                                        District Jinnah Public School & College <br />
                                        Mandi Bahauddin.<br />
                                        Telephone: 0546-650087-88<br />
                                        Admin / Empl Staff /<br />

                                    </div>
                                    <div className="col-12">
                                        <p>To</p>
                                        Ms. Attish Ashraf<br />
                                        d/o Muhammad Ashraf Ali,<br />
                                        r/o Lalazar Colony,<br />
                                        Mandi Bahaudin<br />
                                    </div>
                                    <div className="col-2">
                                        <b> Subject: -</b>
                                    </div>
                                    <div className="col-10">
                                        <b>
                                            TEMPORARY APPOINTMENT AS TEACHER (AGAINST LEAVE VACENCY OF TEACHER Tayyaba Kanwal, MA English who is leave from 6 May to 5 June 2024)
                                        </b>
                                    </div>
                                    <div className="col-12">
                                        <ol>
                                            <li>You, Ms. Attish Ashraf d/o Muhammad Ashraf Ali, r/o Lalazar Colony, Mandi Bahauddin (MA Urdu), are hereby offered the post of Temporary Teacher in District Jinnah Public Higher Secondary School Mandi Bahauddin w.e.f 6 May 2024 on the following terms and conditions: -</li>
                                        </ol>
                                        <table className="table">
                                            <tbody>
                                                <tr>
                                                    <td className="text-nowrap">a.   Period of contract:	</td>
                                                    <td colSpan={2}>6 May 2024 to 31 May 2024</td>
                                                </tr>
                                                <tr>
                                                    <td>b.   Salary:	</td>
                                                    <td>i. Basic Salary </td>
                                                    <td>Rs.	13138.00</td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td>ii.	Adhoc relief Rs.50% of Running Basic Salary</td>
                                                    <td>Rs.	  6568.08</td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td>iii.	House Rent Allowance @ 30% of Basic Salary</td>
                                                    <td>Rs.	  3941.03</td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td>iv.	Adhoc relief 10% of Running Basic Pay</td>
                                                    <td>Rs.	  1313.08</td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td>V. Medical Allowance		</td>
                                                    <td>Rs.	  1809.00</td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td>Vi. Conveyance Allowance 	</td>
                                                    <td>Rs.	  1404.02</td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td>vii.	Public School Allowance 10% of Running Basic Pay</td>
                                                    <td>Rs.	  1313.08</td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td>vii.	Dearness Allowance 20% of Running Basic Pay</td>
                                                    <td>Rs.	  2627.00</td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td>viii.	Adhoce Relief Allowance 30% of  Running Basic Pay</td>
                                                    <td>Rs.	  3941.04</td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <th>Total Emoluments: -</th>
                                                    <th>Rs.      36,057.00</th>
                                                </tr>
                                                <tr>
                                                    <td>c.   Leave:	</td>
                                                    <td colSpan={2}>1x Casual leave with pay is allowed in a month.</td>
                                                </tr>
                                                <tr>
                                                    <td className="text-nowrap">d. Performance Evaluation:  </td>
                                                    <td colSpan={2}>Performance will be evaluated by the Principal and appointment may be terminated prematurely in case of poor performance.</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <ol start={2}>
                                            <li>
                                                Temporary Appointment as Teacher has been made under the provision of      Rule 39 of DJPST A & F Rules – 2009 against leave vacancy of Teacher Tayyaba Kanwal, MA English (from 6 May 24 to 5 June 24). Salary for closure period due to COVID or any other reason on order of government will not be paid.
                                            </li>
                                        </ol>
                                    </div>
                                    <div className="col-4 offset-8">
                                        <b>
                                            Principal <br />
                                            Lt. Col. (R) Ata Ur Rehman
                                        </b>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </Page>
        </>
    );
}

export default AppointmentLetterPrint;