import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import PrintHeader from "../General/PrintHeader";
import Page from "../General/Page";
import RightsCheck from "../General/RightsCheck";

const PrintTrialBalanceLevelWise = () => {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );

  const [DefaultFYear, setDefaultFYear] = useState(
    localStorage.getItem("DefaultFYear")
  );

  const [loading, setLoading] = useState(false);
  const [listOfLedger, setLedger] = useState([]);
  const [OpeningDebitTotal, setOpeningDebitTotal] = useState("");
  const [OpeningCreditTotal, setOpeningCreditTotal] = useState("");
  const [CurrentDebitTotal, setCurrentDebitTotal] = useState("");
  const [CurrentCreditTotal, setCurrentCreditTotal] = useState("");
  const [ClosingDebitTotal, setClosingDebitTotal] = useState("");
  const [ClosingCreditTotal, setClosingCreditTotal] = useState("");
  const [StartDate, setStartDate] = useState("");
  const [EndDate, setEndDate] = useState("");
  const [zeroAccounts, setZeroAccounts] = useState(true);




  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10000);
  const [totalRecords, setTotalRecords] = useState(0);


  const urlParams = new URLSearchParams(window.location.search);

  const Account_Code = urlParams.get('AccountCode');
  const Account_Description = urlParams.get('AccountName');
  const AccountLevel = urlParams.get('Accountlevel');


  useEffect(() => {
    document.title ="Print Trial Balance Level Wise"
    setLoading(true);

 


    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      PageNo: pageNo,
      PageSize: 1000,
      FYear: DefaultFYear,
      StartDate: StartDate,
      EndDate: EndDate,
      AccountLevel: AccountLevel,
      ShowZeroAccounts: zeroAccounts,
      AccountCode: Account_Code,
      Accountname:Account_Description,
    };

    //console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Accounts/GetTrialBalance",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        if (response.data.status_code != 0) {
          setLedger(response.data.ListofRecords);
          setTotalRecords(response.data.totalRecords);
          setOpeningDebitTotal(response.data.OpeningDebitTotal);
          setOpeningCreditTotal(response.data.OpeningCreditTotal);
          setCurrentDebitTotal(response.data.CurrentDebitTotal);
          setCurrentCreditTotal(response.data.CurrentCreditTotal);
          setClosingDebitTotal(response.data.ClosingDebitTotal);
          setClosingCreditTotal(response.data.ClosingCreditTotal);
        } else {
          setLedger(null);
          setTotalRecords(0);
        }

        setLoading(false);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, [
    pageNo,
    pageSize,
    DefaultFYear,
    StartDate,
    EndDate,
    zeroAccounts,
  ]);

  const renderLedger = () => {
    if (listOfLedger != null && listOfLedger.length > 0) {
      let count = 1;
      return listOfLedger.map((item, index) => {
        return (
          <tr key={index}>
            <td>{item.Selected_Account.Account_Code}</td>
            <td>{item.Selected_Account.Account_Description}</td>
            <td className=" text-right">{item.OpeningDebit}</td>
            <td className=" text-right">{item.OpeningCredit}</td>
            <td className=" text-right">{item.CurrentDebit}</td>
            <td className=" text-right">{item.CurrentCredit}</td>
            <td className=" text-right">{item.ClosingDebit}</td>
            <td className=" text-right">{item.ClosingCredit}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="8">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };


  return (
    <>
      <RightsCheck />
      <Page orientation="portrait" size="a4">
        <table className="table table-sm table-bordered table-print">
          <thead>
            <tr>
              <th colSpan={20}>
                <PrintHeader title={`List of Trial Balance Level Wise`} />
              </th>
            </tr>
            <tr>
            <th colSpan={10} className="text-end"> Total Records : {totalRecords} </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td className="text-center" colSpan="8">
                  <Spinner animation="border" role="status"></Spinner>
                </td>
              </tr>
            ) : (
              <>
                 <tr>
                      <th>Account Code</th>
                      <th>Account Name</th>
                      <th colSpan="2" className="text-center">
                        Opening
                      </th>
                      <th colSpan="2" className="text-center">
                        Current
                      </th>
                      <th colSpan="2" className="text-center">
                        Closing
                      </th>
                    </tr>

                    <tr>
                      <th></th>
                      <th></th>
                      <th className="text-center ">Debit</th>
                      <th className="text-center ">Credit</th>
                      <th className="text-center ">Debit</th>
                      <th className="text-center ">Credit</th>
                      <th className="text-center ">Debit</th>
                      <th className="text-center ">Credit</th>
                    </tr>
                {renderLedger()}
              </>
            )}
          </tbody>
        </table>
      </Page>
    </>
  );
};

export default PrintTrialBalanceLevelWise;
