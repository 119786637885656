import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import QRCode from "react-qr-code";
import StudentInfo from "./StudentInfo";
import { calculateColumnTotal } from "../General/Utility";
import { scrollToTop } from "../Shared/ScrollOnTop";
import moment from "moment";
import { useLocation } from "react-router-dom";

function PrintChallan(props) {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [loading, setLoading] = useState(false);
  const [voucheres, setVouchers] = useState([]);
  const [SchoolName, setSchoolName] = useState("");
  const [Site, setSite] = useState("");

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const addBankCopy = params.get("addBankCopy");

  const [bank, setBank] = useState("");
  const [DueDate, setDueDate] = useState("");

  useEffect(() => {
    document.title = "Print Voucher";
    setLoading(true);
    // Get the URL parameters
    const urlParams = new URLSearchParams(window.location.search);

    // Retrieve individual parameter values
    const classId = urlParams.get("Class_Id");
    const concessionRuleId = urlParams.get("ConcessionRuleID");
    const orderBy = urlParams.get("OrderBy");
    const registrationNo = urlParams.get("Registration_No");
    const sectionId = urlParams.get("Section_Id");
    const SessionId = urlParams.get("SessionID");
    const wingId = urlParams.get("Wing_Id");
    const Description = urlParams.get("Description");
    const valuesSelected = urlParams.get("valuesSelected");
    const AdvanceMonths = urlParams.get("AdvanceMonths");
    const ShowHolidayImage = urlParams.get("ShowHolidayImage");
    const noRedCresent = urlParams.get("noRedCresent");
    const Amount = urlParams.get("amount");
    const Student_ID = urlParams.get("Student_ID");
    const StudentType = urlParams.get("StudentType");

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      SelectedStudents: valuesSelected,
      SessionID: SessionId,
      Class_Id: classId,
      Section_Id: sectionId,
      ConcessionRuleID: concessionRuleId,
      Registration_No: registrationNo,
      AdvanceMonths: AdvanceMonths,
      ShowHolidayImage: ShowHolidayImage,
      noRedCresent: noRedCresent,
      Description: Description,
      OrderBy: orderBy,
      Amount: Amount,
      Student_ID: Student_ID,
      StudentType: StudentType,
    };

    //console.log(data);
    var api_config = {
      method: "post",
      url: config.base_url + "ADLedger/PrintVouchersWithAD",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    //console.log(data);

    axios(api_config)
      .then(function (response) {
        //console.log(response);
        if (response.data.status_code == 1) {
          setVouchers(response.data.ListofRecords);
          setSchoolName(response.data.SchoolName);
          setBank(response.data.FeeVoucherStatement);
          setSite(response.data.Website);
          setDueDate(
            moment(
              response.data.ListofRecords[0].Challan_DueDate.split("T")[0]
            ).format("DD MMM YYYY")
          );
        }

        setLoading(false);
      })
      .catch(function (error) {
        ////console.log(error);
      });
  }, []);

  const vouchersWithAddBankCopy = voucheres.map((item) => ({
    ...item,
    addBankCopy, // Assuming you've defined this above
  }));

  if (loading) {
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spinner animation="border" role="status"></Spinner>
      </div>
    );
  } else if (voucheres.length > 0) {
    return vouchersWithAddBankCopy.map((item, index) => {
      //console.log("my", item);
      //console.log("bank", item.addBankCopy);

      return (
        <>
          <div
            key={index}
            style={{
              backgroundColor: "#FFF",
              color: "#000",
              width: "100%",
              fontSize: "11px",
              display: "flex",
              justifyContent: "center",
            }}
            align="right"
          >
            <div
              style={{
                width: "900px",
                height: "750px",
                lineHeight: "18px",
                marginTop: "10px",
                pageBreakAfter: "always",
              }}
            >
              <table style={{ width: "800px" }} cellSpacing={0} cellPadding={0}>
                <tbody>
                  <tr>
                    {item.addBankCopy === "true" ? (
                      <td
                        style={{
                          width: "340px",
                          paddingRight: "30px",
                        }}
                        valign="top"
                        align="left"
                      >
                        <div>
                          <StudentInfo
                            school={SchoolName}
                            studentcode={
                              item.Registration_No == ""
                                ? item.Student_ID + " (New Registration)"
                                : item.Registration_No
                            }
                            class={item.Class_Name}
                            section={item.Section_Name}
                            name={item.Student_Name}
                            wing={item.Wing_Name}
                            father={item.Father_Name}
                            bank={bank}
                            copy="Bank Copy"
                            fee={item.ReducedADLedger}
                            DueDate={DueDate}
                          />
                          <div style={{ padding: "10px 0px 16px 0px" }}>
                            <table
                              style={{ width: "100%" }}
                              cellSpacing={0}
                              cellPadding={0}
                            >
                              <tbody>
                                <tr>
                                  <td>NOT TO BE DEPOSITED AFTER DUE DATE</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div
                            style={{
                              padding: "10px 0px 16px 0px",
                              marginTop: "110px",
                            }}
                            className=" text-center"
                          >
                            <p>Powered By: www.softsolutions.com</p>
                          </div>
                        </div>
                      </td>
                    ) : null}

                    {item.addBankCopy === "true" ? (
                      <div
                        style={{
                          height: "700px",
                          borderLeft: "2px dashed black",
                          margin: "0 10px",
                        }}
                      ></div>
                    ) : null}
                    <td style={{ width: "340px" }} valign="top" align="left">
                      <div>
                        <StudentInfo
                          school={SchoolName}
                          studentcode={
                            item.Registration_No == ""
                              ? item.Student_ID + " (New Registration)"
                              : item.Registration_No
                          }
                          class={item.Class_Name}
                          section={item.Section_Name}
                          name={item.Student_Name}
                          wing={item.Wing_Name}
                          father={item.Father_Name}
                          bank={bank}
                          copy="School Copy"
                          fee={item.ReducedADLedger}
                          DueDate={DueDate}
                        />
                        <div style={{ padding: "10px 0px 16px 0px" }}>
                          <table
                            style={{ width: "100%" }}
                            cellSpacing={0}
                            cellPadding={0}
                          >
                            <tbody>
                              <tr>
                                <td>NOT TO BE DEPOSITED AFTER DUE DATE</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <div style={{ padding: "0px 0px 8px 0px" }}>
                          <table
                            style={{ width: "100%" }}
                            cellSpacing={0}
                            cellPadding={0}
                          >
                            <tbody>
                              <tr>
                                <td
                                  style={{ height: "50px" }}
                                  valign="top"
                                  align="left"
                                >
                                  Officer
                                </td>
                                <td valign="top" align="center">
                                  <div style={{ padding: "0px 0px 0px 0px" }}>
                                    <div
                                      style={{
                                        height: "auto",
                                        margin: "2px auto 0 auto",
                                      }}
                                    >
                                      <QRCode
                                        size={30}
                                        style={{
                                          height: "auto",
                                          maxWidth: "100%",
                                          width: "100%",
                                        }}
                                        value={`${
                                          item.Registration_No == ""
                                            ? item.Student_ID + "N"
                                            : item.Registration_No
                                        } ${calculateColumnTotal(
                                          item.ReducedADLedger,
                                          "PendingFee"
                                        )}`}
                                        //value={`${item.Registration_No} ${calculateColumnTotal(item.ReducedLedger, 'Debit')}`}
                                        viewBox={`0 0 256 256`}
                                      />
                                    </div>
                                  </div>
                                </td>
                                <td valign="top" align="right">
                                  Cashier
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <div className="text-center">
                          <p>Powered By: www.softsolutions.com</p>
                        </div>
                      </div>
                    </td>
                    <div
                      style={{
                        height: "700px",
                        borderLeft: "2px dashed black",
                        margin: "0 10px",
                      }}
                    ></div>
                    <td
                      style={{ width: "340px", paddingLeft: "30px" }}
                      valign="top"
                      align="left"
                    >
                      <div>
                        <div>
                          <StudentInfo
                            school={SchoolName}
                            studentcode={
                              item.Registration_No == ""
                                ? item.Student_ID + " (New Registration)"
                                : item.Registration_No
                            }
                            class={item.Class_Name}
                            section={item.Section_Name}
                            name={item.Student_Name}
                            wing={item.Wing_Name}
                            father={item.Father_Name}
                            bank={bank}
                            showphoto={true}
                            copy="Student Copy"
                            fee={item.ReducedADLedger}
                            DueDate={DueDate}
                          />
                          <div style={{ padding: "10px 0px 16px 0px" }}>
                            <table
                              style={{ width: "100%" }}
                              cellSpacing={0}
                              cellPadding={0}
                            >
                              <tbody>
                                <tr>
                                  <td>NOT TO BE DEPOSITED AFTER DUE DATE</td>
                                </tr>
                              </tbody>
                            </table>
                            <table
                              style={{
                                width: "100%",
                                border: "1px solid #000000",
                              }}
                              cellSpacing={2}
                              cellPadding={2}
                            >
                              <tbody>
                                <tr>
                                  <td align="left">
                                    For Online Services visit{" "}
                                    <strong>{Site}</strong>
                                  </td>
                                  {/* <td>User Name :
                                                                        <br />
                                                                        Password :
                                                                    </td>
                                                                    <td>
                                                                        015431-00
                                                                        <br />
                                                                    </td> */}
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div style={{ padding: "0px 0px 10px 0px" }}>
                            <table
                              style={{ width: "100%" }}
                              cellSpacing={0}
                              cellPadding={0}
                            >
                              <tbody>
                                <tr>
                                  <td align="left">Officer</td>
                                  <td align="right">Cashier</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          {item.ReducedADLedger.length <= 4 ? (
                            <div
                              style={{
                                width: "350px",
                                height: "178px",
                                marginTop: "3px",
                              }}
                              className="text-left"
                            >
                              <h6>Notes</h6>
                              <ol
                                style={{
                                  border: "1px black solid",
                                }}
                              >
                                <li>
                                  In case of any query please contact{" "}
                                  <strong>047-7624477</strong>,{" "}
                                  <strong>047-7624377</strong>
                                </li>
                                <li>
                                  Late fee fine of Rs. 50 per day will be
                                  charged after the due date.
                                </li>
                                <li>
                                  Name of the student will be struck off the
                                  school/college roll if the fee is not
                                  deposited within 15 days after the due date.
                                </li>
                                <li>
                                  Fee once received is non-refundable &
                                  non-transferable.
                                </li>
                                <li>
                                  It will be mandatory to submit the left 15
                                  days before leaving the transport. Otherwise,
                                  the withdrawal case will not be accepted.
                                </li>
                                <li>
                                  Fee will be submitted in the college accounts
                                  office during official school/college time,
                                  till further instructions.
                                </li>
                              </ol>
                            </div>
                          ) : null}
                          <div
                            style={{
                              padding: "10px 0px 16px 0px",
                              marginTop: "57px",
                            }}
                            className=" text-center"
                          >
                            <p>Powered By: www.softsolutions.com</p>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </>
      );
    });
  }
}

export default PrintChallan;
