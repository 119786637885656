import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import PrintHeader from "../General/PrintHeader";
import Page from "../General/Page";

const PrintGeneralLedger = () => {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [DefaultFYear, setDefaultFYear] = useState(
    localStorage.getItem("DefaultFYear")
  );
  const [loading, setLoading] = useState(false);
  const [listOfLedger, setLedger] = useState([]);
  

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  let params = useParams();
  const [AccountName, setAccountName] = useState("");

   const urlParams = new URLSearchParams(window.location.search);

   const AccountCode = urlParams.get('Accountcode');
   const StartDate = urlParams.get('startdate');
   const EndDate = urlParams.get('EndDate');


  useEffect(() => {
    document.title="Print General Ledger"
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      PageNo: pageNo,
      PageSize: pageSize,
      FYear: DefaultFYear,
      AccountCode: AccountCode,
      startDate: StartDate,
      EndDate: EndDate,
    };

    //console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Accounts/GetGeneralLedger",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        if (response.data.status_code != 0) {
          setLedger(response.data.ListofRecords);
          setAccountName(response.data.AccountName);
          setTotalRecords(response.data.totalRecords);
        } else {
          setLedger(null);
          setTotalRecords(0);
        }
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, [pageNo, pageSize, DefaultFYear, AccountCode, StartDate, EndDate]);

  const renderLedger = () => {
    if (listOfLedger != null && listOfLedger.length > 0) {
      let count = 1;
      return listOfLedger.map((item, index) => {
        return (
          <tr key={index}>
            <td>{count++ + pageSize * (pageNo - 1)}</td>
            <td>
              {moment(item.Selected_Voucher.Voucher_Date).format(
                config.date_format_input
              )}
            </td>
            <td>{item.Selected_Voucher.Voucher_Number}</td>
            <td>
              {item.Selected_Voucher.Voucher_Number === "OPV"
                ? "Opening Balance"
                : item.Remarks === ""
                ? item.Selected_Voucher.Remarks
                : item.Remarks}
            </td>
            <td> {item.Dr_Amt}</td>
            <td>{item.Cr_Amt}</td>
            <td>{item.Balance}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="7">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };
  return (
    <>
      <Page orientation="portrait" size="a4">
        <PrintHeader title={`General Ledger for ` + AccountCode + ' ' + AccountName } />
        <table className="table table-sm table-bordered table-print">
          <thead>
            <tr>
              <th>Sr#</th>
              <th>Dated</th>
              <th>Voucher No</th>
              <th>Narration</th>
              <th>Debit</th>
              <th>Credit</th>
              <th>Balance</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td className="text-center" colSpan="6">
                  <Spinner animation="border" role="status"></Spinner>
                </td>
              </tr>
            ) : (
              <>{renderLedger()}</>
            )}
          </tbody>
        </table>
      </Page>
    </>
  );
};

export default PrintGeneralLedger;
