import React from 'react';
import { NavLink } from 'react-router-dom';
import config from "../../Config";
import { useState, useEffect } from "react";


function Footer(props) {
    const [baseURL, setBaseURL] = useState(config.base_url);

    const FYear = localStorage.getItem("DefaultFYear");
    const DefaultSessionName = localStorage.getItem("DefaultSessionName");

    return (
        <>
            <footer>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-2">
                            &copy; 2024 - All Rights Reserved.
                        </div>
                        <div className="col-md-2 d-md-block d-none">
                            {baseURL}
                        </div>
                        <div className="col-md-2 d-md-block d-none">
                            {DefaultSessionName}
                        </div>
                        <div className="col-md-2 d-md-block d-none">
                            Current working year is <NavLink to="/Accounts/ChangeYear"><strong>{FYear}</strong></NavLink>
                        </div>


                        <div className="col-md-3 text-md-end">
                            Powered by: SoftSolutions.com
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Footer;