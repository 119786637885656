import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import NavDropdown from "react-bootstrap/NavDropdown";
import TeacherRightsCheck from "./TeacherRightsCheck";
import { scrollToTop } from "../Shared/ScrollOnTop";
function TeacherMenu(props) {
  return (
    <>
      <TeacherRightsCheck />
      <nav className="navbar navbar-expand-lg navbar-module MenuSticky">
        <div className="container-fluid">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#NavbarSecondary"
            aria-controls="NavbarSecondary"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="NavbarSecondary">
            <ul className="navbar-nav m-auto">
              <li className="nav-item">
                <NavDropdown title="Profile">
                  {/* <NavLink
                    onClick={scrollToTop}
                    className="dropdown-item"
                    to="/teacher/UpdateProfile"
                  >
                    View Profile
                  </NavLink> */}
                  <NavLink
                    onClick={scrollToTop}
                    className="dropdown-item"
                    to="/teacher/change-password"
                  >
                    Change Password
                  </NavLink>
                  <NavLink
                    onClick={scrollToTop}
                    className="dropdown-item"
                    to="/teacher/update-photo"
                  >
                    Update Photo
                  </NavLink>
                </NavDropdown>
              </li>
              <li className="nav-item">
                <NavDropdown title="Exams">
                  <NavLink
                    onClick={scrollToTop}
                    className="dropdown-item"
                    to="/teacher/section-incharge"
                  >
                    Marks Entry as Section Incharge
                  </NavLink>
                  <NavLink
                    onClick={scrollToTop}
                    className="dropdown-item"
                    to="/teacher/subject-teacher"
                  >
                    Marks Entry as Subject Teacher
                  </NavLink>
                </NavDropdown>
              </li>
              <li className="nav-item">
                <NavLink
                  onClick={scrollToTop}
                  className="nav-link"
                  to="/teacher/manage-sections"
                >
                  Manage Sections
                </NavLink>
              </li>
              <li className="nav-item">
                <NavDropdown title="Attendence">
                  <NavLink
                    onClick={scrollToTop}
                    className="dropdown-item"
                    to="/teacher/MarkStudentAttendance"
                  >
                    Mark Attendence
                  </NavLink>
                  <NavLink
                    onClick={scrollToTop}
                    className="dropdown-item"
                    to="/teacher/atsummary"
                  >
                    Attendence Summary
                  </NavLink>
                </NavDropdown>
              </li>
              <li className="nav-item">
                <NavLink
                  onClick={scrollToTop}
                  className="nav-link"
                  to="/teacher/ViewPaySlips"
                >
                  Pay Slips
                </NavLink>
              </li>
              


            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default TeacherMenu;
