import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import { NavLink, Link } from "react-router-dom";
import attendence_yellow from '../../assets/images/icons/attendence_yellow.svg'
import attendence_red from '../../assets/images/icons/attendence_red.svg'
import moment from "moment";
import user from '../../assets/images/user.png'
import PayrollMenu from './PayrollMenu.js';
import { scrollToTop } from '../Shared/ScrollOnTop';
import maleprofile from "../../assets/images/maleprofile.png"
import femaleprofile from "../../assets/images/femaleprofile.png"
import Spinner from "react-bootstrap/Spinner";


function PayrollDashboard(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [dData, setDashboardData] = useState({});
    const [loading, setLoading] = useState(false);
    const [dDataLoading, setdDataLoading] = useState(false);
    const [ListofEmpTypes, setListofEmpTypes] = useState([]);
    const [ListofDesignations, seListofDesignations] = useState([]);
    const [listofBirthdays, setBirthday] = useState([]);

    useEffect(() => {
        document.title = "Student Dashboard";

        setLoading(true);
        setdDataLoading(true);
        const dashboardParms = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'Payroll/GetEmployeeDashboard',
            headers: {
                'Content-Type': 'application/json'
            },
            data: dashboardParms
        };

        axios(api_config)
            .then(function (response) {

                setDashboardData(response.data);
                setListofEmpTypes(response.data.ListofEmpTypes);
                seListofDesignations(response.data.ListofDesignations);
                setBirthday(response.data.ListofBirthday);
                //console.log(response.data)
                setdDataLoading(false);
                setLoading(false);

            })
            .catch(function (error) {
                //console.log(error);
            });





    }, []);

    const renderEmployees = () => {

        if (ListofEmpTypes != null && ListofEmpTypes.length > 0) {
            return (
                ListofEmpTypes.map((item, index) => {
                    return (
                        <li key={index}> <div className="left">
                            <div className="icon">
                                <span>{index + 1}</span>
                            </div>
                            <p>{item.Name}</p>
                        </div>
                            <div className="right">
                                {item.TotalStaff}
                            </div></li>
                    )
                })
            )
        }
        else {
            return (
                <tr>
                    <td colSpan="8"><h4 className="mt-3" style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}>No Data Found</h4></td>
                </tr>
            )
        }

    }

    const renderDesignations = () => {

        if (ListofDesignations != null && ListofDesignations.length > 0) {
            return (
                ListofDesignations.map((item, index) => {
                    return (
                        <li key={index}> <div className="left">
                            <div className="icon">
                                <span>{index + 1}</span>
                            </div>
                            <p>{item.Name}</p>
                        </div>
                            <div className="right">
                                {item.TotalStaff}
                            </div></li>
                    )
                })
            )
        }
        else {
            return (

                <h4 className="mt-3" style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}>No Data Found</h4>

            )
        }

    }


    const renderBirthdays = () => {

        if (listofBirthdays != null && listofBirthdays.length > 0) {
            //console.log(listofBirthdays);
            return (
                listofBirthdays.map((item, index) => {
                    return (
                        <li key={index}>
                            <div className="left">

                                <div className="icon">
                                    <img
                                        className="rounded-circle"
                                        style={{ width: "60px", height: "60px" }}
                                        src={config.StaffImagePath + item.Code + ".jpg"}
                                        alt=""
                                        onError={(e) => {
                                            if (item.Gender === true) {
                                                e.target.src = maleprofile;

                                            }
                                            else
                                                e.target.src = femaleprofile;
                                        }}
                                    />
                                </div>

                                <div className="lable">
                                    <h5>{item.Name}</h5>
                                    <p>
                                        {item.Employee_Type_BE.Name}-
                                        {item.Selected_Designation.Name}
                                    </p>
                                </div>
                            </div>
                            <div className="right">
                                <h4>{item.TotalWorkingDays} Years</h4>
                            </div>
                        </li>
                    )
                })
            )
        }
        else {
            return (

                <h4 className="mt-3" style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}>No Data Found</h4>

            )
        }

    }

    return (
        <>
            <PayrollMenu />

            <div className="page-head">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/Dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Employees</li>
                                </ol>
                            </nav>
                            <div className="page-title">Employees</div>
                        </div>
                        <div className="col-md-6">
                            <div className="page-right-actions">
                                <Link to="/PayRoll/ManageEmployee" className="button button-white button-icon">
                                    Search Employees
                                    <i className="ri-search-line"></i>
                                </Link>
                                <Link to="/Payroll/AddNewEmployee" className="button button-white button-icon">
                                    Add Employee
                                    <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2 10.999L11 11C12.0538 11 12.9181 11.8155 12.9945 12.8507L13 13V14.5C12.999 18 9.284 19 6.5 19C3.77787 19 0.164695 18.044 0.00545406 14.7296L0 14.5V12.999C0 11.9452 0.816397 11.0809 1.85081 11.0045L2 10.999ZM13.22 11H18C19.0538 11 19.9181 11.8164 19.9945 12.8508L20 13V14C19.999 17.062 17.142 18 15 18C14.32 18 13.569 17.904 12.86 17.678C13.196 17.292 13.467 16.851 13.662 16.351C14.205 16.476 14.715 16.5 15 16.5L15.2665 16.494C16.2518 16.4509 18.3529 16.1306 18.4927 14.205L18.5 14V13C18.5 12.7547 18.3222 12.5504 18.0896 12.5081L18 12.5H13.949C13.865 11.9986 13.6554 11.5432 13.3545 11.1598L13.22 11H18H13.22ZM2 12.499L1.89934 12.509C1.77496 12.5343 1.69 12.6018 1.646 12.645C1.6028 12.689 1.53528 12.7733 1.51 12.898L1.5 12.999V14.5C1.5 15.509 1.95 16.222 2.917 16.742C3.74315 17.1869 4.91951 17.4563 6.18258 17.4951L6.5 17.5L6.8174 17.4951C8.08035 17.4563 9.25592 17.1869 10.083 16.742C10.9886 16.2545 11.4416 15.5974 11.4947 14.6849L11.5 14.499V13C11.5 12.7547 11.3222 12.5504 11.0896 12.5081L11 12.5L2 12.499ZM6.5 0C8.985 0 11 2.015 11 4.5C11 6.985 8.985 9 6.5 9C4.015 9 2 6.985 2 4.5C2 2.015 4.015 0 6.5 0ZM15.5 2C17.433 2 19 3.567 19 5.5C19 7.433 17.433 9 15.5 9C13.567 9 12 7.433 12 5.5C12 3.567 13.567 2 15.5 2ZM6.5 1.5C4.846 1.5 3.5 2.846 3.5 4.5C3.5 6.154 4.846 7.5 6.5 7.5C8.154 7.5 9.5 6.154 9.5 4.5C9.5 2.846 8.154 1.5 6.5 1.5ZM15.5 3.5C14.397 3.5 13.5 4.397 13.5 5.5C13.5 6.603 14.397 7.5 15.5 7.5C16.603 7.5 17.5 6.603 17.5 5.5C17.5 4.397 16.603 3.5 15.5 3.5Z" fill="#003970" />
                                    </svg>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="page-content">
                <div className="container">
                    <div className="row gy-3">
                        <div className="col-md-7">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title">Summary</h4>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="icon-box-1">
                                                <p>Total Staff</p>
                                                <h4>{dData.TotalStaff}</h4>
                                                <div className="icon">
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M4 13.999L13 14C14.0538 14 14.9181 14.8155 14.9945 15.8507L15 16V17.5C14.999 21 11.284 22 8.5 22C5.77787 22 2.1647 21.044 2.00545 17.7296L2 17.5V15.999C2 14.9452 2.8164 14.0809 3.85081 14.0045L4 13.999ZM15.22 14H20C21.0538 14 21.9181 14.8164 21.9945 15.8508L22 16V17C21.999 20.062 19.142 21 17 21C16.32 21 15.569 20.904 14.86 20.678C15.196 20.292 15.467 19.851 15.662 19.351C16.205 19.476 16.715 19.5 17 19.5L17.2665 19.494C18.2518 19.4509 20.3529 19.1306 20.4927 17.205L20.5 17V16C20.5 15.7547 20.3222 15.5504 20.0896 15.5081L20 15.5H15.949C15.865 14.9986 15.6554 14.5432 15.3545 14.1598L15.22 14H20H15.22ZM4 15.499L3.89934 15.509C3.77496 15.5343 3.69 15.6018 3.646 15.645C3.6028 15.689 3.53528 15.7733 3.51 15.898L3.5 15.999V17.5C3.5 18.509 3.95 19.222 4.917 19.742C5.74315 20.1869 6.91951 20.4563 8.18258 20.4951L8.5 20.5L8.8174 20.4951C10.0803 20.4563 11.2559 20.1869 12.083 19.742C12.9886 19.2545 13.4416 18.5974 13.4947 17.6849L13.5 17.499V16C13.5 15.7547 13.3222 15.5504 13.0896 15.5081L13 15.5L4 15.499ZM8.5 3C10.985 3 13 5.015 13 7.5C13 9.985 10.985 12 8.5 12C6.015 12 4 9.985 4 7.5C4 5.015 6.015 3 8.5 3ZM17.5 5C19.433 5 21 6.567 21 8.5C21 10.433 19.433 12 17.5 12C15.567 12 14 10.433 14 8.5C14 6.567 15.567 5 17.5 5ZM8.5 4.5C6.846 4.5 5.5 5.846 5.5 7.5C5.5 9.154 6.846 10.5 8.5 10.5C10.154 10.5 11.5 9.154 11.5 7.5C11.5 5.846 10.154 4.5 8.5 4.5ZM17.5 6.5C16.397 6.5 15.5 7.397 15.5 8.5C15.5 9.603 16.397 10.5 17.5 10.5C18.603 10.5 19.5 9.603 19.5 8.5C19.5 7.397 18.603 6.5 17.5 6.5Z"
                                                            fill="#0095DF" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="icon-box-1">
                                                <p>Male</p>
                                                <h4>{dData.MaleStaff}</h4>
                                                <div className="icon">
                                                    <i className="ri-men-line"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="icon-box-1">
                                                <p>Female</p>
                                                <h4>{dData.FemaleStaff}</h4>
                                                <div className="icon">
                                                    <i className="ri-women-line"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div>
                                                    <h4 className="card-title">Employees by Department</h4>
                                                    <ul className="list-info-boxes items-2" >

                                                        {
                                                            loading ?
                                                                <div className="row">
                                                                    <div className="col-md-4 mx-auto ">


                                                                        <Spinner animation="border" role="status"></Spinner>

                                                                    </div>
                                                                </div>
                                                                :
                                                                <>
                                                                    {renderEmployees()}
                                                                </>

                                                        }
                                                    </ul>
                                                    <h4 className="card-title mt-2">Employees by Designation</h4>


                                                    <ul className="list-info-boxes items-2" >

                                                        {
                                                            loading ?
                                                                <div className="row">
                                                                    <div className="col-md-4 mx-auto ">


                                                                        <Spinner animation="border" role="status"></Spinner>

                                                                    </div>
                                                                </div>
                                                                :
                                                                <>
                                                                    {renderDesignations()}
                                                                </>

                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title">Attendance Information ({moment(dData.LastAttendanceDate).format('DD MMM YYYY')})</h4>

                                    {
                                        loading ?
                                            <div className="row">
                                                <div className="col-md-4 mx-auto ">


                                                    <Spinner animation="border" role="status"></Spinner>

                                                </div>
                                            </div>
                                            :
                                            <>
                                                <div className="row align-items-center">
                                                    <div className="col-md-6">
                                                        <div className="icon-box-light bg-red">
                                                            <div className="icon">
                                                                <span>
                                                                    <img src={attendence_red} alt="" />
                                                                </span>
                                                            </div>
                                                            <div className="content">
                                                                <h2>{dData.Absent}</h2>
                                                                <p>Todays Absent</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="icon-box-light bg-yellow pt-0">
                                                            <div className="icon">
                                                                <span>
                                                                    <img src={attendence_yellow} alt="" /></span>
                                                            </div>
                                                            <div className="content">
                                                                <h2>{dData.Present}</h2>
                                                                <p>Present</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="icon-box-light bg-yellow">
                                                            <div className="icon">
                                                                <span>
                                                                    <img src={attendence_yellow} alt="" /></span>
                                                            </div>
                                                            <div className="content">
                                                                <h2>{dData.CasualLeave}</h2>
                                                                <p>Casual Leave</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="icon-box-light bg-yellow">
                                                            <div className="icon">
                                                                <span>
                                                                    <img src={attendence_yellow} alt="" /></span>
                                                            </div>
                                                            <div className="content">
                                                                <h2>{dData.MedicalLeave}</h2>
                                                                <p>Medical Leave</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="icon-box-light bg-yellow pt-0">
                                                            <div className="icon">
                                                                <span>
                                                                    <img src={attendence_yellow} alt="" /></span>
                                                            </div>
                                                            <div className="content">
                                                                <h2>{dData.MaternityLeave}</h2>
                                                                <p>Maternity Leave</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="icon-box-light bg-red">
                                                            <div className="icon">
                                                                <span>
                                                                    <img src={attendence_red} alt="" />
                                                                </span>
                                                            </div>
                                                            <div className="content">
                                                                <h2>{dData.LeaveWithoutPay}</h2>
                                                                <p>Leave WithoutPay</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="icon-box-light bg-red">
                                                            <div className="icon">
                                                                <span>
                                                                    <img src={attendence_red} alt="" />
                                                                </span>
                                                            </div>
                                                            <div className="content">
                                                                <h2>{dData.LeaveETC}</h2>
                                                                <p>Leave ETC</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="icon-box-light bg-red">
                                                            <div className="icon">
                                                                <span>
                                                                    <img src={attendence_red} alt="" />
                                                                </span>
                                                            </div>
                                                            <div className="content">
                                                                <h2>{dData.AttendanceNotAvailable}</h2>
                                                                <p>Attendance NotAvailable</p>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                            </>

                                    }

                                </div>
                            </div>
                            <div className="card mt-4">
                                <div className="card-body">
                                    <h4 className="card-title">Today's Birthdays</h4>
                                    <div>
                                        <ul className="birthday-list scrollbar">
                                            {
                                                loading ?
                                                    <div className="row">
                                                        <div className="col-md-4 mx-auto ">


                                                            <Spinner animation="border" role="status"></Spinner>

                                                        </div>
                                                    </div>
                                                    :
                                                    <>
                                                        {renderBirthdays()}
                                                    </>

                                            }

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PayrollDashboard;