import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import QRCode from "react-qr-code";
import StudentInfo from "../FeeSystem/StudentInfo";
import moment from "moment";
import PrintHeader from "../General/PrintHeader";
import Page from "../General/Page";
import { calculateColumnTotal, getMonthName } from "../General/Utility";
import { scrollToTop } from "../Shared/ScrollOnTop";

const PrintSummarySheet = () => {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [listOfLedger, setLedger] = useState([]);
  const [selectedYear, setselectedYear] = useState("");
  const [selectedMonth, setselectedMonth] = useState("");

  const thStyle = {
    writingMode: "vertical-rl", // Vertical writing mode, rotates text
    textAlign: "center", // Center the text horizontally
  };

  useEffect(() => {
    document.title = "AGS School Management System";

    setLoading(true);
    // Get the URL parameters
    const urlParams = new URLSearchParams(window.location.search);

    // Retrieve individual parameter values
    const year = urlParams.get("year");
    const Month = urlParams.get("month");

    setselectedYear(year);
    setselectedMonth(Month);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      Fyear: year,
      SelectedMonth: Month,
    };

    //console.log(data);
    var api_config = {
      method: "post",
      url: config.base_url + "Payroll/GetSalarySheetSummary",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    //console.log(data);

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        setLedger(response.data.SummarySheet);
        setLoading(false);
        //setTotalRecords(response.data.totalRecords);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, []);

  const renderLedger = () => {
    if (listOfLedger != null && listOfLedger.length > 0) {
      let count = 1;
      let countbreak = 1;
      return listOfLedger.map((item, index) => {
        return (
          <tr key={index} className={countbreak++ == 35 ? "page-break" : ""}>
            <td>{count++}</td>
            <td>{item.Department_Name}</td>
            <td>{item.Total_Employees}</td>
            <td>{Math.round(item.Salary_Type_1)}</td>
            <td>{Math.round(item.Salary_Type_2)}</td>
            <td>{Math.round(item.Salary_Type_3)}</td>
            <td>{Math.round(item.Salary_Type_4)}</td>
            <td>{Math.round(item.Salary_Type_5)}</td>
            <td>{Math.round(item.Salary_Type_6)}</td>
            <td>{Math.round(item.Salary_Type_7)}</td>
            <td>{Math.round(item.Salary_Type_8)}</td>
            <td>{Math.round(item.Salary_Type_9)}</td>
            <td>{Math.round(item.Salary_Type_10)}</td>
            <td>{Math.round(item.Salary_Type_11)}</td>
            <td>{Math.round(item.Salary_Type_12)}</td>
            <td>{Math.round(item.Salary_Type_13)}</td>
            <td>{Math.round(item.Salary_Type_14)}</td>
            <td>{Math.round(item.Salary_Type_15)}</td>
            <td>{Math.round(item.Salary_Type_16)}</td>
            <td>{Math.round(item.Salary_Type_17)}</td>
            <td>{Math.round(item.Salary_Type_18)}</td>
            <td>{Math.round(item.Salary_Type_19)}</td>
            <td>{Math.round(item.Salary_Type_20)}</td>
            <td>{Math.round(item.Salary_Type_21)}</td>
            <td>{Math.round(item.Salary_Type_22)}</td>
            <td>{Math.round(item.Salary_Type_23)}</td>
            <td>{Math.round(item.Salary_Type_24)}</td>
            <td>{Math.round(item.Salary_Type_25)}</td>
            <td>{Math.round(item.Net_Salary)}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="29">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };

  return (
    <>
      <div className="h200">
        <Page orientation="landscape" size="legal" className={`p-0`}>
          <table className="table table-sm table-bordered table-print">
            <thead>
              <tr>
                <th colSpan={29}>
                  <PrintHeader
                    title={`Salary Sheet For the Month of ${getMonthName(
                      selectedMonth
                    )} ${selectedYear} `}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td className="text-center" colSpan="24">
                    <Spinner animation="border" role="status"></Spinner>
                  </td>
                </tr>
              ) : (
                <>
                  <tr>
                    <td>Sr.#</td>
                    <td>Department</td>
                    <td>Total Emp.</td>
                    <td style={thStyle}>BAsic Pay</td>
                    <td style={thStyle}>Amount of Incremnets</td>
                    <td style={thStyle}>50% Ad. Relief. Allow</td>
                    <td style={thStyle}>10% of BP w.e.f Jul 2014</td>
                    <td style={thStyle}>30% House Rent Allowance</td>
                    <td style={thStyle}>Medical Allowance (Fixed)</td>
                    <td style={thStyle}>Convey. Allow. (Fixed)</td>
                    <td style={thStyle}>10% Public Sch. Allow</td>
                    <td style={thStyle}>Dearness Allowance 2023 20%</td>
                    <td style={thStyle}>30% Adhoc relief allowance 2023</td>
                    <td style={thStyle}>Sci/Spec. Allow./Pay/O 'Level'</td>
                    <td style={thStyle}>Special Addl. allowance 2023</td>
                    <td style={thStyle}>Sce. / GS Teaching Allowc.</td>
                    <td style={thStyle}>W.Inc./Class Inc. /Sub.Cordt.</td>
                    <td style={thStyle}>Dearness Allow 23 20% of R.BP</td>
                    <td style={thStyle}>CWF @ 5% of Running BP</td>
                    <td style={thStyle}>EOBI Contributaion</td>
                    <td style={thStyle}>Transport Deduction</td>
                    <td style={thStyle}>I.Tax / Hostel Mess Dues</td>
                    <td style={thStyle}>Any Other Deduction</td>
                    <td style={thStyle}>Leave Deduction</td>
                    <td style={thStyle}>Utility Bills</td>
                    <td style={thStyle}>O Level Detection</td>
                    <td style={thStyle}>PESSI Detection</td>
                    <td style={thStyle}>Arrears</td>
                    <td style={thStyle}>Net Pay</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>1</td>
                    <td>2</td>
                    <td>3</td>
                    <td>4</td>
                    <td>5</td>
                    <td>6</td>
                    <td>7</td>
                    <td>8</td>
                    <td>9</td>
                    <td>10</td>
                    <td>11</td>
                    <td>12</td>
                    <td>13</td>
                    <td>14</td>
                    <td>15</td>
                    <td>16</td>
                    <td>17</td>
                    <td>18</td>
                    <td>19</td>
                    <td>20</td>
                    <td>21</td>
                    <td>22</td>
                    <td>23</td>
                    <td>24</td>
                    <td>25</td>
                    <td></td>
                  </tr>
                  {renderLedger()}
                  <tr>
                    <td></td>
                    <td></td>
                    <td>
                      {Math.round(
                        calculateColumnTotal(listOfLedger, "Total_Employees")
                      )}
                    </td>
                    <td>
                      {Math.round(
                        calculateColumnTotal(listOfLedger, "Salary_Type_1")
                      )}
                    </td>
                    <td>
                      {Math.round(
                        calculateColumnTotal(listOfLedger, "Salary_Type_2")
                      )}
                    </td>
                    <td>
                      {Math.round(
                        calculateColumnTotal(listOfLedger, "Salary_Type_3")
                      )}
                    </td>
                    <td>
                      {Math.round(
                        calculateColumnTotal(listOfLedger, "Salary_Type_4")
                      )}
                    </td>
                    <td>
                      {Math.round(
                        calculateColumnTotal(listOfLedger, "Salary_Type_5")
                      )}
                    </td>
                    <td>
                      {Math.round(
                        calculateColumnTotal(listOfLedger, "Salary_Type_6")
                      )}
                    </td>
                    <td>
                      {Math.round(
                        calculateColumnTotal(listOfLedger, "Salary_Type_7")
                      )}
                    </td>
                    <td>
                      {Math.round(
                        calculateColumnTotal(listOfLedger, "Salary_Type_8")
                      )}
                    </td>
                    <td>
                      {Math.round(
                        calculateColumnTotal(listOfLedger, "Salary_Type_9")
                      )}
                    </td>
                    <td>
                      {Math.round(
                        calculateColumnTotal(listOfLedger, "Salary_Type_10")
                      )}
                    </td>
                    <td>
                      {Math.round(
                        calculateColumnTotal(listOfLedger, "Salary_Type_11")
                      )}
                    </td>
                    <td>
                      {Math.round(
                        calculateColumnTotal(listOfLedger, "Salary_Type_12")
                      )}
                    </td>
                    <td>
                      {Math.round(
                        calculateColumnTotal(listOfLedger, "Salary_Type_13")
                      )}
                    </td>
                    <td>
                      {Math.round(
                        calculateColumnTotal(listOfLedger, "Salary_Type_14")
                      )}
                    </td>
                    <td>
                      {Math.round(
                        calculateColumnTotal(listOfLedger, "Salary_Type_15")
                      )}
                    </td>
                    <td>
                      {Math.round(
                        calculateColumnTotal(listOfLedger, "Salary_Type_16")
                      )}
                    </td>
                    <td>
                      {Math.round(
                        calculateColumnTotal(listOfLedger, "Salary_Type_17")
                      )}
                    </td>
                    <td>
                      {Math.round(
                        calculateColumnTotal(listOfLedger, "Salary_Type_18")
                      )}
                    </td>
                    <td>
                      {Math.round(
                        calculateColumnTotal(listOfLedger, "Salary_Type_19")
                      )}
                    </td>
                    <td>
                      {Math.round(
                        calculateColumnTotal(listOfLedger, "Salary_Type_20")
                      )}
                    </td>
                    <td>
                      {Math.round(
                        calculateColumnTotal(listOfLedger, "Salary_Type_21")
                      )}
                    </td>
                    <td>
                      {Math.round(
                        calculateColumnTotal(listOfLedger, "Salary_Type_22")
                      )}
                    </td>
                    <td>
                      {Math.round(
                        calculateColumnTotal(listOfLedger, "Salary_Type_23")
                      )}
                    </td>
                    <td>
                      {Math.round(
                        calculateColumnTotal(listOfLedger, "Salary_Type_24")
                      )}
                    </td>
                    <td>
                      {Math.round(
                        calculateColumnTotal(listOfLedger, "Salary_Type_25")
                      )}
                    </td>
                    <td>
                      {Math.round(
                        calculateColumnTotal(listOfLedger, "Net_Salary")
                      )}
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>

          <div
            style={{
              clear: "both",
              float: "left",
              marginTop: "20px",
              width: "100%",
            }}
          >
            <table
              className="table table-sm table-bordered table-print"
              style={{ borderCollapse: "collapse", border: "transparent" }}
            >
              <tbody>
                <tr className="my-5">
                  <th
                    style={{ width: "20%", textDecoration: "underline" }}
                  ></th>
                  <th style={{ width: "20%" }}>Principal</th>
                  <th style={{ width: "20%" }}>Admin Officer</th>
                  <th style={{ width: "20%" }}>Bursur</th>
                  <th style={{ width: "20%" }}>Accountant</th>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            style={{
              clear: "both",
              float: "left",
              marginTop: "10px",
              width: "100%",
            }}
          >
            <table
              className="table table-sm table-bordered table-print"
              style={{ borderCollapse: "collapse", border: "transparent" }}
            >
              <tbody>
                <tr className="">
                  <th>DC/CHAIRMAN BOG'S PLEASE:</th>
                </tr>
              </tbody>
            </table>
          </div>
        </Page>
      </div>
    </>
  );
};

export default PrintSummarySheet;
