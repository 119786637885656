import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import LibraryMenu from './LibraryMenu';
import book from '../../assets/images/book.png';
import { NavLink, useParams } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { Form } from "react-bootstrap";
import { scrollToTop } from '../Shared/ScrollOnTop';
import ShowStudent from "../Shared/ShowStudent";
import ShowStaff from "../Shared/ShowStaff";
import RightsCheck from "../General/RightsCheck";
import ShowStudentByRag from "../Shared/ShowStudentByRag";

function IssueBook(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [validated, setValidated] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [hasError, setHasError] = useState(false);
    const [loading, setLoading] = useState(false);

    const [Books, setBooks] = useState('');
    const [CurrentStatus, setCurrentStatus] = useState('');
    const [Binding_Type, setBinding_Type] = useState('');
    const [User_Code, setUser_Code] = useState('');

    const [submitted, setSubmitted] = useState(false);

    const [userType, setUserType] = useState('');

    const handleRadioChange = (event) => {
        setUserType(event.target.value);
        //console.log(event.target.value);
        setUser_Code('')
    };

    let navigate = useNavigate();


    let params = useParams();

    const handleIssueBook = (event) => {

        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            setLoading(true);
            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
                ID: params.id,
                Date_Of_Issue: event.target.IssueDate.value,
                Return_Date: event.target.ReturnDate.value,
                Remarks: event.target.Remarks.value,
            };

            if (userType === 'student') {
                data.Registration_No = User_Code;
            }
            else{
                data.EmployeeCode = User_Code;
            }

        

            //console.log(data);

            var api_config = {
                method: 'post',
                url: config.base_url + 'Library/IssueBook',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };



            axios(api_config)
                .then(function (response) {
                    //console.log(response.data);
                    if (response.data.status_code == 1) {

                        toast.success(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });

                    }
                    else {
                        toast.error(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }

                    setLoading(false);


                })
                .catch(function (error) {
                    setLoading(false);
                    toast.error('Network Error..', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                });
        }

        setValidated(true);
    };

    useEffect(() => {
        document.title = "Issue Book"
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            ID: params.id,
        };


       //console.log(data);

        var api_config = {
            method: 'post',
            url: config.base_url + 'Library/GetBookByID',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };



        axios(api_config)
            .then(function (response) {
               //console.log(response.data);
                if (response.data.status_code == 1) {
                    setBooks(response.data.Book)
                    setBinding_Type(response.data.Book.Binding_Type);
                    setCurrentStatus(response.data.Book.Current_Status);
                }
                else {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
                setLoading(false);

            })
            .catch(function (error) {
                setLoading(false);
                toast.error('Network Error..', {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }, [])

    const handleSearchUser = (event) => {
        ////console.log(event)
        event.preventDefault();
        setSubmitted(true);
      
    };


    return (
        <>
            <LibraryMenu />
            <ToastContainer />
            <RightsCheck/>
            <div className="container body-content">
                <div className="page-head">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><NavLink to="/Dashboard">Dashboard</NavLink></li>
                                        <li className="breadcrumb-item"><NavLink href="/Library/LibraryDashboard">Library</NavLink></li>
                                        <li className="breadcrumb-item active" aria-current="page">Issue Book</li>
                                    </ol>
                                </nav>
                                <div className="page-title">Issue Book</div>
                            </div>
                            <div className="col-md-6">
                                <div className="page-right-actions">
                                    <NavLink to="/Library/ViewLibrary" className="button button-white button-icon">
                                        Manage Library
                                        <i className="ri-file-list-2-line"></i>
                                    </NavLink>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="page-content">
                    <div className="container">
                        <div className="row align-items-center gx-2 mb-3">
                            <div className="col-md-3">
                                <div className="card">
                                    <div className="card-body text-center" style={{ minHeight: "370px" }}>
                                        <img className="img-fluid" style={{height:"350px"}} src={book} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-9">
                                <div className="card">
                                    <div className="card-body" style={{ minHeight: "370px" }}>
                                        <div className="row align-items-center">
                                            <div className="col-md-12">
                                                <div className="row align-items-center" style={{ color: "#E55C73" }}>
                                                    <div className="col-md-6">
                                                        <h3 className="m-0"><b>{Books.Book_Name}</b></h3>
                                                    </div>
                                                    <div className="col-md-6 text-right">
                                                        <p className="m-0"><b>Book No: {Books.Book_No}</b></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="info-list">
                                                    <h6>Writer Name: </h6>
                                                    <p>{Books.Writer_Name}</p>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="info-list">
                                                    <h6>Book Price:</h6>
                                                    <p>{Books.Price}</p>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="info-list">
                                                    <h6>Jild No. :</h6>
                                                    <p>{Books.Jild_No}</p>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="info-list">
                                                    <h6>Publisher Name: </h6>
                                                    <p>{Books.Publisher_Name}</p>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="info-list">
                                                    <h6>No of Pages: </h6>
                                                    <p>{Books.Total_Pages}</p>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="info-list">
                                                    <h6>Binding Type: </h6>
                                                    <p>{Books.Binding_Type}</p>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="info-list">
                                                    <h6>Purchasing Source: </h6>
                                                    <p>{Books.Purchasing_Source}</p>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="info-list">
                                                    <h6>Status: </h6>
                                                    <p>{Books.Current_Status}</p>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="info-list">
                                                    <h6>Edition: </h6>
                                                    <p>{Books.Edition}</p>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="info-list">
                                                    <h6>Enter Classification No:</h6>
                                                    <p>{Books.Category}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center mb-3">
                            <Form noValidate onSubmit={handleSearchUser}>
                                <div className="card">
                                    <div className="card-body p-md-4">
                                        <div className="row align-items-center">
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label  className="col-form-label">Select</label>
                                                    <Form.Group className="d-flex justify-content-between">
                                                        <Form.Check
                                                            type="radio"
                                                            label='Student'
                                                            value='student'
                                                            name="userType"
                                                            checked={userType === 'student'}
                                                            onChange={handleRadioChange}
                                                        />
                                                        <Form.Check
                                                            type="radio"
                                                            label='Staff'
                                                            value='staff'
                                                            name="userType"
                                                            checked={userType === 'staff'}
                                                            onChange={handleRadioChange}
                                                        />
                                                    </Form.Group>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label  className="col-form-label">Enter Code</label>
                                                    <input type="text" onChange={(e) => setUser_Code(e.target.value)} value={User_Code} className="form-control" required="" />
                                                </div>
                                            </div>
                                            <div className="col-md-6 text-end">
                                                <div className="form-group mt-5">
                                                    <button type="submit" className="button button-primary">
                                                        Search
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                        {submitted && User_Code.length > 0 && User_Code != null && (
                            <>
                                {userType === 'student' && <ShowStudentByRag StudentID={User_Code} />}
                                {userType === 'staff' && <ShowStaff EmployeeID={User_Code} />}
                            </>
                        )}
                        <div className="row align-items-center">
                            <Form noValidate validated={validated} onSubmit={handleIssueBook}>
                                <div className="card">
                                    <div className="card-body p-md-4">
                                        <div className="row align-items-end gy-3">
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label  className="col-form-label">Issue Date</label>
                                                    <input type="date" className="form-control" name="IssueDate" required />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label  className="col-form-label">Return Date</label>
                                                    <input type="date" className="form-control" name="ReturnDate" required />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label  className="col-form-label">Remarks:</label>
                                                    <input type="text" className="form-control" name="Remarks" defaultValue={Books.Remarks} />
                                                </div>
                                            </div>
                                            <div className="col-md-2 text-end">
                                                <div className="form-group">
                                                    <button type="submit" className="button button-primary mt-3" >Issue Book</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div >
                </div >
            </div >
        </>
    );
}

export default IssueBook;