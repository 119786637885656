import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import PrintHeader from '../General/PrintHeader';
import Page from "../General/Page";
import RightsCheck from '../General/RightsCheck';

const PrintViewLibrary = () => {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [loading, setLoading] = useState(false);
    const [listOfLedger, setLedger] = useState([]);
 

    // pagination 
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(2000);
    const [totalRecords, setTotalRecords] = useState(0);

    const [isUpdated, setIsUpdated] = useState(false);
    const [BookName, setBookName] = useState('');
    const [WriterName, setWriterName] = useState('');
    const [Publisher, setPublisher] = useState('');
    const [BookNo, setBookNo] = useState('');
    const [Status, setStatus] = useState('');

    useEffect(() => {

        document.title = "Print Manage Library";

        setIsUpdated(false);
        setLoading(true);

        const urlParams = new URLSearchParams(window.location.search);

        const OrderBy = urlParams.get('OrderBy');

        const data = {
          
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            pageNo: pageNo,
            pageSize: pageSize,
            Book_Name: BookName,
            Writer_Name: WriterName,
            Publisher_Name: Publisher,
            Category: '',
            Book_No: BookNo,
            Status: Status,
            OrderBy: OrderBy,

            // Actual_Return_Date: "555",
            // Remarks : "000",
        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'Library/GetAllBooks',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        //console.log(data);

        axios(api_config)
            .then(function (response) {
                 //console.log(response.data);
                setLedger(response.data.ListofRecords);
                setLoading(false);
                setTotalRecords(response.data.totalRecords);
                ////console.log(response.data.totalRecords);
            })
            .catch(function (error) {
                 //console.log(error);
            });

    }, []);

    const renderLedger = () => {

        if (listOfLedger != null && listOfLedger.length > 0) {
            let count = 1;
            let countbreak = 1;
            return (
                listOfLedger.map((item, index) => {
                    return (
                        <tr key={index}
                        //  className={countbreak++ == 35 ? 'page-break' : ''}
                         >
                            <td>{item.ID}</td>
                            <td>{item.Book_No}</td>
                            <td>{item.Writer_Name}</td>
                            <td>{item.Book_Name}</td>
                            <td>{item.Publisher_Name}</td>
                            <td style={{width: "100px"}}>{item.Current_Status}</td>
                            <td style={{width: "200px"}}>{item.Remarks}</td>
                        </tr>

                    )
                })
            )
        }
        else {
            return (
                <tr>
                    <td colSpan="20"><h4 className="mt-3" style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}>No Data Found</h4></td>
                </tr>
            )
        }

    }


    return (
        <>
         <RightsCheck />
            <Page orientation="portrait" size="a4">
                <table className="table table-sm table-bordered table-print">
                    <thead>
                        <tr>
                            <th colSpan={20}>
                                <PrintHeader title={`List of Library Books:  `} />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            loading ?
                                <tr>
                                    <td className="text-center" colSpan="8">
                                        <Spinner animation="border" role="status"></Spinner>
                                    </td>
                                </tr>
                                :
                                <>
                                    <tr>
                                        <th style={{ width: "10px" }}>ID</th>
                                        <th style={{ width: "75px" }}>BookNo</th>
                                        <th style={{ width: "150px" }}>Writer Name</th>
                                        <th style={{ width: "150px" }}>Book Name</th>
                                        <th style={{ width: "80px" }}>Publisher</th>
                                        <th style={{ width: '100px' }}>Status</th>
                                        <th>Remarks</th>
                                    </tr>
                                    {renderLedger()}
                                </>

                        }

                    </tbody>
                </table>
            </Page >
        </>
    );
}

export default PrintViewLibrary;
