import ReportsMenu from "./ReportsMenu";
import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import Page from "../General/Page";
import moment from "moment";
import PrintHeader from "../General/PrintHeader";
import { calculateColumnTotal } from "../General/Utility";
import { useNavigate, useParams } from "react-router-dom";
import { useLocation } from 'react-router-dom';

const PrintStudentStrengthReports = () => {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  //const [DefaultSession, setDefaultSession] = useState(localStorage.getItem("DefaultSession"));

  const [showFilters, setShowFilters] = useState(true);

  const [loading, setLoading] = useState(false);
  const [listOfLedger, setlistOfLedger] = useState([]);
  const [listOfLedger1, setlistOfLedger1] = useState([]);
  const [listOfLedger2, setlistOfLedger2] = useState([]);

  

  
  
  const [isUpdated, setIsUpdated] = useState(false);
 
  let params = useParams();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const ClassId = searchParams.get('ClassId');
  const SessionId = searchParams.get('SessionId');
  const ReportType = searchParams.get('Reporttype');
  const WingId = searchParams.get('WingId');
  const StartDate = searchParams.get('StartDate');
  const ReportMonth = searchParams.get('Reportmonth');
  const ReportYear = searchParams.get('Reportyear');


  //const [ClassId, setClassId] = useState(params.ClassId);
  //const [SessionId, setSessioinId] = useState(params.SessionId);
 // const [ReportType, setReportType] = useState(params.Reporttype);
  //const [WingId, setWingId] = useState(params.WingId);
  //const [StartDate, setStartDate] = useState(params.StartDate);

  useEffect(() => {
    document.title = "Student Strength Report";

    setIsUpdated(false);
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      SessionID: SessionId,
      Wing_Id: WingId,
      Class_Id: ClassId,
      ReportType: ReportType,
      StartDate: StartDate,
      SelectedMonth: ReportMonth,
      FYear: ReportYear,
    };

    //console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Reports/GetStudentStrengthReport",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        if (ReportType == 1 && WingId == 0) {
          setlistOfLedger(response.data.ListofRecords);
        } else if (ReportType == 1 && WingId > 0) {
          setlistOfLedger1(response.data.ListofRecords);
        } else if (ReportType == 2 && WingId == 0) {
          setlistOfLedger(response.data.ListofRecords);
        } else if (ReportType == 2 && WingId > 0) {
          setlistOfLedger1(response.data.ListofRecords);
        } else if (ReportType == 3 && WingId > 0 && ClassId > 0) {
          setlistOfLedger2(response.data.ListofRecords);
        }
        setLoading(false);
        // settotalsections(response.data.totalsections);
        // settotalstrength(response.data.totalstrength);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, []);
  const renderLedger = () => {
    //console.log(listOfLedger);
    if (listOfLedger != null && listOfLedger.length > 0) {
      return listOfLedger.map((item, index) => {
        return (
          <tr key={index}>
            <td>{item.Serial_No}</td>
            <td>{item.Wing_Name}</td>
            <td>{item.Class_Name}</td>
            <td>{item.TotalSections}</td>
            <td>{item.TotalStudents}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="5">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };

  const renderLedger1 = () => {
    if (listOfLedger1 != null && listOfLedger1.length > 0) {
      return listOfLedger1.map((item, index) => {
        return (
          <tr key={index}>
            <td>{item.Serial_No}</td>
            <td>{item.Wing_Name}</td>
            <td>{item.Class_Name}</td>
            <td>{item.Section_Name}</td>
            <td>{item.TotalStudents}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="5">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };

  const renderLedger2 = () => {
    if (listOfLedger2 != null && listOfLedger2.length > 0) {
      return listOfLedger2.map((item, index) => {
        return (
          <tr key={index}>
            <td>{item.Serial_No}</td>
            <td>{item.Class_Name}</td>
            <td>{item.Section_Name}</td>
            <td>{item.TotalStudents}</td>
            <td>{item.Present}</td>
            <td>{item.Absent}</td>
            <td>{item.CasualLeave}</td>
            <td>{item.LateCommer}</td>
            <td></td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="9">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };

  return (
    <>
      <Page orientation="portrait" size="a4">
        <table className="print-main-table">
          <thead>
            <tr>
              <th colSpan={20}>
                <PrintHeader title={`Student Strength Report `} />
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td className="text-center" colSpan="8">
                  <Spinner animation="border" role="status"></Spinner>
                </td>
              </tr>
            ) : (
              <>
                  {ReportType == 1 && WingId == 0 ? (
                    <table className="table table-print">
                      <thead>
                        <tr>
                          <th>Serial No</th>
                          <th>Wing Name.</th>
                          <th>Class Name</th>
                          <th>Total Section</th>
                          <th>Total Strength</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading ? (
                          <tr>
                            <td className="text-center" colSpan="5">
                              <Spinner
                                animation="border"
                                role="status"
                              ></Spinner>
                            </td>
                          </tr>
                        ) : (
                          <>
                            {renderLedger()}
                            <tr>
                              <td colSpan={3} className="text-end">
                                Total:
                              </td>
                              <td>
                                {calculateColumnTotal(
                                  listOfLedger,
                                  "TotalSections"
                                )}
                              </td>
                              <td>
                                {calculateColumnTotal(
                                  listOfLedger,
                                  "TotalStudents"
                                )}
                              </td>
                            </tr>
                          </>
                        )}
                      </tbody>
                    </table>
                  ) : ReportType == 1 && WingId > 0 ? (
                    <table className="table ">
                      <thead>
                        <tr>
                          <th>Serial No</th>
                          <th>Wing Name.</th>
                          <th>Class Name</th>
                          <th>Section Name</th>
                          <th>Total Strength</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading ? (
                          <tr>
                            <td className="text-center" colSpan="5">
                              <Spinner
                                animation="border"
                                role="status"
                              ></Spinner>
                            </td>
                          </tr>
                        ) : (
                          <>
                            {renderLedger1()}
                            <tr>
                              <td colSpan={4} className="text-end">
                                Total:
                              </td>
                              <td>
                                {calculateColumnTotal(
                                  listOfLedger1,
                                  "TotalStudents"
                                )}
                              </td>
                            </tr>
                          </>
                        )}
                      </tbody>
                    </table>
                  ) : ReportType == 2 && WingId == 0 ? (
                    <table className="table ">
                      <thead>
                        <tr>
                          <th>Serial No</th>
                          <th>Wing Name.</th>
                          <th>Class Name</th>
                          <th>Total Section</th>
                          <th>Total Strength</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading ? (
                          <tr>
                            <td className="text-center" colSpan="5">
                              <Spinner
                                animation="border"
                                role="status"
                              ></Spinner>
                            </td>
                          </tr>
                        ) : (
                          <>
                            {renderLedger()}
                            <tr>
                              <td colSpan={3} className="text-end">
                                Total:
                              </td>
                              <td>
                                {calculateColumnTotal(
                                  listOfLedger,
                                  "TotalSections"
                                )}
                              </td>
                              <td>
                                {calculateColumnTotal(
                                  listOfLedger,
                                  "TotalStudents"
                                )}
                              </td>
                            </tr>
                          </>
                        )}
                      </tbody>
                    </table>
                  ) : ReportType == 2 && WingId > 0 ? (
                    <table className="table ">
                      <thead>
                        <tr>
                          <th>Serial No</th>
                          <th>Wing Name.</th>
                          <th>Class Name</th>
                          <th>Section Name</th>
                          <th>Total Strength</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading ? (
                          <tr>
                            <td className="text-center" colSpan="5">
                              <Spinner
                                animation="border"
                                role="status"
                              ></Spinner>
                            </td>
                          </tr>
                        ) : (
                          <>
                            {renderLedger1()}
                            <tr>
                              <td colSpan={4} className="text-end">
                                Total:
                              </td>
                              <td>
                                {calculateColumnTotal(
                                  listOfLedger1,
                                  "TotalStudents"
                                )}
                              </td>
                            </tr>
                          </>
                        )}
                      </tbody>
                    </table>
                  ) : ReportType == 3 && WingId > 0 && ClassId > 0 ? (
                    <table className="table ">
                      <thead>
                        <tr>
                          <th>Serial No</th>
                          <th>Class Name</th>
                          <th>Section Name</th>
                          <th>Total Strength</th>
                          <th>Present</th>
                          <th>Absent</th>
                          <th>Casual Leave</th>
                          <th>Late Commer</th>
                          <th>Remarks</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading ? (
                          <tr>
                            <td className="text-center" colSpan="9">
                              <Spinner
                                animation="border"
                                role="status"
                              ></Spinner>
                            </td>
                          </tr>
                        ) : (
                          <>
                            {renderLedger2()}
                            <tr>
                              <td colSpan={3} className="text-end">
                                Total:
                              </td>
                              <td>
                                {calculateColumnTotal(
                                  listOfLedger2,
                                  "TotalStudents"
                                )}
                              </td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                          </>
                        )}
                      </tbody>
                    </table>
                  ) : (
                    <p>Select Proper Filters to display data</p>
                  )}
              </>
            )}
          </tbody>
        </table>
      </Page>
    </>
  );
};

export default PrintStudentStrengthReports;
