import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import QRCode from "react-qr-code";
import StudentInfo from "../FeeSystem/StudentInfo";
import moment from "moment";
import PrintHeader from "../General/PrintHeader";
import Page from "../General/Page";
import { calculateColumnTotal } from "../General/Utility";
import { scrollToTop } from "../Shared/ScrollOnTop";

const PrintSummarySheetTwo = () => {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [listOfLedger, setLedger] = useState([]);
  const [sMonth, setsMonth] = useState(0);
  const [sYear, setsYear] = useState(0);
  const [departmentName, setDepartmentName] = useState();

  const thStyle = {
    writingMode: 'vertical-rl', // Vertical writing mode, rotates text
    textAlign: 'center', // Center the text horizontally
  };


  useEffect(() => {
    document.title = "Print Summary";

    setLoading(true);
    // Get the URL parameters
    const urlParams = new URLSearchParams(window.location.search);

    // Retrieve individual parameter values
    const selectedYear = urlParams.get("year");
    const selectedMonth = urlParams.get("month");
    const EmployeeTypeId = urlParams.get("EmployeeTypeId");
    const designationId = urlParams.get("designationId");
    const bpsScale = urlParams.get("bpsScale");
    
    

    setsMonth(selectedMonth);
    setsYear(selectedYear);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      FYear: selectedYear,
      SelectedMonth: selectedMonth,
      EmployeeTypeId: EmployeeTypeId,
      DesignationID: designationId,
      SelectedGrade: bpsScale,
    };

    ////console.log(data);
    var api_config = {
      method: "post",
      url: config.base_url + "Payroll/GetSummarySheet",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

   //console.log(data);

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        setLedger(response.data.ListofRecords);
        setDepartmentName(response.data.ReportTitleName);
        setLoading(false);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, []);

  const getMonthName = (monthNumber) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    return monthNames[monthNumber - 1];
  };

  const renderLedger = () => {
    if (listOfLedger.length > 0) {
      let count = 1;
      let countbreak = 1;
      return listOfLedger.map((item, index) => {
        return (
          <tr key={index}>
            <td>{count++}</td>
            <td>{item.Code}</td>
            <td>{item.Employee_Name}</td>
            <td>{item.GPS_Scale}</td>
            <td>{item.Salary_Type_1}</td>
            <td>{item.Salary_Type_2}</td>
            <td>{item.Salary_Type_3}</td>
            <td>{item.Salary_Type_4}</td>
            <td>{item.Salary_Type_5}</td>
            <td>{item.Salary_Type_6}</td>
            <td>{item.Salary_Type_7}</td>
            <td>{item.Salary_Type_8}</td>
            <td>{item.Salary_Type_9}</td>
            <td>{item.Salary_Type_10}</td>
            <td>{item.Salary_Type_11}</td>
            <td>{item.Salary_Type_12}</td>
            <td>{item.Salary_Type_13}</td>
            <td>{item.Salary_Type_14}</td>
            <td>{item.Salary_Type_15}</td>
            <td>{item.Salary_Type_16}</td>
            <td>{item.Salary_Type_17}</td>
            <td>{item.Salary_Type_18}</td>
            <td>{item.Salary_Type_19}</td>
            <td>{item.Salary_Type_20}</td>
            <td>{item.Net_Salary}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="25">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };

  return (
    <>
      <Page orientation="landscape" size="a4">
        <table className="table table-sm table-bordered table-print">
          <thead>
            <tr>
              <th colSpan={25}>
                <PrintHeader
                  title={`SALARY SHEET FOR THE MONTH OF ${
                    getMonthName(sMonth) + " " + sYear
                  } `}
                />
                <label>{` Department/Type: ${departmentName}`}</label>
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td className="text-center" colSpan="25">
                  <Spinner animation="border" role="status"></Spinner>
                </td>
              </tr>
            ) : (
              <>
                <tr>
                  <td>Sr.#</td>
                  <td>Code</td>
                  <td>Staff Name </td>
                  <td>GPS</td>
                  <td style={thStyle}>Basic Pay</td>
                  <td style={thStyle}>Amount of Incremnets</td>
                  <td style={thStyle}>50%  Ad.Ref. of Running BP</td>
                  <td style={thStyle}>10% of Running BP w.e.f Jul 2014</td>
                  <td style={thStyle}>30% House Rent of Basic Pay</td>
                  <td style={thStyle}>Medical Allowance (Fixed)</td>
                  <td style={thStyle}>Convey. Allow. (Fixed)</td>
                  <td style={thStyle}>10% Public Sch.Allowc of BP</td>
                  <td style={thStyle}>Dearness Allow. 23 20% of R.BP</td>
                  <td style={thStyle}>30% Adhoc relief 23 of R.BP</td>
                  <td style={thStyle}>Sci/Spec. Allow./Pay/O 'Level'Allow</td>
                  <td style={thStyle}>Special addl.allow.23 oneTime Fixed</td>
                  <td style={thStyle}>Sce. / GS Teaching Allowc.</td>
                  <td style={thStyle}>W.Inc./Class Inc. /Sub.Cordt.</td>
                  <td style={thStyle}>ARREARS IF ANY</td>
                  <td style={thStyle}>Absent / Leave WOP </td>
                  <td style={thStyle}>CWF @ 5% of Running BP</td>
                  <td style={thStyle}>EOBI Contributaion </td>
                  <td style={thStyle}>Transport Deduction</td>
                  <td style={thStyle}>I.Tax / Other/ Hostel Mess Dues</td>
                  <td style={thStyle}>Net Pay</td>
                </tr>
                {renderLedger()}
                <tr>
                  <td colSpan={4}>Total Amount: </td>
                  <td>{calculateColumnTotal(listOfLedger, "Salary_Type_1")}</td>
                  <td>{calculateColumnTotal(listOfLedger, "Salary_Type_2")}</td>
                  <td>{calculateColumnTotal(listOfLedger, "Salary_Type_3")}</td>
                  <td>{calculateColumnTotal(listOfLedger, "Salary_Type_4")}</td>
                  <td>{calculateColumnTotal(listOfLedger, "Salary_Type_5")}</td>
                  <td>{calculateColumnTotal(listOfLedger, "Salary_Type_6")}</td>
                  <td>{calculateColumnTotal(listOfLedger, "Salary_Type_7")}</td>
                  <td>{calculateColumnTotal(listOfLedger, "Salary_Type_8")}</td>
                  <td>{calculateColumnTotal(listOfLedger, "Salary_Type_9")}</td>
                  <td>
                    {calculateColumnTotal(listOfLedger, "Salary_Type_10")}
                  </td>
                  <td>
                    {calculateColumnTotal(listOfLedger, "Salary_Type_11")}
                  </td>
                  <td>
                    {calculateColumnTotal(listOfLedger, "Salary_Type_12")}
                  </td>
                  <td>
                    {calculateColumnTotal(listOfLedger, "Salary_Type_13")}
                  </td>
                  <td>
                    {calculateColumnTotal(listOfLedger, "Salary_Type_14")}
                  </td>
                  <td>
                    {calculateColumnTotal(listOfLedger, "Salary_Type_15")}
                  </td>
                  <td>
                    {calculateColumnTotal(listOfLedger, "Salary_Type_16")}
                  </td>
                  <td>
                    {calculateColumnTotal(listOfLedger, "Salary_Type_17")}
                  </td>
                  <td>
                    {calculateColumnTotal(listOfLedger, "Salary_Type_18")}
                  </td>
                  <td>
                    {calculateColumnTotal(listOfLedger, "Salary_Type_19")}
                  </td>
                  <td>
                    {calculateColumnTotal(listOfLedger, "Salary_Type_20")}
                  </td>
                  <td>{calculateColumnTotal(listOfLedger, "Net_Salary")}</td>
                </tr>
              </>
            )}
          </tbody>
        </table>

        <div style={{ marginTop: "30px", width: "100%" }}>
          <table
            className="table table-sm table-bordered table-print"
            style={{ borderCollapse: "collapse", border: "transparent" }}
          >
            <tbody>
              <tr className="my-5">
                <th
                  style={{
                    width: "33.33%",
                    textAlign: "left",
                    textDecoration: "underline",
                  }}
                >
                  Accountant
                </th>
                <th
                  style={{
                    width: "33.33%",
                    textAlign: "center",
                    textDecoration: "underline",
                  }}
                >
                  Burser
                </th>
                <th
                  style={{
                    width: "33.33%",
                    textAlign: "right",
                    textDecoration: "underline",
                  }}
                >
                  Head of Wing / Deptt
                </th>
              </tr>
              <tr className="my-5">
                <br />
                <br />
              </tr>
              <tr className="my-5">
                <th
                  style={{
                    width: "33.33%",
                    textAlign: "left",
                    textDecoration: "underline",
                  }}
                >
                  Admin Officer
                </th>
                <th
                  style={{
                    width: "33.33%",
                    textAlign: "center",
                    textDecoration: "underline",
                  }}
                ></th>
                <th
                  style={{
                    width: "33.33%",
                    textAlign: "right",
                    textDecoration: "underline",
                  }}
                >
                  Principal
                </th>
              </tr>
            </tbody>
          </table>
        </div>
      </Page>
    </>
  );
};

export default PrintSummarySheetTwo;
