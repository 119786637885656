
import imgStudent from "../assets/images/icons/student.svg";
import imgFee from "../assets/images/icons/fee.svg";
import imgmonthlytask from "../assets/images/icons/monthly-task.svg";
import imgAnnualtask from "../assets/images/icons/annual-task.svg";
import imgCertificate from "../assets/images/icons/certificate.svg";
import imgSMS from "../assets/images/icons/sms.svg";
import imgLibrary from "../assets/images/icons/library.svg";
import reports from "../assets/images/icons/reports.svg"
import imgPayroll from "../assets/images/icons/payroll.svg"
import imgExamination from "../assets/images/icons/exam.svg"
import imgSecurity from "../assets/images/icons/security.svg"
import imgAccounts from "../assets/images/icons/accounts.svg"
import imgStore from "../assets/images/icons/store.svg"
import imgStaff from "../assets/images/icons/staff.svg"
import imgStudentAtt from "../assets/images/icons/student-1.svg"
import { Link, NavLink } from "react-router-dom";
import config from "../Config";
import { useState, useEffect } from "react";
import { scrollToTop } from './Shared/ScrollOnTop';

function Dashboard() {

    useEffect(() => {

        document.title = "School System Dashboard";
    });


    return (
        <div className="dashboard">
            <div className="container">
                <div className="row">
                    <div className="col-md-10 offset-md-1">
                        <ul className="dashboard-buttons">
                            <li>
                                <NavLink onClick={scrollToTop} to="/students/StudentDashboard">
                                    <img src={imgStudent} alt="" />
                                    <span>Students</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink onClick={scrollToTop} to="/feesystem/feesystemdashboard">
                                    <img src={imgFee} alt="" />
                                    <span>Fee System</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink onClick={scrollToTop} to="/MonthlyTasks/MonthlyTasksDashboard">
                                    <img src={imgmonthlytask} alt="" />
                                    <span>Fee Posting</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink onClick={scrollToTop} to="/AnnualTasks/AnnualTasksDashboard">
                                    <img src={imgAnnualtask} alt="" />
                                    <span>Annual Tasks</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink onClick={scrollToTop} to="/Certificate/CertificateDashboard">
                                    <img src={imgCertificate} alt="" />
                                    <span>Certificate Printing</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink onClick={scrollToTop} to="/SMSAlert/SMSAlertDashboard">
                                    <img src={imgSMS} alt="" />
                                    <span>Send SMS Alert</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink onClick={scrollToTop} to="/Library/LibraryDashboard">
                                    <img src={imgLibrary} alt="" />
                                    <span>Library</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink onClick={scrollToTop} to="/Reports/ReportsDashboard">
                                    <img src={reports} alt="" />
                                    <span>Reports</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink onClick={scrollToTop} to="/PayRoll/PayRollDashboard">
                                    <img src={imgPayroll} alt="" />
                                    <span>Payroll</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink onClick={scrollToTop} to="/ExaminationSystem/ExaminationDashboard">
                                    <img src={imgExamination} alt="" />
                                    <span>Examination</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink onClick={scrollToTop} to="/Security/SecurityDashboard">
                                    <img src={imgSecurity} alt="" />
                                    <span>Security</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink onClick={scrollToTop} to="/Accounts/AccountsDashboard">
                                    <img src={imgAccounts} alt="" />
                                    <span>Accounts</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink onClick={scrollToTop} to="/StockManagement/StocksDashboard">
                                    <img src={imgStore} alt="" />
                                    <span>Stocks</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink onClick={scrollToTop} to="/StaffAttendance/StaffAttendanceDashboard">
                                    <img src={imgStaff} alt="" />
                                    <span>Staff Attendance</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink onClick={scrollToTop} to="/StudentAttendance/StudentAttendanceDashboard">
                                    <img src={imgStudentAtt} alt="" />
                                    <span>Student Attendance</span>
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
