import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";

function BusrouteDropDown(props) {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [Busrouts, setBusrouts] = useState([]);

  useEffect(() => {
    const apiData = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      SchoolID: 1,
      PageNo: 1,
      PageSize: 100,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Busroute/GetBusRoutes",
      headers: {
        "Content-Type": "application/json",
      },
      data: apiData,
    };

    axios(api_config)
      .then(function (response) {
        ////console.log(response.data);
        setBusrouts(response.data.ListofRecords || []);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, []);

  return Busrouts.map((item, index) => {
    return (
      <option
        value={item.Root_ID}
        key={index}
        selected={item.Root_ID == props.selectedRoot_ID}
      >
        {item.Area_Name}
      </option>
    );
  });
}

export default BusrouteDropDown;
