import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import { Link } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate, useParams } from 'react-router-dom';
import { NavLink } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { Form } from "react-bootstrap";
import moment from "moment";
import SetupMenu from './SetupMenu';
import { scrollToTop } from '../Shared/ScrollOnTop';

function EditBankAcc(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [validated, setValidated] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [hasError, setHasError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [Bank, setBank] = useState({});



    let params = useParams();
    const [BankID, setBankID] = useState(params.id);

    let navigate = useNavigate();

    useEffect(() => {
        document.title = "Add/Update Bank Account";

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            ID: BankID

        };
        //console.log(data);
        var api_config = {
            method: 'post',
            url: config.base_url + 'Setup/GetBankByID',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                if (response.data.status_code == 1) {
                    setBank(response.data.Bank);

                }


                setLoading(false);


            })
            .catch(function (error) {
                setLoading(false);
                toast.error('Network Error..', {
                    position: toast.POSITION.TOP_RIGHT
                });
            });


    }, []);

    const handleSubmit = (event) => {

        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            setLoading(true);
            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
                MemberType: MemberType,
                ID: BankID,
                Bank_Name: event.target.txtBankName.value,
                Bank_Address: event.target.txtBankAddress.value,
                Account_No: event.target.txtAccountNo.value,
                Is_Default_Bank: event.target.Is_Default_Bank.value,
                Fee_Voucher_Statement: event.target.txtFeeVoucherStatement.value,
            };

           //console.log(data);

            var api_config = {
                method: 'post',
                url: config.base_url + 'Setup/UpdateBank',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };



            axios(api_config)
                .then(function (response) {
                    //console.log(response.data);
                    if (response.data.status_code == 1) {

                        toast.success(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });

                    }
                    else {
                        toast.error(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }

                    setLoading(false);


                })
                .catch(function (error) {
                    setLoading(false);
                    toast.error('Network Error..', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                });

        }

        setValidated(true);
    };

    return (
        <>
            <SetupMenu />
            <ToastContainer />
            <div className="page-head">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/Dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item"> <Link to="/Setup/SetupDashboard">Setup</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Edit Bank Account</li>
                                </ol>
                            </nav>
                            <div className="page-title">Edit Bank Account</div>
                        </div>
                        <div className="col-md-6">
                            <div className="page-right-actions">
                                <Link to="/Setup/ManageBankAccounts" className="button button-white button-icon">
                                    Manage Bank Account
                                    <i className="ri-list-check"></i>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-content">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <div className="card">

                                <div className="card-body p-md-4">
                                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                        <div className="row align-items-center">
                                            <div className="col-md-4">
                                                <div className="form-group mb-3">
                                                    <label >Bank Name:</label>
                                                    <input type="text" defaultValue={Bank.Bank_Name} className="form-control" required name="txtBankName" />
                                                </div>
                                            </div>
                                            <div className="col-md-5">
                                                <div className="form-group mb-3">
                                                    <label  className="">Bank Address:</label>
                                                    <input type="text" defaultValue={Bank.Bank_Address} className="form-control" required name="txtBankAddress" />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group mb-3">
                                                    <label  className="">Bank Account No. :</label>
                                                    <input type="text" defaultValue={Bank.Account_No} className="form-control" required name="txtAccountNo" />
                                                </div>
                                            </div>
                                            <div className="col-md-8">
                                                <div className="form-group mb-3">
                                                    <label  className="">Bank Statement:</label>
                                                    <input type="text" defaultValue={Bank.Fee_Voucher_Statement} className="form-control" required name="txtFeeVoucherStatement" />
                                                </div>
                                            </div>
                                            <div className="col-md-2">
                                                <div className="form-group pt-md-3 d-flex">
                                                    <div className="form-check">
                                                        <input type="checkbox" id="checkStatus" required name="Is_Default_Bank" className="" />
                                                        <label className="form-check-label ms-md-3 mb-md-0" htmlFor="checkStatus">Status</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-2 text-end">
                                                <div className="form-group ">
                                                    {
                                                        loading ?
                                                            <Spinner animation="border" role="status"></Spinner>

                                                            :
                                                            <button type="submit" value="Save" className="button button-primary w-100 ">SAVE</button>

                                                    }

                                                </div>
                                            </div>

                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EditBankAcc;
