import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate, useParams } from 'react-router-dom';
import moment from "moment";

import PrintHeader from '../General/PrintHeader';
import Page from "../General/Page";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { calculateColumnTotal } from "../General/Utility";

import { useLocation } from 'react-router-dom';


function PrintLeaveRegister(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [loading, setLoading] = useState(false);
    const [slip, setSlip] = useState([]);
    const [reportHeading, setReportHeading] = useState("");

    let params = useParams();

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const selectedIds = searchParams.get('emp_id')?.split(',');

    const type = searchParams.get('type');
    const SessionID = searchParams.get('SessionID');


    useEffect(() => {


        ////console.log(selectedIds);

        document.title = "Print Leave Register";
        setLoading(true);
        const data = {
           AccessKey: AccessKey,
            UserID: UserID,
MemberType: MemberType,
            SelectedStaff: selectedIds,
            SessionID: SessionID,
            leaveType: type

        };

        //console.log(data);

        var api_config = {
            method: 'post',
            url: config.base_url + 'StaffAttendance/GetAttendanceRegister',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                if (response.data.status_code == 1) {
                    setSlip(response.data.ListofStaff);
                    setReportHeading(response.data.ReportHeading);
                    //setListofSalary(response.data.ListofSalary);
                }
                setLoading(false);
            })
            .catch(function (error) {
                setLoading(false);
                toast.error('Network Error..', {
                    position: toast.POSITION.TOP_RIGHT
                });
            });


    }, []);


    return (
        slip.map((item, index) => {
            return (
                <>



                    <Page orientation="portrait" size="a4">


                        {
                            loading ?
                                'Loading..'
                                :
                                <>
                                
                                    <div style={{ padding: "20px  20px 10px 20px" }}>
                                    <PrintHeader title={reportHeading} />
                                       
                                        <table className="table table-sm table-payslip" style={{ fontSize: "12px" }}>
                                            <thead>
                                                <tr>
                                                    <th colSpan={4}>
                                                    <h3 style={{ fontSize: "14px" }}>Personal Information:</h3>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{ width: "100px" }}>Employe Code:</td>
                                                    <td>{item.Code}</td>
                                                    <td>Type/Depart</td>
                                                    <td>{item.Employeetype.Name}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: "100px" }}>Full Name:</td>
                                                    <td>{item.Name}</td>
                                                    <td>Designation</td>
                                                    <td>{item.SelectedDesignation.Name}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: "100px" }}>Father Name:</td>
                                                    <td>{item.Father_Name}</td>
                                                    <td>Pay Scale</td>
                                                    <td>{item.GPS_Scale}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: "100px" }}>Address:</td>
                                                    <td>{item.Home_Address}</td>
                                                    <td>Joining Date:</td>
                                                    <td> 	{moment(item.Date_Of_Joining).format('DD/MM/YYYY')}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: "100px" }}>A/C No.:</td>
                                                    <td>{item.Account_No}</td>
                                                    <td>CNIC No:</td>
                                                    <td>{item.CNIC_NO}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <h3 style={{ fontSize: "14px", marginTop: "50px" }}>Leave Details:</h3>
                                        <table className="table table-sm table-payslip" style={{ fontSize: "12px" }}>
                                            {/* <thead>
                                                <tr>
                                                    <th colSpan={4}>
                                                        <PrintHeader title={`PAY SLIP FOR THE MONTH OF ${moment(item.PaySlipDate).format('MMM, YYYY')} `} />
                                                    </th>
                                                </tr>

                                            </thead> */}
                                            <tbody>
                                                <tr>
                                                    <td>Sr # </td>
                                                    <td>Dated</td>
                                                    <td>No of Days </td>
                                                    <td>Previous </td>
                                                    <td>Total  </td>
                                                    <td>Remaining  </td>
                                                    <td>Signature  </td>
                                                </tr>
                                                {item.StaffAttendance && item.StaffAttendance.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{moment(item.Dated).format("DD MMM YYYY")}</td>
                                                        <td>1</td>
                                                        <td>{item.Previous}</td>
                                                        <td>{item.Total}</td>
                                                        <td>{item.Remaining}</td>
                                                        <td></td>
                                                        
                                                    </tr>
                                                ))}
                                               
                                            </tbody>
                                        </table>
                                        <div style={{ margin: "60px 0" }}>
                                            <p style={{ textAlign: "right", fontSize: "13px" }}><strong></strong> <br />Accounts Officer </p>

                                        </div>
                                        <p style={{ fontSize: "13px" }}><strong>Note:</strong> <br />This register been generated by the system and do not require any signature. </p>
                                    </div>
                                </>
                        }

                    </Page>


                </>
            );
        })
    )

}

export default PrintLeaveRegister;

