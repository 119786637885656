import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "cropperjs/dist/cropper.css";
import { Link } from "react-router-dom";
import StudentPanelMenu from "./StudentPanelMenu";
import ShowStudent from "../Shared/ShowStudent";
import StudentRightsCheck from "./StudentRightsCheck";
import { Modal, ModalFooter } from "react-bootstrap";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import config from "../../Config";
import axios from "axios";
import moment from "moment";

const StudentUpdateProfile = (props) => {

  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
  const [loading, setLoading] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [image, setImage] = useState('');
  const [cropData, setCropData] = useState("#");
  const [cropper, setCropper] = useState();
  const [imageSelected, setImageSelected] = useState(false);
  const [imageCropped, setImageCropped] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);

  const [Student, setStudent] = useState({});

  useEffect(() => {



    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      ID: UserID

    };
    setLoading(true);
    var api_config = {
      method: 'post',
      url: config.base_url + 'Student/GetStudentByID',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(api_config)
      .then(function (response) {
       //console.log(response.data.Student);
        if (response.data.status_code == 1) {
          setStudent(response.data.Student);
        }
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        toast.error('Network Error..', {
          position: toast.POSITION.TOP_RIGHT
        });
      });

  }, [UserID]);

  const DeleteImageSelected = () => {
    setImageSelected(false);
    setImageCropped(false)
  }

  const handleShowModal = () => setShowModal(true);

  const handleCloseModal = () => {
    setShowModal(false);
    setImageSelected(false);
    setLoading(false);
  }


  const onChange = (e) => {
    e.preventDefault();
    setImageSelected(true);
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  const getCropData = () => {
    setLoading(true);
    if (typeof cropper !== "undefined") {

      var cropData1 = cropper.getCroppedCanvas().toDataURL();
      setImageCropped(true);


      var cleanerBase64 = cropData1.substring(22);


      const data = {
        AccessKey: AccessKey,
        UserID: UserID,
        Img_File: cleanerBase64
      };



      var api_config = {
        method: 'post',
        url: config.base_url + 'Members/UpdatePhoto',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios(api_config)
        .then(function (response) {


          if (response.data.status_code == 1) {
            setIsUpdated(true);
            setImageSelected(false);
            localStorage.removeItem("Img_File");
            localStorage.setItem("Img_File", UserID + ".JPG");
            window.location.reload();
          }
          else {
            setIsUpdated(false);
          }

          setLoading(false);

        })
        .catch(function (error) {
         //console.log(error);
        });

    }
  };

  const renderButtons = () => {
    if (imageSelected === true) {
      return (
        <>
          <button className="button button-outline" onClick={handleCloseModal}>Close</button>
          <button className="button button-primary" disabled={loading} onClick={getCropData}>Save Photo {loading ? '...' : ''}</button>
        </>
      )
    }
    else if (imageSelected === false && isUpdated === true) {
      return (
        <>
          <button className="button button-outline" onClick={handleCloseModal}>Close</button>
        </>
      )
    }
  }


  return (
    <>
      {/* <StudentPanelMenu /> */}
      <ToastContainer />
      <StudentRightsCheck />
      <Modal show={showModal} onHide={handleCloseModal} className="bs-modal upload-img-model">
        <Modal.Header closeButton>
          <Modal.Title>Update Profile Photo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            isUpdated ?
              <>
                <div className="text-center">

                  <p>Photo Updated Successfully</p>
                </div>

              </>
              :
              <>
                <div className="upload-photo-wrap">
                  {
                    imageSelected ?
                      <Cropper

                        zoomTo={0.5}
                        // aspectRatio={8 / 8}
                        preview=".img-preview"
                        src={image}
                        dragMode="move"
                        viewMode={2}
                        minCropBoxHeight={10}
                        minCropBoxWidth={10}
                        background={false}
                        responsive={true}
                        autoCropArea={1}
                        checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                        onInitialized={(instance) => {
                          setCropper(instance);
                        }}
                        guides={true}
                      />
                      :
                      <div className="choose-photo">
                        <label htmlFor="ChoosePhoto">
                          <i className="ri-upload-cloud-line"></i>
                          <span>Browse Photo</span>
                          <input id="ChoosePhoto" accept="image/jpeg,image/png" type="file" onChange={onChange} />
                        </label>

                      </div>

                  }


                </div>
              </>

          }
         
          <div className="modal-footer">
            {renderButtons()}
          </div>
        </Modal.Body>
      </Modal>
      <div className="container body-content">
        <div className="page-head">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/student-panel/dashboard">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Student Profile
                    </li>
                  </ol>
                </nav>
                <div className="page-title">Student Profile</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container body-content">
        <div className="student-card">
          <div className="card">
            <div className="card-header">
              <div className="profile">
                <div className="profile_header">
                  <div className="profile_photo" onClick={handleShowModal}>
                    <img className="img-fluid" src={config.StudentImagePath + Student.Registration_No + '.jpg'} />
                    <div className="profile_edit">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125" />
                      </svg>

                    </div>
                  </div>
                </div>
              </div>
              <div className="user-name">
                <h1>{Student.Student_Name}</h1>
                <p>{Student.Father_Name}</p>
              </div>
              <b>Student ID: {Student.Student_ID}</b>
            </div>
            <div className="card-body">
              <div className="menu">
                <div className="outline">
                  <span>Wing:<b> {Student.SelectedClass?.SelectedWing?.Wing_Name}</b></span>
                </div>
                <div className="outline">
                  <span>Class:<b> {Student.SelectedClass?.Class_Name}</b></span>
                </div>
                <div className="outline">
                  <span>Section:<b> {Student.SelectedSection?.Section_Name}</b></span>
                </div>
              </div>
              <ul className="profile-list">
                <li>Registration No:</li>
                <li>{Student.Registration_No && Student.Registration_No.length !== 0 ? Student.Registration_No : "----------------------------"}</li>
                <li>Mobile No:</li>
                <li>{Student.Contact_Mobile && Student.Contact_Mobile.length !== 0 ? Student.Contact_Mobile : "----------------------------"}</li>
                <li>Status:</li>
                <li>{
                  Student.Status_Type_Id === 1 ? "Normal" :
                    Student.Status_Type_Id === 2 ? "Stucked Off" :
                      Student.Status_Type_Id === 3 ? "Left" :
                        Student.Status_Type_Id === 4 ? "Study Complete" :
                          Student.Status_Type_Id === 5 ? "SLC" :
                            Student.Status_Type_Id === 6 ? "Freezed" : "Not Enrolled"

                }</li>
                <li>Gender:</li>
                <li>{Student.Gender === 'male' ? (
                  <>Female</>
                ) : (
                  <>Male</>
                )}</li>
                <li>Bay Form No:</li>
                <li>{Student.Bay_Form_No && Student.Bay_Form_No.length !== 0 ? Student.Bay_Form_No : "----------------------------"}</li>
                <li>Date Of Birth:</li>
                <li>{moment(Student.Date_Of_Birth).format('DD/MM/YYYY')}</li>
                <li>Date of Joining:</li>
                <li>{moment(Student.Date_Of_Joining).format('DD/MM/YYYY')}</li>
              </ul>
              <div className="location shadow-sm">
                <i className="ri-map-pin-fill"></i>
                <p>{Student.Permanent_Address && Student.Permanent_Address.length !== 0 ? Student.Permanent_Address : "----------------------------"}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StudentUpdateProfile;
