import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SetupMenu from "./SetupMenu";
import moment from "moment";
import Pagination from "../General/Pagination";
import { scrollToTop } from "../Shared/ScrollOnTop";
import { Form, PageItem } from "react-bootstrap";

function ManageBankAccounts(props) {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [loading, setLoading] = useState(false);
  const [listOfLedger, setLedger] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);
  const [RouteCode, SetRouteCode] = useState("");
  const [AreaName, SetAreaName] = useState("");
  const [BusNo, SetBusNo] = useState("");

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [showFilters, setShowFilters] = useState(true);
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    document.title = "Manage Bus Routes";

    setIsUpdated(false);
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      pageNo: pageNo,
      pageSize: pageSize,
      Root_Code: RouteCode,
      Area_Name: AreaName,
      BusNumber: BusNo,
      SchoolID: 1,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Busroute/GetBusRoutes",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        setLedger(response.data.ListofRecords || []);
        setLoading(false);
        setTotalRecords(response.data.totalRecords || []);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, [pageNo, pageSize, isUpdated, BusNo, RouteCode, AreaName]);

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      const data = {
        AccessKey: AccessKey,
        UserID: UserID,
        MemberType: MemberType,
        pageNo: pageNo,
        pageSize: pageSize,
      };

      var api_config = {
        method: "post",
        url: config.base_url + "Busroute/GetBusRoutes",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(api_config)
        .then(function (response) {
          //console.log(response.data);
          setLedger(response.data.ListofRecords);
          setLoading(false);
          setTotalRecords(response.data.totalRecords);
        })
        .catch(function (error) {
          //console.log(error);
        });
    }

    setValidated(true);
  };

  const handlePageChange = (page) => {
    setPageNo(page);
  };

  const renderLedger = () => {
    if (
      listOfLedger &&
      listOfLedger.length != null &&
      listOfLedger.length > 0
    ) {
      return listOfLedger.map((item, index) => {
        return (
          <tr key={index}>
            <td>{item.Root_ID}</td>
            <td>{item.Root_Code}</td>
            <td>{item.Area_Name}</td>
            <td>{item.BusNumber}</td>
            <td>{item.Root_Details}</td>
            <td>{item.PickUp_Fee}</td>
            <td>{item.DropOf_Fee}</td>
            <td>{item.PickDrop_Fee}</td>
            <td>{item.NoofStudents}</td>
            <td>
              <ul
                className="inline-action"
                aria-labelledby="dropdownMenuButton1"
              >
                <li>
                  <NavLink
                    to={"/Setup/EditBusRoutes/" + item.Root_ID}
                    className=" green"
                  >
                    <i className="ri-edit-line"></i>
                  </NavLink>
                </li>
                <li>
                  <button
                    onClick={(e) => deleteBusRoute(item.Root_ID)}
                    className="red"
                  >
                    <i className="ri-delete-bin-line"></i>
                  </button>
                </li>
                <li>
                  <NavLink
                    to={`/Setup/PrintRoutewise-AttendenceSheet/?routeid=${item.Root_ID}&&routename=${item.Area_Name}`}
                    className=" green"
                  >
                    <i className="ri-map-pin-line"></i>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`/Setup/PrintBuswise-AttendenceSheet/?BusNumber=${item.BusNumber}`}
                    className=" blue"
                  >
                    <i className="ri-bus-line"></i>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`/Setup/PrintRoutewise-StudentList/?routeid=${item.Root_ID}&&routename=${item.Area_Name}`}
                    className="green"
                  >
                    <i className="ri-pages-line"></i>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`/Setup/PrintBuswise-StudentList/?BusNumber=${item.BusNumber}`}
                    className="blue"
                  >
                    <i className="ri-pages-line"></i>
                  </NavLink>
                </li>
              </ul>
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="9">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };
  const deleteBusRoute = (ID) => {
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      Root_ID: ID,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "/Busroute/DeleteBusRoute",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        if (response.data.status_code == 1) {
          setIsUpdated(true);
        }
        // setIsUpdated(false);
      })
      .catch(function (error) {
        //console.log(error);
      });
  };

  return (
    <>
      <SetupMenu />
      <ToastContainer />
      <div className="page-head">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/Dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    {" "}
                    <Link to="/Setup/SetupDashboard">Setup</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Manage Bus Routes
                  </li>
                </ol>
              </nav>
              <div className="page-title">Manage Bus Routes</div>
            </div>
            <div className="col-md-6">
              <div className="page-right-actions">
                <Link
                  to="/Setup/AddBusRoutes"
                  className="button button-white button-icon"
                >
                  Add Bus Route
                  <i className="ri-add-line"></i>
                </Link>

                <button
                  onClick={() => {
                    setShowFilters((prevIsActive) => !prevIsActive);
                  }}
                  className="button button-white button-icon"
                >
                  {showFilters ? "Hide Filters" : "Show Filters"}
                  <i className="ri-filter-3-line"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`collapse ${showFilters ? "show" : ""} `}
        id="collapseFilteres"
      >
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <div className="page-content">
            <div className="container">
              <div className="card mb-2">
                <div className="card-body p-md-4">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <label>Area Name:</label>
                        <input
                          type="text"
                          name="txtAreaName"
                          onChange={(e) => SetAreaName(e.target.value)}
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <label className="">Route Code:</label>
                        <input
                          type="text"
                          name="txtRouteCode"
                          onChange={(e) => SetRouteCode(e.target.value)}
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <label className="">Bus No:</label>
                        <input
                          type="text"
                          name="txtBusNo"
                          onChange={(e) => SetBusNo(e.target.value)}
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="col-md-2 offset-md-1">
                      <div className="form-group">
                        <button
                          type="submit"
                          value="Search"
                          onClick={handleSubmit}
                          className="button button-primary mt-4 w-100"
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>

      <div className="page-content">
        <div className="container">
          <div className="card">
            <div className="card-body">
              <table className="table table-theme table-hover">
                <thead>
                  <tr>
                    <th>Sr#</th>
                    <th>Route Code</th>
                    <th>Area Name</th>
                    <th>Bus Number</th>
                    <th>Route Details</th>
                    <th>PickUp Fee</th>
                    <th>DropOff Fee</th>
                    <th>Pick & Drop Fee</th>
                    <th>No Of Students</th>
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td className="text-center" colSpan="8">
                        <Spinner animation="border" role="status"></Spinner>
                      </td>
                    </tr>
                  ) : (
                    <>{renderLedger()}</>
                  )}
                </tbody>
              </table>
              {totalRecords > 10 && (
                <div className="pagination-wrap">
                  <div className="row align-items-center">
                    <div className="col-md-2 form_default">
                      <div className="d-flex align-items-center">
                        <label className="me-2" style={{ fontSize: "15px" }}>
                          Page Size
                        </label>
                        <div className="form-group">
                          <select
                            className="form-select"
                            style={{ width: "100px" }}
                            onChange={(e) => {
                              setPageNo(1);
                              setPageSize(parseInt(e.target.value));
                            }}
                          >
                            <option value="10" selected>
                              10
                            </option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <Pagination
                      pageNo={pageNo}
                      pageSize={pageSize}
                      totalRecords={totalRecords}
                      onPageChange={handlePageChange}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="card mt-md-3">
            <div className="card-body">
              <div className="row justify-content-end">
                <div className="col-md-2">
                  <NavLink
                    target="_blank"
                    to={`/Setup/print/BusRoutes?routename=${AreaName}&routecode=${RouteCode}&busno=${BusNo} `}
                    className="button button-primary w-100"
                  >
                    Print This Report
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ManageBankAccounts;
