import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import QRCode from "react-qr-code";
import StudentInfo from "../FeeSystem/StudentInfo";
import moment from "moment";
import PrintHeader from "../General/PrintHeader";
import Page from "../General/Page";
import { calculateColumnTotal, getMonthName } from "../General/Utility";
const StockItemsPrint = () => {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [loading, setLoading] = useState(false);
  const [listOfLedger, setLedger] = useState([]);
  const [ReportMonth, setReportMonth] = useState("");
  const [ReportYear, setReportYear] = useState("");
  const [Session, setSession] = useState("");
  const [Class, setClass] = useState("");
  const [Wing, setWing] = useState("");
  const [Section, setSection] = useState("");
  const [OrderBy, setOrderBy] = useState("");

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [TotalAmount, setTotalAmount] = useState(0);

  useEffect(() => {
    document.title = "AGS School Management System";

    setLoading(true);
    // Get the URL parameters
    const urlParams = new URLSearchParams(window.location.search);

    // Retrieve individual parameter values
    const Stock_Code = urlParams.get("Stock_Code");
    const Item_Name = urlParams.get("Item_Name");
    const Register_Type = urlParams.get("Register_Type");
    const OrderBy = urlParams.get("OrderBy");

    setReportYear(ReportYear);
    setReportMonth(ReportMonth);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      pageNo: 1,
      pageSize: 10000,
      Stock_Code: Stock_Code,
      Item_Name: Item_Name,
      Register_Type: Register_Type,
      OrderBy: OrderBy,
    };

    //console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "/Stock/GetStockItems",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    //console.log(data);
    axios(api_config)
      .then(function (response) {
        // console.log(response.data);
        setLedger(response.data.ListofRecords);
        setLoading(false);
        setTotalRecords(response.data.totalRecords);
        setTotalAmount(response.data.TotalAmount);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, []);

  const renderLedger = () => {
    if (listOfLedger.length > 0) {
      let count = 1;
      // let countbreak = 1;
      return listOfLedger.map((item, index) => {
        return (
          <tr key={index}>
            <td>{item.ID}</td>
            <td>{item.Stock_Code}</td>
            <td>{item.Item_Name}</td>
            <td>{item.Register_Type}</td>
            <td>{item.Current_Balance}</td>
            <td>{item.Price_Per_Unit}</td>
            <td>{item.Current_Balance * item.Price_Per_Unit}</td>
            <td>{item.Register_Ref}</td>
            <td>{item.CurrentBookValue}</td>
            <td>
              {item.DateOfPurchase == null
                ? " "
                : moment(item.DateOfPurchase).format("YYYY-MM-DD")}
            </td>
            <td>{item.DepreciationRate}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="20">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };

  return (
    <>
      <Page orientation="portrait" size="a4">
        <table className="table table-sm table-bordered table-print">
          <thead>
            <tr>
              <th colSpan={20}>
                <PrintHeader title={`STOCK ITEM DETAILS :`} />
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td className="text-center" colSpan="8">
                  <Spinner animation="border" role="status"></Spinner>
                </td>
              </tr>
            ) : (
              <>
                <tr>
                  <th>ID.</th>
                  <th>Stock Code</th>
                  <th style={{ width: "140px" }}>Item Name</th>
                  <th>Register Type</th>
                  <th>Current Balance</th>
                  <th>Price Per Unit</th>
                  <th>Stock Value</th>
                  <th>Register Ref</th>
                  <th>Current Book Value</th>
                  <th>Date Of Purchase</th>
                  <th>Depreciation Rate</th>
                </tr>
                {renderLedger()}
                <tr>
                  <th colSpan={5}></th>
                  <th>Total:</th>
                  <th colSpan={2}>
                    {calculateColumnTotal(listOfLedger, "Price_Per_Unit")}
                  </th>
                  <th colSpan={3}>
                    {calculateColumnTotal(listOfLedger, "CurrentBookValue")}
                  </th>
                </tr>
              </>
            )}
          </tbody>
        </table>
      </Page>
    </>
  );
};

export default StockItemsPrint;
